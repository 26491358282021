export default (props) => {
    return (
        <div className={"row"}>
            <div className="col-12 pagination-panel-top">
                <select value={props.pageData.pageSize} onChange={(event) => {
                    event.preventDefault();
                    props.updatePageSize(event.target.value);
                }}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                <span> / Page</span>
            </div>
        </div>
    )
}
