import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "../../common/TextField";
import DropDown from "../../common/TextField/dropDown";
import { updateSpeciality } from "./action";
import { resumeBuilderSelector } from "./selector";
import { profileBuilderSelector } from "../selector";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const dispatch = useDispatch();
  const { specialities, masterDatas } = useSelector(resumeBuilderSelector);
  const { profile_builder_flags } = useSelector(profileBuilderSelector);
  const [skills, setSkills] = useState(specialities);
  const [userInput, setUserInput] = useState("");
  const [filteredSpecialities, setFilteredSpecialities] = useState([]);
  const [isSpecialitiesUlVisble, setIsSpecialitiesUlVisble] = useState(false);
  const specialitySelect = useRef(null);
  const [speciality, setSpeciality] = useState([]);
  const multiselectRef = useRef();

  const updateVal = JSON.parse(
    JSON.stringify(skills)
  );
  updateVal?.forEach((item) => {
    if (item.skill === 290) {
      item.years = null
    } else if (item.skill === 1023){
      item.years = 0
    }
  })

  useEffect(() => {
    dispatch(updateSpeciality(updateVal));
  }, [skills]);

  useEffect(() => {
    const spec = [];
    masterDatas.specialities.map((item) => {
      specialities.map((item2) => {
        if (item.id === item2.skill) {
          spec.push(item);
        }
      });
    });
    setSpeciality(spec);
  }, [specialities]);

  useEffect(() => {
    setFilteredSpecialities(masterDatas.specialities);
  }, [masterDatas.specialities]);

  useEffect(() => {
    document.addEventListener("click", specialityOutside, false);
    return () => {
      document.removeEventListener("click", specialityOutside, false);
    };
  }, [isSpecialitiesUlVisble]);

  const specialityOutside = (event) => {
    const parent1 = document.querySelectorAll(
      ".specialties-select .displayNone"
    );
    if (
      specialitySelect.current &&
      !specialitySelect.current.contains(event.target)
    ) {
      if (
        isSpecialitiesUlVisble &&
        parent1[0]?.style?.display !== "none" &&
        event.target.id !== "cerification"
      ) {
        const parent = document.querySelectorAll(
          ".specialties-select .optionListContainer"
        );
        parent[0].classList.remove("displayBlock");
        // parent2[0].classList.remove('selected');
        parent[0].classList.add("displayNone");
        setIsSpecialitiesUlVisble(false);
      }
    }
  };

  const updateSkill = (e, index) => {
    const { value, name } = e.target;
    const newSkills = specialities.map((skill) => ({ 
      ...skill
    }));
    newSkills[index][name] = value.replace(/\D/g, "");
    setSkills(newSkills);
  };

  const updateOtherSkillValue = (e, index) => {
    const { value, name } = e.target;
    const newSkills = specialities.map((skill) => ({ ...skill }));
    newSkills[index][name] = value;
    newSkills[index]["isOther"] = true;
    setSkills(newSkills);
  };
  const addSkill = (isOther = false) => {
    const newSkills = [...specialities];
    newSkills.push({
      skill: "",
      years: "",
      isOther: isOther,
    });
    setSkills(newSkills);
  };

  const addOtherSkill = (event) => {
    event.preventDefault();
    addSkill(true);
  };

  const removeRow = (index) => {
    const newSkills = [...specialities];
    newSkills.splice(index, 1);
    if (newSkills.length === 0) {
      multiselectRef.current.resetSelectedValues();
    }
    setSkills(newSkills);
  };

  const searchSpecialities = () => {
    if (userInput) {
      const parent = document.querySelectorAll(
        ".specialties-select .optionListContainer"
      );
      parent[0].classList.remove("displayNone");
      parent[0].classList.add("displayBlock");
      let filteredData = masterDatas.specialities.filter(
        (suggestion) =>
          suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      // filteredData = [...filteredData, ...certifications.primary]
      setFilteredSpecialities(filteredData);
      setIsSpecialitiesUlVisble(true);
    }
  };

  const clearSpecialities = () => {
    setUserInput("");
    setFilteredSpecialities(masterDatas.specialities);
  };

  const updateSpecValue = (target) => {
    const { value } = target;
    if (target.action === "add") {
      const newSkills = [...specialities];
      newSkills.push({
        skill: value.id,
        years: "",
        isOther: false,
      });
      setSkills(newSkills);
    } else if (target.action === "remove") {
      let newSkills = [...specialities];
      newSkills = newSkills.filter((item) => item.skill !== value.id);
      setSkills(newSkills);
    }
  };

  const getSpecialityName = (id) => {
    if (id) {
      const specialitiesName = masterDatas.specialities.filter(
        (item) => item.id === id
      )[0];
      return specialitiesName?.name;
    } else {
      return "";
    }
  };

  const errors = props.errors ? props.errors["specialities"] : [];
  return (
    <div id="DivFor_YSpecialties">
      <div id="largemodal_YSpecialties_1">
        <div id="largemodal_YSpecialties_2">
          <div id="largemodal_YSpecialties_3">
            <div className="card">
              <div className="col-lg-12 col-md-12">
                <div className="row">
                  <div className="card-header" style={{ display: "block" }}>
                    <div className="col-lg-12 col-md-12">
                      <h4>Medical Specialties*</h4>
                    </div>
                    <div className="ms-3" style={{ color: "#13aeeb" }}>
                      Use the search Specialties text box and enter a keyword to
                      search for Specialties containing it or, use the select
                      dropdown box to see all Specialties in alphabetical order.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
              <div style={{border:"none"}} className="form-control border-none">
                 <div style={{gap:"1rem"}} className="d-flex flex-sm-row flex-column mb-5">
                  <input
                    type="text"
                    className="form-control me-2"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Search Medical Specialties"
                  />
                  {/* <span className="me-2">Loadding...</span> */}
                  <div style={{rowGap:"0.5rem"}} className="d-flex align-items-center">
                    <button
                      className="btn btn-primary sw-btn-next me-2"
                      type="button"
                      id="cerification"
                      onClick={searchSpecialities}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-primary sw-btn-next"
                      type="button"
                      onClick={clearSpecialities}
                    >
                      Clear
                    </button>
                </div>
                </div>
                </div>
                <span className="specialties-select" ref={specialitySelect}>
                  <DropDown
                    name={"specialties"}
                    label={"Select all that apply*"}
                    labelClassName="fw-bold"
                    id={"specialties"}
                    options={filteredSpecialities}
                    multiselectRef={multiselectRef}
                    multiple={true}
                    value={speciality}
                    updateValue={updateSpecValue}
                    isObject={true}
                    divClass={"col-lg-12 col-md-12"}
                    errors={
                      props.errors && props.errors.mustspecialities
                        ? props.errors["mustspecialities"]
                        : []
                    }
                  />
                </span>
                {specialities.length > 0 &&
                  specialities.map((skill, index) => (
                    <div className="row" id="displ" hidden={skill.isOther}>
                      <div
                        className="col-lg-12 col-md-12"
                        id="divSpecialClone1"
                      >
                        <div style={{gap:"1rem"}} className="row ms-1" id="">
                          <div className="col-sm">
                            <div>Specialties Name{profile_builder_flags?.specialties_year_of_experience?.isRequired ? '*' : '' }</div>
                            <div>
                              <b>{getSpecialityName(skill.skill)}</b>
                            </div>
                          </div>
                          { skill?.skill === 290 ? 
                          <div className="col-sm">
                          </div> 
                          :  
                            <div className="col-sm"  >
                            <div style={{display : skill?.skill === 1023 && 'none' }}>Years of Experience{profile_builder_flags?.specialties_year_of_experience?.isRequired ? '*' : '' }</div>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputname"
                              placeholder="Years of Experience"
                              name={"years"}
                              onChange={(event) => updateSkill(event, index)}
                              value={skill.years}
                              maxlength="2"
                              style={{display : skill?.skill === 1023 && 'none' }}
                            />
                            {errors && errors[index] && (
                              <div className="invalid-input">
                                {errors[index]["year"]}
                              </div>
                            )}
                          </div>
                        }
                          <div className=" col-sm">
                            {
                              <a
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  removeRow(index);
                                }}
                              >
                                <i className="fe fe-trash-2 "></i>
                              </a>
                            }
                          </div>

                          <div className="row">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="row" id="displ" style={{ display: "none" }}>
                  <div className="row">
                    <div className="col-lg-3 col-md-3">
                      <a
                        id="btnAddSpl"
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          addSkill();
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-1 col-md-3">
                            <i
                              className="icon-2x icon-plus"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="icon-plus"
                              aria-label="Add Specialties"
                            ></i>
                          </div>
                          <div className="col-lg-6 col-md-3">Add New</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ display: "none" }}>
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <h4>Other Specialities </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ display: "none" }}>
                <div className="col-lg-12 col-md-12">
                  {specialities.map((skill, index) => (
                    <div className="row" hidden={!skill.isOther}>
                      <div
                        className="col-lg-12 col-md-12"
                        id="divcloneSpecialities"
                      >
                       
                          <div className="col-sm">
                            <input
                              type="text"
                              value={skill.otherName}
                              className="form-control"
                              id="otherspecialityname"
                              placeholder="List other specialities"
                              name={"otherName"}
                              onChange={(event) =>
                                updateOtherSkillValue(event, index)
                              }
                            />
                          </div>
                           
                          <div className="col-sm">
                             <input
                              type="text"
                              className="form-control"
                              id="exampleInputname"
                              placeholder="Years of Experience"
                              value={skill.years}
                              name={"years"}
                              onChange={(event) =>
                                updateOtherSkillValue(event, index)
                              }
                              maxlength="2"
                            />

                          <div className="col-sm">
                            <a
                              onClick={(event) => {
                                event.preventDefault();
                                removeRow(index);
                              }}
                            >
                              <i className="fe fe-trash-2"></i>
                            </a>
                          </div>
                          <div className="row">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {errors &&
                    errors
                      .filter((item) => item.type === 1)
                      .map((error) => (
                        <div className="invalid-input">{error.message}</div>
                      ))}
                  <div className="row">
                    <div className="col-lg-3 col-md-3">
                      <a
                        id="btnAddothCertifications"
                        href="#"
                        onClick={addOtherSkill}
                      >
                        <div className="row">
                          <div className="col-lg-1 col-md-3">
                            <i
                              className="icon-2x icon-plus"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="icon-plus"
                              aria-label="Add References"
                              style={{ "font-size": "20px" }}
                            ></i>
                          </div>
                          <div className="col-lg-6 col-md-3">Add New</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
