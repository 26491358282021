import DropDown from "../../common/TextField/dropDown";
import {useDispatch, useSelector} from "react-redux";
import {preferenceSelector} from "./selector";
import {updateTravelDistance} from "./action";

export default (props) => {
    const dispatch = useDispatch();
    const {travelDistance, masterDatas} = useSelector(preferenceSelector);
    const setTravelDistance = (target) => {
        const value = target.value;
        dispatch(updateTravelDistance(value));
    };

    return (
        <div id="DivFor_Distance">
            <div id="largemodal_Distance_1">
                <div id="largemodal_Distance_2">
                    <div id="largemodal_Distance_3">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Travel Distance*</h4>
                            </div>
                            <br/>
                            <div className="card-body">
                                <DropDown name={"travelDistance"}
                                          label={"Preferred Travel Distance to Employers One Way*"}
                                          options={masterDatas.travelDistance} value={[travelDistance]}
                                          id={"travelDistance"}
                                          updateValue={setTravelDistance} isObject={true} multiple={false}
                                          errors={props.errors ? props.errors['travelDistance'] : []}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
