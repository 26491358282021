import { useSelector } from "react-redux";
import useConfiguration from "./useConfiguration";
import { profileBuilderSelector } from "../components/ProfileBuilder/selector";
import {tenantIdList } from "../helpers/enum";

function useValidation() {
  const { profile_builder_flags } = useSelector(profileBuilderSelector);

  const config = useConfiguration();
  const login = JSON.parse(sessionStorage.getItem('login'));
  const tenantId = login?.tenantId;
  let data = config.validations ? config.validations : [];

  const itemsToChange = [
    {
      path: ["personalInfo", "spokenLanguagesList", "required"],
      value: profile_builder_flags?.spoken_Languages?.isRequired ?? true,
    },
    {
      path: ["emergencyContact", "firstName", "required"],
      value: profile_builder_flags?.emergency_contact?.isRequired ?? true,
    },
    {
      path: ["emergencyContact", "lastName", "required"],
      value: profile_builder_flags?.emergency_contact?.isRequired ?? true,
    },
    {
      path: ["emergencyContact", "phoneNumber", "required"],
      value: profile_builder_flags?.emergency_contact?.isRequired ?? true,
    },
    {
      path: ["emergencyContact", "relationship", "required"],
      value: profile_builder_flags?.emergency_contact?.isRequired ?? true,
    },
    {
      path: ["references", "firstName", "required"],
      value: profile_builder_flags?.references?.isRequired ?? true,
    },
    {
      path: ["references", "lastName", "required"],
      value: profile_builder_flags?.references?.isRequired ?? true,
    },
    {
      path: ["references", "phoneNumber", "required"],
      value: profile_builder_flags?.references?.isRequired ?? true,
    },
    {
      path: ["preferences", "vaccination", "required"],
      value: profile_builder_flags?.covid_19?.isRequired ?? true,
    },
    {
      path: ["workHistory", "employer", "required"],
      value: profile_builder_flags?.work_history?.isRequired ?? true,
    },
    {
      path: ["workHistory", "fromMonth", "required"],
      value: profile_builder_flags?.work_history?.isRequired ?? true,
    },
    {
      path: ["workHistory", "fromYear", "required"],
      value: profile_builder_flags?.work_history?.isRequired ?? true,
    },
    {
      path: ["workHistory", "toMonth", "required"],
      value: profile_builder_flags?.work_history?.isRequired ?? true,
    },
    {
      path: ["workHistory", "toYear", "required"],
      value: profile_builder_flags?.work_history?.isRequired ?? true,
    },
    {
      path: ["education", "name", "required"],
      value: profile_builder_flags?.education?.isRequired ?? true,
    },
    {
      path: ["education", "fromMonth", "required"],
      value: profile_builder_flags?.education?.isRequired ?? true,
    },
    {
      path: ["education", "fromYear", "required"],
      value: profile_builder_flags?.education?.isRequired ?? true,
    },
    {
      path: ["education", "toMonth", "required"],
      value: profile_builder_flags?.education?.isRequired ?? true,
    },
    {
      path: ["education", "toYear", "required"],
      value: profile_builder_flags?.education?.isRequired ?? true,
    },
  ];

  itemsToChange.forEach((item) => {
    let currentItem = data;
    const { path, value } = item;
    for (let i = 0; i < path.length - 1; i++) {
      if (currentItem[path[i]] === undefined) {
        currentItem[path[i]] = {};
      }
      currentItem = currentItem[path[i]];
    }
    currentItem[path[path.length - 1]] = value;
  });

  function validate(formName, values) {
    const validationResult = {
      valid: true,
      errors: {},
    };
    const isEmptyObject = (value) => {
      return typeof value === "object" && Object.keys(value).length === 0;
    };
    const isEmpty = (value) => {
      return (
        value === undefined ||
        value === null ||
        (value.trim && value.trim()) === "" ||
        isEmptyObject(value)
      );
    };
    if (!config.validations) {
      return validationResult;
    }
    const validationConfig = config.validations[formName];

    values.map((value) => {
      const validationObj = validationConfig[value.name] || {};
      validationResult.errors[value.name] = [];
      if (
        validationObj.required &&
        (isEmpty(value.data) || value.data.length === 0)
      ) {
        validationResult.errors[value.name].push("This field is required");
        validationResult.valid = false;
      }
      if(validationObj?.requiredOnTenants && (isEmpty(value.data) || value.data.length === 0)){
        const notRequiredTenants = [tenantIdList.nursesEtc,tenantIdList.fiveStar];
        if(notRequiredTenants?.indexOf(+tenantId) === -1){
          validationResult.errors[value.name].push("This field is required");
          validationResult.valid = false;
        }
      }
      if (
        validationObj?.removedHypenMin &&
        value.data &&
        value?.data?.replace(/-/g, '')?.length < validationObj.removedHypenMin && validationObj?.requiredOnTenants
      ) {
        const notRequiredTenants = [tenantIdList.nursesEtc,tenantIdList.fiveStar];
        if(notRequiredTenants?.indexOf(+tenantId) === -1){
          validationResult.errors[value.name].push(
            `At least ${validationObj.removedHypenMin} characters long`
          );
          validationResult.valid = false;
        }
      }
      if (
        validationObj.min &&
        value.data &&
        value.data.length < validationObj.min
      ) {
        validationResult.errors[value.name].push(
          `At least ${validationObj.min} characters long`
        );
        validationResult.valid = false;
      }
      if (
        validationObj.max &&
        value.data &&
        value.data.length > validationObj.max
      ) {
        validationResult.errors[value.name].push(
          `At most ${validationObj.max} characters long`
        );
        validationResult.valid = false;
      }
      if (validationObj.pattern && value.data) {
        const regex = new RegExp(validationObj.pattern);
        if (!regex.test(value.data)) {
          validationResult.errors[value.name].push(
            validationObj.message || `This field has invalid pattern`
          );
          validationResult.valid = false;
        }
      }
    });
    return validationResult;
  }

  return validate;
}

export default useValidation;
