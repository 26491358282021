import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useConfiguration from '../../hooks/useConfiguration';
import useSessionStorage from '../../hooks/useSessionStorage';
import Footer from "../common/Footer";
import Header from "../common/Header";
import Loader from "../common/Loader";
import Sidebar from "../common/Sidebar";
import DateField from '../common/TextField/dateTime';
import DropDown from "../common/TextField/dropDown";
import AccordianTable from "./AccordianTable";
import { getMessagesData, updateParams } from './action';
import { messaageSelector } from './selector';
import { updateFilterParams } from "./slice";



// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { data, params, masterData } = useSelector(messaageSelector);
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    const [showFilter, setShowFilter] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form, setForm] = useState(params);

    useEffect(() => {
        if (config.baseUrl) {
            if (!login) {
                navigate("/");
            } else {
                const newparams = {
                    ...params,
                    candidateUniqueId: login.candidateUniqueId
                }
                dispatch(getMessagesData({
                    url: `${config.baseUrl}/Messages/GetMessageListtoGrid`, params: newparams
                }));
            }
        }
    }, [config])

    useEffect(() => {
        document.title = 'Messages';
    }, [])

    useEffect(() => {
        const newparams = {
            ...form,
            'employerUniqueId': login.employerUniqueId,
        }
        dispatch(updateParams(newparams));
    }, [form]);

    const updateDate = target => {
        const {name,value} = target;
        dispatch(updateFilterParams({name,value}))
    }
    const updateMulSeleDrop = (target) => {

    }
    const updateDropVal = target => {
        const {name,value} = target;
        dispatch(updateFilterParams({name,value:parseInt(value)}))
    }

    const filterData = () => {
        const newparams = {
            ...params,
            candidateUniqueId: login.candidateUniqueId
        }
        if(params?.fromDateRange ||params?.toDateRange ||(typeof params?.messageStatus === "number")){
        dispatch(getMessagesData({
            url: `${config.baseUrl}/Messages/GetMessageListtoGrid`, params: newparams
        }));
        }
    }
    const clearFilter = () => {
        if(params?.fromDateRange ||params?.toDateRange ||(typeof params?.messageStatus === "number")){
            const newparams = {
                ...params,
                messageStatus:null,
                fromDateRange:null,
                toDateRange:null,
                candidateUniqueId: login.candidateUniqueId
            }
            dispatch(getMessagesData({
                url: `${config.baseUrl}/Messages/GetMessageListtoGrid`, params: newparams
            })).then(() => dispatch(updateParams(newparams)));
        }
    }
    return (
        <div className="page">
            <div className="page-main">
                <Loader />
                <Header />
                <Sidebar />
                <div className="main-content app-content mt-0 main-background">
                    <div className="side-app">
                        <div className="main-container container-fluid">
                            <div className="page-header">
                                <h1 className="page-title">My Notifications </h1>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Search Notifications</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="input-group mb-3 col-lg-6 col-md-4">
                                                    <input type="text" className="form-control" placeholder="Search" />
                                                    <button className="btn btn-primary" type="submit"><i className="fe fe-search"></i></button>
                                                </div>
                                                <div className="col-lg-6 col-md-4">
                                                    <button className="btn btn-primary" onClick={() => setShowFilter(!showFilter)}><i className="bi bi-filter fa-lg"></i>Filter</button>
                                                </div>
                                            </div>
                                            <br />
                                            {showFilter && <>
                                                <div className="row">
                                                    {/* <DropDown name={"Location"} label={'loction'}
                                                        placeholder={"Select Location Name"} updateValue={updateMulSeleDrop}
                                                        value={''}
                                                        options={[]} showArrow={true} isObject={true} multiple={true}
                                                        errors={''} divClass={'col-lg-3 col-md-3'} /> */}
                                                    <DateField name={"fromDateRange"}
                                                        divClass="col-lg-3 col-md-3"
                                                        label={"Received Date Range From"}
                                                        id={"fromDateRange"}
                                                        value={params?.fromDateRange ? params?.fromDateRange:""}
                                                        updateValue={updateDate}
                                                        includeTime={false} />
                                                    <DateField name={"toDateRange"}
                                                        divClass="col-lg-3 col-md-3"
                                                        label={"Received Date Range To"}
                                                        id={"toDateRange"}
                                                        value={params?.toDateRange ? params?.toDateRange:""}
                                                        updateValue={updateDate}
                                                        includeTime={false} />
                                                    <div className='col-lg-3 col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor={'messageStatus'}>Read/Unread</label>
                                                            <select className="form-select"
                                                                data-placeholder="messageStatus"
                                                                value={typeof params?.messageStatus === "number" ? params?.messageStatus :""}
                                                                name={"messageStatus"}
                                                                onChange={(e) => updateDropVal(e.target)}
                                                            >
                                                                <option value={""}>--Select--</option>
                                                                {
                                                                masterData?.readList.map((option) => (
                                                                    <option value={option.value}>{option.name}</option>
                                                                ))
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-3' style={{marginTop: "28px"}}>
                                                        <button className="btn btn-primary me-3" onClick={filterData}>Apply</button>
                                                        <button className="btn btn-primary" onClick={clearFilter}>Clear Filter</button>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                    <AccordianTable messageData={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}