import Loader from "../common/Loader";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../Login/action";
import PageHeader from "../ProfileBuilder/pageHeader";
import Registration from "../Summary/registration";
import { useSelector, useDispatch } from "react-redux";
import { personalInfoSelector } from "../ProfileBuilder/PersonalInfo/selector";
import { getCurrentPersonalInfo, loadMasterData } from "../ProfileBuilder/PersonalInfo/action";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import Modal from "../Summary/modal";
import EditEmail from "./editEmail";
import useValidation from "../../hooks/useValidation";
import './account.css';
import requestUtil from "../../helpers/requestUtil";
import { setLoadingPageFalse, setLoadingPageTrue } from "../common/Loader/action";
import Error from "../common/Message/error";
import { getFileName, getFilePath } from "../../utils/service";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [changeEmail, setChangeEmail] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [registrationErrors, setRegistrationErrors] = useState({});
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const config = useConfiguration();
    const storage = useSessionStorage();
    const dispatch = useDispatch();
    const validate = useValidation();
    const login = storage.getItem("login");
    const { data } = useSelector(personalInfoSelector);
    const imageUpload = useRef(null);
    const [profile, setProfile] = useState('')
    const navigate = useNavigate();
    const fileName = getFileName(login?.imageUrl);
    const filePath = getFilePath(login?.imageUrl);
    const loadPersonalInfo = () => dispatch(getCurrentPersonalInfo({
        url: `${config.baseUrl}/Candidates/getpersonalinfo`, userId: login.candidateId
    }));
    const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
    useEffect(() => {
        document.title = "Overture Account";
        if (config.baseUrl && !data.candidateId) {
            dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
                loadPersonalInfo();
            })
        }
    }, [config]);
    const deactivateAccount = (event) => {
        event.preventDefault();
        dispatch(setLoadingPageTrue());
        requestUtil(`${config.baseUrl}/Candidates/deactivate/${login.candidateId}`, 'POST').then(response => {
            dispatch(logOut()).then(() => {
                dispatch(setLoadingPageFalse());
                navigate("/")
                window.location.reload();
            }
            );
        }).catch(error => {
            dispatch(setLoadingPageFalse());
            setErrorMessage(error.message);
        });
    }
    const deleteAccount = (event) => {
        event.preventDefault();
        dispatch(setLoadingPageTrue());
        requestUtil(`${config.baseUrl}/Candidates/deleteaccount/${login.candidateId}`, 'POST').then(response => {
            dispatch(logOut()).then(() => {
                dispatch(setLoadingPageFalse());
                navigate("/");
                window.location.reload();
            }
            );
        }).catch(error => {
            dispatch(setLoadingPageFalse());
            setErrorMessage(error.message);
        });
    }
    const editEmail = (event) => {
        event.preventDefault();
        setChangeEmail(!changeEmail);
        setNewEmail('');
        setErrorMessage('');
        setMessage('');
    }
    const updateEmail = (target) => {
        setNewEmail(target.value);
    }
    const validateEmail = () => {
        const result = validate("updateEmail", [{ 'name': 'email', data: newEmail }]);
        if (result.valid) {
            setFormErrors({});
        } else {
            setFormErrors(result.errors);
        }
        if (newEmail === data.email) {
            setFormErrors({ "email": ['New email should not be same as old email'] });
            return false;
        }
        return result.valid;
    }
    const saveEmail = (event) => {
        event.preventDefault();
        if (validateEmail()) {
            dispatch(setLoadingPageTrue());
            requestUtil(`${config.baseUrl}/Candidates/updateemailid`, "POST", {
                candidateId: login.candidateId,
                oldEmailId: login.email,
                newEmailId: newEmail
            }).then(response => {
                setMessage(response.message);
                requestUtil(`${config.baseUrl}/Candidates/sendmail-for-changeemailid`, "POST", {
                    candidateId: login.candidateId,
                    oldEmailId: login.email,
                    newEmailId: newEmail,
                    sourceWebsite: window.location.hostname
                }).then(response => {
                    dispatch(getCurrentPersonalInfo({
                        url: `${config.baseUrl}/Candidates/getpersonalinfo`, userId: login.candidateId
                    })).then(() => {
                        dispatch(setLoadingPageFalse());
                    });
                }).catch(error => {
                    setErrorMessage(error.message || "Failed to send email");
                    dispatch(setLoadingPageFalse());
                })
            }).catch(error => {
                setErrorMessage(error.message || "Failed to update email");
                dispatch(setLoadingPageFalse());
            });
        }
    }
    const validatePersonalInfo = () => {
        const keys = Object.keys(data);
        const registrationResult = validate("personalInfo", keys.map(key => {
            return {
                name: key, data: data[key]
            }
        }));
        if (registrationResult.valid) {
            setRegistrationErrors({});
        } else {
            setRegistrationErrors(registrationResult.errors);
        }
        return registrationResult.valid;
    }
    const savePersonalInfo = () => {
        if (validatePersonalInfo()) {
            dispatch(setLoadingPageTrue());
            requestUtil(`${config.baseUrl}/Candidates/savemyaccountinfo`, "POST", {
                candidateId: login.candidateId,
                ...data
            }).then(response => {
                setMessage(response.message);
                setLoadingPageFalse();
                loadPersonalInfo();
                dispatch(setLoadingPageFalse());
            }).catch(error => {
                setErrorMessage(error.message || "Failed to update personal info");
                dispatch(setLoadingPageFalse());
            });
            return true;
        }
    }
    const cancelEdit = () => {
        loadPersonalInfo();
    }
    const saveProfileImage = async (event) => {
        const file = event.target.files[0];
        const formdata = new FormData();
        formdata.append('file', file);
        try {
            dispatch(setLoadingPageTrue());
            const response = await  requestUtil(`${config.baseUrl}/Candidates/profile/imageupload?appUserId=${login.appUserId}`,'POST', formdata, null, true);
            if(response){
                storage.setItem('login', JSON.stringify({ ...login, imageUrl: response}))
                loadPersonalInfo();
            }
        } catch (error) {
            setErrorMessage(error.message || "Failed to upload profile image");
        } finally {
            dispatch(setLoadingPageFalse());
        }
    }
    useEffect(() => {
        if (filePath && fileName) {
            setProfile(`${filePath}${fileName}`)
        }
    }, [filePath, fileName])
    return (
        <div className="page">
            <div className="page-main">
                <Loader />
                {!mobileAuth && <Header profile={profile} />}
                {!mobileAuth && (login.employerOvertureStatus !== 0 ? <Sidebar /> : login?.profileFlag.isProfileSubmitted && <Sidebar />)}
                <div className={mobileAuth ? 'main-content mt-0 main-background' : login.employerOvertureStatus !== 0 ? 'main-content app-content mt-0 main-background' : !login?.profileFlag.isProfileSubmitted ? 'main-content mt-0 main-background' : 'main-content app-content mt-0 main-background'}>
                    <div className="side-app">
                        <div className={mobileAuth ? "main-container container" : login.employerOvertureStatus !== 0 ? 'main-container container-fluid' : !login?.profileFlag.isProfileSubmitted ? 'main-container container' : 'main-container container-fluid'}>
                            <PageHeader previous={"Dashboard"} current={"Profile Builder"} />
                            <div className="row">
                                {
                                    errorMessage && !changeEmail ?
                                        <Error errorMessage={errorMessage} />
                                        : null
                                }
                                <div className="col-xl-12">
                                    <div className="card">
                                        {
                                            changeEmail &&
                                            <Modal label={"Email Update"} showHide={editEmail} size={"md"} saveFunction={saveEmail}>
                                                <EditEmail updateValue={updateEmail} newEmail={newEmail} errors={formErrors['email']}
                                                    errorMessage={errorMessage} message={message} />
                                            </Modal>
                                        }
                                        <div className="row p-2 mt-2">
                                            <div className="col-xl-8">
                                                <Registration title={"My Info"} {...data} errors={registrationErrors}
                                                    saveContinue={savePersonalInfo} cancel={cancelEdit} />
                                                {/*
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title">Deactivate Account</div>
                                                </div>
                                                <div className="card-body">
                                                    <p>When you de-activate you can come back anytime to re-activate</p>
                                                    <label className="custom-control custom-checkbox mb-0">
                                                    </label>
                                                </div>
                                                <div className="card-footer text-end">
                                                    <a href="#" onClick={deactivateAccount}
                                                       className="btn btn-primary my-1">Deactivate</a>&nbsp;
                                                    <a href="javascript:void(0)" className="btn btn-danger my-1">Cancel</a>
                                                </div>
                                            </div>
                                            */}
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <div className="card-title">Edit Contact</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="text-center">
                                                            <input type={'file'} style={{ display: 'none' }} ref={imageUpload} onChange={saveProfileImage} />
                                                            <div className="">
                                                                <a className="" href="#" onClick={(event => {
                                                                    event.preventDefault();
                                                                    imageUpload.current.click();
                                                                    return false;
                                                                })}>
                                                                    <div>
                                                                        {(filePath && fileName)?
                                                                            <img src={profile ? profile : `${filePath}${fileName}`}
                                                                                className="rounded-circle" height={267} width={260} />
                                                                            :
                                                                            <img src="assets/images/users/nurseuserm.jpg" alt="profile-user" className="avatar  profile-user brround cover-image" />
                                                                        }
                                                                    </div>
                                                                    {/*<img alt="avatar" src={`${login.profileImagePath}/${login.profileImageName}`} className="brround"/>*/}
                                                                </a>
                                                            </div>

                                                        </div>
                                                        <div className="main-chat-msg-name text-center mt-3">
                                                            <a href="#" onClick={(event => {
                                                                event.preventDefault();
                                                            })}>
                                                                <h5 className="mb-1 text-dark fw-semibold">{`${data.firstName} ${data.lastName}`}</h5>
                                                            </a>
                                                            <p className="text-muted mt-0 mb-0 pt-0 fs-13">{login && login?.jobCategory}</p>
                                                        </div>
                                                        <div className="card-body no-padding">
                                                            <div className="social social-profile-buttons d-lg-flex gap-2">
                                                                <a className="social-icon text-primary" ><i className="fe fe-mail"></i></a>

                                                                <span className="my-auto">{data.email}</span>
                                                                <button id="bAcep" type="button" className="btn  btn-sm"
                                                                    data-bs-toggle="modal" data-bs-target="#modaldemo8"
                                                                    title="Edit email" onClick={editEmail}>
                                                                    <i className="fe fe-edit"></i>
                                                                </button>

                                                            </div>
                                                            <div className="social social-profile-buttons">
                                                                <a className="social-icon text-primary" ><i
                                                                    className="fe fe-phone"></i></a>
                                                                <span className="my-auto">{data.cellPhoneNumber}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { /*<div className="card-footer text-end">
                                                    <a href="javascript:void(0)" className="btn btn-primary my-1">Update</a>&nbsp;
                                                    <a href="javascript:void(0)" className="btn btn-danger my-1">Cancel</a>
                                                        </div>*/}
                                                </div>
                                            </div>
                                            {/*
                                        <div className="col-xl-8">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title">Delete Account</div>
                                                </div>
                                                <div className="card-body">
                                                    <p>when you delete account, you will lose all your profile and
                                                        associated
                                                        information</p>
                                                    <label className="custom-control custom-checkbox mb-0">
                                                    </label>
                                                </div>
                                                <div className="card-footer text-end">
                                                    <a href="#" className="btn btn-primary my-1" onClick={deleteAccount}>Delete
                                                        Account</a>&nbsp;
                                                    <a href="javascript:void(0)" className="btn btn-danger my-1">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                                        */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
