import {setLoading} from "../common/Loader/slice";
import {setPage, ratingLoadingFailed, updateRatings, updatePageSize, setSorting, updateSelectedJob,setRatings} from "./slice";
import requestUtil from "../../helpers/requestUtil";

const createLoadRequest = (page, pageSize, candidateId, JobUniqueId,LocationId,ShowDynamicData,sortOrder, sortColumn) => {
    const request = {
        "page": page,
        "pageSize": pageSize,
        // "candidateUniqueId": `${candidateId}`,
        // "JobUniqueId": `${JobUniqueId}`,
        // "LocationId": LocationId,
        // "ShowDynamicData":ShowDynamicData,
        "sortOrder" :`${sortOrder}`,
        "sortColumn" :`${sortColumn}`,
    }
    if(sortColumn && sortColumn.length > 0){
        request.sortOrder = sortOrder;
        request.sortColumn = sortColumn;
    }
    return request;
}

export function updateSelectedJobDetails(jobId){
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            dispatch(updateSelectedJob({
                data: jobId
            }));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(ratingLoadingFailed(e.message));
            dispatch(setLoading(false));
        }
    }

}

export function loadRatings(url, candidateId, JobUniqueId,LocationId,ShowDynamicData,pageName) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const state = getState();
            const { page, pageSize, sortOrder, sortColumn } = state.ratings.ratinghistory;
            const request = createLoadRequest(page, pageSize, candidateId,JobUniqueId, LocationId,ShowDynamicData,sortOrder, sortColumn);
            const unclaimedShifts = await requestUtil(`${url}/CandidatesJobs/get-candidate-rating-history-list`, 'POST', request);
            dispatch(updateRatings({
                data: unclaimedShifts,
                page: page,
                pageName
            }));           
            dispatch(setRatings(unclaimedShifts?.overAllRating));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(ratingLoadingFailed(e.message));
            dispatch(setLoading(false));
        }
    }
}
export function nextPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { page, pageSize, data } = state.ratings[pageName];
        const lastPage = Math.ceil(data.totalCount/pageSize)
        dispatch(setPage({
            pageName,
            page: (page + 1) > lastPage ? lastPage : (page + 1)
        }));
    }
}
export function firstPage(pageName) {
    return async (dispatch) => {
        dispatch(setPage({
            pageName,
            page: 1
        }));
    }
}
export function gotoPage(pageName, pageNumber) {
    return async (dispatch) => {
        dispatch(setPage({
            pageName,
            page: pageNumber
        }));
    }
}
export function previousPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { page } = state.ratings[pageName];
        dispatch(setPage({
            pageName,
            page: (page - 1) < 1 ? 1 : (page-1)
        }));
    }
}
export function lastPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { pageSize, data } = state.ratings[pageName];
        const lastPage = Math.ceil(data.totalCount/pageSize)
        dispatch(setPage({
            pageName,
            page: lastPage
        }));
    }
}
export function pageSize(pageName, size){
    return async (dispatch) => {
        dispatch(updatePageSize({
            pageName,
            pageSize: size
        }));
    }
}

export function updateSort(pageName, order, column) {
    return async (dispatch) => {
        dispatch(setSorting({
            pageName,
            sortOrder: order,
            sortColumn: column
        }));
    }
}

// export function claimJob(url, candidateId, jobId, claimedstatus, pageName) {
//     return async (dispatch) => {
//         dispatch(setLoading(true));
//         try {
//             await requestUtil(`${url}/Candidates/claim`, "POST", {
//                 "candidateUniqueId": candidateId,
//                 "jobuniqueid": jobId,
//                 "claimedstatus": claimedstatus
//             });
//             dispatch(loadRatings(url, candidateId, pageName));
//             dispatch(setLoading(false));
//         } catch(error) {
//             dispatch(setLoading(false));
//             dispatch(ratingLoadingFailed(error.message));
//         }
//     }
// // }
// export function unClaimJob(url, candidateId, jobId, claimedstatus, pageName) {
//     return async (dispatch) => {
//         dispatch(setLoading(true));
//         try {
//             await requestUtil(`${url}/Candidates/unclaim`, "POST", {
//                 "candidateUniqueId": candidateId,
//                 "jobuniqueid": jobId,
//                 "claimedstatus": claimedstatus
//             });
//             dispatch(loadRatings(url, candidateId, pageName));
//             dispatch(setLoading(false));
//         } catch(error) {
//             dispatch(setLoading(false));
//             dispatch(ratingLoadingFailed(error.message));
//         }
//     }
// }
