import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { dashboardSelector } from "./selector";
import { loadDashboard } from "./action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import Card from "./card";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import Spinner from "../common/Spinner/spinner";

export default () => {
    const dispatch = useDispatch();
    const config = useConfiguration();
    const storage = useSessionStorage();
    const navigate = useNavigate();
    const login = storage.getItem("login");
    const { data, loadingFlag } = useSelector(dashboardSelector);
    // console.log(data);
    // const profileSubmitted = data.profileFlag && data.profileFlag.isProfileSubmitted;
    const dashboardProfileinfo = data.myDashboardProfileinfo || []
    //console.log(dashboardProfileinfo);
    useEffect(() => {
        document.title = "Overture Dashboard";
        if (config.baseUrl) {
            if (!login) {
                navigate("/");
            } else {
                dispatch(loadDashboard(config.baseUrl, login.candidateId,login.jobTypeId,login.candidateUniqueId));
            }
        }
    }, [config]);
    const navigateToPage = (event, nav) => {
        event.preventDefault();
        navigate(nav.toLowerCase());
    };
    return (
        <div className="page">
            {/* <Loader /> */}
            {loadingFlag > 0 && <Spinner />}
            <Header />
            <Sidebar />
            <div className="page-main">
                <div className="main-content app-content mt-0 main-background">
                    <div className="side-app">
                        <div className="main-container container-fluid">
                            <div className="page-header">
                                <h1 className="page-title">Candidate Dashboard</h1>
                                <div>

                                </div>
                            </div>
                            <div className="row">
                                {
                                    dashboardProfileinfo?.map(item => (
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-5" key={item.dashboardWidgetId}>
                                            <a href="#" onClick={(e) => navigateToPage(e, item.webpagelink)}>
                                                <Card smallTitle={item.smallHeading} title={item.heading} percentage={item.percentage ? parseInt(item.percentage) : 0}
                                                    pathColor={item.colorCode}
                                                    // bgClass={item.bulletinColorClass} 
                                                    // valueLabel={item.subheading} 
                                                    // valueSuffix={item.countValueSuffix}
                                                    // value={item.heading === "Notifications" ? 0:item.countValue }
                                                    >
                                                    <div className="chart-circle-value-3 fs-20" style={{ color: item.colorCode }}>
                                                        <i className={item.iconclass} />
                                                    </div>
                                                </Card></a>
                                        </div>
                                    ))
                                }

                                <div class="modal fade" id="modaldemo8">
                                    <div class="modal-dialog modal-dialog-centered text-center" role="document">
                                        <div class="modal-content modal-content-demo">
                                            <div class="modal-header">
                                                <h6 class="modal-title">Future Services</h6>
                                                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal">
                                                    <span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Once you are authorized by an employer this selection
                                                    will be activated</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
