import { domainMappedId } from "./enum";

export const mapSignupUrl = {
  nurseLive: ["accounts.overturexl.com"],
  securityLive: ["accountsec.overturexl.com"],
  restaurantLive: ["accountyum.overturexl.com"],
  dentalLive: ["accountdds.overturexl.com"],
  fiveStarLive: ["5starmedicalstaffingcandidates.overturexl.com"],
  nursesEtcLive:["nursesetccandidates.overturexl.com"],
  nursesEtcQA:["qatestcandidatenursesetc.overturexl.com"],
  nursesEtcDEV: ['devcandidatenursesetc.overturexl.com'],
  pureHeartLive:["pureheartcandidates.overturexl.com"],
  fiveStarQA: ["qatestcandidate5star.overturexl.com"],
  fiveStar: ["dev5starnurse.overturexl.com"],

  nurse: ["stagingcandidate.overturexl.com", 'qatestcandidate.overturexl.com'],
  security: [
    "stagingcandidatesec.overturexl.com",
    "securityjobdev.overturexl.com",
    "secjobstaging.overturexl.com",
    "elecansec.overturexl.com",
    "qatestcandidatesec.overturexl.com",
  ],
  restaurant: [
    "yumjobdev.overturexl.com",
    "restjobstaging.overturexl.com",
    "qatestcandidateyum.overturexl.com",
    "elecanyum.overturexl.com",
  ],
  dental: [
    "dentaljobdev.overturexl.com",
    "dentaljobstaging.overturexl.com",
    "qatestcandidatedental.overturexl.com",
    "elecandds.overturexl.com",
  ],
  listStyle: ["dynamiccandidates.overturexl.com"],
  listStyleStag: ["stagingcandidates.overturexl.com"],
  listStyle5StarStag: ["5starstagingcandidates.overturexl.com"],
};

export const marketingURLs = {
  lifeStyleStaging: "https://stagingmarketingapp.overturexl.com/api",
  fiveStarLifeStyleStaging: "https://5starstagingmarketingapp.overturexl.com/api",
  fiveStarLive: "https://5starmarketingapp.overturexl.com/api",
  pureHeartLive: "https://pureheartmarketingapp.overturexl.com/api",
  nurseLive: "https://marketingapp.overturexl.com/api",
  nurseQA: "https://development.overturexl.com/QAMarketingApp/api",
};
export const notShowOvertureMsg = [domainMappedId.fiveStar,domainMappedId.nursesEtc, domainMappedId.pureHeart]
export const getMarketingBaseURL = () => {
  let baseMarketingURL = "";
  const hostName = window.location.hostname;
  if (hostName === "5starstagingcandidates.overturexl.com") {
    baseMarketingURL = marketingURLs.fiveStarLifeStyleStaging;
  } else if (hostName === "stagingcandidates.overturexl.com'") {
    baseMarketingURL = marketingURLs.lifeStyleStaging;
  } 
else if(hostName === "5starmedicalstaffingcandidates.overturexl.com"){
  baseMarketingURL = marketingURLs.fiveStarLive;
}
else if(hostName === "pureheartcandidates.overturexl.com"){
  baseMarketingURL = marketingURLs.pureHeartLive;
}
else if(hostName === "accounts.overturexl.com"){
  baseMarketingURL = marketingURLs.nurseLive;
}
else if(hostName === "qatestcandidate.overturexl.com"){
  baseMarketingURL = marketingURLs.nurseQA;
}
  else {
    baseMarketingURL = marketingURLs.nurseLive;
  }
  return baseMarketingURL;
};
