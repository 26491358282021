export const headersData = [
  {
    name: "ShiftDate",
    field: "occurrenceDateToDisplay",
    type: "text",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "StartTime",
    field: "shiftStartTime",
    type: "text",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "EndTime",
    field: "shiftEndTime",
    type: "text",
    sort: true,
    minWidth: 150,
  },
  {
    name: "CheckIn",
    field: "isCheckedIn",
    type: "Myshift_color",
  },
  {
    name: "CheckOut",
    field: "isCheckedOut",
    type: "Myshift_color",
  },
  {
    name: "Approver",
    field: "approversList",
    type: "color",
  },
  {
    name: "Paid",
    field: "isPaid",
    type: "Unpaidis_checkedout",
  },
  {
    name: "RatePerHour",
    field: "ratePerHourToDisplay",
    type: "text",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "Incentive",
    field: "IncentiveToDisplay",
    type: "text",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "TotalShiftAmount",
    field: "totalShiftAmountToDisplay",
    type: "text",
    sort: true,
    minWidth: 180,
    maxWidth: 200,
  },
];
