import React, {useEffect, useState} from "react";
import './index.css';
import RegistrationForm from "./registrationForm";
import EmergencyContactForm from "./emergencyContactForm";
import ReferencesForm from "./referenceForm";
import {useDispatch, useSelector} from "react-redux";
import useConfiguration from "../../../hooks/useConfiguration";
import {getCurrentPersonalInfo, loadMasterData, saveMapLatAndLng} from "./action";
import useSessionStorage from "../../../hooks/useSessionStorage";
import Maps from "../../common/Maps";
import Modal from "../modal";
import { personalInfoSelector } from "./selector";
import { getCurrentResumeBuilderJobCatIdList } from "../ResumeBuilder/action";
import { profileBuilderSelector } from "../selector";


export default (props) => {
    const { data } = useSelector(personalInfoSelector);
    const { profile_builder_flags } = useSelector(profileBuilderSelector);
    const dispatch = useDispatch();
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem('login');
    const [showModal, setShowModal] = useState(false);
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    useEffect(() => {
        window.scrollToTop && window.scrollToTop();
    }, []);
    useEffect(() => {
        config.baseUrl && dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
            dispatch(getCurrentPersonalInfo({
                url: `${config.baseUrl}/Candidates/getpersonalinfo`, userId: login.candidateId
            }));
        }).then(() => {
            dispatch(getCurrentResumeBuilderJobCatIdList({
                url: `${config.baseUrl}/Candidates/getjobinfo`, userId: login.candidateId
            }));
        })
    }, [config]);
    const showMap = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
    };
    const closeMap = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        
    };
    const setLatLng = (e) => {
        if(e.latLng.lat() && e.latLng.lng()) {
            setLatitude(e.latLng.lat())
            setLongitude(e.latLng.lng())
        }        
    }

    const saveLatLng = () => {
        if (longitude && latitude) {
            const mapData = {
                "candidateId": login.candidateId,
                "latitude": latitude,
                "longitude": longitude
                };
            dispatch(saveMapLatAndLng({ url: `${config.baseUrl}/Candidates/update-candidate-latitude-longtitude`, val: mapData})).then(() => {
                dispatch(getCurrentPersonalInfo({
                    url: `${config.baseUrl}/Candidates/getpersonalinfo`, userId: login.candidateId
                }));
            })
        }
        setShowModal(!showModal);
    }
    return (
        <form>
            <div id="DivFor_yourjobpost">
                <div id="largemodal_Jobopportunity_1">
                    <div id="largemodal_Jobopportunity_2">
                        <div id="largemodal_Jobopportunity_3">
                            <div className="card">
                            {
                    showModal && <Modal showHide={closeMap} saveFunction={saveLatLng} label={"Map"}>
                        {data && data.approxLatitude && data.approxLongitude &&
                        <Maps
                        googleMapURL= {config.mapURL}
                        containerElement= {<div style={{height: '400px'}}/>}
                        mapElement= {<div style={{height: '100%'}}/>}
                        loadingElement={<div style={{ height: `100%` }} />}
                        lat={data.approxLatitude}
                        lng={data.approxLongitude}
                        setLatLng={setLatLng}
                        />}
                    </Modal>
                }
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="card-header">
                                            <div className="col-md-11">
                                                <h4>Registration Form</h4>
                                            </div>
                                            <div className="col-md-1" style={{float: 'right', cursor: 'pointer'}} onClick={showMap}>
                                                     <i className="fe fe-map"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <RegistrationForm errors={props.registrationErrors}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="DivFor_EC">
                <div id="largemodal_EC_1">
                    <div id="largemodal_EC_2">
                        <div id="largemodal_EC_3">
                            <div className="card">
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="card-header">
                                            <div className="col-lg-4 col-md-4">
                                                <h4>Emergency Contact{profile_builder_flags?.emergency_contact?.isRequired ? '*' : '' }</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <EmergencyContactForm errors={props.emergencyContactErrors}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="DivFor_References">
                <div id="largemodal_References_1">
                    <div id="largemodal_References_2">
                        <div id="largemodal_References_3">
                            <div className="card">
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="card-header">
                                            <div className="col-lg-12 col-md-12">
                                                <h4>Your References</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReferencesForm errors={props.referencesErrors} aboutusError={props.aboutUsErrors}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
