import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    unclaimed: {
        page: 1,
        data: {},
        totalPages: 0,
        pageSize: 10,
        sortColumn: [],
        sortOrder: 'Acs'
    },
    selectedJob: '',
    shiftsLoadingError: null
}
const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}
export const cancelclaimSlice = createSlice({
    name: 'cancelclaim',
    initialState,
    reducers: {
       
        updateCancelClaim: (state, action) => {
            state[action.payload.pageName].data = action.payload.data;
            state[action.payload.pageName].page = Number(action.payload.page);
        },
        shiftLoadingFailed: (state, action) => {
            state.shiftsLoadingError = action.payload;
        },
        updatePageSize: (state, action) => {
            state[action.payload.pageName].pageSize = Number(action.payload.pageSize);
            state[action.payload.pageName].page = 1;
        },
        setPage: (state, action) => {
            state[action.payload.pageName].page = Number(action.payload.page);
        },
        setSorting: (state, action) => {
            const currentSortColumns = state[action.payload.pageName].sortColumn;
            //console.log(currentSortColumns);
           // console.log([...currentSortColumns, action.payload.sortColumn].filter(onlyUnique));
            state[action.payload.pageName].sortOrder = action.payload.sortOrder;
            state[action.payload.pageName].sortColumn = action.payload.sortColumn;//[...currentSortColumns, action.payload.sortColumn].filter(onlyUnique)[0];
        }
    }
});

export const {
    shiftLoadingFailed,
    updatePageSize,
    updateCancelClaim,
    setPage,
    setSorting,
    updateSelectedJob
} = cancelclaimSlice.actions;

export default cancelclaimSlice.reducer;
