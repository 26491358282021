import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DomainDetails from '../../conf/domaindetails.json';
import { encryptionString } from '../../helpers/encryption';
import { randomString } from '../../helpers/randomString';
import useConfiguration from '../../hooks/useConfiguration';
import useSessionStorage from '../../hooks/useSessionStorage';
import useValidation from '../../hooks/useValidation';
import Checkbox from '../common/Checkbox';
import Loader from '../common/Loader';
import TextField from '../common/TextField';
import DropDown from '../common/TextField/dropDown';
import { careersAdTracking, getMarketingAppAccessToken, loadMasterData, reset, signup, signupauth } from './action';
import { signUpSelector } from './selector';
import SignupQa from './SignupQa';
import { Modal, ModalBody } from 'react-bootstrap';
import { getMarketingBaseURL, mapSignupUrl } from '../../helpers/domainUrl';
import { domainMappedId } from '../../helpers/enum';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, masterData, signupAuthData } = useSelector(signUpSelector);
  const navigate = useNavigate();
  const validate = useValidation();
  const config = useConfiguration();
  const baseMarketingUrl = getMarketingBaseURL();
  const storage = useSessionStorage();
  const dispatch = useDispatch();
  const domainJobtypeId = sessionStorage.getItem('domainJobtypeId');
  const domainLogos = JSON.parse(sessionStorage.getItem('domainLogos'));
  const termsConditions = JSON.parse(sessionStorage.getItem('termsConditions'));
  const category = searchParams.get('category');
  const career = searchParams.get('career');
  const identifier = encodeURIComponent(searchParams.get('identifier'));
  const ovEntry = encodeURIComponent(searchParams.get('ovEntry'));
  const currentDomainName = domainLogos; // "dev.overturexl.com";
  const qsjobtype = searchParams.get('qsjobtype');
  let defaultJoBtype = [];
  const [jobtypeId, setjobtypeId] = useState([]);
  const [showJobType, setshowJobType] = useState(false);
  const [isAllowedSignup, setIsAllowedSignup] = useState(false);
  const [showAllowedSignupModal, setAllowedSignupModal] = useState(false);
  // get a source
  const src = searchParams.get('src');

  if (qsjobtype != null) {
    sessionStorage.setItem('domainJobtypeId', '0');
  }

  useEffect(() => {
    document.title = 'Overture Candidate Signup';
  }, []);

  useEffect(() => {
    if (config.baseUrl) {
      if (category != '' && category != null) {
        dispatch(
          signupauth({
            url: `${config.baseUrl}/Candidates/signinauth`,
            param: {
              identifier: null,
              ovEntry: null,
              category: category, //"EAAAAG8XSjA3nk0SeNNQrYoSnkAGKDgWI4naaF5aGEl3XAWe",
              currentDomainName: window.location.origin,
            },
          })
        );
      }
    }
  }, [config]);
  useEffect(() => {
    if (domainJobtypeId === '0') {
      setshowJobType(true);
    }
    config.baseUrl &&
      dispatch(loadMasterData(`${config.baseUrl}`)).then(() => { });
  }, [config]);

  useEffect(() => {
    if (masterData.Jobtype) {
      if (domainJobtypeId != '0') {
        const fildata = masterData.Jobtype.filter(
          (item) => item.id === domainJobtypeId
        );
        setjobtypeId(fildata);
      } else {
        const filSelectedData = masterData.Jobtype.map((item) => item.id);
        setjobtypeId(filSelectedData);
      }
    }
  }, [masterData.Jobtype]);

  const { signUpSuccess, signUpError, signUpErrorMessage } =
    useSelector(signUpSelector);
  if (signUpSuccess) {
    dispatch(reset());
    navigate('/signup/success');
  }
  const [signUpForm, setSignUpForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    zipCode: '',
    terms: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const goToLogin = () => {
    navigate('/');
  };
  const validateForm = () => {
    const result = validate('signUp', [
      // {name: 'jobType', data: signUpForm.jobType.id},
      { name: 'firstName', data: signUpForm.firstName },
      { name: 'lastName', data: signUpForm.lastName },
      { name: 'email', data: signUpForm.email },
      { name: 'password', data: signUpForm.password },
      { name: 'confirmPassword', data: signUpForm.confirmPassword },
      { name: 'zipCode', data: signUpForm.zipCode },
      { name: 'terms', data: signUpForm.terms },
    ]);
    if (result.valid) {
      if (!signUpForm.terms) {
        setFormErrors({
          terms: ['You must agree to the terms and conditions'],
        });
        return false;
      } else {
        setFormErrors({});
      }
    } else {
      setFormErrors(result.errors);
    }
    return result.valid;
  };
  const customValidate = () => {
    if (!signUpForm.terms) {
      setFormErrors({
        ...formErrors,
        terms: ['You must agree to the terms and conditions'],
      });
      return false;
    }
    return true;
  };
  const updateFormValue = (target) => {
    const { name, value } = target;
    setSignUpForm({ ...signUpForm, [name]: value });
    dispatch(reset());
  };
  const customValidation = () => {
    if (signUpForm.password !== signUpForm.confirmPassword) {
      setFormErrors({
        ...formErrors,
        confirmPassword: ['Password and Confirm Password must match'],
      });
      return false;
    }
    return true;
  };

  const signUpClicked = (e) => {
    e.preventDefault();
    if (!customValidation()) {
      return false;
    }
    if (validateForm()) {
      const iv = randomString(16, '0,1,2,3,4,5,6,7,8,9');
      if (jobtypeId.length != 0) {
        dispatch(
          signup({
            // url: `${config.baseUrl}/Candidates/signup`,
            url: `${config.baseUrl}/Candidates/signupV2`,
            formData: {
              jobTypeId: signUpForm.jobType.id
                ? signUpForm.jobType.id
                : jobtypeId[0].id,
              firstName: signUpForm.firstName,
              lastName: signUpForm.lastName,
              email: signUpForm.email,
              password: encryptionString(
                config.SaltKeyForJSandAPI,
                iv,
                signUpForm.password
              ),
              ivKey: iv,
              zipCode: signUpForm.zipCode,
              jobCategoryId: signupAuthData.category
                ? signupAuthData.category
                : null,
              websiteDomainName: window.location.hostname === 'localhost' ? 'dev5starnurse.overturexl.com' : window.location.hostname
            },
          })
        ).then(data => {
          if (data?.candidateQuickRegisterId && category && career && signUpForm.email) {
            dispatch(getMarketingAppAccessToken({ baseUrl: config.baseUrl })).then(accessToken => {
              if (accessToken) {
                const params = {
                  candidateQuickRegisterId: data?.candidateQuickRegisterId,
                  signupCompleted: true,
                  career,
                  jobCategoryId: parseInt(category),
                  email: signUpForm.email,
                }
                dispatch(careersAdTracking({ url: `${config.baseUrl}/MarketingApp/save-careersadtracking`, params, accessToken }))
              }
            })
          }
        })
      } else {
        dispatch(
          signup({
            // url: `${config.baseUrl}/Candidates/signup`,
            url: `${config.baseUrl}/Candidates/signupV2`,
            formData: {
              jobTypeId: domainJobtypeId,
              firstName: signUpForm.firstName,
              lastName: signUpForm.lastName,
              email: signUpForm.email,
              password: encryptionString(
                config.SaltKeyForJSandAPI,
                iv,
                signUpForm.password
              ),
              ivKey: iv,
              zipCode: signUpForm.zipCode,
              jobCategoryId: category ? category : null,
              websiteDomainName: window.location.hostname === 'localhost' ? 'dev5starnurse.overturexl.com' : window.location.hostname
            },
          })
        ).then(data => {
          if (data?.candidateQuickRegisterId && category && career && signUpForm.email) {
            dispatch(getMarketingAppAccessToken({ baseUrl: config?.baseUrl })).then(accessToken => {
              if (accessToken) {
                const params = {
                  candidateQuickRegisterId: data?.candidateQuickRegisterId,
                  signupCompleted: true,
                  career,
                  jobCategoryId: parseInt(category),
                  email: signUpForm.email,
                }
                dispatch(careersAdTracking({ url: `${config?.baseUrl}/MarketingApp/save-careersadtracking`, params, accessToken }))
              }
            })
          }
        })
      }
    }
  };

  const [passwordType, setPasswordType] = useState('password');
  const [passwordInput, setPasswordInput] = useState('');
  const [iconStyleText, seticonStyleText] = useState(
    'zmdi zmdi-eye-off text-muted'
  );
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      seticonStyleText('zmdi zmdi-eye text-muted');
      return;
    }
    setPasswordType('password');
    seticonStyleText('zmdi zmdi-eye-off text-muted');
  };

  const [passwordTypeConfirm, setPasswordTypeConfirm] = useState('password');
  const [passwordInputConfirm, setPasswordInputConfirm] = useState('');
  const [iconStyleTextConfirm, seticonStyleTextConfirm] = useState(
    'zmdi zmdi-eye-off text-muted'
  );
  const handlePasswordChangeConfirm = (evnt) => {
    setPasswordInputConfirm(evnt.target.value);
  };
  const togglePasswordConfirm = () => {
    if (passwordTypeConfirm === 'password') {
      setPasswordTypeConfirm('text');
      seticonStyleTextConfirm('zmdi zmdi-eye text-muted');
      return;
    }
    setPasswordTypeConfirm('password');
    seticonStyleTextConfirm('zmdi zmdi-eye-off text-muted');
  };


  const navigateToMainUrl = () => {
    // setAllowedSignupModal(false)
    // if (mapSignupUrl.nurseLive?.indexOf(window.location.hostname) !== -1) {
    //   window.open("https://overturexl.com/candidates.html#getstart", "_self")
    // }
    // else if (mapSignupUrl.securityLive?.indexOf(window.location.hostname) !== -1) {
    //   window.open("https://overturesec.com/candidates.html#getstart", "_self")
    // }
    // else if (mapSignupUrl.restaurantLive?.indexOf(window.location.hostname) !== -1) {
    //   window.open("https://overtureyum.com/candidates.html#getstart", "_self")
    // }
    // else if (mapSignupUrl.dentalLive?.indexOf(window.location.hostname) !== -1) {
    //   window.open("https://overturedds.com/candidates.html#getstart", "_self")
    // }

    // else if (mapSignupUrl.fiveStar?.indexOf(window.location.hostname) !== -1) {
    //   window.open("https://dev5star.overturexl.com/index.html", "_self")
    // }

    // else if (mapSignupUrl.nurse?.indexOf(window.location.hostname) !== -1) {
    //   window.open(window.location.origin + "/website/candidates.html#getstart", "_self")
    // }
    // else if (mapSignupUrl.security?.indexOf(window.location.hostname) !== -1) {
    //   window.open(window.location.origin + "/website/security.html#getstart", "_self")
    // }
    // else if (mapSignupUrl.restaurant?.indexOf(window.location.hostname) !== -1) {
    //   window.open(window.location.origin + "/website/restaurant.html#getstart", "_self")
    // }
    // else if (mapSignupUrl.dental?.indexOf(window.location.hostname) !== -1) {
    //   window.open(window.location.origin + "/website/dental.html#getstart", "_self")
    // }
    // else if (mapSignupUrl.listStyle?.indexOf(window.location.hostname) !== -1) {
    //   window.open("https://dynamicwebsite.overturexl.com", "_self")
    // }
    // else {
    //   window.open(window.location.origin + "/website/candidates.html#getstart", "_self")
    // }
    if (mapSignupUrl.nurseLive?.indexOf(window.location.hostname) !== -1) {
      window.open("https://overturexl.com/candidates.html#getstart", "_self")
    }
    else if (mapSignupUrl.securityLive?.indexOf(window.location.hostname) !== -1) {
      window.open("https://overturesec.com/candidates.html#getstart", "_self")
    }
    else if (mapSignupUrl.restaurantLive?.indexOf(window.location.hostname) !== -1) {
      window.open("https://overtureyum.com/candidates.html#getstart", "_self")
    }
    else if (mapSignupUrl.dentalLive?.indexOf(window.location.hostname) !== -1) {
      window.open("https://overturedds.com/candidates.html#getstart", "_self")
    }
    else if (mapSignupUrl.fiveStarLive?.indexOf(window.location.hostname) !== -1) {
      window.open("https://5starmedicalstaffing.overturexl.com", "_self")
    }
    else if (mapSignupUrl.nursesEtcLive?.indexOf(window.location.hostname) !== -1) {
      window.open("https://nursesetc.overturexl.com", "_self")
    }
    else if (mapSignupUrl.pureHeartLive?.indexOf(window.location.hostname) !== -1) {
      window.open("https://pureheart.overturexl.com", "_self")
    }
    else if (mapSignupUrl.fiveStarQA?.indexOf(window.location.hostname) !== -1) {
      window.open("https://qatestcandidate5star.overturexl.com/5star/index.html", "_self")
    }

    else if (mapSignupUrl.fiveStar?.indexOf(window.location.hostname) !== -1) {
      window.open("https://dev5star.overturexl.com/index.html", "_self")
    }

    else if (mapSignupUrl.nurse?.indexOf(window.location.hostname) !== -1) {
      window.open(window.location.origin + "/website/candidates.html#getstart", "_self")
    }
    else if (mapSignupUrl.security?.indexOf(window.location.hostname) !== -1) {
      window.open(window.location.origin + "/website/security.html#getstart", "_self")
    }
    else if (mapSignupUrl.restaurant?.indexOf(window.location.hostname) !== -1) {
      window.open(window.location.origin + "/website/restaurant.html#getstart", "_self")
    }
    else if (mapSignupUrl.dental?.indexOf(window.location.hostname) !== -1) {
      window.open(window.location.origin + "/website/dental.html#getstart", "_self")
    }
    else if (mapSignupUrl.listStyle?.indexOf(window.location.hostname) !== -1) {
      window.open("https://dynamicwebsite.overturexl.com", "_self")
    }
    else if (mapSignupUrl.listStyleStag?.indexOf(window.location.hostname) !== -1) {
      window.open("https://stagingwebsite.overturexl.com/xl", "_self")
  }
  else if (mapSignupUrl.listStyle5StarStag?.indexOf(window.location.hostname) !== -1) {
      window.open("https://5starstagingwebsite.overturexl.com", "_self")
  }
  else if (mapSignupUrl.nursesEtcQA?.indexOf(window.location.hostname) !== -1) {
    window.open("https://qatestcandidatenursesetc.overturexl.com/website/indexnursesetc.html", "_self")
  }
  else if (mapSignupUrl.nursesEtcDEV?.indexOf(window.location.hostname) !== -1) {
    window.open("https://devcandidatenursesetc.overturexl.com/website/nursesetc-candidates.html", "_self")
  }
    else {
      window.open(window.location.origin + "/website/candidates.html#getstart", "_self")
    }
  }
  return (
    <div>
      <Loader />
      <div className="page">
        <div className="">
          <div className="container-login100">
            <div className="wrap-login100 p-6">
              <form className="login100-form validate-form">
                <span className="login100-form-title pb-5">
                  <a href="/">
                    <img
                      // style={{ height: '62px' }}
                      style={{minWidth:"270px", maxHeight:"90px", maxWidth:"280px" }}
                      src={domainLogos[domainJobtypeId]}
                      className="header-brand-img"
                      alt=""
                    />
                  </a>
                </span>
                <span className="login100-form-title">
                  Candidate Registration
                </span>

                {/* {
                  showAllowedSignupModal && 
                  <Modal
                  size={"lg"}
                  centered={true}
                  show={true}
                  >
                    <ModalBody>
                    <form className="login100-form validate-form">
                                <span className="login100-form-title pb-5">
                                    <a href="/">
                                        <img
                                            style={{ height: '62px' }}
                                            src={`/${domainLogos[domainJobtypeId]}`} className="header-brand-img" alt="" />
                                    </a>
                                </span>
                                <div className="">
                                    <div className="modal-body text-center p-4 pb-5">
                                        <h4>
                                        OvertureXL is currently operating in USA.<br />
                                        You are not qualified to apply for this position now. <br /> we will soon expanding our operations around the world.  <br />
                                        </h4>
                                        <p></p>
                                        <a className="btn btn-success pd-x-25" href="#" onClick={() => navigateToMainUrl()}>OK</a>
                                    </div>
                                </div>
                            </form>
                    </ModalBody>
                  </Modal>
                   
                  } */}


                {signUpError && (
                  <>
                    <div
                      className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
                      role="alert"
                      aria-live="assertive"
                      aria-atomic="true"
                      data-bs-autohide="false"
                    >
                      <div className="d-flex">
                        <div className="toast-body">
                          {signUpErrorMessage ||
                            'Sign up failed! Please try again.'}
                        </div>
                      </div>
                    </div>
                    <div>&nbsp;</div>
                  </>
                )}

                {showJobType && (
                  <>
                    <div
                      class="wrap-input100 validate-input input-group"
                      id="Jobtyptoggle"
                    >
                      <label for="exampleInputJobtyp" class="logintextspace">
                        <h5>Job type</h5>
                      </label>
                    </div>
                    <div
                      className="setnew"
                      style={{ margin: '-15px 0 0 -10px', width: '212%' }}
                    >
                      <DropDown
                        name={'jobType'}
                        placeholder={'Job Type'}
                        updateValue={updateFormValue}
                        value={''}
                        options={masterData.Jobtype}
                        showArrow={true}
                        isObject={true}
                        multiple={false}
                      />
                    </div>
                  </>
                )}
                {
                  isAllowedSignup ? <>
                    <div
                      class="wrap-input100 validate-input input-group"
                      id="Firstnametoggle"
                    >
                      <label for="exampleInputname" class="logintextspace">
                        <h5>First name</h5>
                      </label>
                    </div>
                    <TextField
                      placeholder={'First name'}
                      name={'firstName'}
                      type={'text'}
                      iconClass={'mdi mdi-account'}
                      errors={formErrors['firstName']}
                      updateValue={updateFormValue}
                    />
                    <div
                      class="wrap-input100 validate-input input-group"
                      id="Lasttoggle"
                    >
                      <label for="exampleInputname" class="logintextspace">
                        <h5>Last name</h5>
                      </label>
                    </div>
                    <TextField
                      placeholder={'Last name'}
                      name={'lastName'}
                      type={'text'}
                      iconClass={'mdi mdi-account'}
                      errors={formErrors['lastName']}
                      updateValue={updateFormValue}
                    />
                    <div
                      class="wrap-input100 validate-input input-group"
                      id="Emailtoggle"
                    >
                      <label for="exampleInputname" class="logintextspace">
                        <h5>Email</h5>
                      </label>
                    </div>
                    <TextField
                      placeholder={'Email'}
                      name={'email'}
                      type={'email'}
                      iconClass={'zmdi zmdi-email'}
                      errors={formErrors['email']}
                      updateValue={updateFormValue}
                    />
                    <div
                      class="wrap-input100 validate-input input-group"
                      id="Passwordtoggle"
                    >
                      <label for="exampleInputname" class="logintextspace">
                        <h5>Password</h5>
                      </label>
                    </div>
                    <TextField
                      placeholder={'Password'}
                      name={'password'}
                      type={passwordType}
                      iconClass={iconStyleText}
                      onChange={handlePasswordChange}
                      errors={formErrors['password']}
                      updateValue={updateFormValue}
                      clickeye={togglePassword}
                    />
                    <div class="passwordhint-font">
                      Must have 8 -16 characters with at least 1 number,
                      <br />1 uppercase letter and 1 special character (!@#$%&)
                      <br />
                      &nbsp;
                    </div>

                    <div
                      class="wrap-input100 validate-input input-group"
                      id="ConfirmPasswordtoggle"
                    >
                      <label for="exampleInputname" class="logintextspace">
                        <h5>Confirm Password</h5>
                      </label>
                    </div>
                    <TextField
                      placeholder={'Confirm Password'}
                      name={'confirmPassword'}
                      type={passwordTypeConfirm}
                      iconClass={iconStyleTextConfirm}
                      onChange={handlePasswordChangeConfirm}
                      errors={formErrors['confirmPassword']}
                      updateValue={updateFormValue}
                      clickeye={togglePasswordConfirm}
                    />
                    <div
                      class="wrap-input100 validate-input input-group"
                      id="Zipcodetoggle"
                    >
                      <label for="exampleInputname" class="logintextspace">
                        <h5>Zipcode</h5>
                      </label>
                    </div>
                    <TextField
                      placeholder={'Zip Code'}
                      name={'zipCode'}
                      type={'text'}
                      iconClass={'zmdi zmdi-google-maps'}
                      errors={formErrors['zipCode']}
                      updateValue={updateFormValue}
                    />
                    <Checkbox
                      domainJobtypeId={domainJobtypeId}
                      name={'terms'}
                      updateValue={updateFormValue}
                      errors={formErrors['terms']}
                    >
                      <>
                        I agree to the{' '}
                        {
                          +domainJobtypeId === 9 ? <span>Nurses Etc Staffing Policy Statement</span> :   <a
                          // href="https://overturexl.com/terms-conditions.html"
                          href={termsConditions[domainJobtypeId]}
                          target="_blank"
                          rel="noreferrer"
                          className={`${parseInt(domainJobtypeId) === 5 ? "text-yum " : "text-primary"}`}
                        >
                          {parseInt(domainJobtypeId) === 7 ? '5 Star Medical Staffing Policy Statement' :  +domainJobtypeId === domainMappedId.pureHeart ? 'Pure Heart Staffing Policy Statement' : 'Overture Lifestyles Candidate Profile Creation Agreement'}
                        </a>
                        }
                      
                        {/* <a
                          // href="https://overturexl.com/terms-conditions.html"
                          href={termsConditions[domainJobtypeId]}
                          target="_blank"
                          rel="noreferrer"
                          className={`${parseInt(domainJobtypeId) === 5 ? "text-yum " : "text-primary"}`}
                        >
                          {parseInt(domainJobtypeId) === 7 ? '5 Star Medical Staffing Policy Statement': +domainJobtypeId === 9 ? "Nurses Etc Staffing Policy Statement" : 'Overture Lifestyles Candidate Profile Creation Agreement'}
                        </a> */}
                      </>
                    </Checkbox>
                    <div className="container-login100-form-btn">
                      <a
                        href="Success.html"
                        className={`login100-form-btn ${parseInt(domainJobtypeId) === 5 ? "btn-yum" : "btn-primary"}`}
                        // className="login100-form-btn btn-primary"
                        onClick={signUpClicked}
                      >
                        Sign Up
                      </a>
                    </div>
                    <div className="text-center pt-3">
                      {!src &&
                        <span className="login100-form-title">
                          Already have an account?
                          <a
                            href="#"
                            onClick={goToLogin}
                            className={`${parseInt(domainJobtypeId) === 5 ? "text-yum " : "text-primary"} ms-1`}
                          // className="text-primary ms-1"
                          >
                            Sign In
                          </a>
                        </span>
                      }
                    </div>
                  </> : showAllowedSignupModal ? <form className="login100-form validate-form">
                    <div className="">
                      <div className="modal-body text-center p-4 pb-5">
                        <h4>
                          We are sorry, at this time you do not qualify to apply for this position.
                        </h4> <br />
                        <p></p>
                        <a className="btn btn-success pd-x-25" href="#" onClick={() => navigateToMainUrl()}>OK</a>
                      </div>
                    </div>
                  </form> : <SignupQa
                    isAllowedSignup={isAllowedSignup}
                    setIsAllowedSignup={setIsAllowedSignup}
                    setAllowedSignupModal={setAllowedSignupModal}
                  />
                }

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
