import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import requestUtil from "../../helpers/requestUtil";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from '../../hooks/useSessionStorage';
import { getMessagesData } from './action';
import { messagesheaderSelector } from './selector';
import './messageHeader.css'

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { data, params, totalCount } = useSelector(messagesheaderSelector);
    const navigate = useNavigate();
    let refreshTimer = null;
    const config = useConfiguration();
    // const [errorMessage, setErrorMessage,setTotalCount] = useState(undefined);
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    const dispatch = useDispatch();

    const MyMessage = (event) => {
        event.preventDefault();
        navigate('/messages');
    }
    useEffect(() => {
        props.getmessagecount(totalCount)
    }, [totalCount])
    // console.log(100);
    // console.log(data);
    const refreshGpsData = () => {
        if (config.baseUrl) {
            if (!login) {
                navigate("/");
            } else {
                const newparams = {
                    ...params,
                    candidateUniqueId: login.candidateUniqueId
                }
                dispatch(getMessagesData({
                    url: `${config.baseUrl}/Messages/get-candidate-message-notification`, params: newparams
                }));
                if (!refreshTimer) {
                    refreshTimer = setInterval(() => {
                        refreshGpsData();
                    }, 300000);
                }
            }
        }
    }

    // useEffect(() => {
    //     if (config.baseUrl) {
    //         if (!login) {
    //             navigate("/");
    //         } else {
    //             dispatch(getMessagesData({
    //                 url: `${config.baseUrl}/Messages/GetMessageListtoGrid`, params: params
    //             }));
    //             if(!refreshTimer) {
    //                 refreshTimer = setInterval(() => {
    //                     refreshGpsData();
    //                 }, 15000);
    //             }
    //         }
    //     }
    // }, [config])
    const stopRefresh = () => {
        if (refreshTimer) {
            clearInterval(refreshTimer);
            refreshTimer = null;
        }
    }

    useEffect(() => {
        // if(props.jobUniqueId && config.baseUrl) {
        //     requestUtil(`${config.baseUrl}/Jobs/getshiftdetails/${props.jobUniqueId}`, 'GET').then(result => {
        //         setShiftDetails(result);
        //     }).catch(err => {
        //         setErrorMessage(err.message);
        //     });
        //     stopRefresh();
        //     refreshGpsData(props.jobUniqueId, props.candidateUniqueId);
        // }
        refreshGpsData();
    }, [props, config])
    useEffect(() => {
        return () => {
            stopRefresh();
        }
    }, []);
    // const updateDistanceData = (distance) => {
    //     distanceSummaryRef.current.updateDistanceData(distance);
    // }
    return (
        <div>
            {/* {
               // errorMessage &&
                <>
                    <div
                        className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
                        role="alert" aria-live="assertive" aria-atomic="true"
                        data-bs-autohide="false">
                        <div className="d-flex col-6">
                            <div className="toast-body">
                                {errorMessage || 'GPS error! Please try again.'}
                            </div>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                </>
            } */}
            {
                <>

                    <div className="drop-heading border-bottom">
                        <div className="d-flex">
                            <h6 className="mt-1 mb-0 fs-16 fw-semibold text-dark">You have {data.length} Messages</h6>
                            {/* <div className="ms-auto">
                       <a href="#"
                          className="text-muted p-0 fs-12">make all  unread</a>
                   </div> */}
                        </div>
                    </div>
                    <div className="message-menu">

                        {
                            data.map(item => {
                                return (
                                    <span className="dropdown-item d-flex">
                                        <span
                                            className="avatar avatar-md brround me-3 align-self-center cover-image users1"
                                            data-bs-image-src="~/assets/images/users/1.jpg"></span>
                                        <div className="wd-90p">
                                            <div className="d-flex">
                                                <h5 className="mb-1">{item.SenderName}</h5>
                                                <small className="text-muted ms-auto text-end">
                                                    {item.messageDateTime}
                                                </small>
                                            </div>
                                            <span style={{width:"200px"}} className="dangerouse_render_truncate" dangerouslySetInnerHTML={{__html: item.messageText }}>  
                                                {/* {item.messageText}    */}
                                                </span>
                                        </div>
                                    </span>
                                )
                            })
                        }

                    </div>
                    <div className="dropdown-divider m-0"></div>
                    {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={MyMessage}
                        className="dropdown-item text-center p-3 text-muted">See all
                        Messages</a>
                </>

           /* <SimpleTable headers={tableHeader} rows={generateRowData()}/>
           <DistanceSummary ref={distanceSummaryRef} />
            {
                mapDataReady() && <DirectionMap apiKey={config.directionAPIKey}
                                                from={ {lat: parseFloat(gpsResult.latitude), lng: parseFloat(gpsResult.longtitude)} }
                                                to={ {lat: parseFloat(props?.locationInfo.latitude), lng: parseFloat(props?.locationInfo.longitude) }}
                                                updateDistanceData={updateDistanceData}/>
            } */}
        </div>
    )
}
