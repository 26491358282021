import {createSlice} from "@reduxjs/toolkit";
import {serverToState} from "./transformer";

const domainJobtypeId = sessionStorage.getItem("domainJobtypeId");

const initialState = {
    preferredShifts: [],
    preferredShiftTimeOfTheDay: [],
    travelDistance: {},
    preferredWorkDays: {
        preference: "0",
        options: []
    },
    holidayPreference: {
        preference: "0",
        options: []
    },
    vaccination: null,
    masterDatas: {
        shifts: [],
        travelDistance: [],
        preferredWorkDays: {},
        preferredWorkDaysRaw: [],
        covidVaccine: [],
        holidayPreference: {},
        holidayPreferenceRaw: [],
        shiftTimeOfTheDay: []
    }
}

export const preferenceSlice = createSlice({
    name: "preference",
    initialState,
    reducers: {
        loadPreferencedata: (state, action) => {
            const transformedData = serverToState(action.payload, state.masterDatas);
            state.preferredShifts = transformedData.preferredShifts;
            state.preferredShiftTimeOfTheDay = transformedData.preferredShiftTimeOfTheDay;
            state.travelDistance = transformedData.travelDistance;
            state.preferredWorkDays = transformedData.preferredWorkDays;
            state.holidayPreference = transformedData.holidayPreference;
            state.vaccination = transformedData.vaccination;
        },
        addPreferredShifts: (state, action) => {
            const newShifts = [...state.preferredShifts, action.payload];
            state.preferredShifts = newShifts;
        },
        removePreferredShifts: (state, action) => {
            const newShifts = state.preferredShifts.filter(shift => shift.id !== action.payload.id)
            state.preferredShifts = newShifts;
        },
        addPreferredShiftTimeOfTheDay: (state, action) => {
            const newShifts = [...state.preferredShiftTimeOfTheDay, action.payload];
            state.preferredShiftTimeOfTheDay = newShifts;
        },
        removePreferredShiftTimeOfTheDay: (state, action) => {
            const newShifts = state.preferredShiftTimeOfTheDay.filter(shift => shift.id !== action.payload.id)
            state.preferredShiftTimeOfTheDay = newShifts;
        },
        
        setTravelDistance: (state, action) => {
            state.travelDistance = action.payload;
        },
        setPreferredWorkDays: (state, action) => {
            state.preferredWorkDays = action.payload;
        },
        setHolidayPreference: (state, action) => {
            state.holidayPreference = action.payload;
        },
        setVaccination: (state, action) => {
            state.vaccination = action.payload;
        },
        setMasterDatas: (state, action) => {
            if (action.payload.shifts) {
                state.masterDatas.shifts = action.payload.shifts;
                state.masterDatas.travelDistance = action.payload.travelDistance;
                state.masterDatas.preferredWorkDays = action.payload.preferredWorkDays;
                state.masterDatas.preferredWorkDaysRaw = action.payload.preferredWorkDaysRaw;
                state.masterDatas.holidayPreference = action.payload.holidayPreference;
                state.masterDatas.holidayPreferenceRaw = action.payload.holidayPreferenceRaw;
                state.masterDatas.covidVaccine = action.payload.covidVaccine;
                state.masterDatas.shiftTimeOfTheDay = action.payload.shiftTimeOfTheDay;
            }
        }
    }
});

export const {
    addPreferredShifts, removePreferredShifts, setTravelDistance, setPreferredWorkDays,
    setHolidayPreference, setVaccination, setMasterDatas, loadPreferencedata,
    addPreferredShiftTimeOfTheDay, removePreferredShiftTimeOfTheDay
} = preferenceSlice.actions;
export default preferenceSlice.reducer;
