import { createSlice } from "@reduxjs/toolkit";
const initialState =
{
    claimedCandidateInfo: {
        claimedCandidateInfo: {},
    },
    jobType: {
        domian: [],
        type: []
    },
    jobInfo: {
        jobTitle: '',
        jobDescription: '',
        jobTemplateName: '',
        jobTemplateId: 0,
        jobCategoryId: 0,
        jobCategory: ''

    },
    educationInfo: {
        levelOfEducation: '',
        levelOfEducationID: 0,
        languagesSpoken: [],
        otherLanguagesSpoken: []
    },
    shift: {
        startTime: '',
        endTime: '',
        hours: 0
    },
    jobLocationsList: [
        {
            locationId: 0,
            locationName: '',
            zipcode: 0,
            city: '',
            stateName: '',
            country: '',
            streetAddress: '',
            phoneNumber: 0,
            timezone: '',
            approxLatitude: 0,
            approxLongitude: 0,
            jobCode: '',
            jobDateToDisplay: '',
            shiftEndTime: '',
            shiftStartTime: ''
        }
    ],
    speciality: {
        specialityDetails: [],
        otherSpecialityDetails: []
    },
    certification: {
        definedCertifications: [],
        otherCertifications: []
    },
    emrehrExperience: {
        definedExperience: [],
        otherExperience: []
    },
    COVIDVaccinationDetails: [],
    templateDetails: {
        id: 0,
        name: ''
    },
    errorMessage: ''
}

export const jobDetailsSlice = createSlice({
    name: 'jobDetails',
    initialState,
    reducers: {
        updateJobDetails: (state, action) => {
            const data = action.payload.data.jobDetails;
            // const educationData = action.payload.data.educationDetails;
            state['claimedCandidateInfo'] = {
                claimedCandidateInfo: data?.claimedCandidateInfo,
            };
            state['jobType'] = {
                domain: data?.candidateJobDomainIdList,
                type: data?.candidateJobTypeIdList
            };
            state['jobInfo'] = {
                jobTitle: data?.jobTitle,
                jobDescription: data?.jobDescription,
                jobTemplateName: data?.jobTemplateName,
                jobTemplateId: data?.jobTemplateId,
                jobCategoryId: data?.jobCategory,
                jobCategory: data?.jobCategory
            };

            state['educationInfo'] = {

                levelOfEducation: data?.candidateJobLevelOfEducationIdList[0]?.levelOfEducationName,
                levelOfEducationID: data?.candidateJobLevelOfEducationIdList[0]?.levelOfEducationId,
                languagesSpoken: data?.candidateSpokenLanguagesList,
                otherLanguagesSpoken: data?.candidateOtherSpokenLanguagesList
            };
            state['shift'] = {
                startTime: data?.shiftStartTime,
                endTime: data?.shiftEndTime, hours: data?.shiftHours
            };
            state['jobLocationsList'] = data?.candidateJobLocationsList;

            state['speciality'] = {
                specialityDetails: data?.candidateJobSpecialtyList,
                otherSpecialityDetails: data?.candidateJobSpecialtyListOther,
            };

            state['certification'] = {
                definedCertifications: data?.candidateCertificationsList,
                otherCertifications: data?.candidateCertificationsListOther
            };

            state['emrehrExperience'] = {
                definedExperience: data?.candidateJobEMREHRSystemList,
                otherExperience: data?.candidateJobEMREHRSystemListOther
            };

            state['templateDetails'] = {
                id: data?.jobTemplateId,
                name: data?.jobTemplateName
            };

            state['COVIDVaccinationDetails'] = data?.candidateCovid19VaccinatedPreferencesList

        },
        updateJobFailed: (state, action) => {
            state['errorMessage'] = action.payload;
        }
    }
});

export const {
    updateJobDetails,
    updateJobFailed
} = jobDetailsSlice.actions;

export default jobDetailsSlice.reducer;
