import React from "react";
import ReactStars from "react-rating-stars-component";

export default (params) => {
    const value = params?.value?.value;
    let color = '';
    if (value > 3) {
        color = 'green';
      } else if (value > 2 && value <= 3) {
        color = 'yellow';
      } else if (value > 0 && value <= 2) {
        color = 'red ';
      } else if (value <= 0) {
        color = 'black ';
      }

    const star = {
        size: 15,
        count: 5,
        value: value,
        edit: false,
        color: color,
        activeColor: color,
        a11y: true,
        isHalf: true,
        emptyIcon: <i className="fa fa-star-o" />,
        halfIcon: <i style={{color:color}}  className="fa fa-star-half-o" />,
        filledIcon: <i className="fa fa-star" />,
    }
    return <ReactStars {...star} />
}
