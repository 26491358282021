import React from "react";
import './input.css';
// import formatters from "./formatters";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const [key, setKey] = React.useState(undefined)
    function formatPhone(event) {
        const element = event
        let caret = element.selectionStart
        let value = element.value.split("")

        // sorry for magical numbers
        // update value and caret around delimiters
        if ((caret === 4 || caret === 8) && key !== "Delete" && key !== "Backspace") {
            caret++
        } else if ((caret === 3 || caret === 7) && key === "Backspace") {
            value.splice(caret - 1, 1)
            caret--
        } else if ((caret === 3 || caret === 7) && key === "Delete") {
            value.splice(caret, 1);
        }

        // update caret for non-digits
        if (key.length === 1 && /[^0-9]/.test(key)) caret--

        value = value.join("")
            // remove everithing except digits
            .replace(/[^0-9]+/g, "")
            // limit input to 10 digits
            .replace(/(.{10}).*$/, "$1")
            // insert "-" between groups of digits
            .replace(/^(.?.?.?)(.?.?.?)(.?.?.?.?)$/, "$1-$2-$3")
            // remove exescive "-" at the end
            .replace(/-*$/, "")

        window.requestAnimationFrame(() => {
            element.setSelectionRange(caret, caret)
        })

        return value
    }
    const formatValue = (target) => {

        if (props.format) {
            // target.value = formatters(target.value, props.format);
            target.value = formatPhone(target)
        } else {
            const caret = target.selectionStart
            const element = target
            window.requestAnimationFrame(() => {
                element.selectionStart = caret
                element.selectionEnd = caret
            })
        }
        return target;
    };
    const divClass = props.divClass ? props.divClass : 'col-lg-6 col-md-12';
    return (
        <div className={divClass}>
            <div className="form-group">
                {!props.noLabel && <label htmlFor={props.name}>{props.label}</label>}
                <input type="text"
                    className={`form-control ms-0 ${(props.errors && props.errors.length > 0) && 'has-errors'}`}


                    id={props.name} value={props.value}
                    placeholder={props.placeholder} name={props.name}
                    onChange={(event) => props.updateValue(formatValue(event.target))}
                    autoFocus={props.autoFocus}
                    onKeyDown={event => setKey(event.key)}
                />
                {props.errors && props.errors.map(error => (<div className="invalid-input">{error}</div>))}
            </div>
        </div>
    )
}
