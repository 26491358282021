import {useState} from "react";
import ResumeForms from "../ProfileBuilder/ResumeBuilder/resumeForms";
import Modal from "./modal";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { useDispatch, useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import { validateResume } from "../ProfileBuilder/ResumeBuilder/customResumeValidation";
import { resumeBuilderSelector } from "../ProfileBuilder/ResumeBuilder/selector";
import { saveResumeBuilder } from "../ProfileBuilder/ResumeBuilder/action";
import { setLoading } from "../common/Loader/slice";
import * as Toasts from "../common/Toastify";
import { setLoadingFlagDecrement, setLoadingFlagIncrement } from "../ProfileBuilder/slice";

export default (props) => {
    const { login_roles_flags, profile_builder_flags } = useSelector(profileBuilderSelector);
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    const resumeForm = useSelector(resumeBuilderSelector);
    const dispatch = useDispatch();
    const [resumeBuilderError, setResumeBuilderError] = useState({});
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const baseUrl = config.baseUrl;
    const [showModal, setShowModal] = useState(false);
    const editResume = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('resumeBuilder');
        }
    };
    const saveResume = (event) => {
        event.preventDefault();
        // if (props.saveContinue('resumeBuilder', false)) {
        //     setShowModal(!showModal);
        // }


        let valid = true;
        const resumeFormValid = validateResume(resumeForm, login.jobTypeInfo, profile_builder_flags);
        if (resumeFormValid.isValid) {
        dispatch(
            saveResumeBuilder({
              url: `${config.baseUrl}`,
              candidateId: login.candidateId,
              userId: login.appUserId,
              gotoNextTab: false,
              flag: login?.jobTypeInfo?.showQATab,
            })
          );
        setShowModal(!showModal);
        setResumeBuilderError({});
        } else {
            setResumeBuilderError(resumeFormValid.errors);
            valid = false;
            return;
        }



    };
    const downloadUploadDoc = async (fileName) => {
        const requestHeaders = {
            'Content-Type': 'application/json',
            'Authorization': login.accessToken  ? `Bearer ${login.accessToken }` : '',
            'Website-Domain-Name': window.location.hostname === 'localhost' ? 'nursejobdev.overturexl.com' : window.location.hostname,
        };
        const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
        try {
            dispatch(setLoadingFlagIncrement())
            if(mobileAuth){
                const response = await fetch(`${config.baseUrl}/Candidates/get-candidate-resume-download-mobilebrowser`, {
                    headers:{...requestHeaders},
                    method:"GET"
                });
                const url = await response.json();
                window.location.href = `${url}?${fileName}`
            } else {
                const response = await fetch(`${config.baseUrl}/Candidates/get-candidate-resume-download`, {
                    headers:{...requestHeaders},
                    method:"GET"
                });
                if(response){
                    const blob = await response.blob();
                    let downnloadURL = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = downnloadURL;
                    a.download = fileName;
                    a.click();
                }
            }
        } catch (error) {
            // dispatch(setError(error.message))
            Toasts.error(error.message)
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
    };
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal && <Modal label={"Resume Upload"} showHide={editResume} saveFunction={saveResume}>
                        <ResumeForms errors={resumeBuilderError}
                                     educationErrors={props.educationErrors}
                                     workHistoryErrors={props.workHistoryErrors}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                                <h4 className="text-wrap mr-2">Resume Upload</h4>
                                {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                                <a id="EditResumeUplaod" href="#" onClick={editResume}>
                                    <i className="fe fe-edit" title="Edit Resume Upload"></i>
                                </a> : ''
                                }
                        </div>
                    </div>

                    <div className="row">
                        <div className="card-header">
                                {props.noResume ? ( 
                                    <a/> 
                                ) : (

                                    <a  style={{cursor:"pointer"}}
                                    onClick={() => downloadUploadDoc( props.resumeFile.displayToName || props.resumeFile.name)}
                                    //    target="_blank" 
                                       className="text-break" >
                                        {(props.resumeFile && props.resumeFile.displayToName || props.resumeFile.name) &&
                                        <div>
                                         { props.resumeFile.name &&  <>
                                        <i>{props.resumeFile && props.resumeFile.displayToName || props.resumeFile.name} -</i>
                                        <i className="mdi mdi-arrange-bring-forward" data-bs-toggle="tooltip" title=""
                                           data-bs-original-title="mdi-arrange-bring-forward"
                                           aria-label="mdi-arrange-bring-forward"></i>
                                            </> }
                                           </div>
                                          
                                        }
                                    </a>
                                    
                                )}


                           

                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                {/*<a href={`${baseUrl}/Candidates/getresumefile/${login.candidateId}`}  target="_blank">
                               
                                <i>{props.resumeFile && props.resumeFile.name} -</i>
                                <i className="mdi mdi-arrange-bring-forward" data-bs-toggle="tooltip" title=""
                                data-bs-original-title="mdi-arrange-bring-forward"
                                aria-label="mdi-arrange-bring-forward"></i> </a>
                                */}
                            </div>
                            {
                                props.noResume && <div className="col-lg-4 col-md-4">
                                    <i className="fa fa-check" data-bs-toggle="tooltip" title=""
                                       data-bs-original-title="fa fa-check" aria-label="fa fa-check"></i>
                                    <label htmlFor="exampleInputname1"> No Resume </label>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        props.noResume && <>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <br/>
                                        <h4>Work History</h4>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <a id="EditResumeUplaod" href="#" onClick={editResume}>
                                            <i className="fe fe-edit" title="Edit Resume Upload"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4 col-md-3">
                                        <label className="form-label">Employer
                                        </label></div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">From Month</label>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">From Year</label>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">To Month</label>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">To Year</label>
                                    </div>
                                </div>
                                {
                                    props.workHistory && props.workHistory.map(history => (
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3">
                                                <label>{history.employer}</label>
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {history.fromMonth}
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {history.fromYear}
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {history.toMonth}
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {history.toYear}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <br/>
                                        <h4>Education</h4>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <a id="EditResumeUplaod" href="#" onClick={editResume}>
                                            <i className="fe fe-edit" title="Edit Resume Upload"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4 col-md-3">
                                        <label className="form-label">Education</label>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">From Month</label>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">From Year</label>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">To Month</label>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="form-label">To Year</label>
                                    </div>
                                </div>
                                {
                                    props.education && props.education.map(edu => (
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3">
                                                <label>{edu.name}</label>
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {edu.fromMonth}
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {edu.fromYear}
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {edu.toMonth}
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                {edu.toYear}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
