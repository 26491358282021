import { paginationCalculator } from "pagination-calculator";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import DataGrid from "../common/DataGrid";
import Header from "../common/Header";
import Loader from "../common/Loader";
import { ModalBox } from "../common/Modal/ModalBox";
import Sidebar from "../common/Sidebar";
import { getUnClaimCandidateReasons } from "../MyShift/action";
import { myshiftSelector } from "../MyShift/selector";
import { updateClaimandUnclaimMsg } from "../Shifts/action";
import { shiftsSelector } from "../Shifts/selector";
import Spinner from "../common/Spinner/spinner";
import {
  cancelclaim,
  claim,
  firstPage,
  gotoPage,
  lastPage,
  loadShifts,
  nextPage,
  pageSize,
  previousPage,
  updateClaimandUnclaimMsgMultiShiftJobInfo,
  updateSort,
  clearData,
} from "./action";
import { multishiftjobinfoSelector } from "./selector";
import {
  setClaimUnclaimErrorMessageMultiShiftJobInfo,
  setClaimUnclaimSuccessMessageMultiShiftJobInfo,
} from "./slice";

const MultiShiftJobInfo = () => {
  const navigate = useNavigate();
  const {
    multishiftjobinfo,
    claimUnclaimErrorMessageMultiShiftJobInfo,
    claimUnclaimSuccessMessageMultiShiftJobInfo,
    loadingFlag
  } = useSelector(multishiftjobinfoSelector);

  // Unclaim Reasons get Form MyShiftSelector
  const { unClaimEmployerReasons } = useSelector(myshiftSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const config = useConfiguration();
  const storage = useSessionStorage();
  const login = storage.getItem("login");
  const { params, subUrl, headersData, pageName } = location.state || "";
  const { myShiftPage } = location.state;
  const { unpaid } = location.state;
  const headers = headersData;


  // Claim Unclaim Modal Box Show and SetShow
  const [claimUnclaimModalShow, setClaimUnclaimModalShow] = useState(false);

  // Claim Unclaim get a data from Function
  const [claimUnclaimUniqueData, setClaimUnclaimUniqueData] = useState(null);
  const { selectedJob, claimUnclaimErrorMessage, claimUnclaimSuccessMessage } =
    useSelector(shiftsSelector);
  const [unclaimBtnIsDiabled, setUnclaimBtnIsDiabled] = useState(false);

  // Confirmation Modal Box Dropdown Form initial data
  const [ModalFormData, setModalFormData] = useState({
    reason: "",
    comments: "",
  });

  // Confirmation Modal Box Dropdown Form Error initial data
  const [ErrorsModalFormData, setErrorsModalFormData] = useState({
    reasonError: "",
    commentsError: "",
  });

  // Confirmation Modal Box Dropdown Form HandleChange Function
  const ConfirmationModalBoxHandleChange = (e) => {
    setModalFormData({
      ...ModalFormData,
      [e.target.name]: e.target.value,
    });
  };

  // Claim or Cancel Modal Box open Button function
  const claimUnclaimModalOpenBtn = ({
    jobUniqueId,
    locationName,
    isClaimed,
    jobsClaimId,
    occurranceDate,
    starttime,
    endtime,
    locationNameMultishift,
    occurrenceDate,
    jobsShiftUniqueId,
    correctionType
  }) => {
    setClaimUnclaimModalShow(true);
    setClaimUnclaimUniqueData({
      jobUniqueId,
      locationName,
      isClaimed,
      jobsClaimId,
      occurranceDate,
      starttime,
      endtime,
      locationNameMultishift,
      occurrenceDate,
      jobsShiftUniqueId,
      correctionType
    });
  };

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, []);

  // Claim or Cancel Modal Box Close Button function
  const claimUnclaimModalCloseBtn = () => {
    setErrorsModalFormData({
      commentsError: "",
      reasonError: "",
    });
    setModalFormData({
      reason: "",
      comments: "",
    });
    setClaimUnclaimModalShow(!claimUnclaimModalShow);
  };

  // Claim or Cancel Modal Box Function
  const claimUnclaimSave = () => {
    // Error Handling for Unlcaim Reasons Dropdown List
    if (!ModalFormData.reason) {
      setErrorsModalFormData({
        ...ErrorsModalFormData,
        reasonError: "Please choose at least one Reason",
      });
      return;
    } else if (ModalFormData.reason) {
      setErrorsModalFormData({
        ...ErrorsModalFormData,
        reasonError: "",
      });
      if (parseInt(ModalFormData.reason) === 0) {
        if (!ModalFormData.comments) {
          setErrorsModalFormData({
            ...ErrorsModalFormData,
            commentsError: "Please Enter the comments",
          });
          return;
        } else if (ModalFormData.comments) {
          setErrorsModalFormData({
            reasonError: "",
            commentsError: "",
          });
        }
      }
    }
    // Unclaim API Method
    if (claimUnclaimUniqueData.isClaimed) {
      // API call before disable unclaim btn
      setUnclaimBtnIsDiabled(true);
      dispatch(
        cancelclaim(
          config.baseUrl,
          login.candidateUniqueId,
          claimUnclaimUniqueData.jobUniqueId,
          claimUnclaimUniqueData.isClaimed ? 2 : 2,
          "multishiftjobinfo",
          claimUnclaimUniqueData?.jobsClaimId,
          claimUnclaimUniqueData.occurrenceDate,
          claimUnclaimUniqueData.starttime,
          claimUnclaimUniqueData.endtime,
          ModalFormData.reason,
          ModalFormData.comments,
          claimUnclaimUniqueData.jobsShiftUniqueId,
          claimUnclaimUniqueData?.correctionType,
          pageName
        )
      ).then(() => {
        setModalFormData({
          reason: "",
          comments: "",
        });
        claimUnclaimModalCloseBtn();
        setUnclaimBtnIsDiabled(false);
      });
    }
  };

  useEffect(() => {
    document.title = "MultiShiftJobInfo";
    if (config.baseUrl) {
      if (!login) {
        navigate("/");
      } else {
        dispatch(
          loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
        );
        // Unclaim Reasons API Method
        dispatch(getUnClaimCandidateReasons(`${config.baseUrl}`));
      }
    }
  }, [config]);

  // Table body Content Manipulation Area
  const generateRowData = (data, headers) => {
    const rowData = {};
    headers.forEach((header) => {
      if (header.type === "text") {
        rowData[header.name] = data[header.field];
      } else if (header.field === "isClaimed") {
        const isClaimed = data[header.field];
        const iconClass = isClaimed ? "green-icon" : "grey-icon";
        rowData[header.name] = {
          type: "icon",
          class: `fa fa-check-circle icon-align ${iconClass}`,
          onClick: () => {
            // UnClaim Confirmation Modal Box
            claimUnclaimModalOpenBtn({
              jobUniqueId: data?.jobUniqueId,
              locationName:
                multishiftjobinfo?.headerMsgData?.location ||
                multishiftjobinfo?.headerMsgData?.locationName,
              isClaimed: data?.isClaimed,
              jobsClaimId: data?.jobsClaimId,
              occurranceDate: data.scheduleOccurrenceDate,
              starttime: data?.shiftStartTime,
              endtime: data?.shiftEndTime,
              locationNameMultishift:
                multishiftjobinfo?.headerMsgData?.location ||
                multishiftjobinfo?.headerMsgData?.locationName,
              occurrenceDate: data.occurrenceDate,
              jobsShiftUniqueId: data.jobsShiftUniqueId,
              correctionType: data?.occurrenceCorrectionType
            });
          },
        };
      } else if (header.field === "isCheckedIn") {
        rowData[header.name] = {
          type: "Myshift_color",
          value: data?.[header.field],
        };
      } else if (header.field === "isCheckedOut") {
        rowData[header.name] = {
          type: "Myshift_color",
          value: data?.[header.field],
        };
      } else if (header.field === "approversList") {
        rowData[header.name] = {
          type: "color",
          value: data?.[header.field],
        };
      } else if (header.field === "isPaid") {
        rowData[header.name] = {
          type: "Unpaidis_Paid",
          value: data?.[header.field],
        };
      }
      // rate per hour column
      if (header.field === "ratePerHourToDisplayMultishift") {
        if (
          data.ratePerHourToDisplayMultishift === "$0" ||
          data.ratePerHourToDisplayMultishift === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.ratePerHourToDisplayMultishift;
        }
      }
      // Incentive column
      if (header.field === "IncentiveToDisplay") {
        if (
          data.incentiveToDisplay === "$0" ||
          data.incentiveToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.incentiveToDisplay;
        }
      }
      // Total Shift Amount
      if (header.field === "totalShiftAmountToDisplay") {
        if (
          data.totalShiftAmountToDisplay === "$0" ||
          data.totalShiftAmountToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.totalShiftAmountToDisplay;
        }
      }
    });
    return rowData;
  };

  const createPageData = (page, pageSize, totalCount) => {
    if (page === 1) {
      return {
        from: page,
        to: pageSize,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    } else {
      const pageData = paginationCalculator({
        total: totalCount,
        current: page,
        pageSize: pageSize,
      });
      return {
        from: pageData.showingStart,
        to: pageData.showingEnd,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    }
  };
  const gotoFirstPage = () => {
    dispatch(firstPage("multishiftjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };
  const gotoNextPage = () => {
    dispatch(nextPage("multishiftjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };
  const gotoPreviousPage = () => {
    dispatch(previousPage("multishiftjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };
  const gotoLastPage = () => {
    dispatch(lastPage("multishiftjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };
  const gotoSelectedPage = (page) => {
    dispatch(gotoPage("multishiftjobinfo", page)).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };
  const setPageSize = (size) => {
    dispatch(pageSize("multishiftjobinfo", size)).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };

  const formatTableData = (dataList) => {
    const tableData = {
      headers,
      rows: multishiftjobinfo?.occurrenceList?.map((data) => ({
        columns: generateRowData(data, headers),
      })),
      options: {
        sortable: false,
        filter: false,
        pagination: false,
        pageSize: dataList?.pageSize || 10,
      },
      pageData: createPageData(
        dataList?.page,
        dataList?.pageSize,
        dataList?.data?.totalCount
      ),
      firstPage: gotoFirstPage,
      nextPage: gotoNextPage,
      previousPage: gotoPreviousPage,
      lastPage: gotoLastPage,
      updatePageSize: setPageSize,
      gotoPage: gotoSelectedPage,
      headerComponentParams: {
        sort: (order, column) => {
          dispatch(updateSort("multishiftjobinfo", order, column)).then(() => {
            dispatch(
              loadShifts(
                config.baseUrl,
                login.candidateUniqueId,
                params,
                subUrl
              )
            );
          });
        },
        headers,
      },
    };
    return tableData;
  };

  // Message Modal Box after Load data for MultishiftJobInfo Page
  const refreshJobListMultiShiftJobInfo = () => {
    dispatch(
      loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
    );
    dispatch(updateClaimandUnclaimMsgMultiShiftJobInfo(null));
  };

  // MultishiftJobInfo Page Error and Success Message
  const errorMessageMultiShiftJobInfo =
    claimUnclaimErrorMessageMultiShiftJobInfo !== null;
  const successMessageMultiShiftJobInfo =
    claimUnclaimSuccessMessageMultiShiftJobInfo !== null;

  return (
    <div className="page">
      {/* <Loader /> */}
      <Header />
      <Sidebar />
      {loadingFlag > 0 && <Spinner />}
      {claimUnclaimModalShow && (
        <ModalBox
          size={"md"}
          label={"Choose the Unclaim Reason"}
          children={
            <div>
              <div>
                Are you sure want to proceed with unclaim for this{" "}
                {claimUnclaimUniqueData.locationName ||
                  claimUnclaimUniqueData.locationNameMultishift}{" "}
                job?
              </div>
              {/* UnClaim Reasons Dropdown */}
              <div className="mt-5">
                <label for="reason" className="mx-2">
                  Choose a Reason :
                </label>
                <select
                  name="reason"
                  id="reason"
                  className="form-select form-control"
                  required
                  onChange={ConfirmationModalBoxHandleChange}
                >
                  <option selected disabled>
                    Choose any one
                  </option>
                  {unClaimEmployerReasons.map((reasonLists, index) => {
                    return (
                      <option key={index} value={reasonLists.id}>
                        {reasonLists.name}
                      </option>
                    );
                  })}
                </select>
                {
                  <span className="text-danger mx-2">
                    {ErrorsModalFormData?.reasonError}
                  </span>
                }
                {parseInt(ModalFormData.reason) === 0 ? (
                  <div className="mt-2">
                    <textarea
                      className="form-control"
                      name="comments"
                      value={ModalFormData.comments}
                      onChange={ConfirmationModalBoxHandleChange}
                    ></textarea>
                    {
                      <span className="text-danger mx-2">
                        {ErrorsModalFormData?.commentsError}
                      </span>
                    }
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          }
          disabled={unclaimBtnIsDiabled}
          showHide={claimUnclaimModalCloseBtn}
          buttonName={"UnClaim"}
          saveFunction={claimUnclaimSave}
        />
      )}
      {/* MultishiftJobInfo Page Claim and Unclaim Success or Error Message Modal Box */}
      <Modal
        show={errorMessageMultiShiftJobInfo || successMessageMultiShiftJobInfo}
        size={"lg"}
        centered={true}
        title="Error"
      >
        <Modal.Header>
          <Modal.Title>
            {errorMessageMultiShiftJobInfo ? "Error" : "Success"}
          </Modal.Title>
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={refreshJobListMultiShiftJobInfo}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {errorMessageMultiShiftJobInfo && (
            <div
              className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-autohide="false"
            >
              <div className="toast-body">
                {claimUnclaimErrorMessageMultiShiftJobInfo}
              </div>
            </div>
          )}
          {successMessageMultiShiftJobInfo && (
            <div className="modal-body text-center p-4 pb-5">
              <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
              <h4>{claimUnclaimSuccessMessageMultiShiftJobInfo}</h4>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={refreshJobListMultiShiftJobInfo}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="page-main">
        <div className="main-content app-content mt-0 main-background">
          <div className="side-app">
            <div className="main-container container-fluid">
              <div className="page-header">
                <h1 className="page-title">MultiShiftJobInfo</h1>
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      {/* Header for Common Details Card 1 */}
                      <div className="row justify-content-between">
                        {/* Header Column 1 */}
                        <div className="col-lg-6 d-flex flex-column gap-3">
                          {/* Location Name */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">
                              Location Name :
                            </div>
                            <div className="col">
                              {multishiftjobinfo?.headerMsgData?.location ||
                                multishiftjobinfo?.headerMsgData?.locationName}
                            </div>
                          </div>
                          {/* Job Title */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">Job Title :</div>
                            <div className="col">
                              {multishiftjobinfo?.headerMsgData?.jobTitle}
                            </div>
                          </div>
                          {/* recurrenceDetail */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">
                              Recurrence Detail :
                            </div>
                            <div className="col">
                              {
                                multishiftjobinfo?.headerMsgData
                                  ?.recurrenceDetail
                              }
                            </div>
                          </div>
                        </div>
                        {/* Header Column 2 */}
                        <div className="col-lg-4 d-flex flex-column gap-3">
                          {/* Start Date */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">Start Date :</div>
                            <div className="col">
                              {
                                multishiftjobinfo?.headerMsgData
                                  ?.jobStartDateToDisplay
                              }
                            </div>
                          </div>
                          {/* End Date */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">End Date :</div>
                            <div className="col">
                              {
                                multishiftjobinfo?.headerMsgData
                                  ?.jobEndDateToDisplay
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Table Card 2 */}
              <div className="row">
                <div>
                  <div className="card">
                    <div className="card-body">
                      <DataGrid
                        {...formatTableData(multishiftjobinfo)}
                        headerSpace={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiShiftJobInfo;
