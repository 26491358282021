import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resumeBuilderSelector } from './selector';
import { updatePrimaryEmrEhr, updateEmrEhr } from './action';
import DropDown from "../../common/TextField/dropDown";
import useConfiguration from "../../../hooks/useConfiguration";
import { profileBuilderSelector } from "../selector";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const dispatch = useDispatch();
    const { emrehr, masterDatas } = useSelector(resumeBuilderSelector);
    const { profile_builder_flags } = useSelector(profileBuilderSelector);
    const [data, setData] = useState(emrehr);
    const config = useConfiguration();
    const emrehrSelect = useRef(null);
    const [userInput, setUserInput] = useState('');
    const [filteredEmrEhr, setFilteredEmrEhr] = useState([]);
    const [isEmrEhrUlVisble, setIsEmrEhrUlVisble] = useState(false);

    useEffect(() => {
        dispatch(updateEmrEhr(data));
    }, [data])

    useEffect(() => {
        setFilteredEmrEhr(masterDatas.emrehr);
    }, [masterDatas.emrehr])

    useEffect(() => {
        document.addEventListener("click", emrEhrOutside, false);
        return () => {
            document.removeEventListener("click", emrEhrOutside, false);
        };
    }, [isEmrEhrUlVisble]);


    const emrEhrOutside = event => {
        const parent1 = document.querySelectorAll('.emrehr-select .displayNone');
        if (emrehrSelect.current && !emrehrSelect.current.contains(event.target)) {
            if (isEmrEhrUlVisble && parent1[0]?.style?.display !== 'none' && event.target.id !== 'cerification') {
                const parent = document.querySelectorAll('.emrehr-select .optionListContainer');
                parent[0].classList.remove('displayBlock');
                parent[0].classList.add('displayNone');
                setIsEmrEhrUlVisble(false);
            }
        }
    };

    const updateData = (target) => {
        const value = target.value;
        const action = target.action || 'add';
        dispatch(updatePrimaryEmrEhr(value, action));
    };
    const updateOtherData = (target, index) => {
        const newEmrehr = [...emrehr.others];
        newEmrehr[index] = target.value;
        setData({
            ...emrehr,
            others: newEmrehr
        });
    };

    const removeRow = (index) => {
        const newEmrehr = [...emrehr.others];
        newEmrehr.splice(index, 1);
        setData({
            ...emrehr,
            others: newEmrehr
        });
    };

    const addRow = (event) => {
        event.preventDefault();
        const newEmrehr = [...emrehr.others];
        newEmrehr.push("");
        setData({
            ...emrehr,
            others: newEmrehr
        });
    };

    const searchEmrEhr = () => {
        if (userInput) {
            const parent = document.querySelectorAll('.emrehr-select .optionListContainer');
            parent[0].classList.remove('displayNone');
            parent[0].classList.add('displayBlock');
            let filteredData = masterDatas.emrehr.filter(
                suggestion =>
                    suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            );
            filteredData = [...filteredData, ...emrehr.primary]
            setFilteredEmrEhr(filteredData);
            setIsEmrEhrUlVisble(true);
        }
    }

    const clearEmrEhr = () => {
        setUserInput('');
        setFilteredEmrEhr(masterDatas.emrehr);
    }

    return (
        <div id="DivFor_EHREMR">
            <div id="largemodal_EHREMR_1">
                <div id="largemodal_EHREMR_2">
                    <div id="largemodal_EHREMR_3">
                        <div className="card">
                            <div className="col-lg-12 col-md-12">
                                <div className="row">
                                    <div className="card-header" style={{ display: 'block' }}>
                                        <div className="col-lg-12 col-md-12">
                                            <h4>EMR/EHR Systems{profile_builder_flags?.emr_ehr_systems?.isRequired ? '*' : ''}</h4>
                                        </div>
                                        <div className="ms-3" style={{ color: '#13aeeb' }}>Use the search EMR/EHR Systems text box and enter a keyword to search for EMR/EHR Systems containing it or, use the select dropdown box to see all EMR/EHR Systems values in alphabetical order.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                            <div style={{border:"none"}} className="form-control border-none">
                                <div style={{gap:"1rem"}} className="d-flex flex-sm-row flex-column mb-5">
                                    <input type="text" className="form-control me-2"
                                        value={userInput}
                                        onChange={e => setUserInput(e.target.value)}
                                        placeholder="Search EMR/EHR Systems" />
                                    {/* <span className="me-2">Loadding...</span> */}
                                <div style={{rowGap:"0.5rem"}} className="d-flex align-items-center">
                                    <button className="btn btn-primary sw-btn-next me-2" type="button" id="cerification" onClick={searchEmrEhr}>Search</button>
                                    <button className="btn btn-primary sw-btn-next" type="button" onClick={clearEmrEhr}>Clear</button>
                                </div>
                                </div>
                                
                                <span className="emrehr-select" ref={emrehrSelect}>
                                    <DropDown name={'emrehr'} label={profile_builder_flags?.emr_ehr_systems?.isRequired ? 'Select all that apply*' : 'Select all that apply'} labelClassName="fw-bold" id={'emrehr'}
                                        options={filteredEmrEhr} multiple={true} name={'primary'}
                                        updateValue={updateData} isObject={true} value={emrehr.primary}
                                        divClass={'col-lg-12 col-md-12'}
                                        errors={(props.errors && props.errors.emrehr) ? props.errors.emrehr['primary'] : []} />
                                </span>
                            </div>
                            </div>
                            <br></br>
            <br></br>
            <br></br>
            <br></br>
                            <div className="card-body" style={{ display: "none" }}>
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <h4>Other EMR/EHR Systems</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body" style={{ display: "none" }}>
                                <div className="col-lg-12 col-md-12">
                                    {
                                        emrehr.others.map((other, index) => (
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12" id="divAddEMREHRSystem">
                                                    <div style={{gap:"1rem"}} className="row" id="">
                                                        <div className="col-sm">
                                                            <input type="text" value={other} className="form-control"
                                                                id="othercertifyname"
                                                                placeholder="List EMR/EHR Systems"
                                                                onChange={(event) => updateOtherData(event.target, index)} />
                                                        </div>
                                                        <div className="col-sm">
                                                            <a href="#" onClick={(event) => {
                                                                event.preventDefault();
                                                                removeRow(index)
                                                            }}><i className="fe fe-trash-2"></i></a>
                                                        </div>
                                                        <div className="row">
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <a id="btnAddEMREHRSystem" href="#" onClick={addRow}>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-3">
                                                        <i className="icon-2x icon-plus" data-bs-toggle="tooltip"
                                                            title="" data-bs-original-title="icon-plus"
                                                            aria-label="Add Certifications"></i>
                                                    </div>
                                                    <div className="col-lg-6 col-md-3">
                                                        Add New
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
