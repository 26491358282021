import DropDown from "../../common/TextField/dropDown";
import {useDispatch, useSelector} from "react-redux";
import {preferenceSelector} from "./selector";
import {updatePreferredShifts} from "./action";

export default (props) => {
    const dispatch = useDispatch();
    const {preferredShifts, masterDatas} = useSelector(preferenceSelector);
    const updatePreferedShift = (target) => {
        const value = target.value;
        const action = target.action || 'add';
        dispatch(updatePreferredShifts(value, action));
    };
    return (
        <div id="DivFor_Shifts">
            <div id="largemodal_Shifts_1">
                <div id="largemodal_Shifts_2">
                    <div id="largemodal_Shifts_3">
                        <div className="card">
                            <div className="card-header">
                                <h2 className="card-title">Preferred Shifts*</h2>
                            </div>
                            <div className="card-body">
                                <DropDown 
                                name={"preferredShift"} 
                                label={"Select all that apply*"} 
                                labelClassName="fw-bold"
                                options={masterDatas.shifts} 
                                value={preferredShifts} 
                                multiple={true}
                                id={"preferredShift"}
                                updateValue={updatePreferedShift} 
                                isObject={true}
                                errors={props.errors ? props.errors['preferredShifts'] : []}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
