import { useSelector } from "react-redux";
import { loginSelector } from "../../Login/selector";
import useSessionStorage from "../../../hooks/useSessionStorage";

export default () => {
    const storage = useSessionStorage();
    const login = storage.getItem('login');
    const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
    const { profileFlag } = useSelector(loginSelector);
    return (
        <footer className={mobileAuth ? "main-background" : !login?.profileFlag.isProfileSubmitted ? "main-background" : "footer main-background"}>
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-md-12 col-sm-12 text-center">
                        © {new Date().getFullYear()} Overture. All Rights Reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}
