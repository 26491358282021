import { useEffect } from "react";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getDynamicFormData, getProfileBuilderFlagsFields, getTrackingParams, get_login_roles, loginV1 } from "../Login/action";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { loginSelector } from "../Login/selector";
import { useLocation, useNavigate } from "react-router-dom";
import { set_login_roles_flags } from "../ProfileBuilder/slice";
import { getCurrentPersonalInfo, loadMasterData } from "../ProfileBuilder/PersonalInfo/action";
import { getMasterData, getPreference } from "../ProfileBuilder/Preference/action";
import { getCurrentResumeBuilder } from "../ProfileBuilder/ResumeBuilder/action";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import Spinner from "../common/Spinner/spinner";

const MobileAuth = () => {
  // useLocation hook use get a page data
  const location = useLocation();
  // get a data from Login Selector
  const { isSuccess, data, profileFlag } = useSelector(loginSelector);
  const { loadingFlag } = useSelector(profileBuilderSelector);
  const trackingHistoryParams = useSelector(store => store.login.trackingHistoryParams)
  // Dispatch
  const dispatch = useDispatch();
  // Config
  const config = useConfiguration();
  // Session Storage
  const storage = useSessionStorage();
  // Navigation
  const navigate = useNavigate();
  // get a Mobile Auth from session storage
  const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
  // page name got a login page
  const { pageName } = location.state || "";


  useEffect(() => {
    if (isSuccess && data?.loginRoles?.tenantRoles?.at(0)?.status === false) {
        console.log('LOGIN IN');
        config.baseUrl &&
        dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
            console.log('PERSONAL INFO');
          dispatch(
            getCurrentPersonalInfo({
              url: `${config.baseUrl}/Candidates/getpersonalinfo`,
              userId: data.candidateId,
            })
          );
        })
      config.baseUrl &&
        dispatch(getMasterData(config.baseUrl)).then(() => {
          dispatch(getPreference(config.baseUrl, data.candidateId));
        });

        setTimeout(() => {
          dispatch(
            getCurrentResumeBuilder({
              url: `${config.baseUrl}/Candidates/getjobinfo`,
              userId: data.candidateId,
        }))
        },2000)
    }
},[isSuccess])

  // Login API Call
  useEffect(() => {
    if (config.baseUrl && mobileAuth) {
      dispatch(getProfileBuilderFlagsFields(config.baseUrl));
      dispatch(
        loginV1({
          url: `${config.baseUrl}/Authenticate/c/signin`,
          email: mobileAuth.username,
          iv: mobileAuth.iv,
          encryptPassword: mobileAuth.encryptPassword,
          baseUrl: config.baseUrl
        })
      ).then((response) => {
        dispatch(getTrackingParams(config.baseUrl, { ...trackingHistoryParams, userName: mobileAuth.username }, response))
        dispatch(getDynamicFormData(config.baseUrl));
      });
    }
  }, [config.baseUrl]);

  // If Success Navigate a Profile Page
  if (isSuccess) {
    storage.setItem("login", data);
    if (pageName === "bank") {
      navigate("/bankdetail");
    } else if (pageName === "account") {
      navigate("/account")
    } else if (pageName === "updatepassword") {
      navigate("/updatepassword")
    } else if (pageName === "ContactPhone") {
      navigate("/call")
    } else {
      // dispatch(get_login_roles(config.baseUrl)).then((response) => {
      //   let can_edit_profile_filter;
      //   // Create Object for respective names
      //   data?.loginRoles?.tenantRoles?.forEach(element => {
      //   if (element?.roleName === response?.CanEditProfileFilter) {
      //   can_edit_profile_filter = element
      //   }
      //   })
      //   // Set a object for Login Roles
      //   const login_roles_types = {
      //     can_edit_profile_filter
      //   }
  
      //   dispatch(set_login_roles_flags(login_roles_types));
        setTimeout(() => {
            navigate('/profile');
        },1000)
    
    // })
    // navigate('/profile');
    }
  }

  return (
    <div>
      <Loader />
      {/* {loadingFlag > 0 && <Spinner />} */}
    </div>
  );
};

export default MobileAuth;
