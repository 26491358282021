import { useState } from "react";

export default (props) => {
    return (
        
        <div className="col-md-12 col-xl-12">
            <div className="card">

                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            <div className="col-lg-4 col-md-4 jobheader">
                                <b><h4 className="headerfontbold">Education Info</h4></b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <> 
                        <div className="row">
                         <div className="col-lg-3 col-md-3">
                            <label className="form-label">Education Level :</label> </div>
                            <div className="col-lg-4 col-md-4 mt-2">                               
                            <label htmlFor="Location Name">{props?.educationInfo?.levelOfEducation}</label>
                        </div> </div>
                        <div className="row">
                        <div className="col-lg-3 col-md-3">                           
                                <label className="form-label">Language Spoken :</label> </div>                                
                                {
                                    props?.educationInfo?.languagesSpoken.map(language => (
                                        <div className="col-lg-4 col-md-4 mt-2"> <label>{language.name}</label> </div>
                                    ))
                                }
                            </div>
                            {props?.educationInfo?.otherLanguagesSpoken.length?
                            <div className="row">
                            <div className="col-lg-3 col-md-3">
                                <label className="form-label">Other Language Spoken :</label></div>
                                {
                                    props?.educationInfo?.otherLanguagesSpoken.map(language => (
                                        <div className="col-lg-4 col-md-4 mt-2"> <label>{language.name}</label></div>
                                    ))
                                }
                            </div>:''}
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
