import {useDispatch, useSelector} from "react-redux";
import {preferenceSelector} from "./selector";
import React, {useState} from "react";
import {updateHolidayPreference, updatePreferredWorkDays} from "./action";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const dispatch = useDispatch();
    const {preferredWorkDays, holidayPreference, masterDatas} = useSelector(preferenceSelector);

    const [holidayRadio, setHolidayRadio] = useState(holidayPreference.preference)
    const [Weekdaysall, setWeekdaysallValue] = useState(preferredWorkDays.preference)

    const setPrefferedWorkDays = (target) => {
        const value = target.value;
        const checked = target.checked;
        const newWorkDays = {...preferredWorkDays, options: [...preferredWorkDays.options]};
        if (checked) {
            newWorkDays.options = [...masterDatas.preferredWorkDaysRaw.map(item => item.id)];
        } else {
            newWorkDays.options = [];
        }
        dispatch(updatePreferredWorkDays(newWorkDays));
        setWeekdaysallValue(value);
    };
    const setWeekdaysall = (target) => {
        const value = target.value;
        const checked = target.checked;
        const newWorkDays = {...preferredWorkDays};
        newWorkDays.options = [...newWorkDays.options];
        if (checked) {
            newWorkDays.options.push(Number(value));
        } else {
            newWorkDays.options.splice(newWorkDays.options.indexOf(Number(value)), 1);
        }
        dispatch(updatePreferredWorkDays(newWorkDays));
    };
    const setPrefferedHolidaysAll = (target) => {

        const value = target.value;
        const checked = target.checked;
        const newHoliday = {...holidayPreference, options: [...holidayPreference.options]};
        if (checked) {
            newHoliday.options = [...masterDatas.holidayPreferenceRaw.map(item => item.id)];
        } else {
            newHoliday.options = [];
        }
        dispatch(updateHolidayPreference(newHoliday));
        setHolidayRadio(value);
    };

    const setHolidayList = (target) => {
        const value = target.value;
        const checked = target.checked;
        const newHoliday = {...holidayPreference};
        newHoliday.options = [...newHoliday.options];
        if (checked) {
            newHoliday.options.push(Number(value));
        } else {
            newHoliday.options.splice(newHoliday.options.indexOf(Number(value)), 1);
        }
        dispatch(updateHolidayPreference(newHoliday));
    };
    const selectHolidayPreference = (target) => {
        const value = target.value;
        const newHoliday = {...holidayPreference, options: [...holidayPreference.options]};
        newHoliday.preference = Number(value);
        if (Number(value) === 0) {
            newHoliday.options = [];
        }
        if (Number(value) === 1) {
            newHoliday.options = [...masterDatas.holidayPreferenceRaw.map(item => item.id)];
        }
        dispatch(updateHolidayPreference(newHoliday));
        setHolidayRadio(value);
    };
    return (
        <div id="DivFor_Week">
            <div id="largemodal_Week_1">
                <div id="largemodal_Week_2">
                    <div id="largemodal_Week_3">
                        {
                           ( (props?.showModal && props?.name === "preferredWorkDays") ||(!props?.showModal)) && <div className="card">
                           <div className="card-header">
                               <h4 className="card-title">Preferred Work Days</h4>
                           </div>
                           <div className="card-body">
                               <div className="row">


                                   <div className="col-lg-4 col-md-4">
                                       <label className="custom-control custom-checkbox mt-4">
                                           <input type="checkbox" className="custom-control-input" name={"All"}
                                                  value={0} onChange={(event) => setPrefferedWorkDays(event.target)}/>
                                           <span className="custom-control-label">
                                                       {"All"}
                                                   </span>
                                       </label>
                                   </div>
                                   {
                                       Object.keys(masterDatas.preferredWorkDays).map((weekday) => {
                                           return (
                                               <div className={"row"}>
                                                   {
                                                       masterDatas.preferredWorkDays[weekday].map(W => (
                                                           <div className={"col-lg-4 col-md-4"}>
                                                               <label className="custom-control custom-checkbox mt-4">
                                                                   <input type="checkbox"
                                                                          className="custom-control-input"
                                                                          name={W.name}
                                                                          value={W.id}
                                                                          checked={preferredWorkDays.options.indexOf(W.id) > -1}
                                                                          onChange={(event) => setWeekdaysall(event.target)}/>
                                                                   <span className="custom-control-label">
                                                                                   {W.name}
                                                                                </span>
                                                               </label>
                                                           </div>
                                                       ))
                                                   }
                                               </div>
                                           )
                                       })
                                   }

                               </div>
                           </div>
                         
                       </div> 
                        }
                        {
                           ( (props?.showModal && props?.name === "workingHolidays") ||(!props?.showModal) )&&  <div className="card">
                            <div className="card-header">
                                    <h4 className="card-title">Working Holiday Preferences</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="">
                                            <label className="custom-control custom-checkbox mt-4">
                                                <input type="checkbox" className="custom-control-input"
                                                       id="chkboxallholiday" name={"All"}
                                                       value={3}
                                                       onChange={(event) => setPrefferedHolidaysAll(event.target)}/>
                                                <span className="custom-control-label">
                                                            {"All"}
                                                        </span>
                                            </label>
                                        </div>
    <br></br>
                                        {
                                            Object.keys(masterDatas.holidayPreference).map((holiday) => {
                                                return (
                                                    <div className={"verticalalignholiday  mb-4"} >
                                                        {
                                                            masterDatas.holidayPreference[holiday].map(h => (
                                                                <div className={""} >
                                                                    <label className="custom-control custom-checkbox mt-4">
                                                                        <input type="checkbox"
                                                                               className="custom-control-input"
                                                                               name={h.name}
                                                                               value={h.id}
                                                                               checked={holidayPreference.options.indexOf(h.id) > -1}
                                                                               onChange={(event) => setHolidayList(event.target)}/>
                                                                        <span className="custom-control-label">
                                                                                        {h.name}
                                                                                     </span>
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio display-none">
                                                    <input className="custom-control-input" type="radio" id="Holidays1"
                                                           value={0} name="customRadio"
                                                           onChange={(event) => selectHolidayPreference(event.target)}
                                                           checked={holidayPreference.preference === 0}/>
                                                    <label htmlFor="Holidays1" className="custom-control-label">No
                                                        Holidays</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio display-none">
                                                    <input className="custom-control-input" type="radio" id="Holidays2"
                                                           value={1} name="customRadio"
                                                           onChange={(event) => selectHolidayPreference(event.target)}
                                                           checked={holidayPreference.preference === 1}/>
                                                    <label htmlFor="Holidays2" className="custom-control-label">All
                                                        Holidays</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio  display-none">
                                                    <input className="custom-control-input" type="radio" id="Holidays3"
                                                           value={2} name="customRadio"
                                                           onChange={(event) => selectHolidayPreference(event.target)}
                                                           checked={holidayPreference.preference === 2}/>
                                                    <label htmlFor="Holidays3" className="custom-control-label">Working
                                                        Holiday Preferences</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (holidayRadio === '1') && <div className="card-body" id="Someholidayslist">
                                                <div className="form-group">
                                                    {
                                                        Object.keys(masterDatas.holidayPreference).map((holiday) => {
                                                            return (
                                                                <div className={"row"}>
                                                                    {
                                                                        masterDatas.holidayPreference[holiday].map(h => (
                                                                            <div className={"col-lg-4 col-md-4"}>
                                                                                <label
                                                                                    className="custom-control custom-checkbox mt-4">
                                                                                    <input type="checkbox"
                                                                                           className="custom-control-input"
                                                                                           name={h.name}
                                                                                           value={h.id}
                                                                                           checked={holidayPreference.options.indexOf(h.id) > -1}
                                                                                           onChange={(event) => setHolidayList(event.target)}/>
                                                                                    <span className="custom-control-label">
                                                                                        {h.name}
                                                                                     </span>
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            (holidayRadio === '2') && <div className="card-body" id="Someholidayslist">
                                                <div className="form-group">
                                                    {
                                                        Object.keys(masterDatas.holidayPreference).map((holiday) => {
                                                            return (
                                                                <div className={"row"}>
                                                                    {
                                                                        masterDatas.holidayPreference[holiday].map(h => (
                                                                            <div className={"col-lg-4 col-md-4"}>
                                                                                <label
                                                                                    className="custom-control custom-checkbox mt-4">
                                                                                    <input type="checkbox"
                                                                                           className="custom-control-input"
                                                                                           name={h.name}
                                                                                           value={h.id}
                                                                                           onChange={(event) => setHolidayList(event.target)}/>
                                                                                    <span className="custom-control-label">
                                                                                        {h.name}
                                                                                     </span>
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            (holidayRadio === '4') && <div className="card-body" id="Someholidayslist">
                                                <div className="form-group">
                                                    {
                                                        Object.keys(masterDatas.holidayPreference).map((holiday) => {
                                                            return (
                                                                <div className={"row"}>
                                                                    {
                                                                        masterDatas.holidayPreference[holiday].map(h => (
                                                                            <div className={"col-lg-4 col-md-4"}>
                                                                                <label
                                                                                    className="custom-control custom-checkbox mt-4">
                                                                                    <input type="checkbox"
                                                                                           className="custom-control-input"
                                                                                           name={h.name}
                                                                                           value={h.id}
                                                                                           onChange={(event) => setHolidayList(event.target)}/>
                                                                                    <span className="custom-control-label">
                                                                                        {h.name}
                                                                                     </span>
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            (Weekdaysall === '3') && <div className="card-body" id="Weekdayslist">
                                                <div className="form-group">
                                                    {
                                                        Object.keys(masterDatas.preferredWorkDays).map((holiday) => {
                                                            return (
                                                                <div className={"row"}>
                                                                    {
                                                                        masterDatas.preferredWorkDays[holiday].map(h => (
                                                                            <div className={"col-lg-4 col-md-4"}>
                                                                                <label
                                                                                    className="custom-control custom-checkbox mt-4">
                                                                                    <input type="checkbox"
                                                                                           className="custom-control-input"
                                                                                           name={h.name}
                                                                                           value={h.id}
                                                                                           checked={preferredWorkDays.options.indexOf(h.id) > -1}
                                                                                           onChange={(event) => setWeekdaysall(event.target)}/>
                                                                                    <span className="custom-control-label">
                                                                                        {h.name}
                                                                                     </span>
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                       
                    </div>
                </div>
            </div>
        </div>
    )
}
