import { serverToState } from './transformer';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  data: {
    noResume: false,
    resumeFile: {
      name: '',
      content: null,
      displayToName: '',
    },
  },
  workHistory: [
    {
      employer: '',
      fromMonth: '',
      fromYear: '',
      toMonth: '',
      toYear: '',
      flag: false,
    },
  ],
  educationList: [
    {
      historyType: 1,
      name: '',
      fromMonth: '',
      fromYear: '',
      toMonth: '',
      toYear: '',
      flag: false,
    },
  ],
  categories: [],
  certifications: {
    primary: [],
    others: [''],
  },
  specialities: [],

  emrehr: {
    primary: [],
    others: [''],
  },
  educationLevel: '',
  masterDatas: {
    educationLevel: [],
    jobCategories: [],
    emrehr: [],
    specialities: [],
    certifications: [],
  },
  resumeBuilderData: [],
  resumeDownloadLink: ''
};
export const resumeBuilderSlice = createSlice({
  name: 'resumeBuilder',
  initialState,
  reducers: {
    setEducationLevel: (state, action) => {
      state.educationLevel = action.payload;
    },
    setSpecialities: (state, action) => {
      state.specialities = action.payload;
    },
    addCategory: (state, action) => {
      const newCategory = [action.payload];
      state.categories = newCategory;
    },
    removeCategory: (state, action) => {
      const newCategory = state.categories.filter(
        (category) => category.id !== action.payload.id
      );
      state.categories = newCategory;
    },
    addPrimaryEmrEhr: (state, action) => {
      const newPrimary = [...state.emrehr.primary, action.payload];
      state.emrehr.primary = newPrimary;
    },
    removePrimaryEmrEhr: (state, action) => {
      const newPrimary = state.emrehr.primary.filter(
        (item) => item.id !== action.payload.id
      );
      state.emrehr.primary = newPrimary;
    },
    setEmrEhr: (state, action) => {
      state.emrehr = action.payload;
    },
    addPrimaryCertification: (state, action) => {
      const newPrimary = [
        ...state.certifications.primary,
        {
          ...action.payload,
          expirationDate: null,
          fileName: null,
          uploadedCertificatesList: [{ id: 0, name: null, blobName: null }],
        },
      ];
      state.certifications.primary = newPrimary;
    },
    removePrimaryCertification: (state, action) => {
      const newPrimary = state.certifications.primary.filter(
        (cert) => cert.id !== action.payload.id
      );
      state.certifications.primary = newPrimary;
    },
    updatePrimaryCertificationDate: (state, action) => {
      const newPrimary = [
        ...state.certifications.primary.filter(
          (filterValue) => filterValue.id !== action.payload.id
        ),
      ];

      newPrimary.splice(action.payload.index, 0, {
        ...action.payload,
      });
      state.certifications.primary = newPrimary;
    },
    setCertifications: (state, action) => {
      state.certifications = action.payload;
    },
    setEducation: (state, action) => {
      state.educationList = action.payload;
    },
    setWorkHistory: (state, action) => {
      state.workHistory = action.payload;
    },
    setResumeBuilderData: (state, action) => {
      const transformedData = serverToState(action.payload, state.masterDatas);
      const NameToDisplay = action.payload;
      state.workHistory = transformedData.workHistory;
      state.educationList = transformedData.educationList;
      state.categories = transformedData.categories;
      state.certifications = transformedData.certifications;
      state.emrehr = transformedData.emrehr;
      state.specialities = transformedData.specialities;
      state.educationLevel = transformedData.educationLevel;
      state.data = transformedData.data;
      state.data.resumeFile.displayToName = NameToDisplay.resumeFileNameToDisplay;
      state.resumeDownloadLink = action.payload?.resumeDownloadUrl
    },
    setResumeBuilderInitialData: (state, action) => {
      state.resumeBuilderData = action.payload
    },
    setNoResume: (state, action) => {
      state.data.noResume = action.payload;
    },
    setResumeFile: (state, action) => {
      state.data.resumeFile = action.payload;
      state.data.noResume = false;
    },
    setMasterDatas: (state, action) => {
      if (action.payload.educationLevel) {
        state.masterDatas.educationLevel = action.payload.educationLevel;
      }
      if (action.payload.jobCategories) {
        state.masterDatas.jobCategories = action.payload.jobCategories;
      }
      if (action.payload.emrehr) {
        state.masterDatas.emrehr = action.payload.emrehr;
      }
      if (action.payload.certifications) {
        state.masterDatas.certifications = action.payload.certifications;
      }
      if (action.payload.specialities) {
        state.masterDatas.specialities = action.payload.specialities;
      }
    },
    resetLevelBasedDropdownDatas: state => {
      state.certifications = initialState.certifications;
      state.emrehr = initialState.emrehr;
      state.specialities = initialState.specialities;
    },
    resetLevelBasedDatas: state => {
      state.masterDatas = {
        ...state.masterDatas,
        emrehr: [],
        specialities: [],
        certifications: [],
      }
    },
    setDownloadLink: (state, { payload }) => {
      state.resumeDownloadLink = payload
    }
  },
});
export const {
  setResumeBuilderData,
  setWorkHistory,
  setNoResume,
  setEducation,
  setCertifications,
  addPrimaryCertification,
  removePrimaryCertification,
  updatePrimaryCertificationDate,
  setSpecialities,
  setEducationLevel,
  addPrimaryEmrEhr,
  removePrimaryEmrEhr,
  setEmrEhr,
  setResumeFile,
  addCategory,
  removeCategory,
  setMasterDatas,
  resetLevelBasedDropdownDatas,
  resetLevelBasedDatas,
  setResumeBuilderInitialData,
  setDownloadLink
} = resumeBuilderSlice.actions;
export default resumeBuilderSlice.reducer;
