export const domainMappedId = {
    "fiveStar":7,
    "dynamicLifeStyle":8,
    "nursesEtc":9,
    "pureHeart":10,
};

export const tenantIdList = {
    overture:5001,
    fiveStar:5002,
    nursesEtc:5003,
}