import TextField from "../common/TextField/withLabel";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bankDetailSelector } from './selector';
import { updatePaymentDetail } from './action';


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { errors, paymentMode, changeOnlineAccountTypeValuefn } = props;
    const dispatch = useDispatch();
    const { masterData, paymentData, masterOnlineAccountTypeData } = useSelector(bankDetailSelector);
    const [form, setForm] = useState(paymentData);
    const [OnlineAccountTypeshow, setOnlineAccountType] = useState('');
    const [OnlineAccountTypeId, setOnlineAccountTypeId] = useState('');


    // useEffect(() => {
    //     setForm({
    //         ...paymentData ,
    //         paymentMode:paymentMode

    //     });
    // }, [paymentData]);

    const updateFormValue = (target) => {
        setForm({
            ...paymentData,
            [target.name]: target.value
        });
    }

    const changeOnlineAccountTypeValue = (name, id) => {
        setOnlineAccountType(name);
        changeOnlineAccountTypeValuefn(id);
        setForm({
            ...paymentData,
            onlineAccountType: id

        });
    }

    useEffect(() => {
        setOnlineAccountType(paymentData?.onlineAccountTypeText);
        changeOnlineAccountTypeValuefn(paymentData?.onlineAccountType);
    }, [paymentData]);

    useEffect(() => {
        dispatch(updatePaymentDetail(form));
    }, [form]);
    return (
        <div className="card-body">

            <div className="row">

                {<>
                    {
                        masterOnlineAccountTypeData?.onlineAccountTypes?.map((item) => (
                            <div className="col-md-4">
                                <label className="custom-control custom-radio">
                                    <input type="radio" name={'onlineAccountType'}
                                        checked={item.id === paymentData.onlineAccountType}
                                        onChange={() => { changeOnlineAccountTypeValue(item.name, item.id) }}
                                        value={paymentData.onlineAccountType} />
                                    <img style={{ width: "100px", height: "40px" }} src={item.id === paymentData.onlineAccountType ? `assets/images/payments/${item.name?.toLowerCase()}.png` : `assets/images/payments/${item.name?.toLowerCase()}gray.png`} />
                                </label>
                            </div>
                        ))}
                    {errors['onlineAccountType'] && errors['onlineAccountType'].map(error => (<div className="invalid-input">{error}</div>))}
                </>}

            </div>

            <div className="row">
                <TextField name={'onlineAccountHolderName'} label={'Name*'} placeholder={"Name"}
                    updateValue={updateFormValue} value={paymentData.onlineAccountHolderName} errors={errors['onlineAccountHolderName']} />
                <TextField name={'username'} label={'User Name*'} placeholder={"User Name"}
                    updateValue={updateFormValue} value={paymentData.username} errors={errors['username']} />
            </div>
            <div className="row">
                <TextField name={'accountEmailId'} label={'Email*'} placeholder={"Email "}
                    updateValue={updateFormValue} value={paymentData.accountEmailId} errors={errors['accountEmailId']} />
                <TextField name={'accountPhoneNumber'} label={'Phone Number*'} placeholder={"Phone Number"}
                    updateValue={updateFormValue} value={paymentData.accountPhoneNumber} errors={errors['accountPhoneNumber']} format={"phone"} />

            </div>
        </div>
    )
} 