export const serverToState = (serverData, masterDatas) => {

    const data = {
        preferredShifts: serverData.preferredShiftList.map(shift => {
            return {...masterDatas.shifts.find(sh => sh.id === shift)};
        }),

        preferredShiftTimeOfTheDay: serverData.preferredShiftTimeOfTheDay.map(shiftTimeOfTheDay => {
            return {...masterDatas.shiftTimeOfTheDay.find(sh => sh.id === shiftTimeOfTheDay)};
        }  ),

        travelDistance: {...masterDatas.travelDistance.find(dist => dist.id === serverData.travelDistance)},

        preferredWorkDays: {
            preference: serverData.workOnWorkDays,
            options: serverData.preferredWorkDaysList
        },
        holidayPreference: {
            preference: serverData.workOnHolidays,
            options: serverData.preferredHolidaysToWorkList
        },
        vaccination: serverData.covid19Vaccinated
    }

        return data;
}
export const stateToServer = (stateData, candidateId, appUserId) => {

    const data = {
        candidateId, appUserId,
        preferredShiftList: stateData.preferredShifts.map(shift => shift.id),
        candidatesPreferredShiftTimeOfTheDay: stateData.preferredShiftTimeOfTheDay.map(shiftTimeOfTheDay => shiftTimeOfTheDay.id),
        travelDistance: stateData.travelDistance.id,
        //preferredWorkDaysAny: stateData.preferredWorkDays.includes('any'),
        workOnWorkDays: stateData.preferredWorkDays.preference,
        preferredWorkDaysList: stateData.preferredWorkDays.options,
        workOnHolidays: stateData.holidayPreference.preference,
        preferredHolidaysToWorkList: stateData.holidayPreference.options,
        covid19Vaccinated: stateData.vaccination
    }

        return data;

}
