import requestUtil from '../../../helpers/requestUtil';
import { setLoading } from '../../common/Loader/slice';
import * as Toasts from '../../common/Toastify';
import { updateProfileFlag } from '../../Login/action';
import { nextTab } from '../action';
import { setError, setLoadingFlagDecrement, setLoadingFlagIncrement } from '../slice';
import {
  addCategory,
  addPrimaryCertification,
  addPrimaryEmrEhr,
  removeCategory,
  removePrimaryCertification,
  removePrimaryEmrEhr,
  resetLevelBasedDatas,
  resetLevelBasedDropdownDatas,
  setCertifications,
  setEducation,
  setEducationLevel,
  setEmrEhr,
  setMasterDatas,
  setNoResume,
  setResumeBuilderData,
  setResumeBuilderInitialData,
  setResumeFile,
  setSpecialities,
  setWorkHistory,
  updatePrimaryCertificationDate,
  setDownloadLink
} from './slice';
import { stateToServer } from './transformer';
import { setJobCategory } from '../../Login/slice'
import useSessionStorage from '../../../hooks/useSessionStorage';
import { toast } from 'react-toastify';

export function updateEducationLevel(level) {
  return (dispatch) => {
    dispatch(setEducationLevel(level));
  };
}

export function updateNoResume(data) {
  return (dispatch) => {
    dispatch(setNoResume(data));
  };
}

export function updateWorkHistory(workHistory) {
  return (dispatch) => {
    dispatch(setWorkHistory(workHistory));
  };
}

export function updateEducation(education) {
  return (dispatch) => {
    dispatch(setEducation(education));
  };
}

export function updateSpeciality(speciality) {
  return (dispatch) => {
    dispatch(setSpecialities(speciality));
  };
}

export function updatePrimaryCertification(cert, action) {
  return (dispatch) => {
    switch (action) {
      case 'add':
        dispatch(addPrimaryCertification(cert));
        break;
      case 'remove':
        dispatch(removePrimaryCertification(cert));
        break;
      default:
        break;
    }
  };
}

export function updatePrimaryEmrEhr(emrEhr, action) {
  return (dispatch) => {
    switch (action) {
      case 'add':
        dispatch(addPrimaryEmrEhr(emrEhr));
        break;
      case 'remove':
        dispatch(removePrimaryEmrEhr(emrEhr));
        break;
      default:
        break;
    }
  };
}
export function updateCategory(category) {
  return (dispatch) => {
    dispatch(addCategory(category));
  };
}
// export function updateCategory(category, action) {
//     return (dispatch) => {
//         switch (action) {
//             case 'add':
//                 dispatch(addCategory(category));
//                 break;
//             case 'remove':
//                 dispatch(removeCategory(category));
//                 break;
//             default:
//                 break;
//         }
//     };
// }

export function updateEmrEhr(emrEhr) {
  return (dispatch) => {
    dispatch(setEmrEhr(emrEhr));
  };
}

export function updateCertification(certification) {
  return (dispatch) => {
    dispatch(setCertifications(certification));
  };
}

export function getCurrentResumeBuilderJobCatIdList({ url, userId }) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    try {
      const response = await requestUtil(`${url}/${userId}`, 'GET');
      dispatch(setResumeBuilderInitialData(response));
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}

export function getCurrentResumeBuilder({ url, userId }) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    const storage = useSessionStorage();
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    try {
      const response = await requestUtil(`${url}/${userId}`, 'GET');
      if (login_roles_flags_list?.can_edit_profile_filter?.status) {
      dispatch(setResumeBuilderData(response));
      }
      // dispatch(setLoading(false));
      return response
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}

export function saveResumeBuilder({
  url,
  candidateId,
  userId,
  gotoNextTab = true,
  flag,
}) {
  return async (dispatch, getState) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    const stateData = getState().resumeBuilder;
    const transformedData = stateToServer(stateData, candidateId, userId);
    try {
      const response = await requestUtil(
        `${url}/Candidates/savejobinfo`,
        'POST',
        transformedData
      );
      if (gotoNextTab) {
        dispatch(nextTab(flag));
        dispatch(updateProfileFlag('isJobInfoCompleted'));
      }
      const jobCategories = stateData.categories?.length ? stateData.categories[0].name : '';
      // console.log('=================', jobCategories);
      dispatch(setJobCategory(jobCategories));
      // dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}

export function updateResumeFile(file) {
  return (dispatch) => {
    dispatch(setResumeFile(file));
  };
}

export function deleteResumeFile(baseUrl, candidateId, userId, resumeFile) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    try {
      await requestUtil(`${baseUrl}/Candidates/deletefile`, 'POST', {
        candidateId,
        userId,
        resumeFileName: resumeFile,
        fileType: 1,
      });
      dispatch(
        updateResumeFile({
          name: '',
        })
      );
      dispatch(setNoResume(true));
      dispatch(setDownloadLink(''))
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}

export function loadMasterDatas(url, jobId) {
  return async dispatch => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    try {
      const educationLevel = await requestUtil(`${url}/Dropdown/GetLevelOfEducationsV2/${jobId}`, 'GET');
      const jobCategories = await requestUtil(`${url}/Dropdown/getjobcategories/${jobId}`, 'GET');
      dispatch(setMasterDatas({
        educationLevel: educationLevel,
        jobCategories,
      }));
      // dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error.message))
      // dispatch(setLoading(false));
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
  };
}

// Level based dropdown based API data
export function getLevelBasedAPIDatas(url, jobId, candidateUniquepramId, jobCatId) {
  return async (dispatch, getState) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    const state = getState();
    const { certifications: cerficationsDrpodown, specialities: specialitiesDropdown, emrehr: emrehrDropdown } = state.resumeBuilder;
    try {
      const certifications = await requestUtil(`${url}/Dropdown/GetCertificationsbyJobLevel`, 'POST', {
        jobTypeId: jobId,
        candidateUniqueId: candidateUniquepramId,
        jobLevel: jobCatId
      });

      const emrehr = await requestUtil(`${url}/Dropdown/getemrehrsystemsByJobLevel`, 'POST', {
        jobTypeId: jobId,
        candidateUniqueId: candidateUniquepramId,
        jobLevel: jobCatId
      });

      const specialities = await requestUtil(`${url}/Dropdown/getspecialtiesByJobLevel`, 'POST', {
        jobTypeId: jobId,
        candidateUniqueId: candidateUniquepramId,
        jobLevel: jobCatId
      });

      const levelBasedDatas = {
        certifications,
        emrehr,
        specialities
      }
      dispatch(setMasterDatas({
        ...levelBasedDatas
      }));


      if (specialitiesDropdown.length > 0) {
        const specialityMasterIdList = specialities?.map(item => item.id);
        const specialityFilteredResult = specialitiesDropdown?.filter(item => specialityMasterIdList?.indexOf(item?.skill) > -1);
        dispatch(setSpecialities(specialityFilteredResult?.length > 0 ? specialityFilteredResult : []))
      }
      if (emrehrDropdown?.primary?.length > 0) {
        const emrehrMasterIdList = emrehr?.map(item => item.id);
        const emrehrFilteredResult = emrehrDropdown?.primary?.filter(item => emrehrMasterIdList?.indexOf(item?.id) > -1);
        dispatch(setEmrEhr({
          primary: emrehrFilteredResult?.length > 0 ? emrehrFilteredResult : [],
          others: ['']
        }))
      }
      if (cerficationsDrpodown?.primary?.length > 0) {
        const certificationMasterIdList = certifications?.map(item => item.id);
        const certificationFilteredResult = cerficationsDrpodown?.primary?.filter(item => certificationMasterIdList?.indexOf(item?.id) > -1);
        dispatch(setCertifications({
          primary: certificationFilteredResult?.length > 0 ? certificationFilteredResult : [],
          others: ['']
        }))
      }
      // dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}

// Level based dropdown reset dropdown
export function resetLevelBasedDropdown() {
  return async (dispatch) => {
    dispatch(resetLevelBasedDropdownDatas());
  };
}

// Level based datas
export function resetLevelBasedDatasList() {
  return async (dispatch) => {
    dispatch(resetLevelBasedDatas());
  };
}



export function uploadResume(url, userId, file) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    try {
      const formdata = new FormData();
      formdata.append('file', file);
      const response = await requestUtil(
        `${url}/Candidates/resumeupload?CandidateId=${userId}`,
        'POST',
        formdata,
        null,
        true
      );
      console.log(response?.uri);
      if (response) {
        dispatch(setDownloadLink(response?.uri))
      }
    } catch (error) {
      dispatch(
        updateResumeFile({
          name: '',
        })
      );
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}

export function uploadCertificate(url, candidateUniqueId, candidateId, file) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    try {
      const formdata = new FormData();
      formdata.append('file', file);
      const response = await requestUtil(
        `${url}/Candidates/certificationsUpload/${candidateUniqueId}/${candidateId}`,
        'POST',
        formdata,
        null,
        true
      );
      // dispatch(setLoading(false));
      toast.success('Uploaded Successfully')
      return response;
    } catch (error) {
      // dispatch(setError(error.message));
      toast.error(error.message);
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}

export function deleteUploadCertificate(url, requestJson) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFlagIncrement())
    try {
      const response = await requestUtil(
        `${url}/Candidates/deletecertificationuploadedfile`,
        'POST',
        requestJson
      );
      // dispatch(setLoading(false));
      Toasts.success(response.message);
      return response;
    } catch (error) {
      // dispatch(setError(error.message));
      Toasts.error(error.message);
    } finally {
      dispatch(setLoadingFlagDecrement())
    }
    // dispatch(setLoading(false));
  };
}



export const downloadUploadedDocumentResumeBuilder = (url,candidateUniqueId, sectionId, fieldId, fileId,blobName,fileName,accessToken) => {
  return async (dispatch, getState) => {
      // dispatch(setLoading(true));
      const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
      dispatch(setLoadingFlagIncrement())
      try {
          const requestHeaders = {
              'Content-Type': 'application/json',
              'Authorization': accessToken ? `Bearer ${accessToken}` : '',
              'Website-Domain-Name': window.location.hostname === 'localhost' ? 'nursejobdev.overturexl.com' : window.location.hostname,
          };
          const params = {
              "blobName": blobName ?? "",
              "attachedFileName": fileName ?? "",
              "blobNameAttachmentId": parseInt(fileId),
              "isNotSavedFile": parseInt(fileId) > 0 || parseInt(fileId) !== 0 ? true : false
          }
          if(mobileAuth){
            const response = await fetch(`${url}/Candidates/download-candidatecerification-document-mobilebrowser`, {
              headers:{...requestHeaders},
              method:"POST",
              body:JSON.stringify(params)
          })
            const mobileURL = await response.json();
            window.location.href =`${mobileURL}?${fileName}`
        } else {
          const response = await fetch(`${url}/Candidates/download-candidatecerification-document`, {
            headers:{...requestHeaders},
            method:"POST",
            body:JSON.stringify(params)
        })
        const blob = await response.blob();
        let downnloadURL = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = downnloadURL;
        a.download = fileName;
        a.click();
        }
      } catch (error) {
          dispatch(setError(error.message))
      } finally {
          dispatch(setLoadingFlagDecrement())
      }
      // dispatch(setLoading(false));
  }
}