import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loadingFlag: 0,
    data: {},
    errorMessage: null
}

export const dashboardSlice = createSlice({
    name: 'dashboard', initialState,
    reducers: {
        loadCandidateDashboard: (state, action) => {
            state.data = action.payload;
            state.errorMessage = null;
        },
        loadCandidateDashboardError: (state, action) => {
            state.data = {};
            state.errorMessage = action.payload;
        },
        setLoadingFlagIncrement: (state) => {
            state.loadingFlag = state.loadingFlag + 1;
        },
        setLoadingFlagDecrement: (state) => {
            if (state.loadingFlag !== 0) {
              state.loadingFlag = state.loadingFlag - 1;
            }
        },
    }
});
export const {
    loadCandidateDashboard,
    loadCandidateDashboardError,
    setLoadingFlagIncrement,
    setLoadingFlagDecrement
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
