export default (props) => {
  return (
    <div className="col-md-12 col-xl-12">
      <div className="card">
        <div className="col-lg-12 col-md-12">
          <div className="row">
            <div className="card-header">
              <div className="col-lg-4 col-md-4 jobheader">
                <b>
                  <h4 className="headerfontbold">Claimed Info</h4>
                </b>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <div className="row">
              {/* Column 1 */}
              <div className="col d-flex flex-column gap-3">
                <div className="row">
                  <div className="col-lg-5 fw-bold">Claimed on :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.claimedDateToDisplay}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 fw-bold">Checked In :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.isCheckedIn === false ? (
                      <div>No</div>
                    ) : (
                      <div>Yes</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 fw-bold">Checked In Date :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.checkedInDateToDisplay}
                  </div>
                </div>
              </div>
              {/* Column 2 */}
              <div className="col d-flex flex-column gap-3">
                <div className="row">
                  <div className="col-lg-5 fw-bold">Checked Out :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.isCheckedOut === false ? (
                      <div>No</div>
                    ) : (
                      <div>Yes</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 fw-bold">Checked Out Date :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.checkedOutDateToDisplay}
                  </div>
                </div>
              </div>
              {/* Column 3 */}
              <div className="col d-flex flex-column gap-3">
                <div className="row">
                  <div className="col-lg-5 fw-bold">Approvals by :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.approversList?.map(
                      (approvar) => {
                        return (
                          <div>
                            {approvar.approverName}
                            {approvar.isApproved === true ? (
                              <div>(Yes)</div>
                            ) : (
                              <div>(No)</div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 fw-bold">Paid :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.isPaid === true ? (
                      <div>Yes</div>
                    ) : (
                      <div>No</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 fw-bold">Paid Date :</div>
                  <div className="col">
                    {props?.claimedCandidateInfo?.paidDateToDisplay}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
