import requestUtil from '../../helpers/requestUtil';
import { setLoading } from '../common/Loader/slice';
import { resetError, setError, setMessages,setTotalCount } from './slice';

export function resetErrorStatus() {
    return async dispatch => {
        dispatch(resetError());
    };
}

export function getMessagesData({ url, params }) {
    return async dispatch => {
       // dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setMessages(response.candidateEmployerMessages));
            dispatch(setTotalCount(response.totalCount));
        } catch (error) {
            dispatch(setError(error.message))
        }
        // console.log(setMessages);
        //dispatch(setLoading(false));
    };
}