import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useConfiguration from "../../../hooks/useConfiguration";
import useSessionStorage from "../../../hooks/useSessionStorage";
import { getMasterData, getPreference } from './action';
import CovidForm from "./covidForm";
import './index.css';
import PreferredDaysForm from "./preferredDaysForm";
import ShiftsForm from "./shiftsForm";
import TravelDistanceForm from "./travelDistanceForm";
import ShiftTimeOfDay from "./shiftTimeOfDay";

export default (props) => {
    const dispatch = useDispatch();
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    useEffect(() => {
        config.baseUrl && dispatch(getMasterData(config.baseUrl)).then(() => {
            dispatch(getPreference(config.baseUrl, login.candidateId));
        });
        window.scrollToTop && window.scrollToTop();
    }, [config]);
    return (
        <div id="step-12" className="tab-pane step-content block-display" style={{ marginBottom: '70px' }}>
            <form>

                <TravelDistanceForm errors={props.preferenceErrors} />
                <PreferredDaysForm />
                <CovidForm errors={props.preferenceErrors} />
                <ShiftsForm errors={props.preferenceErrors} />
                <ShiftTimeOfDay errors={props.preferenceErrors}/> 
            </form>
        </div>
    )
}
