import { setLoading } from "../common/Loader/slice";
import {
  setPage,
  shiftLoadingFailed,
  updateCancelClaim,
  updatePageSize,
  setSorting,
} from "./slice";
import requestUtil from "../../helpers/requestUtil";

const createLoadRequest = (
  page,
  pageSize,
  sortOrder,
  sortColumn
) => {
  const request = {
    page: page,
    pageSize: pageSize,
  };

  if (sortColumn && sortColumn.length > 0) {
    request.sortOrder = sortOrder;
    request.sortColumn = sortColumn;
  }
  return request;
};

export function loadcancelclaim(url, pageName) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const state = getState();
      const { page, pageSize, sortOrder, sortColumn } =
        state.cancelclaim.unclaimed;
      const request = createLoadRequest(page, pageSize, sortOrder, sortColumn);
      const CancelClaimHistory = await requestUtil(
        `${url}/CandidatesJobs/get-my-cancelled-shifts`,
        "POST",
        request
      );
      dispatch(
        updateCancelClaim({
          data: CancelClaimHistory,
          page: page,
          pageName,
        })
      );
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(shiftLoadingFailed(e.message));
      dispatch(setLoading(false));
    }
  };
}
export function nextPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page, pageSize, data } = state.cancelclaim[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: page + 1 > lastPage ? lastPage : page + 1,
      })
    );
  };
}
export function firstPage(pageName) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: 1,
      })
    );
  };
}
export function gotoPage(pageName, pageNumber) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: pageNumber,
      })
    );
  };
}
export function previousPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page } = state.cancelclaim[pageName];
    dispatch(
      setPage({
        pageName,
        page: page - 1 < 1 ? 1 : page - 1,
      })
    );
  };
}
export function lastPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { pageSize, data } = state.cancelclaim[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: lastPage,
      })
    );
  };
}
export function pageSize(pageName, size) {
  return async (dispatch) => {
    dispatch(
      updatePageSize({
        pageName,
        pageSize: size,
      })
    );
  };
}

export function updateSort(pageName, order, column) {
  return async (dispatch) => {
    dispatch(
      setSorting({
        pageName,
        sortOrder: order,
        sortColumn: column,
      })
    );
  };
}
