import { setLoading } from "../common/Loader/slice";
import {
  setPage,
  shiftLoadingFailed,
  updateEncounterjobinfo,
  updatePageSize,
  setSorting,
  setClaimUnclaimMsgResetEncounterJobInfo,
  setClaimUnclaimSuccessMessageEncounterJobInfo,
  setClaimUnclaimErrorMessageEncounterJobInfo,
  updateOccurrenceData,
} from "./slice";
import requestUtil from "../../helpers/requestUtil";
import * as Toasts from "../common/Toastify";
const createLoadRequest = (page, pageSize, sortOrder, sortColumn) => {
  const request = {
    page: page,
    pageSize: pageSize,
  };
  if (sortColumn && sortColumn.length > 0) {
    request.sortOrder = sortOrder;
    request.sortColumn = sortColumn;
  }
  return request;
};

export function loadShifts(url, params, subUrl) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    let data
    const state = getState();
    const { page, pageSize, sortOrder, sortColumn } =
    state.encounterjobinfo.encounterjobinfo;
    try {

      const request = createLoadRequest(page, pageSize, sortOrder, sortColumn);
      const response = await requestUtil(`${url}/${subUrl}`, "POST", {
        ...request,
        ...params,
      });
       data = response;
      dispatch(
        updateEncounterjobinfo({
          data: data,
          page: page,
          pageName: "encounterjobinfo",
        })
      );
      dispatch(setLoading(false));
    } catch (e) {
      if (!data) {
      dispatch(
        updateEncounterjobinfo({
          data: [],
          page: page,
          pageName: "encounterjobinfo",
        })
      );
    }
      dispatch(setLoading(false));
    }
  };
}
export function nextPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page, pageSize, data } = state.encounterjobinfo[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: page + 1 > lastPage ? lastPage : page + 1,
      })
    );
  };
}
export function firstPage(pageName) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: 1,
      })
    );
  };
}
export function gotoPage(pageName, pageNumber) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: pageNumber,
      })
    );
  };
}
export function previousPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page } = state.encounterjobinfo[pageName];
    dispatch(
      setPage({
        pageName,
        page: page - 1 < 1 ? 1 : page - 1,
      })
    );
  };
}
export function lastPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { pageSize, data } = state.encounterjobinfo[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: lastPage,
      })
    );
  };
}
export function pageSize(pageName, size) {
  return async (dispatch) => {
    dispatch(
      updatePageSize({
        pageName,
        pageSize: size,
      })
    );
  };
}

export function updateSort(pageName, order, column) {
  return async (dispatch) => {
    dispatch(
      setSorting({
        pageName,
        sortOrder: order,
        sortColumn: column,
      })
    );
  };
}

export function cancelclaim(
  url,
  candidateId,
  jobId,
  claimedstatus,
  pageName,
  jobsClaimId,
  starttime,
  endtime,
  unClaimReasonId,
  otherUnClaimReason,
  jobsShiftUniqueId,
  occurranceDate
) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(
        `${url}/CandidatesJobs/encounter-unclaimoccurrance`,
        "POST",
        {
          candidateUniqueId: candidateId,
          jobuniqueid: jobId,
          claimedstatus: claimedstatus,
          jobsClaimId: jobsClaimId ? jobsClaimId : 0,
          breakType: 0,
          starttime: starttime,
          endtime: endtime,
          isParent: false,
          unClaimReasonId: unClaimReasonId,
          otherUnClaimReason: otherUnClaimReason,
          jobsShiftUniqueId: jobsShiftUniqueId,
          occurranceDate: occurranceDate,
        }
      );
      dispatch(setClaimUnclaimSuccessMessageEncounterJobInfo(response.message));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setClaimUnclaimSuccessMessageEncounterJobInfo(error.message));
      dispatch(setLoading(false));
    }
  };
}

export const updateClaimandUnclaimMsgEncounterJobInfo = (data) => {
  return async (dispatch) => {
    dispatch(setClaimUnclaimMsgResetEncounterJobInfo(data));
  };
};
