import { setLoading } from "../common/Loader/slice";
import { updateJobDetails, updateJobFailed } from "./slice";
import requestUtil from "../../helpers/requestUtil";

export function getJobDetails(url, jobId, id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const state = getState();
            const jobDetails = await requestUtil(`${url}/Candidates/jobdetail/${jobId}`, 'GET');
            // const educationLevelValue = await requestUtil(`${url}/Dropdown/GetLevelOfEducations`, 'GET');
            // const educationLevelValue = await requestUtil(`${url}/Dropdown/GetLevelOfEducationsV2/${id}`, 'GET');
            //    dispatch(updateJobDetails({
            //     data: {
            //         jobDetails: jobDetails,
            //         educationDetails: educationLevelValue
            //     }
            // }));
            dispatch(updateJobDetails({
                data: {
                    jobDetails: jobDetails,
                }
            }));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(updateJobFailed(e.message));
            dispatch(setLoading(false));
        }
    }
}
export function getJobDetailsView(url, candidateId, jobId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const state = getState();
            const jobDetails = await requestUtil(`${url}/Candidates/jobdetail/${jobId}`, 'GET');
            // const educationLevelValue = await requestUtil(`${url}/Dropdown/GetLevelOfEducations`, 'GET');
            const educationLevelValue = await requestUtil(`${url}/Dropdown/GetLevelOfEducationsV2/${jobId}`, 'GET');
            dispatch(updateJobDetails({
                data: {
                    jobDetails: jobDetails,
                    educationDetails: educationLevelValue
                }
            }));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(updateJobFailed(e.message));
            dispatch(setLoading(false));
        }
    }
}
