import { paginationCalculator } from "pagination-calculator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import DataGrid from "../common/DataGrid";
import Header from "../common/Header";
import Loader from "../common/Loader";
import Sidebar from "../common/Sidebar";
import JobDetails from "../JobDetails/jobDetails";
import {
  updateSelectedJobDetails,
  updateClaimandUnclaimMsg,
} from "../Shifts/action";
import { shiftsSelector } from "../Shifts/selector";
import { headersData } from "./MultishitTableHeader";
import {
  firstPage,
  gotoPage,
  lastPage,
  loadcancelclaim,
  nextPage,
  pageSize,
  previousPage,
  updateSort,
} from "./action";
import { cancelclaimhistorySelector } from "./selector";
import { Modal } from "react-bootstrap";
import { CancelClaimHistoryTableHeaders } from "./CancelClaimHistoryTableHeaders";
import { EncounterTableHeaders } from "./EncounterTableHeader";

export default () => {
  // Hooks
  const dispatch = useDispatch();
  const config = useConfiguration();
  const storage = useSessionStorage();
  const navigate = useNavigate();

  // Cancel Claim History Selector
  const { unclaimed } = useSelector(cancelclaimhistorySelector);

  // Shift Selector
  const { selectedJob, claimUnclaimErrorMessage, claimUnclaimSuccessMessage } =
    useSelector(shiftsSelector);

  // get from details in local storage
  const login = storage.getItem("login");

  // Cancel Claim History Table Headers
  const headers = CancelClaimHistoryTableHeaders;

  // Cancel Claim History Master Page API Method
  useEffect(() => {
    document.title = "Cancel Claim History";
    if (config.baseUrl) {
      if (!login) {
        navigate("/");
      } else {
        dispatch(loadcancelclaim(config.baseUrl, "unclaimed"));
      }
    }
  }, [config]);

  const clearJobDetailsView = () => {
    dispatch(updateSelectedJobDetails(""));
  };

  // Multishift Job Info Page
  const MultiShiftJobInfo = (params) => {
    navigate("/multishiftjobinfo", {
      state: {
        params: params,
        headersData: headersData,
        subUrl: "CandidatesJobs/get-my-cancelled-shifts-occurrences",
      },
    });
  };

  // Encounter Occuerence Page Info Function
  const EncounterJobInfo = (params) => {
    navigate("/encounterjobinfo", {
      state: {
        params: params,
        subUrl:
          "CandidatesJobs/get-cancelledClaimHistory-encounter-occurrences",
        EncounterTableHeaders: EncounterTableHeaders,
      },
    });
  };

  // Table Content Manupulation area
  const generateRowData = (data, headers) => {
    const rowData = {};
    headers.forEach((header) => {
      // Text
      if (header.type === "text") {
        rowData[header.name] = data[header.field];
      } else if (header.field === "ratingChange") {
        rowData[header.name] = {
          type: "RatingHistory_ratings",
          value: data[header.field],
        };
      }
      // View Job Detials
      if (header.field === "viewJobDetails") {
        rowData[header.name] = {
          type: "icon",
          class: `icon-align ${header.class}`,
          onClick: () => {
            dispatch(updateSelectedJobDetails(data["jobUniqueId"]));
          },
        };
      }
      // Shift date
      if (header.field === "shiftDate") {
        if (data?.shiftType === 3) {
          rowData[header.name] = (
            <span className="text-wrap" style={{ width: "6rem" }}>
              {data?.recurrenceDetail}
            </span>
          );
        } else {
          rowData[header.name] = data?.shiftDate;
        }
      }
      // MultiShift
      if (header.field === "multiShift") {
        const shiftType = data?.shiftType;
        if (shiftType === 3) {
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-binoculars icon-align`,
            onClick: () => {
              const params = {
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              };
              MultiShiftJobInfo(params);
            },
          };
        } else if (shiftType === 2) {
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-hourglass icon-align`,
            onClick: () => {
              const params = {
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              };
              EncounterJobInfo(params);
            },
          };
        } else {
          rowData[header.name] = {
            type: "icon",
            class: ``,
            onClick: () => {},
          };
        }
      }
    });
    return rowData;
  };
  const createPageData = (page, pageSize, totalCount) => {
    if (page === 1) {
      return {
        from: page,
        to: pageSize,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    } else {
      const pageData = paginationCalculator({
        total: totalCount,
        current: page,
        pageSize: pageSize,
      });
      return {
        from: pageData.showingStart,
        to: pageData.showingEnd,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    }
  };

  // Table Pagination
  const gotoFirstPage = () => {
    dispatch(firstPage("unclaimed")).then(() => {
      dispatch(
        loadcancelclaim(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoNextPage = () => {
    dispatch(nextPage("unclaimed")).then(() => {
      dispatch(
        loadcancelclaim(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoPreviousPage = () => {
    dispatch(previousPage("unclaimed")).then(() => {
      dispatch(
        loadcancelclaim(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoLastPage = () => {
    dispatch(lastPage("unclaimed")).then(() => {
      dispatch(
        loadcancelclaim(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoSelectedPage = (page) => {
    dispatch(gotoPage("unclaimed", page)).then(() => {
      dispatch(
        loadcancelclaim(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const setPageSize = (size) => {
    dispatch(pageSize("unclaimed", size)).then(() => {
      dispatch(
        loadcancelclaim(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };

  // Table format and data maping
  const formatTableData = (dataList) => {
    const tableData = {
      headers,
      rows: dataList?.data?.jobCancelCancelledShifts?.map((data) => ({
        columns: generateRowData(data, headers),
      })),
      options: {
        sortable: false,
        filter: false,
        pagination: true,
        pageSize: dataList?.pageSize || 10,
      },
      pageData: createPageData(
        dataList?.page,
        dataList?.pageSize,
        dataList?.data?.totalCount
      ),
      firstPage: gotoFirstPage,
      nextPage: gotoNextPage,
      previousPage: gotoPreviousPage,
      lastPage: gotoLastPage,
      updatePageSize: setPageSize,
      gotoPage: gotoSelectedPage,
      headerComponentParams: {
        sort: (order, column) => {
          dispatch(updateSort("unclaimed", order, column)).then(() => {
            dispatch(
              loadcancelclaim(
                config.baseUrl,
                login.candidateUniqueId,
                "unclaimed"
              )
            );
          });
        },
        headers,
      },
    };
    return tableData;
  };

  const refreshJobList = () => {
    dispatch(
      loadcancelclaim(config.baseUrl, login.candidateUniqueId, "unclaimed")
    );
    dispatch(updateClaimandUnclaimMsg(null));
  };

  const errorMessage = claimUnclaimErrorMessage !== null;
  const successMessage = claimUnclaimSuccessMessage !== null;

  return (
    <div className="page">
      <Loader />
      <Header />
      <Sidebar />
      <Modal
        show={errorMessage || successMessage}
        size={"lg"}
        centered={true}
        title="Error"
      >
        <Modal.Header>
          <Modal.Title>{errorMessage ? "Error" : "Success"}</Modal.Title>
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={refreshJobList}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <div
              className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-autohide="false"
            >
              <div className="toast-body">{claimUnclaimErrorMessage}</div>
            </div>
          )}
          {successMessage && (
            <div className="modal-body text-center p-4 pb-5">
              <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
              <h4>{claimUnclaimSuccessMessage}</h4>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={refreshJobList}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {selectedJob !== "" ? (
        <JobDetails
          jobId={selectedJob}
          returnToGrid={clearJobDetailsView}
          isReadOnly={true}
        />
      ) : (
        <div className="page-main">
          <div className="main-content app-content mt-0 main-background">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <h1 className="page-title">My Unclaim Shift History</h1>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div
                            className={
                              "col-md-12 col-lg-12 col-xl-12 outer-div"
                            }
                          >
                            <DataGrid
                              {...formatTableData(unclaimed)}
                              headerSpace={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
