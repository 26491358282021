export const EncounterTableHeaders = [
  {
    name: "Shift Date",
    field: "occurrenceDateToDisplay",
    type: "text",
    sort: true,
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Start Time",
    field: "shiftStartTime",
    type: "text",
    sort: true,
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "End Time",
    field: "shiftEndTime",
    type: "text",
    sort: true,
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "CheckIn",
    field: "isCheckedIn",
    type: "Myshift_color",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "CheckOut",
    field: "isCheckedOut",
    type: "Myshift_color",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Approver",
    field: "approversList",
    type: "color",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Paid",
    field: "isPaid",
    type: "Unpaidis_checkedout",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Rate Per Hour",
    field: "ratePerHourToDisplay",
    type: "text",
    sort: true,
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Incentive",
    field: "IncentiveToDisplay",
    type: "text",
    sort: true,
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Total Shift Amount",
    field: "totalShiftAmountToDisplay",
    type: "text",
    sort: true,
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Patient Name",
    field: "patientName",
    type: "text",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Patient Phone",
    field: "patientPhone",
    type: "text",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Patient Street Address",
    field: "patientStreetAddress",
    type: "text",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
  {
    name: "Patient Zip Code",
    field: "patientZipCode",
    type: "text",
    width: 270,
    minWidth: 170,
    maxWidth: 470,
  },
];
