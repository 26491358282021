import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import useSessionStorage from "../../../hooks/useSessionStorage";
import { logOut } from "../../Login/action";
import MessagesHeader from "../../MessagesHeader";
import './header.css';
import { headerInfoSelector } from './selector';
import {
    getUserNotifyList,
    saveUserNotifyList,
    setUserNotifyList,
} from './action';
import useConfiguration from '../../../hooks/useConfiguration';
import UserNotificationModal from '../Modal/UserNotificationModal';
import { loginSelector } from '../../Login/selector';
import requestUtil from '../../../helpers/requestUtil';
import { getFileName, getFilePath } from '../../../utils/service';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const [messagecount, setMessagecount] = useState(0);
    const navigate = useNavigate();
    const storage = useSessionStorage();
    const dispatch = useDispatch();
    const login = storage.getItem("login");
    const fileName = getFileName(login?.imageUrl);
    const filePath = getFilePath(login?.imageUrl);
    const { userNotifyList } = useSelector(headerInfoSelector);
    const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
    const config = useConfiguration();

    // if (login?.jobTypeId === 1) {
    //     sessionStorage.setItem("domainJobtypeId", "1");
    // }
    // else if (login?.jobTypeId === 2) {
    //     sessionStorage.setItem("domainJobtypeId", "2");
    // }
    // else if (login?.jobTypeId === 0) {
    //     sessionStorage.setItem("domainJobtypeId", "0");
    // }
    const domainJobtypeId = sessionStorage.getItem("domainJobtypeId");
    const domainLogos = JSON.parse(sessionStorage.getItem('domainLogos'));
    const domainIcons = JSON.parse(sessionStorage.getItem('domainIcons'));
    const gotoProfilePage = (event) => {
        event.preventDefault();
        navigate('/account');
    }
    const logout = (event) => {
        event.preventDefault();
        storage.setItem("login", null);
        storage.setItem("userNotification", false);
        sessionStorage.setItem('profileBuilderTabFlags', null);
        sessionStorage.setItem('profileBuilderTabFlagsFromAdmin', null);
        dispatch(logOut()).then(() => {
            navigate('/');
            window.location.reload();
        })
    }
    const gotoUpdatePassword = (event) => {
        event.preventDefault();
        navigate('/updatepassword');
    }
    const gotoBankDetail = (event) => {
        event.preventDefault();
        navigate('/bankdetail');
    }
    const getmessagecount = (count) => {
        setMessagecount(count)
    }

    useEffect(() => {
        const userNotification = storage.getItem('userNotification') || false;
        if (config.baseUrl && !userNotification) {
            dispatch(
                getUserNotifyList(config.baseUrl, {
                    appuserUniqueId: login.appUserUniqueId,
                })
            );
            storage.setItem('userNotification', true);
        }
    }, [config]);

    const handleUserNotificationClose = (id) => {
        let notifyValue = userNotifyList.notifiyList.find(
            (value) => value.featuresNotifyId === id
        );
        notifyValue = { ...notifyValue, isActive: false };

        const data = {
            ...userNotifyList,
            notifiyList: [
                ...userNotifyList.notifiyList.filter(
                    (value) => value.featuresNotifyId !== id
                ),
                notifyValue,
            ],
        };
        dispatch(setUserNotifyList(data));
    };

    const handleUserNotificationSave = (id) => {
        dispatch(
            saveUserNotifyList(config.baseUrl, {
                appuserUniqueId: `${login.appUserUniqueId}`,
                featuresNotifyId: id,
            })
        );
        handleUserNotificationClose(id);
    };

    //   useEffect(() => {
    //     if (config.baseUrl) {
    //         dispatch(getUserNotifyList(config.baseUrl, {
    //             appuserUniqueId: `${login.appUserUniqueId}`
    //         }))
    //     }
    //   },[config])

    // const downloadUploadDoc = async (filename, docId) => {
    //     console.log(filename);
    //     console.log(docId);
    //     await requestUtil(`${config.baseUrl}/Notify/download-notify-document?featuresNotifyAttachmentId=${docId}`, 'GET')
    //         .then(response => {
    //             if (response) {
    //                 response.blob().then(blob => {
    //                     let url = window.URL.createObjectURL(blob);
    //                     let a = document.createElement('a');
    //                     a.href = url;
    //                     a.download = filename;
    //                     a.click();
    //                 });
    //             }
    //         }).catch(error => {
    //             console.error('Error during file download:', error);
    //         });
    // }

    const downloadUploadDoc = async (filename, docId) => {
        try {
            const response = await fetch(`${config.baseUrl}/Notify/download-notify-document?featuresNotifyAttachmentId=${docId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${login.accessToken}`,
                },
            });
    
            if (response.ok) {
                const blob = await response.blob();
    
                console.log('Blob Size:', blob.size);
                console.log('Blob Type:', blob.type);
    
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
    
                // Extract filename from Content-Disposition header if available
                const contentDisposition = response.headers.get('Content-Disposition');
                const suggestedFilename = contentDisposition ? contentDisposition.split('filename=')[1] : null;
        
                // Use the provided filename or suggested filename
                a.download = filename || suggestedFilename || 'downloaded-file';
    
                // Trigger download
                a.click();
            } else {
                console.error('Server returned an error:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error during file download:', error);
        }
    };
    

    return (
        <>
            <ToastContainer style={{ "zIndex": 999999, marginTop: mobileAuth ? "80px" : ''  }} />

            {!!userNotifyList &&
                !!userNotifyList.notifiyList &&
                userNotifyList.notifiyList
                    .filter((value) => value.isActive === true)
                    .reverse()
                    .map((value) => {
                        return (
                            <UserNotificationModal
                                showModal={true}
                                htmlData={value.messageHtmlText}
                                headerText={value.headerText}
                                id={value.featuresNotifyId}
                                files={value?.notifyAttachmentsList}
                                handleUserNotificationClose={handleUserNotificationClose}
                                handleUserNotificationSave={handleUserNotificationSave}
                                downloadFile={downloadUploadDoc}
                            />
                        );
                    })}


            <div className="app-header header sticky app-header-margin">
                <div className="container-fluid main-container">

                    {!login?.profileFlag.isProfileSubmitted ?
                        <div className="side-header border-0" style={{ height: '65px' }}>
                            <a className="header-brand1" href="#">
                                <img
                                    // style={{ height:'62px' }}
                                    style={{minWidth:"250px", maxHeight:"90px", maxWidth:"210px" }}
                                    src={domainLogos[domainJobtypeId]} className="header-brand-img light-logo1"
                                    alt="logo" />
                            </a>
                        </div>
                        : ''
                    }

                    <div className="d-flex">
                        {
                          !login?.profileFlag.isProfileSubmitted && <img style={{ height: "100%", width: "60%", objectFit: "cover" }} src={domainLogos[domainJobtypeId]} className="d-md-none d-inline-block"
                                alt="logo" />
                        }
                        {login?.profileFlag.isProfileSubmitted ?

                            <div className='d-flex align-items-center '>
                                <a aria-label="Hide Sidebar" className="app-sidebar__toggle" data-bs-toggle="sidebar"
                                    href="#"></a>
                                <img style={{ height: "100%", width: "60%", objectFit: "cover" }} src={domainLogos[domainJobtypeId]} className="d-md-none d-inline-block"
                                    alt="logo" />
                            </div>
                            : ''
                        }
                        <a className="logo-horizontal" href="MyDashboard">
                            <img src="/assets/images/brand/logo.png" className="header-brand-img light-logo"
                                alt="logo" />
                            <img
                                // style={{ height:  '62px' }}
                                style={{minWidth:"270px", maxHeight:"90px", maxWidth:"280px" }}
                                src={domainLogos[domainJobtypeId]} className="header-brand-img light-logo"
                                alt="logo" />
                        </a>
                        {login?.profileFlag.isProfileSubmitted ?
                            <div className="main-header-center ms-3 d-none d-lg-block">
                                <input className="form-control" placeholder="Search for results..." type="search" />
                                <button className="btn px-0 pt-2"><i className="fe fe-search" aria-hidden="true"></i></button>
                            </div> : ''
                        }
                        <div className="d-flex order-lg-2 ms-auto header-right-icons">
                            <button className="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button"
                                data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
                                aria-controls="navbarSupportedContent-4" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon fe fe-more-vertical"></span>
                            </button>
                            <div className="navbar navbar-collapse responsive-navbar p-0">
                                <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                                    <div className="d-flex order-lg-2">

                                        {/* <div className="dropdown  d-flex notifications">
                                            <a className="nav-link icon" data-bs-toggle="dropdown">
                                                <i className="fe fe-bell"></i><span className=" pulse"></span>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <div className="drop-heading border-bottom">
                                                    <div className="d-flex">
                                                        <h6 className="mt-1 mb-0 fs-16 fw-semibold text-dark">Notifications</h6>
                                                    </div>
                                                </div>
                                                <div className="notifications-menu">
                                                    <a className="dropdown-item d-flex" href="notify-list.html">
                                                        <div
                                                            className="me-3 notifyimg  bg-primary brround box-shadow-primary">
                                                            <i className="fe fe-mail"></i>
                                                        </div>
                                                        <div className="mt-1 wd-80p">
                                                            <h5 className="notification-label mb-1">New Application
                                                                received</h5>
                                                            <span className="notification-subtext">3 days ago</span>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item d-flex" href="notify-list.html">
                                                        <div
                                                            className="me-3 notifyimg  bg-secondary brround box-shadow-secondary">
                                                            <i className="fe fe-check-circle"></i>
                                                        </div>
                                                        <div className="mt-1 wd-80p">
                                                            <h5 className="notification-label mb-1">Project has been
                                                                approved</h5>
                                                            <span className="notification-subtext">2 hours ago</span>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item d-flex" href="notify-list.html">
                                                        <div
                                                            className="me-3 notifyimg  bg-success brround box-shadow-success">
                                                            <i className="fe fe-shopping-cart"></i>
                                                        </div>
                                                        <div className="mt-1 wd-80p">
                                                            <h5 className="notification-label mb-1">Your Product
                                                                Delivered</h5>
                                                            <span className="notification-subtext">30 min ago</span>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item d-flex" href="notify-list.html">
                                                        <div className="me-3 notifyimg bg-pink brround box-shadow-pink">
                                                            <i className="fe fe-user-plus"></i>
                                                        </div>
                                                        <div className="mt-1 wd-80p">
                                                            <h5 className="notification-label mb-1">Friend Requests</h5>
                                                            <span className="notification-subtext">1 day ago</span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="dropdown-divider m-0"></div>
                                                <a href="notify-list.html"
                                                   className="dropdown-item text-center p-3 text-muted">View all
                                                    Notification</a>
                                            </div>
                                        </div> */}
                                        {login?.profileFlag.isProfileSubmitted ?
                                            <div className="dropdown  d-flex message">
                                                <span style={{ fontSize: '20px', fontWeight: 'bold', margin: '0px -12px 0px 0px', padding: '20px 0px 0px 2px' }}>{messagecount}</span>
                                                <a className="nav-link icon text-center" data-bs-toggle="dropdown">
                                                    <i className="fe fe-message-square" style={{ fontSize: '30px' }}></i>
                                                    {messagecount !== 0 &&
                                                        <span className="pulse-danger" style={{ fontSize: '12px' }}></span>
                                                    }
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                    <MessagesHeader getmessagecount={getmessagecount}></MessagesHeader>
                                                </div>
                                            </div> 
                                            : ''}
                                        <div className="dropdown d-flex profile-1">
                                            <a href="#" data-bs-toggle="dropdown"
                                                className="nav-link leading-none d-flex">
                                                {fileName ?
                                                    <img src={props?.profile ? props?.profile : `${filePath}${fileName}`} alt="profile-user"
                                                        className="rounded-circle" width={44} height={42}/>
                                                    :
                                                    <img src="assets/images/brand/user-blue.svg" alt="profile-user" className="avatar  profile-user brround cover-image" />
                                                }
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <div className="drop-heading">
                                                    <div className="text-center">
                                                        <h5 className="text-dark mb-0 fs-14 fw-semibold">{login && login?.fullName}</h5>
                                                        {/* <small className="text-muted">Registered nurse</small> */}
                                                        <small className="text-muted">{login && login?.jobCategory}</small>
                                                    </div>
                                                </div>
                                                <div className="dropdown-divider m-0"></div>
                                                {/* <a className="dropdown-item" href="#" onClick={gotoProfilePage}>
                                                    <i className="dropdown-icon fe fe-user"></i> My Account
                                                </a> */}
                                                {/* {login?.candidateOvertureStatus === 2 &&
                                                    <a className="dropdown-item" href="#" onClick={gotoBankDetail}>
                                                        <i className="dropdown-icon fe fe-alert-circle"></i> Bank Detail
                                                    </a>
                                                } */}
                                                <a className="dropdown-item" href="#" onClick={logout}>
                                                    <i className="dropdown-icon fe fe-alert-circle"></i> Sign out
                                                </a>
                                                {/* <a className="dropdown-item" href="#" onClick={gotoUpdatePassword}>
                                                    <i className="dropdown-icon zmdi zmdi-lock"></i> Update Password
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumps-prevent jumps-prevent-padding"></div>
        </>
    )
}
