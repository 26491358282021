import { setLoading } from "../common/Loader/slice";
import {
  setPage,
  shiftLoadingFailed,
  updateMultishiftjobinfo,
  updatePageSize,
  setSorting,
  setClaimUnclaimMsgResetMultiShiftJobInfo,
  setClaimUnclaimSuccessMessageMultiShiftJobInfo,
  setClaimUnclaimErrorMessageMultiShiftJobInfo,
  updateOccurrenceData,
  reset,
  setLoadingFlagIncrement,
  setLoadingFlagDecrement
} from "./slice";
import requestUtil from "../../helpers/requestUtil";
import * as Toasts from "../common/Toastify";
const createLoadRequest = (
  page,
  pageSize,
  sortOrder,
  sortColumn,
) => {
  const request = {
    page: page,
    pageSize: pageSize,
  };
  if (sortColumn && sortColumn.length > 0) {
    request.sortOrder = sortOrder;
    request.sortColumn = sortColumn;
  }
  return request;
};

export function loadShifts(
  url,
  candidateId,
  params,
  subUrl
) {
  return async (dispatch, getState) => {
    dispatch(setLoadingFlagIncrement());
    try {
      const state = getState();
      const { page, pageSize, sortOrder, sortColumn } =
        state.multishiftjobinfo.multishiftjobinfo;
      const request = createLoadRequest(
        page,
        pageSize,
        sortOrder,
        sortColumn
      );
      const response = await requestUtil(
        `${url}/${subUrl}`,
        "POST",
        {...request,...params}
      );
      let data = response;
      dispatch(
        updateMultishiftjobinfo({
          data: data,
          page: page,
          pageName: 'multishiftjobinfo',
        })
      );
      dispatch(setLoadingFlagDecrement());
    } catch (e) {
      dispatch(setLoadingFlagDecrement());
    }
  };
}
export function nextPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page, pageSize, data } = state.multishiftjobinfo[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: page + 1 > lastPage ? lastPage : page + 1,
      })
    );
  };
}
export function firstPage(pageName) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: 1,
      })
    );
  };
}
export function gotoPage(pageName, pageNumber) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: pageNumber,
      })
    );
  };
}
export function previousPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page } = state.multishiftjobinfo[pageName];
    dispatch(
      setPage({
        pageName,
        page: page - 1 < 1 ? 1 : page - 1,
      })
    );
  };
}
export function lastPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { pageSize, data } = state.multishiftjobinfo[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: lastPage,
      })
    );
  };
}
export function pageSize(pageName, size) {
  return async (dispatch) => {
    dispatch(
      updatePageSize({
        pageName,
        pageSize: size,
      })
    );
  };
}

export function updateSort(pageName, order, column) {
  return async (dispatch) => {
    dispatch(
      setSorting({
        pageName,
        sortOrder: order,
        sortColumn: column,
      })
    );
  };
}

export function claim(url, candidateId, jobId, claimedstatus, pageName, occurranceDate, starttime, endtime) {
  return async (dispatch) => {
      dispatch(setLoading(true));
      try {
          const response = await requestUtil(`${url}/Candidates/claim`, "POST", {
              "candidateUniqueId": candidateId,
              "jobuniqueid": jobId,
              "claimedstatus": claimedstatus,
              "breakType": 1,
              "occurranceDate": occurranceDate,
              "starttime": starttime,
              "endtime": endtime
          });
          dispatch(setLoading(false));
          dispatch(setClaimUnclaimSuccessMessageMultiShiftJobInfo(response.message));
      } catch (error) {
          dispatch(setLoading(false));
          dispatch(setClaimUnclaimErrorMessageMultiShiftJobInfo(error.message));
      }
  }
}

export function cancelclaim(url, candidateId, jobId, claimedstatus, pageName, jobsClaimId, occurranceDate, starttime, endtime, unClaimReasonId, otherUnClaimReason, jobsShiftUniqueId, correctionType, currentPageName) {
  return async (dispatch) => {
      dispatch(setLoading(true));
      try {
          const response = await requestUtil(`${url}/CandidatesJobs/unclaimoccurrance`, "POST", {
              "candidateUniqueId": candidateId,
              "jobuniqueid": jobId,
              "claimedstatus": claimedstatus,
              "jobsClaimId": jobsClaimId ? jobsClaimId : 0,
              "occurranceDate": occurranceDate,
              "breakType": 0,
              "starttime": starttime,
              "endtime": endtime,
              "isParent": false,
              "unClaimReasonId": unClaimReasonId,
              "otherUnClaimReason": otherUnClaimReason,
              "jobsShiftUniqueId": jobsShiftUniqueId,
              ...(currentPageName === 'myShift' && {correctionType})
          });
          dispatch(setClaimUnclaimSuccessMessageMultiShiftJobInfo(response.message));
          dispatch(setLoading(false));
      } catch (error) {
          dispatch(setClaimUnclaimErrorMessageMultiShiftJobInfo(error.message));
          dispatch(setLoading(false));
      }
  }
}

export const updateClaimandUnclaimMsgMultiShiftJobInfo = (data) => {
  return async dispatch => {
      dispatch(setClaimUnclaimMsgResetMultiShiftJobInfo(data));
  }
}

export const clearData = () => {
  return async (dispatch) => {
    dispatch(reset());
  };
};