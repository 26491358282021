import {useState} from "react";
import Modal from "./modal";
import CovidForm from "../ProfileBuilder/Preference/covidForm";
import { useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import useSessionStorage from "../../hooks/useSessionStorage";

export default (props) => {
    const { login_roles_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const editCovid = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('preference');
        }
    };
    const saveCovid = (event) => {
        event.preventDefault();
        if (props.saveContinue('preference', false)) {
            setShowModal(!showModal);
        }
    };
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal &&
                    <Modal label={"Covid 19 Vaccination Status ?"} showHide={editCovid} saveFunction={saveCovid}>
                        <CovidForm errors={props.errors}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                                <h4 className="text-wrap mr-2">Covid 19 Vaccination Status?</h4>
                                {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                                <a id="EditCovid" href="#" onClick={editCovid}>
                                    <i className="fe fe-edit" title="Edit Covid 19 Vaccination Status"></i>
                                </a> : ''
                                }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <label htmlFor="exampleInputname">{props.vaccination}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
