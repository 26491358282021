import {createSlice} from "@reduxjs/toolkit";

const initialData = {
    jobType: [],
}
const initialState = {
    data: initialData,
    masterData: {
        jobTypes: []
    },
    signUpSuccess: false,
    signUpError: false,
    signUpErrorMessage: '',
    email: '',
    signupAuthData: {},
}
export const signUpSlice = createSlice({
    name: 'signUp',
    initialState,
    reducers: {
        resetError: (state) => {
            state.signUpError = false;
            state.signUpSuccess = false;
            state.signUpErrorMessage = '';
        },
        signUpSuccess: (state, action) => {
            state.signUpSuccess = true;
            state.email = action.payload
        },
        signUpError: (state, action) => {
            state.signUpError = true;
            state.signUpErrorMessage = action.payload;
        },
        setMasterData: (state, action) => {         
            if (action.payload) {
                state.masterData = action.payload;
            }
        },
        setsignupAuthData: (state, action) => {  
            console.log(action.payload)       
            if (action.payload) {
                state.signupAuthData = action.payload;
            }
        },
    }
});
export const {signUpSuccess, signUpError, resetError,setMasterData,setsignupAuthData} = signUpSlice.actions;
export default signUpSlice.reducer;
