import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FromMonths from '../../common/FromMonths';
import FromYears from '../../common/FromYears';
import Months from '../../common/Months';
import TextField from '../../common/TextField/withLabel';
import Years from '../../common/Years';
import { updateEducation } from './action';
import { resumeBuilderSelector } from './selector';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const dispatch = useDispatch();
  const { educationList } = useSelector(resumeBuilderSelector);
  const [edu, setEdu] = React.useState(educationList);
  useEffect(() => {
    dispatch(updateEducation(edu));
  }, [edu]);
  const fieldChanged = (index, target) => {
    const { name, value } = target;
    setEdu((prevState) => {
      const newEdu = prevState.map((item) => ({ ...item }));
      if (value === 'Present') {
        newEdu[index]['toMonth'] = 'Present';
        newEdu[index]['toYear'] = 'Present';
      }
      newEdu[index][name] = value;
      return newEdu;
    });
  };
  const newRow = (event) => {
    event.preventDefault();
    setEdu((prevState) => {
      const newEdu = prevState.map((item) => ({ ...item }));
      newEdu.push({
        name: '',
        fromMonth: '',
        fromYear: '',
        toMonth: '',
        toYear: '',
      });
      return newEdu;
    });
  };
  const removeRow = (index) => {
    setEdu((prevState) => {
      const newEdu = [...prevState];
      newEdu.splice(index, 1);
      if (newEdu.length === 0) {
        newEdu.push({
          name: '',
          fromMonth: '',
          fromYear: '',
          toMonth: '',
          toYear: '',
        });
      }
      return newEdu;
    });
  };

  const selectToMonthAndToYear = (index, e) => {
    if (e.target.checked) {
      setEdu((prevState) => {
        const newEdu = prevState.map((item) => ({ ...item }));
        newEdu[index]['toMonth'] = 'Present';
        newEdu[index]['toYear'] = 'Present';
        newEdu[index]['flag'] = true;
        return newEdu;
      });
    } else {
      setEdu((prevState) => {
        const newEdu = prevState.map((item) => ({ ...item }));
        newEdu[index]['toMonth'] = '';
        newEdu[index]['toYear'] = '';
        newEdu[index]['flag'] = false;
        return newEdu;
      });
    }
  };

  return (
    <>
      <hr className="new5" />
      <div className="col-lg-12 col-md-12">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <h4>Education</h4>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-none d-md-block">
              <label className="card-title">Education</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-none d-md-block">
              <label className="card-title">From Month</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-none d-md-block">
              <label className="card-title">From Year</label>
            </div>
          </div>
          {/* <div className="col-lg-1 col-md-1">
                        <div className="form-group">
                            <div className="col-sm"></div>
                        </div>
                    </div> */}
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-none d-md-block">
              <label className="card-title">To Month</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-none d-md-block">
              <label className="card-title">To Year</label>
            </div>
          </div>
          <div className="col-lg-1 col-md-1">
            <div className="form-group d-none d-md-block">
              <label className="card-title">Delete</label>
            </div>
          </div>
        </div>
        <div className="row" id="divEdClone">
          {edu.map((e, index) => (
            <div className="row">
              {/* Education */}
               <div className="form-group  d-md-none">
              <label className="card-title">Education</label>
            </div>
              <TextField
                divClass={'col-lg-2 col-md-2'}
                noLabel={true}
                name="name"
                placeholder={'Type Here'}
                value={e.name}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['name']}
              />
              {/* From Months */}
              <div className="form-group  d-md-none">
              <label className="card-title">From Month</label>
            </div>
              <FromMonths
                name={'fromMonth'}
                value={e.fromMonth}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['fromMonth']}
              />

              {/* From Years */}
              <div className="form-group d-md-none">
              <label className="card-title">From Year</label>
            </div>
              <FromYears
                name={'fromYear'}
                value={e.fromYear}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['fromYear']}
              />
              {/* <div className="col-lg-1 col-md-1">
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" onClick={(e) => selectToMonthAndToYear(index, e)}
                                        checked={e.toMonth === 'Present' && e.toYear === 'Present' ? true : false}/>
                                    </div>
                                </div></div> */}
              {/* flag={e.flag || (e.toMonth === 'Present' && e.toYear === 'Present') ? true : false} */}
              {/* To Months */}
              <div className="form-group  d-md-none">
              <label className="card-title">To Month</label>
            </div>
              <Months
                name={'toMonth'}
                value={e.toMonth}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['toMonth']}
              />
              {/* flag={e.flag || (e.toYear === 'Present' && e.toMonth === 'Present') ? true : false} */}
              {/* To Year */}
              <div className="form-group d-md-none">
              <label className="card-title">To Year</label>
            </div>
              <Years
                name={'toYear'}
                value={e.toYear}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['toYear']}
              />
              {/* delete */}
              <div className="form-group d-block d-md-none ">
                <a
                      onClick={(event) => {
                        event.preventDefault();
                        removeRow(index);
                      }}
                    >
                      <i className="fe fe-trash-2"></i>
                      <label style={{marginLeft:"0.5rem"}} className="card-title d-md-none">Delete</label>
                    </a>
                </div>
              <div className="col-lg-1 col-md-1 d-none d-md-block">
                <div className="form-group">
                  <div className="col-sm">
                    <a
                      onClick={(event) => {
                        event.preventDefault();
                        removeRow(index);
                      }}
                    >
                      <i className="fe fe-trash-2"></i>
                    </a>
                  </div>
                  <label className="card-title d-md-none">Delete</label>
                </div>
              </div>
              <div className="row">&nbsp;</div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-2">
            <a id="btnEd" href="#" onClick={newRow}>
              <div className="row">
                <div  className="d-flex align-items-center ">
                  <i
                    className="icon-2x icon-plus"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="icon-plus"
                    aria-label="Add Education"
                  ></i>
                   <div style={{marginLeft:"0.5rem"}}>Add New</div>
                </div>
               
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
