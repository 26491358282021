// Import Files
import Header from "../common/Header";
import Loader from "../common/Loader";
import Sidebar from "../common/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import DataGrid from "../common/DataGrid";
import { useEffect, useState } from "react";
import useConfiguration from "../../hooks/useConfiguration";
import { useDispatch, useSelector } from "react-redux";
import useSessionStorage from "../../hooks/useSessionStorage";
import { encounterjobinfoSelector } from "./selector";
import { paginationCalculator } from "pagination-calculator";
import {
  cancelclaim,
  claim,
  firstPage,
  gotoPage,
  lastPage,
  loadShifts,
  nextPage,
  pageSize,
  previousPage,
  updateClaimandUnclaimMsgEncounterJobInfo,
  updateSort,
} from "./action";
import { ModalBox } from "../common/Modal/ModalBox";
import { myshiftSelector } from "../MyShift/selector";
import { Modal } from "react-bootstrap";
import { getUnClaimCandidateReasons } from "../MyShift/action";

const EncounterJobInfo = () => {
  // Encounter Page Using Hooks
  const config = useConfiguration();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const storage = useSessionStorage();

  // Unclaim reasons get from MyShift Selector
  const { unClaimEmployerReasons } = useSelector(myshiftSelector);

  // get from data use Location hook
  const { params, subUrl, EncounterTableHeaders } = location.state || "";

  // Table headers data
  const headers = EncounterTableHeaders;

  // get from data in local storage
  const login = storage.getItem("login");

  // get from data for Encounter job Info selector
  const {
    encounterjobinfo,
    claimUnclaimErrorMessageEncounterJobInfo,
    claimUnclaimSuccessMessageEncounterJobInfo,
  } = useSelector(encounterjobinfoSelector);

  // Claim Unclaim get a data from Function
  const [claimUnclaimUniqueData, setClaimUnclaimUniqueData] = useState(null);

  // Claim Unclaim Modal Box Show and SetShow
  const [claimUnclaimModalShow, setClaimUnclaimModalShow] = useState(false);

  // Confirmation Modal Box Dropdown Form initial data
  const [ModalFormData, setModalFormData] = useState({
    reason: "",
    comments: "",
  });

  // Confirmation Modal Box Dropdown Form Error initial data
  const [ErrorsModalFormData, setErrorsModalFormData] = useState({
    reasonError: "",
    commentsError: "",
  });

  // Main Page Loading API Method
  useEffect(() => {
    document.title = "EncounterJobInfo";
    if (config.baseUrl) {
      if (!login) {
        navigate("/");
      } else {
        dispatch(loadShifts(config.baseUrl, params, subUrl));
        if (unClaimEmployerReasons?.length === 0) {
        dispatch(getUnClaimCandidateReasons(`${config.baseUrl}`));
        }
      }
    }
  }, [config]);

  // Claim or Cancel Modal Box open Button function
  const claimUnclaimModalOpenBtn = ({
    jobUniqueId,
    locationName,
    isClaimed,
    jobsClaimId,
    starttime,
    endtime,
    jobsShiftUniqueId,
    occurranceDate,
  }) => {
    setClaimUnclaimModalShow(true);
    setClaimUnclaimUniqueData({
      jobUniqueId,
      locationName,
      isClaimed,
      jobsClaimId,
      starttime,
      endtime,
      jobsShiftUniqueId,
      occurranceDate,
    });
  };

  // Table Body data Handle
  const generateRowData = (data, headers) => {
    const rowData = {};
    headers.forEach((header) => {
      // Text value
      if (header.type === "text") {
        rowData[header.name] = data[header.field];
        // Claimed
      } else if (header.field === "isClaimed") {
        const isClaimed = data[header.field];
        const iconClass = isClaimed ? "green-icon" : "grey-icon";
        rowData[header.name] = {
          type: "icon",
          class: `fa fa-check-circle icon-align ${iconClass}`,
          onClick: () => {
            claimUnclaimModalOpenBtn({
              jobUniqueId: data?.jobUniqueId,
              locationName: encounterjobinfo?.headerMsgData?.locationName,
              isClaimed: data?.isClaimed,
              jobsClaimId: data?.jobsClaimId,
              starttime: data?.shiftStartTime,
              endtime: data?.shiftEndTime,
              jobsShiftUniqueId: data.jobsShiftUniqueId,
              occurranceDate: data.occurrenceDate,
            });
          },
        };
        // Checked In
      } else if (header.field === "isCheckedIn") {
        rowData[header.name] = {
          type: "Myshift_color",
          value: data[header.field],
        };
        // Checked Out
      } else if (header.field === "isCheckedOut") {
        rowData[header.name] = {
          type: "Myshift_color",
          value: data[header.field],
        };
        // Approvals List
      } else if (header.field === "approversList") {
        rowData[header.name] = {
          type: "color",
          value: data[header.field],
        };
        // Paid
      } else if (header.field === "isPaid") {
        rowData[header.name] = {
          type: "Unpaidis_Paid",
          value: data[header.field],
        };
      }
      // rate per hour column
      if (header.field === "ratePerHourToDisplay") {
        if (
          data.ratePerHourToDisplay === "$0.00" ||
          data.ratePerHourToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.ratePerHourToDisplay;
        }
      }
      // Incentive column
      if (header.field === "IncentiveToDisplay") {
        if (
          data.incentiveToDisplay === "$0.00" ||
          data.incentiveToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.incentiveToDisplay;
        }
      }
      // Total Shift Amount
      if (header.field === "totalShiftAmountToDisplay") {
        if (
          data.totalShiftAmountToDisplay === "$0.00" ||
          data.totalShiftAmountToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.totalShiftAmountToDisplay;
        }
      }
    });
    return rowData;
  };

  // Table Pagination Handle
  const createPageData = (page, pageSize, totalCount) => {
    if (page === 1) {
      return {
        from: page,
        to: pageSize,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    } else {
      const pageData = paginationCalculator({
        total: totalCount,
        current: page,
        pageSize: pageSize,
      });
      return {
        from: pageData.showingStart,
        to: pageData.showingEnd,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    }
  };

  // Table Pagination First Page
  const gotoFirstPage = () => {
    dispatch(firstPage("encounterjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };

  // Table Pagination Next Page
  const gotoNextPage = () => {
    dispatch(nextPage("encounterjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };

  // Table Pagination Previous Page
  const gotoPreviousPage = () => {
    dispatch(previousPage("encounterjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };

  // Table Pagination Last Page
  const gotoLastPage = () => {
    dispatch(lastPage("encounterjobinfo")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };

  // Table Pagination Selected Page
  const gotoSelectedPage = (page) => {
    dispatch(gotoPage("encounterjobinfo", page)).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };

  // Table Pagination Page Size
  const setPageSize = (size) => {
    dispatch(pageSize("encounterjobinfo", size)).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, params, subUrl)
      );
    });
  };

  // Table Headers and Body Function
  const formatTableData = (dataList) => {
    const tableData = {
      headers,
      rows: encounterjobinfo?.occurrenceList?.map((data) => ({
        columns: generateRowData(data, headers),
      })),
      options: {
        sortable: false,
        filter: false,
        pagination: true,
        pageSize: dataList?.pageSize || 10,
      },
      pageData: createPageData(
        dataList?.page,
        dataList?.pageSize,
        dataList?.data?.totalCount
      ),
      firstPage: gotoFirstPage,
      nextPage: gotoNextPage,
      previousPage: gotoPreviousPage,
      lastPage: gotoLastPage,
      updatePageSize: setPageSize,
      gotoPage: gotoSelectedPage,
      headerComponentParams: {
        sort: (order, column) => {
          dispatch(updateSort("multishiftjobinfo", order, column)).then(() => {
            dispatch(
              loadShifts(
                config.baseUrl,
                login.candidateUniqueId,
                params,
                subUrl
              )
            );
          });
        },
        headers,
      },
    };
    return tableData;
  };

  // Confirmation Modal Box Dropdown Form HandleChange Function
  const ConfirmationModalBoxHandleChange = (e) => {
    setModalFormData({
      ...ModalFormData,
      [e.target.name]: e.target.value,
    });
  };

  // Claim or Cancel Modal Box Close Button function
  const claimUnclaimModalCloseBtn = () => {
    setErrorsModalFormData({
      commentsError: "",
      reasonError: "",
    });
    setModalFormData({
      reason: "",
      comments: "",
    });
    setClaimUnclaimModalShow(!claimUnclaimModalShow);
  };

  // Claim or Cancel Modal Box Function
  const claimUnclaimSave = () => {
    // Error Handling for Unlcaim Reasons Dropdown List
    if (!ModalFormData.reason) {
      setErrorsModalFormData({
        ...ErrorsModalFormData,
        reasonError: "Please choose at least one Reason",
      });
      return;
    } else if (ModalFormData.reason) {
      setErrorsModalFormData({
        ...ErrorsModalFormData,
        reasonError: "",
      });
      if (parseInt(ModalFormData.reason) === 0) {
        if (!ModalFormData.comments) {
          setErrorsModalFormData({
            ...ErrorsModalFormData,
            commentsError: "Please Enter the comments",
          });
          return;
        } else if (ModalFormData.comments) {
          setErrorsModalFormData({
            reasonError: "",
            commentsError: "",
          });
        }
      }
    }
    // Unclaim API Method
    if (claimUnclaimUniqueData.isClaimed) {
      dispatch(
        cancelclaim(
          config.baseUrl,
          login.candidateUniqueId,
          claimUnclaimUniqueData.jobUniqueId,
          claimUnclaimUniqueData.isClaimed ? 2 : 2,
          "encounterjobinfo",
          claimUnclaimUniqueData?.jobsClaimId,
          claimUnclaimUniqueData.starttime,
          claimUnclaimUniqueData.endtime,
          ModalFormData.reason,
          ModalFormData.comments,
          claimUnclaimUniqueData.jobsShiftUniqueId,
          claimUnclaimUniqueData.occurranceDate
        )
      ).then(() => {
        setModalFormData({
          reason: "",
          comments: "",
        });
        claimUnclaimModalCloseBtn();
      });
    }
  };


    // Message Modal Box after Load data for MultishiftJobInfo Page
    const refreshJobListEncounterJobInfo = () => {
      dispatch(loadShifts(config.baseUrl, params, subUrl))
      dispatch(updateClaimandUnclaimMsgEncounterJobInfo(null));
    };

  // Main Page Return
  return (
    <div className="page">
      <Loader />
      <Header />
      <Sidebar />
      {/* Unclaim Modal Show With Reasons */}
      {claimUnclaimModalShow && (
        <ModalBox
          size={"md"}
          label={"Choose the Unclaim Reason"}
          children={
            <div>
              <div>
                Are you sure want to proceed with unclaim for this{" "}
                {claimUnclaimUniqueData.locationName}
                job?
              </div>
              {/* UnClaim Reasons Dropdown */}
              <div className="mt-5">
                <label for="reason" className="mx-2">
                  Choose a Reason :
                </label>
                <select
                  name="reason"
                  id="reason"
                  className="form-select form-control"
                  required
                  onChange={ConfirmationModalBoxHandleChange}
                >
                  <option selected disabled>
                    Choose any one
                  </option>
                  {unClaimEmployerReasons.map((reasonLists, index) => {
                    return (
                      <option key={index} value={reasonLists.id}>
                        {reasonLists.name}
                      </option>
                    );
                  })}
                </select>
                {
                  <span className="text-danger mx-2">
                    {ErrorsModalFormData?.reasonError}
                  </span>
                }
                {parseInt(ModalFormData.reason) === 0 ? (
                  <div className="mt-2">
                    <textarea
                      className="form-control"
                      name="comments"
                      value={ModalFormData.comments}
                      onChange={ConfirmationModalBoxHandleChange}
                    ></textarea>
                    {
                      <span className="text-danger mx-2">
                        {ErrorsModalFormData?.commentsError}
                      </span>
                    }
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          }
          showHide={claimUnclaimModalCloseBtn}
          buttonName={"UnClaim"}
          saveFunction={claimUnclaimSave}
        />
      )}


            {/* MultishiftJobInfo Page Claim and Unclaim Success or Error Message Modal Box */}
            <Modal
        show={claimUnclaimErrorMessageEncounterJobInfo || claimUnclaimSuccessMessageEncounterJobInfo}
        size={"lg"}
        centered={true}
        title="Error"
      >
        <Modal.Header>
          <Modal.Title>
            {claimUnclaimErrorMessageEncounterJobInfo ? "Error" : "Success"}
          </Modal.Title>
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={refreshJobListEncounterJobInfo}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {claimUnclaimErrorMessageEncounterJobInfo && (
            <div
              className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-autohide="false"
            >
              <div className="toast-body">
                {claimUnclaimErrorMessageEncounterJobInfo}
              </div>
            </div>
          )}
          {claimUnclaimSuccessMessageEncounterJobInfo && (
            <div className="modal-body text-center p-4 pb-5">
              <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
              <h4>{claimUnclaimSuccessMessageEncounterJobInfo}</h4>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={refreshJobListEncounterJobInfo}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>




      <div className="page-main">
        <div className="main-content app-content mt-0 main-background">
          <div className="side-app">
            <div className="main-container container-fluid">
              <div className="page-header">
                {/* Page Title */}
                <h1 className="page-title">Encounter Job Info</h1>
                {/* Back Button */}
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      {/* Header for Common Details Card 1 */}
                      <div className="row justify-content-between">
                        {/* Header Column 1 */}
                        <div className="col-lg-6 d-flex flex-column gap-3">
                          {/* Location Name */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">
                              Location Name :
                            </div>
                            <div className="col">
                              {encounterjobinfo?.headerMsgData?.locationName}
                            </div>
                          </div>
                          {/* Job Title */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">Job Title :</div>
                            <div className="col">
                              {encounterjobinfo?.headerMsgData?.jobTitle}
                            </div>
                          </div>
                        </div>
                        {/* Header Column 2 */}
                        <div className="col-lg-4 d-flex flex-column gap-3">
                          {/* Start Date */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">Start Date :</div>
                            <div className="col">
                              {
                                encounterjobinfo?.headerMsgData
                                  ?.jobStartDateToDisplay
                              }
                            </div>
                          </div>
                          {/* End Date */}
                          <div className="row">
                            <div className="col-lg-4 fw-bold">End Date :</div>
                            <div className="col">
                              {
                                encounterjobinfo?.headerMsgData
                                  ?.jobEndDateToDisplay
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Table Card 2 */}
              <div className="row">
                <div>
                  <div className="card">
                    <div className="card-body">
                      {/* Table Details Passing Props data */}
                      <DataGrid {...formatTableData()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EncounterJobInfo;
