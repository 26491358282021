export const headersData = [
  {
    name: "ShiftDate",
    field: "occurrenceDateToDisplay",
    type: "text",
    sort: true,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    name: "StartTime",
    field: "shiftStartTime",
    type: "text",
    sort: true,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    name: "EndTime",
    field: "shiftEndTime",
    type: "text",
    sort: true,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    name: "CheckIn",
    field: "isCheckedIn",
    type: "Myshift_color",
  },
  {
    name: "CheckOut",
    field: "isCheckedOut",
    type: "Myshift_color",
  },
  {
    name: "Approver",
    field: "approversList",
    type: "color",
  },
  {
    name: "Paid",
    field: "isPaid",
    type: "Unpaidis_checkedout",
  },
  {
    name: "RatePerHour",
    field: "ratePerHourToDisplayMultishift",
    type: "text",
    sort: true,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    name: "Incentive",
    field: "IncentiveToDisplay",
    type: "text",
    sort: true,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    name: "TotalShiftAmount",
    field: "totalShiftAmountToDisplay",
    type: "text",
    sort: true,
    minWidth: 180,
    maxWidth: 200,
  },
  {
    name: "Claim/Cancel",
    field: "isClaimed",
    type: "icon",
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    name: "checked In Time",
    field: "checkedInTime",
    type: "text",
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    name: "checked Out Time",
    field: "checkedOutTime",
    type: "text",
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
];
