import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { gotoTab } from "../ProfileBuilder/action";
import { useNavigate } from "react-router-dom";
import { notShowOvertureMsg } from "../../helpers/domainUrl";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const domainJobtypeId = sessionStorage.getItem("domainJobtypeId");
    const domainLogos = JSON.parse(sessionStorage.getItem('domainLogos'));
    const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
    const notShowOverture = notShowOvertureMsg?.indexOf(+domainJobtypeId) > -1 ? true : false;
    const channelName = useRef();
    const channelUrl = useRef()
    useEffect(() => {
        document.title = "Save Success";
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    const mapSignupUrl = {
        nurseLive:["accounts.overturexl.com"],
        securityLive:["accountsec.overturexl.com"],
        restaurantLive:["accountyum.overturexl.com"],
        dentalLive:["accountdds.overturexl.com"],
    
        nurse:["stagingcandidate.overturexl.com",],
        security:["stagingcandidatesec.overturexl.com", "securityjobdev.overturexl.com", 'secjobstaging.overturexl.com',"elecansec.overturexl.com","qatestcandidatesec.overturexl.com"],
        restaurant:["yumjobdev.overturexl.com","restjobstaging.overturexl.com",'qatestcandidateyum.overturexl.com',"elecanyum.overturexl.com"],
        dental:["dentaljobdev.overturexl.com",'dentaljobstaging.overturexl.com','qatestcandidatedental.overturexl.com','elecandds.overturexl.com'],
        listStyle: ["dynamiccandidates.overturexl.com"],
        listStyleStag:['stagingcandidates.overturexl.com'],
        listStyle5StarStag:['5starstagingcandidates.overturexl.com']
      }
    
    //   setFaqUrl and profileName
    if(mapSignupUrl.nurseLive?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = "https://overturexl.com/candidates.html#Faq";
        channelName.current = "OvertureXL"
      } 
      else if(mapSignupUrl.securityLive?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = "https://overturesec.com/candidates.html#Faq";
        channelName.current = "OvertureSEC"
      } 
      else if(mapSignupUrl.restaurantLive?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = "https://overtureyum.com/candidates.html#Faq";
        channelName.current = "OvertureYUM"
      } 
    else if(mapSignupUrl.dentalLive?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = "https://overturedds.com/candidates.html#Faq";
        channelName.current = "OvertureDDS"
    } 
      else if(mapSignupUrl.nurse?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = window.location.origin + "/website/candidates.html#Faq";
        channelName.current = "OvertureXL"
      } 
      else if(mapSignupUrl.security?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = window.location.origin + "/website/security.html#Faq";
        channelName.current = "OvertureSEC"
      } 
      else if(mapSignupUrl.restaurant?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = window.location.origin + "/website/restaurant.html#Faq";
        channelName.current = "OvertureYUM"
      } 
      else if(mapSignupUrl.dental?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = window.location.origin + "/website/dental.html#Faq";
        channelName.current = "OvertureDDS"
      }
      else if(mapSignupUrl.listStyle?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = "https://dynamicwebsite.overturexl.com";
        channelName.current = "Dynamic Life Style"
      }
      else if(mapSignupUrl.listStyleStag?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = "https://stagingwebsite.overturexl.com/xl";
        channelName.current = "Dynamic Life Style"
      }
      else if(mapSignupUrl.listStyle5StarStag?.indexOf(window.location.hostname) !== -1){
        channelUrl.current = "https://5starstagingwebsite.overturexl.com";
        channelName.current = "Dynamic Life Style"
      }
      else {
        channelUrl.current = window.location.origin + "/website/candidates.html#Faq";
        channelName.current = "OvertureXL"
      }
    return (
        <>
            {/* desktop */}
            <div className="card d-none d-md-block">
                <div className="card-body">
                    <div className="modal d-block pos-static">
                        <div className="container-login100">
                            <div className="wrap-login100 p-6">
                                <form className="login100-form validate-form">
                                    <span className="login100-form-title pb-5">
                                        <a href=""> <img src={domainLogos[domainJobtypeId]}
                                            className="header-brand-img" alt=""
                                            // style={{ height: '62px' }}
                                            style={{minWidth:"270px", maxHeight:"90px", maxWidth:"280px" }}
                                        /></a>
                                    </span>
                                    <div style={{width:"80%",margin:" 0 auto"}} className="">
                                        <div className="modal-body text-center p-4 pb-5">
                                            <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
                                            <h4 className="text-success mb-4">
                                            Thank you for submitting your profile and joining our incredible community of qualified professionals!
                                            </h4>
                                            {(notShowOverture) ? null :   <>
                                            <p className="mb-4">
                                            Now that you have submitted your profile, Overture will review your qualifications and experience. Once this is done and your profile submission is approved, our employers will have access to review all of the information you submitted.
                                            </p>                                
                                          <p>
                                          Be sure to read more about how {channelName.current && channelName.current} works, by visiting our FAQs section here: <a target="_blank" href={`${channelUrl.current && channelUrl.current}`}>{channelUrl.current && channelUrl.current}</a>
                                          </p>
                                            <p>
                                            All of your questions are addressed in our FAQ section.
                                            </p>
                                            </> }
                                            {!mobileAuth &&
                                                <a className="btn btn-success pd-x-25" href="#" onClick={(event) => {
                                                    event.preventDefault();
                                                    dispatch(gotoTab('personalInfo'));
                                                    navigate('/dashboard');
                                                }}>OK</a>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* mobile */}
            <div className="d-md-none">
                <div className="">
                    <div className="modal d-block pos-static">
                        <div className="container-login100">
                            <div className="wrap-login100 ">
                                <form style={{width:"100%"}} className="login100-form validate-form">
                                    <span className="login100-form-title pb-5">
                                        <a href=""> <img src={domainLogos[domainJobtypeId]}
                                            className="header-brand-img" alt=""
                                            // style={{ height:   '62px' }}
                                            style={{minWidth:"270px", maxHeight:"90px", maxWidth:"280px" }}
                                        /></a>
                                    </span>
                                    <div style={{margin:" 0 auto"}}  className="">
                                        <div className="modal-body text-center p-4 pb-5">
                                            <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
                                            <h4 style={{fontSize:"0.87rem"}}  className="text-success mb-4">
                                            Thank you for submitting your profile and joining our incredible community of qualified professionals!
                                            </h4>
                                            
                                        </div>
                                    </div>
                                    { (notShowOverture) ? null :      <>
                                        <p className="mb-4 mx-0 p-0 text-center">
                                            Now that you have submitted your profile, Overture will review your qualifications and experience. Once this is done and your profile submission is approved, our employers will have access to review all of the information you submitted.
                                            </p> 
                                    <p className="mb-4 mx-0 p-0 text-center">
                                    Be sure to read more about how {channelName.current && channelName.current} works, by visiting our FAQs section here: 
                                            </p> 
                                            <a style={{display:"block" ,height:"auto",maxWidth:"100%",textAlign:"center",wordBreak:"break-word"}} className="mb-4" target="_blank" href={`${channelUrl.current && channelUrl.current}`}>{channelUrl.current && channelUrl.current}</a>
                                            <p  className="mb-4 mx-0 p-0 text-center">
                                            All of your questions are addressed in our FAQ section.
                                            </p>
                                        </> }
                                   
                                    <div className="d-flex">
                                        {!mobileAuth &&
                                            <a className="btn btn-success pd-x-25 mx-auto" href="#" onClick={(event) => {
                                                event.preventDefault();
                                                dispatch(gotoTab('personalInfo'));
                                                navigate('/dashboard');
                                            }}>OK</a>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
