import { paginationCalculator } from "pagination-calculator";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { ChatWindow } from "../common/chatWindow";
import DataGrid from "../common/DataGrid";
import Header from "../common/Header";
import Loader from "../common/Loader";
import { ModalBox } from "../common/Modal/ModalBox";
import Sidebar from "../common/Sidebar";
import JobDetails from "../JobDetails/jobDetails";
import {
  claimJob,
  firstPage,
  getPersonalMsg,
  gotoPage,
  lastPage,
  loadShifts,
  nextPage,
  pageSize,
  previousPage,
  sendPersonal,
  unClaimJob,
  updateMsgFlag,
  updateSelectedJobDetails,
  updateSort,
} from "./action";
import { headersData } from "./MultishiftTableHeader";
import { shiftsSelector } from "./selector";
export default () => {
  // Hooks
  const dispatch = useDispatch();
  const config = useConfiguration();
  const storage = useSessionStorage();
  const navigate = useNavigate();
  // Unclaimed Open Shift Selector
  const {
    unclaimed,
    selectedJob,
    claimUnclaimErrorMessage,
    claimUnclaimSuccessMessage,
    chat,
    msgSendFlag,
  } = useSelector(shiftsSelector);
  // get form data in Local Storage
  const login = storage.getItem("login");
  // Claim / Cancel Modal Box Popup open and Close toogle
  const [claimUnclaimModalShow, setClaimUnclaimModalShow] = useState(false);
  // get from parameters for claim / Cancel
  const [claimUnclaimUniqueData, setClaimUnclaimUniqueData] = useState(null);
  // get message parameters
  const [msgParams, setmsgParams] = useState({
    webPageId: 0,
    shiftUniqueId: "",
    candidateUniqueId: "",
    employerUniqueId: "",
  });
  // message page
  const [openMessaging, setOpenMessaging] = useState(false);
  // chat data
  const [chatData, setChatData] = useState([]);
  // Table Headers data
  const headers = [
    {
      name: "Location",
      field: "location",
      type: "text",
      sort: true,
      filter: true,
      width: 370,
      minWidth: 170,
      maxWidth: 570,
    },
    {
      name: "Department",
      field: "candidateDepartmentName",
      type: "text",
      sort: true,
      filter: true,
      width: 370,
      minWidth: 170,
      maxWidth: 570,
    },
    {
      name: "Shift date",
      field: "shiftDateToDisplay",
      type: "text",
      sort: true,
      width:300,
      minWidth: 300,
      maxWidth: 500,
    },
    {
      name: "Shift End Date",
      field: "jobEndDateToDisplay",
      type: "text",
      sort: true,
      minWidth: 170,
    },
    {
      name: "Shift",
      field: "multiShift",
      type: "icon",
      filter: false,
      hide: false,
    },
    {
      name: "Shift start time",
      field: "startTime",
      type: "text",
      sort: true,
      width: 170,
      minWidth: 170,
      maxWidth: 170,
    },
    {
      name: "Shift end time",
      field: "endTime",
      type: "text",
      sort: true,
      width: 170,
      minWidth: 170,
      maxWidth: 170,
    },
    {
      name: "Rate Per Hour",
      field: "ratePerHourToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Total Shift Amount",
      field: "totalShiftAmountToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Incentive",
      field: "incentiveToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Claim/Cancel",
      field: "isClaimed",
      type: "icon",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    // {
    //   name: "View",
    //   field: "viewJobDetails",
    //   type: "icon",
    //   class: "fa fa-file",
    // },
    // {
    //   name: "Message",
    //   field: "messagepopup",
    //   type: "icon",
    //   class: "fa fa-commenting-o",
    // },
  ];
  // Load Unclaim Main Page API Method
  useEffect(() => {
    document.title = "Unclaimed shifts";
    if (config.baseUrl) {
      if (!login) {
        navigate("/");
      } else {
        dispatch(
          loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
        );
      }
    }
  }, [config]);

  useEffect(() => {
    const newData = JSON.parse(JSON.stringify(chat));
    buildGroupMsg(newData);
  }, [chat]);

  const buildGroupMsg = (data) => {
    const groups = data.reduce((groups, messages) => {
      const date = messages.messageDateTime.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(messages);
      return groups;
    }, {});

    const groupMsg = Object.keys(groups).map((date) => {
      return {
        date,
        messages: groups[date],
      };
    });
    setChatData(groupMsg);
  };

  const clearJobDetailsView = () => {
    dispatch(updateSelectedJobDetails(""));
  };
  // Multishift Job Info Page Navigation with send some Parameters
  const MultiShiftJobInfo = (params) => {
    navigate("/multishiftjobinfo", {
      state: {
        params: params,
        unclaimPage: true,
        headersData: headersData,
        subUrl: "CandidatesJobs/get-open-shifts-multioccurences",
      },
    });
  };
  // Claim or Cancel Modal Box open Button function
  const claimUnclaimModalOpenBtn = ({
    jobsShiftUniqueId,
    locationName,
    isClaimed,
  }) => {
    setClaimUnclaimModalShow(true);
    setClaimUnclaimUniqueData({ jobsShiftUniqueId, locationName, isClaimed });
  };
  // Claim or Cancel Modal Box Close Button function
  const claimUnclaimModalCloseBtn = () => {
    setClaimUnclaimModalShow(!claimUnclaimModalShow);
  };
  // Claim or Cancel Modal Box Function
  const claimUnclaimSave = () => {
    if (!claimUnclaimUniqueData.isClaimed) {
      dispatch(
        claimJob(config.baseUrl, claimUnclaimUniqueData.jobsShiftUniqueId)
      );
    }
    claimUnclaimModalCloseBtn();
  };
  // Table body Content Manipulation Area
  const generateRowData = (data, headers) => {
    const rowData = {};
    headers.forEach((header) => {
      // Only Text show data
      if (header.type === "text") {
        rowData[header.name] = data[header.field];
      } else {
        // isClaimed column
        if (header.field === "isClaimed") {
          const isClaimed = data[header.field];
          const iconClass = isClaimed ? "green-icon" : "grey-icon";
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-check-circle icon-align ${iconClass}`,
            onClick: () => {
              claimUnclaimModalOpenBtn({
                locationName: data.location,
                isClaimed: data.isClaimed,
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              });
            },
          };
          // View Job Details column
        } else if (header.field === "viewJobDetails") {
          rowData[header.name] = {
            type: "icon",
            class: `icon-align ${header.class}`,
            onClick: () => {
              dispatch(updateSelectedJobDetails(data["jobUniqueId"]));
            },
          };
          // Message Popup column
        } else if (header.field === "messagepopup") {
          rowData[header.name] = {
            type: "icon",
            class: `icon-align ${header.class}`,
            onClick: () => {
              openChantWindow(data.jobsShiftUniqueId);
            },
          };
        } else {
          rowData[header.name] = {
            type: "icon",
            class: `icon-align ${header.class}`,
          };
        }
      }
      // rate per hour column
      if (header.field === "ratePerHourToDisplayMultishift") {
        if (
          data.ratePerHourToDisplayMultishift === "$0" ||
          data.ratePerHourToDisplayMultishift === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.ratePerHourToDisplayMultishift;
        }
      }
      // Incentive column
      if (header.field === "incentiveToDisplay") {
        if (
          data.incentiveToDisplay === "$0" ||
          data.incentiveToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.incentiveToDisplay;
        }
      }
      // Total Shift Amount
      if (header.field === "totalShiftAmountToDisplay") {
        if (
          data.totalShiftAmountToDisplay === "$0" ||
          data.totalShiftAmountToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.totalShiftAmountToDisplay;
        }
      }
      // Shift date
      if (header.field === "shiftDateToDisplay") {
        const shiftType = data?.shiftType;
        if (shiftType === 3) {
          if (data?.shiftType === 3) {
            rowData[header.name] = (
              <span className="text-wrap" style={{ width: "6rem" }}>
                {data?.recurrenceDetail}
              </span>
            );
          } 
        } else {
          rowData[header.name] = data?.shiftDateToDisplay;
        }
      }
      // Multishift column
      if (header.field === "multiShift") {
        const shiftType = data?.shiftType;
        if (shiftType === 3) {
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-binoculars icon-align`,
            onClick: () => {
              const params = {
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              };
              MultiShiftJobInfo(params);
            },
          };
        }
      }
    });

    return rowData;
  };

  const openChantWindow = (jobsShiftUniqueId) => {
    if (jobsShiftUniqueId) {
      setmsgParams({
        webPageId: 0,
        shiftUniqueId: jobsShiftUniqueId,
        candidateUniqueId: login.candidateUniqueId,
        employerUniqueId: null,
      });
      const newparams = {
        webPageId: 0,
        shiftUniqueId: jobsShiftUniqueId,
        candidateUniqueId: login.candidateUniqueId,
        employerUniqueId: null,
      };
      dispatch(
        getPersonalMsg({
          url: `${config.baseUrl}/Messages/GetCandidateMessages`,
          params: newparams,
        })
      );
      setOpenMessaging(!openMessaging);
    }
  };

  const sendMsg = (text) => {
    const newparams = {
      ...msgParams,
      fromCandidate: true,
      messageText: text,
    };
    dispatch(
      sendPersonal({
        url: `${config.baseUrl}/Messages/SendMessageToEmployer`,
        params: newparams,
      })
    );
  };

  useEffect(() => {
    if (msgSendFlag && config.baseUrl) {
      dispatch(
        getPersonalMsg({
          url: `${config.baseUrl}/Messages/GetCandidateMessages`,
          params: msgParams,
        })
      ).then(() => {
        dispatch(updateMsgFlag(false));
      });
    }
  }, [msgSendFlag]);

  const createPageData = (page, pageSize, totalCount) => {
    if (page === 1) {
      return {
        from: page,
        to: pageSize,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    } else {
      const pageData = paginationCalculator({
        total: totalCount,
        current: page,
        pageSize: pageSize,
      });
      return {
        from: pageData.showingStart,
        to: pageData.showingEnd,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    }
  };
  const gotoFirstPage = () => {
    dispatch(firstPage("unclaimed")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoNextPage = () => {
    dispatch(nextPage("unclaimed")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoPreviousPage = () => {
    dispatch(previousPage("unclaimed")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoLastPage = () => {
    dispatch(lastPage("unclaimed")).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const gotoSelectedPage = (page) => {
    dispatch(gotoPage("unclaimed", page)).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };
  const setPageSize = (size) => {
    dispatch(pageSize("unclaimed", size)).then(() => {
      dispatch(
        loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
      );
    });
  };

  const formatTableData = (dataList) => {
    const tableData = {
      headers,
      rows: dataList?.data?.jobOpenshifts?.map((data) => ({
        columns: generateRowData(data, headers),
      })),
      options: {
        sortable: false,
        filter: false,
        pagination: true,
        pageSize: dataList?.pageSize || 10,
      },
      pageData: createPageData(
        dataList?.page,
        dataList?.pageSize,
        dataList?.data?.totalCount
      ),
      firstPage: gotoFirstPage,
      nextPage: gotoNextPage,
      previousPage: gotoPreviousPage,
      lastPage: gotoLastPage,
      updatePageSize: setPageSize,
      gotoPage: gotoSelectedPage,
      headerComponentParams: {
        sort: (order, column) => {
          dispatch(updateSort("unclaimed", order, column)).then(() => {
            dispatch(
              loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed")
            );
          });
        },
        headers,
      },
    };
    return tableData;
  };
  const refreshJobList = () => {
    dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "unclaimed"));
  };
  const errorMessage = claimUnclaimErrorMessage !== null;
  const successMessage = claimUnclaimSuccessMessage !== null;
  return (
    <div className="page">
      <Loader />
      <Header />
      <Sidebar />
      {/* Claim or Cancel Modal Box */}
      {claimUnclaimModalShow && (
        <ModalBox
          size={"md"}
          label={"Claim"}
          children={`Are you sure  want to proceed with Claim for this ${claimUnclaimUniqueData.locationName} job?`}
          showHide={claimUnclaimModalCloseBtn}
          buttonName={"Confirm"}
          saveFunction={claimUnclaimSave}
        />
      )}
      <Modal
        show={errorMessage || successMessage}
        size={"lg"}
        centered={true}
        // title="Error"
      >
        <Modal.Header>
          <Modal.Title>{errorMessage ? "Error" : "Success"}</Modal.Title>
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={refreshJobList}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* {errorMessage && (
            <div
              className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-autohide="false"
            >
              <div className="toast-body">{claimUnclaimErrorMessage}</div>
            </div>
          )} */}
          {errorMessage && (
            <div className="modal-body text-center p-4 pb-5">
              <i  className="icon icon-close  fs-70 text-danger  my-4 d-inline-block"></i>
              <h4>{claimUnclaimErrorMessage}</h4>
            </div>
          )}
          {successMessage && (
            <div className="modal-body text-center p-4 pb-5">
              <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
              <h4>{claimUnclaimSuccessMessage}</h4>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={refreshJobList}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {selectedJob !== "" ? (
        <JobDetails
          jobId={selectedJob}
          returnToGrid={clearJobDetailsView}
          isReadOnly={true}
          unClaimedPage={true}
        />
      ) : (
        <div className="page-main">
          <div className="main-content app-content mt-0 main-background">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <h1 className="page-title">Unclaimed Open Shifts</h1>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div
                            className={
                              "col-md-12 col-lg-12 col-xl-12 outer-div"
                            }
                          >
                            <DataGrid
                              {...formatTableData(unclaimed)}
                              headerSpace={true}
                            />
                          </div>
                          {
                            <div className="message-overlay">
                              {openMessaging && (
                                <ChatWindow
                                  closeChatWindow={() => {
                                    setOpenMessaging(!openMessaging);
                                  }}
                                  chatData={chatData}
                                  sendMsg={sendMsg}
                                />
                              )}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
