import {useState} from "react";
import Modal from "./modal";
import SpecialitiesForm from '../ProfileBuilder/ResumeBuilder/specialities';
import { useDispatch, useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import useSessionStorage from "../../hooks/useSessionStorage";
import { validateResume } from "../ProfileBuilder/ResumeBuilder/customResumeValidation";
import { resumeBuilderSelector } from "../ProfileBuilder/ResumeBuilder/selector";
import { saveResumeBuilder } from "../ProfileBuilder/ResumeBuilder/action";
import useConfiguration from "../../hooks/useConfiguration";

export default (props) => {
    const { login_roles_flags, profile_builder_flags } = useSelector(profileBuilderSelector);
    const resumeForm = useSelector(resumeBuilderSelector);
    const storage = useSessionStorage();
    const config = useConfiguration();
    const dispatch = useDispatch();
    const login = storage.getItem('login');
    const [resumeBuilderError, setResumeBuilderError] = useState({});
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const editSpecialities = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('resumeBuilder');
        }
    };
    const saveSpecialities = (event) => {
        event.preventDefault();
        // if (props.saveContinue('resumeBuilder', false)) {
        //     setShowModal(!showModal);
        // }
        let valid = true;
        const resumeFormValid = validateResume(resumeForm, login.jobTypeInfo, profile_builder_flags);
        if (resumeFormValid.isValid) {
        dispatch(
            saveResumeBuilder({
              url: `${config.baseUrl}`,
              candidateId: login.candidateId,
              userId: login.appUserId,
              gotoNextTab: false,
              flag: login?.jobTypeInfo?.showQATab,
            })
          );
        setShowModal(!showModal);
        setResumeBuilderError({});
        } else {
            setResumeBuilderError(resumeFormValid.errors);
            valid = false;
            return;
        }

    };
    const getSkillName = (skill) => {
        if (skill && skill !== "") {
            const masterSkill = props.masterDatas.specialities.find(speciality => speciality.id === Number(skill));
            if (masterSkill) {
                return masterSkill.name;
            }
        }
        return '';
    };
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal &&
                    <Modal label={"Medical Specialties"} showHide={editSpecialities} saveFunction={saveSpecialities}>
                        <SpecialitiesForm errors={resumeBuilderError}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            <h4 className="text-wrap mr-2">Medical Specialties</h4>
                            {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                            <a id="EditYSpecialties" href="#" onClick={editSpecialities}>
                                <i className="fe fe-edit" title="Edit Medical Specialties"></i>
                            </a> : ''
                             }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="row">
                            <div className="row">
                                <div className="col-lg-6 col-md-3">
                                    <label className="form-label">Medical Specialties</label>
                                </div>
                                <div className="col-lg-4 col-md-3">
                                    <label className="form-label">Years Of Experience</label>
                                </div>
                            </div>
                            {
                                props?.specialities?.map(speciality => {
                                    return !speciality.isOther ?
                                    (
                                    <div className="row">
                                        <div className="col-lg-6 col-md-3">
                                            <label>{getSkillName(speciality.skill)}</label>
                                        </div>
                                        <div className="col-lg-4 col-md-3">
                                            {speciality.years}
                                        </div>
                                    </div>
                                    ) : null;
                                })
                            }
                        </div>
                    </div>
                    <hr className="Line" style={{ display: "none" }}/>
                    <br/>
                    <div className="row" style={{ display: "none" }}>
                        <div className="col-lg-4 col-md-4">
                            <h3>Other Specialities</h3>
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <a id="EditSpeciality" href="#" onClick={editSpecialities}>
                                <i className="fe fe-edit" title="Edit Speciality Form"></i>
                            </a>
                        </div>
                    </div>
                    <div className="form-group" style={{ display: "none" }}>
                        {
                            props?.specialities?.map(speciality => {
                                return speciality.isOther ?
                                    (
                                        <div className="row">
                                            <div className="col-lg-6 col-md-3">
                                                <label>{speciality.otherName}</label>
                                            </div>
                                            <div className="col-lg-4 col-md-3">
                                                {speciality.years}
                                            </div>
                                        </div>
                                    ) : null;
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
