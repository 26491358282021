import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useConfiguration from "../../../hooks/useConfiguration";
import useSessionStorage from "../../../hooks/useSessionStorage";
import DynamicForm from '../../common/DynamicForm/form';
import { deleteFile, downloadUploadedDocument, getDynamicFormData, updateExpirationDate, updateQADetail, uploadFile } from './action';
import { qandAlInfoSelector } from './selector';
import { setQaDataList } from './slice';
import { resumeBuilderSelector } from '../ResumeBuilder/selector';
import { ToastContainer } from 'react-toastify';
import { getCurrentResumeBuilder } from '../ResumeBuilder/action';

const QandA = (props) => {
    const {categories} = useSelector(resumeBuilderSelector);
    const jobLevelId = categories.map((item) => item.id)
    const { formdata, downloadUrl, qaDataList } = useSelector(qandAlInfoSelector);
    const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
    const { qandAErrors } = props
    const dispatch = useDispatch();
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem('login');
    const [form, setForm] = useState(formdata);
    // const [dataCopy,setDataCopy] = useState([]);
    useEffect(() => {
        if (config.baseUrl) {
            if (jobLevelId?.length) {
                dispatch(getDynamicFormData({ url: config.baseUrl, jobLevel: jobLevelId }));
            } else {
                dispatch(
                    getCurrentResumeBuilder({
                      url: `${config.baseUrl}/Candidates/getjobinfo`,
                      userId: login.candidateId,
                    })
                  ).then((res) => {              
                    dispatch(getDynamicFormData({ url: config.baseUrl, jobLevel: res?.jobCategoryIdList }));
                  })
            }
        }
    }, [config])

    useEffect(() => {
        dispatch(updateQADetail(form));
    }, [form]);
    useEffect(() => {
        if(!qaDataList.length){
        dispatch(setQaDataList(formdata))
      }
    },[formdata])
    const updateVal = (target, type, sectionId, fieldId) => {
        const newVal = JSON.parse(JSON.stringify(formdata));
        newVal.forEach(item => {
            if (item.sectionId === sectionId) {
                item.fields.forEach(val => {
                   if (val.id === fieldId) {
                        if (type === 'CheckboxMulti') {
                            val.selectedValueOptions = checkboxMulti(val.selectedValueOptions, target.value, target.checked)
                        } else if (type === 'YesNoRadio') {
                            val.selectedValue = yesNoRadio(target.value)
                        }
                    }
                })
            }
        });
        setForm(newVal);
    }

    const fileUploade = (e, type, sectionId, fieldId, fileId) => {
        const file = e.target.files[0];
        dispatch(uploadFile(config.baseUrl, login.candidateUniqueId, file, type, sectionId, fieldId, fileId));
    }

    const checkboxMulti = (data, val, checked) => {
        const arrVal = [...data];
        const index = arrVal.indexOf(val)
        if (checked) {
            arrVal.push(val);
        } else {
            arrVal.splice(index, 1);
        }

        return [...new Set(arrVal)]
    }

    const yesNoRadio = (val) => {
        if (val === 'yes') {
            return "Yes";
        } else if (val === 'no') {
            return "No"
        }
    }

    const removeFile = (sectionId, fieldId, fileId) => {
        dispatch(deleteFile(config.baseUrl,  login.candidateUniqueId , sectionId, fieldId, fileId));
    }
    const downloadUploadedDoc = (sectionId, fieldId, fileId,blobFileName,fileName) => {
        dispatch(downloadUploadedDocument(config.baseUrl,  login.candidateUniqueId , sectionId, fieldId, fileId,blobFileName,fileName,login.accessToken));
    }

    const updateDate = (expirationDate,sectionId,fieldId,attachmentId) => {
        dispatch(updateExpirationDate(expirationDate,sectionId,fieldId,attachmentId))
    }
    return (
        <form>
            <div id="DivFor_EC">
            {   mobileAuth &&
                      <ToastContainer style={{ "zIndex": 999999 }} />
            }
                <div id="largemodal_EC_1">
                    <div id="largemodal_EC_2">
                        <div id="largemodal_EC_3">
                            {
                                formdata?.length > 0 &&

                                formdata?.map((item,index) => (
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className='text-wrap fw-bold'>{item?.sectionHeading}</h4>
                                        </div>
                                        <div className='card-body'>
                                            <DynamicForm fields={item?.fields} updateVal={updateVal} sectionId={item?.sectionId}
                                                fileUploade={fileUploade} removeFile={removeFile}
                                                errors={qandAErrors ? qandAErrors[item?.sectionId] : ''} downloadUrl={downloadUrl} updateDate={updateDate} dataCopy = {qaDataList && qaDataList?.[index]?.fields} downloadUploadDoc = {downloadUploadedDoc}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default QandA;