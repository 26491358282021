import useEventListener from '@use-it/event-listener';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { encryptionString, decryptionString } from '../../helpers/encryption';
import { randomString } from '../../helpers/randomString';
import useConfiguration from '../../hooks/useConfiguration';
import useSessionStorage from '../../hooks/useSessionStorage';
import useValidation from '../../hooks/useValidation';
import Loader from '../common/Loader';
import TextField from '../common/TextField';
import { getDynamicFormData, getProfileBuilderFlagsFields, getTrackingParams, get_login_roles, loginV1, resetError } from './action';
import { loginSelector } from './selector';
import CryptoJS from 'crypto-js';
import { mapSignupUrl } from '../../helpers/domainUrl';
import { set_login_roles_flags } from '../ProfileBuilder/slice';
import { profileBuilderSelector } from '../ProfileBuilder/selector';
import { getCurrentResumeBuilder, getLevelBasedAPIDatas, loadMasterDatas } from '../ProfileBuilder/ResumeBuilder/action';
import { getCurrentPersonalInfo, loadMasterData } from '../ProfileBuilder/PersonalInfo/action';
import { setResumeBuilderData } from '../ProfileBuilder/ResumeBuilder/slice';
import { getMasterData, getPreference } from '../ProfileBuilder/Preference/action';
import { resumeBuilderSelector } from '../ProfileBuilder/ResumeBuilder/selector';
import Spinner from '../common/Spinner/spinner';
import { usePageViews } from '../../app/UsePageView';
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    usePageViews();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validate = useValidation();
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem('login');
    const [cookies, setCookie] = useCookies(['userName']);
    const { isError, isSuccess, data, errorMessage, profileFlag, loadingFlag } =
        useSelector(loginSelector);
    const trackingHistoryParams = useSelector(store => store.login.trackingHistoryParams)
    const {categories, resumeBuilderData} = useSelector(resumeBuilderSelector);
    const domainJobtypeId = sessionStorage.getItem('domainJobtypeId');
    const domainLogos = JSON.parse(sessionStorage.getItem('domainLogos'));
    const domainTitles = JSON.parse(sessionStorage.getItem('domainTitles'));
    const domainIcons = JSON.parse(sessionStorage.getItem('domainIcons'));
    const ENTER_KEYS = [13, 'Enter'];

    const { login_roles_flags } = useSelector(profileBuilderSelector);

    const [formData, setFormData] = useState({
        email: cookies.userName || '',
        encryptPassword: '',
        iv: '',
    });

    // Search params use to get a url
    const [searchParams, setSearchParams] = useSearchParams();
    // get a data via search params in bellow
    const src = searchParams.get('src');
    const username = searchParams.get('username');
    // get a full url
    const url = new URL(window.location.href);
    // Your input string
    var inputString = url.search;
    // Regular expression pattern to match the encryptPassword parameter
    var pattern = /encryptPassword=([^&]+)/;
    // Use the match method to find the match
    var match = inputString.match(pattern);
    // Check if a match is found
    if (match) {
        // Extract the value of encryptPassword
        var encryptPassword = match[1];
    }

    const iv = searchParams.get('iv');
    const page = searchParams.get('page');
    const usertoken = searchParams.get('usertoken');
    const countryPhoneCode = searchParams.get('countryPhoneCode');
    const jobsShiftUniqueId = searchParams.get('jobsShiftUniqueId');
    const jobsClaimId = searchParams.get('jobsClaimId');


    useEffect(() => {
        if (src && username && encryptPassword && iv) {
            // search params data store an object
            const mobileAuth = {
                src,
                username,
                encryptPassword,
                iv
            }
            // put a session storage for mobile auth details
            sessionStorage.setItem('mobileAuth', JSON.stringify(mobileAuth));
            // Navigate a Mobile Auth Page
            navigate('/mobileauthcheking', {
                state: {
                    pageName: page ? page : '',
                }
            })
        } else if (src && usertoken && iv && config.SaltKeyForJSandAPI) {
            let inputString = url.search;
            // Regular expression pattern to match the encryptPassword parameter
            let pattern = /usertoken=([^&]+)/;
            // Use the match method to find the match
            let match = inputString.match(pattern);
            if (match && match?.length) {
                const val = decryptionString(match[1], iv, config.SaltKeyForJSandAPI);
                const credential = val.split('$');
                // search params data store an object
                if (credential && credential?.length) {
                    const mobileAuth = {
                        src,
                        username: credential[0],
                        encryptPassword: credential[1],
                        iv,
                        userName: searchParams.get('ContactName'),
                        toNumber: `+${countryPhoneCode}${searchParams.get('ToNumber').trim()}`,
                        jobsShiftUniqueId,
                        jobsClaimId

                    }
                    // put a session storage for mobile auth details
                    sessionStorage.setItem('mobileAuth', JSON.stringify(mobileAuth));
                    // Navigate a Mobile Auth Page
                    navigate('/mobileauthcheking', {
                        state: {
                            pageName: page ? page : '',
                        }
                    })
                }
            }
        }

    }, [config.SaltKeyForJSandAPI])


    // useEffect(() => {
    //     if (isSuccess && data?.loginRoles?.tenantRoles?.at(0)?.status === false) {
    //         console.log('LOGIN IN');
    //     //     config.baseUrl &&
    //     //     dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
    //     //         console.log('PERSONAL INFO');
    //     //       dispatch(
    //     //         getCurrentPersonalInfo({
    //     //           url: `${config.baseUrl}/Candidates/getpersonalinfo`,
    //     //           userId: data.candidateId,
    //     //         })
    //     //       );
    //     //     })
    //     //   config.baseUrl &&
    //     //     dispatch(getMasterData(config.baseUrl)).then(() => {
    //     //       dispatch(getPreference(config.baseUrl, data.candidateId));
    //     //     });
  
    //         // setTimeout(() => {
    //         //   dispatch(
    //         //     getCurrentResumeBuilder({
    //         //       url: `${config.baseUrl}/Candidates/getjobinfo`,
    //         //       userId: data.candidateId,
    //         // }))
    //         // },2000)
    //     }
    // },[isSuccess])


    const [rememberMe, setRememberMe] = useState(cookies.rememberMe ? JSON.parse(cookies.rememberMe) : false);
    const [formErrors, setFormErrors] = useState({});
    if (isSuccess) {
        storage.setItem('login', data);
        if (profileFlag && profileFlag.isProfileSubmitted) {
            navigate('/dashboard');
        } else {
            if (config.baseUrl) {
                // dispatch(get_login_roles(config.baseUrl)).then((response) => {
                //     let can_edit_profile_filter;
                //     // Create Object for respective names
                //     data?.loginRoles?.tenantRoles?.forEach(element => {
                //     if (element?.roleName === response?.CanEditProfileFilter) {
                //     can_edit_profile_filter = element
                //     }
                //     })
                //     // Set a object for Login Roles
                //     const login_roles_types = {
                //       can_edit_profile_filter
                //     }
              
                //     dispatch(set_login_roles_flags(login_roles_types));
                    setTimeout(() => {
                        navigate('/profile');
                    },1000)
                
                // })
            }
            // navigate('/profile');
        }

    }
    const validateForm = () => {
        const result = validate('login', [
            { name: 'email', data: formData.email },
            {
                name: 'encryptPassword',
                data: formData.encryptPassword,
            },
        ]);
        if (result.valid) {
            setFormErrors({});
        } else {
            setFormErrors(result.errors);
        }
        return result.valid;
    };
    const updateFormValue = (target) => {
        const { name, value } = target;
        if (rememberMe) {
            name === 'email' && setCookie('userName', value, { path: '/' });
        }
        setFormData({ ...formData, [name]: value });
    };

    const loginClicked = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            const iv = randomString(16, '0,1,2,3,4,5,6,7,8,9');
            dispatch(getProfileBuilderFlagsFields(config.baseUrl))
            dispatch(
                loginV1({
                    url: `${config.baseUrl}/Authenticate/c/signin`,
                    email: formData.email,
                    iv: iv,
                    encryptPassword: encryptionString(
                        config.SaltKeyForJSandAPI,
                        iv,
                        formData.encryptPassword
                    ),
                    baseUrl: config.baseUrl
                })
            ).then((response) => {
                dispatch(getTrackingParams(config.baseUrl, { ...trackingHistoryParams, userName: formData.email }, response))
                dispatch(getDynamicFormData(config.baseUrl));
            });
        }
    };

    const handleKeyEvent = ({ key }) => {
        if (ENTER_KEYS.includes(key)) {
            const isValid = validateForm();
            if (isValid) {
                const iv = randomString(16, '0,1,2,3,4,5,6,7,8,9');
                dispatch(getProfileBuilderFlagsFields(config.baseUrl))
                dispatch(
                    loginV1({
                        url: `${config.baseUrl}/Authenticate/c/signin`,
                        email: formData.email,
                        iv: iv,
                        encryptPassword: encryptionString(
                            config.SaltKeyForJSandAPI,
                            iv,
                            formData.encryptPassword
                        ),
                        baseUrl: config.baseUrl
                    })
                ).then((response) => {
                    dispatch(getTrackingParams(config.baseUrl, { ...trackingHistoryParams, userName: formData.email }, response))
                    dispatch(getDynamicFormData(config.baseUrl));
                });
            }
        }
    };
    useEventListener('keydown', handleKeyEvent);


    useEffect(() => {
        document.title = 'Overture Candidate Login';
    }, []);

    const signUpClicked = (e) => {
        e.preventDefault();
        dispatch(resetError())
        if (mapSignupUrl.nurseLive?.indexOf(window.location.hostname) !== -1) {
            window.open("https://overturexl.com/candidates.html#getstart", "_self")
        }
        else if (mapSignupUrl.securityLive?.indexOf(window.location.hostname) !== -1) {
            window.open("https://overturesec.com/candidates.html#getstart", "_self")
        }
        else if (mapSignupUrl.restaurantLive?.indexOf(window.location.hostname) !== -1) {
            window.open("https://overtureyum.com/candidates.html#getstart", "_self")
        }
        else if (mapSignupUrl.dentalLive?.indexOf(window.location.hostname) !== -1) {
            window.open("https://overturedds.com/candidates.html#getstart", "_self")
        }
        else if (mapSignupUrl.fiveStarLive?.indexOf(window.location.hostname) !== -1) {
            window.open("https://5starmedicalstaffing.overturexl.com", "_self")
        }
        else if (mapSignupUrl.nursesEtcLive?.indexOf(window.location.hostname) !== -1) {
            window.open("https://nursesetc.overturexl.com", "_self")
        }
        else if (mapSignupUrl.pureHeartLive?.indexOf(window.location.hostname) !== -1) {
            window.open("https://pureheart.overturexl.com", "_self")
        }
        else if (mapSignupUrl.fiveStarQA?.indexOf(window.location.hostname) !== -1) {
            window.open("https://qatestcandidate5star.overturexl.com/5star/index.html", "_self")
        }

        else if (mapSignupUrl.fiveStar?.indexOf(window.location.hostname) !== -1) {
            window.open("https://dev5star.overturexl.com/index.html", "_self")
        }

        else if (mapSignupUrl.nurse?.indexOf(window.location.hostname) !== -1) {
            // window.open(window.location.origin + "/website/candidates.html#getstart", "_self")
            window.open(window.location.origin + "/website/XL/index.html", "_self")
        }
        else if (mapSignupUrl.security?.indexOf(window.location.hostname) !== -1) {
            // window.open(window.location.origin + "/website/security.html#getstart", "_self")
            window.open(window.location.origin + "/website/SEC/index.html", "_self")
        }
        else if (mapSignupUrl.restaurant?.indexOf(window.location.hostname) !== -1) {
            // window.open(window.location.origin + "/website/restaurant.html#getstart", "_self")
            window.open(window.location.origin + "/website/YUM/index.html", "_self")
        }
        else if (mapSignupUrl.dental?.indexOf(window.location.hostname) !== -1) {
            // window.open(window.location.origin + "/website/dental.html#getstart", "_self")
            window.open(window.location.origin + "/website/DDS/index.html", "_self")
        }
        else if (mapSignupUrl.listStyle?.indexOf(window.location.hostname) !== -1) {
            window.open("https://dynamicwebsite.overturexl.com", "_self")
        }
        else if (mapSignupUrl.listStyleStag?.indexOf(window.location.hostname) !== -1) {
            window.open("https://stagingwebsite.overturexl.com/xl", "_self")
        }
        else if (mapSignupUrl.listStyle5StarStag?.indexOf(window.location.hostname) !== -1) {
            window.open("https://5starstagingwebsite.overturexl.com", "_self")
        }
        else if (mapSignupUrl.nursesEtcQA?.indexOf(window.location.hostname) !== -1) {
            window.open("https://qatestcandidatenursesetc.overturexl.com/website/indexnursesetc.html", "_self")
        }
        else if (mapSignupUrl.nursesEtcDEV?.indexOf(window.location.hostname) !== -1) {
            window.open("https://devcandidatenursesetc.overturexl.com/website/nursesetc-candidates.html", "_self")
        }
        else {
            window.open(window.location.origin + "/website/candidates.html#getstart", "_self")
        }

    };
    const forgotPassword = (e) => {
        e.preventDefault();
        navigate('/forgot');
    };

    const [passwordType, setPasswordType] = useState('password');

    const [passwordInput, setPasswordInput] = useState('');

    const [iconStyleText, seticonStyleText] = useState(
        'zmdi zmdi-eye-off text-muted'
    );

    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    };
    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            seticonStyleText('zmdi zmdi-eye text-muted');
            return;
        }
        setPasswordType('password');
        seticonStyleText('zmdi zmdi-eye-off text-muted');
    };
    const rememberMeClicked = (event) => {
        if (event.target.checked) {
            setCookie('userName', formData['email'], { path: '/' });
            setCookie('rememberMe', JSON.stringify(true), { path: '/' });
            setRememberMe(true);
        } else {
            setCookie('userName', '', { path: '/' });
            setCookie('rememberMe', false, { path: '/' });
            setRememberMe(false);
        }
        // console.log(domainLogos[domainJobtypeId]);
    };
    // useEffect(() => {
    //     let link = document.querySelector("link[rel~='icon']");
    //     let titleElement = document.querySelector("title");
    //     if (!link) {
    //       link = document.createElement('link');
    //       link.rel = 'icon';
    //       document.getElementsByTagName('head')[0].appendChild(link);
    //     }
    //     if(!titleElement){
    //         titleElement = document.createElement('title');
    //         document.getElementsByTagName('head')[0].appendChild(titleElement);
    //     }
    //     if(link){
    //         console.log({result:domainTitles[domainJobtypeId]});
    //         link.href = domainIcons[domainJobtypeId]
    //     }
    //     if(titleElement)
    //     titleElement.innerHTML = `${domainTitles[domainJobtypeId]} Candidate Login`
    //   }, []);

//  const login_roles_fields = storage.getItem('login_roles');

//   // Login Roles state
//   const [convert_login_roles_flags, set_convert_login_roles_flags] = useState(login?.loginRoles?.tenantRoles ? login?.loginRoles?.tenantRoles :[]);


//  // Login Roles Initialize
//   let can_edit_profile_filter;
//   // Create Object for respective names
//   convert_login_roles_flags?.forEach(element => {
//     if (element?.roleName === login_roles_fields?.CanEditProfileFilter) {
//       can_edit_profile_filter = element
//      }
//   })
//   // Set a object for Login Roles
//   const login_roles_types = {
//     can_edit_profile_filter
//   }

//   console.log(convert_login_roles_flags);


//     // Object store a profile builder redux
//     useEffect(() => {
//         if (config.baseUrl) {
//         dispatch(set_login_roles_flags(login_roles_types));
//       }
  
//     },[convert_login_roles_flags])





    return (
        <div className={''}>
            <Loader />
        {/* {loadingFlag > 0 && <Spinner />} */}
            {!src ?
                <div className="page">
                    <div className="">
                        <div className="container-login100">
                            <div className="wrap-login100 p-6">
                                <form className="login100-form needs-validation">
                                    <span className="login100-form-title pb-5">
                                        <a href="">
                                            <img
                                                // style={{ height: '62px' }}
                                                // style={{ height: '62px' }}
                                                style={{minWidth:"270px", maxHeight:"90px", maxWidth:"280px" }}
                                                src={domainLogos[domainJobtypeId]}
                                                className="header-brand-img"
                                                alt=""
                                            />
                                        </a>
                                    </span>
                                    <span className="login100-form-title pb-5">
                                        Candidate Login
                                    </span>
                                    {isError && (
                                        <>
                                            <div
                                                className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
                                                role="alert"
                                                aria-live="assertive"
                                                aria-atomic="true"
                                                data-bs-autohide="false"
                                            >
                                                <div className="logingerrormgs">
                                                    <div className="toast-body">
                                                        {errorMessage || 'Login failed! Please try again.'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>&nbsp;</div>
                                        </>
                                    )}
                                    <div className="panel panel-primary">
                                        <div className="panel-body tabs-menu-body p-0 pt-5">
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="tab5">
                                                    <div
                                                        className="wrap-input100 validate-input input-group"
                                                        id="Emailtoggle"
                                                    >
                                                        <label htmlFor="exampleInputname" className="logintextspace">
                                                            <h5 className={`${parseInt(domainJobtypeId) === 5 && 'text-yum'}`}>Email</h5>
                                                        </label>
                                                    </div>
                                                    <TextField
                                                        placeholder={'Email'}
                                                        type={'email'}
                                                        name={'email'}
                                                        iconClass={'zmdi zmdi-email text-muted'}
                                                        updateValue={updateFormValue}
                                                        errors={formErrors['email']}
                                                        value={formData.email}
                                                    />

                                                    <div
                                                        className="wrap-input100 validate-input input-group"
                                                        id="Passwordtoggle"
                                                    >
                                                        <label htmlFor="exampleInputname" className="logintextspace">
                                                            <h5 className={`${parseInt(domainJobtypeId) === 5 && 'text-yum'}`}>Password</h5>
                                                        </label>
                                                    </div>

                                                    <TextField
                                                        placeholder={'Password'}
                                                        clickeye={togglePassword}
                                                        type={passwordType}
                                                        name={'encryptPassword'}
                                                        iconClass={iconStyleText}
                                                        onChange={handlePasswordChange}
                                                        updateValue={updateFormValue}
                                                        errors={formErrors['encryptPassword']}
                                                        data-tip="This is the text of the tooltip"
                                                    />

                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className={`${parseInt(domainJobtypeId) === 5 ? 'custom-control-input-yum' : 'custom-control-input'}`}
                                                                // className="custom-control-input"
                                                                type="checkbox"
                                                                id="rememberme"
                                                                checked={rememberMe}
                                                                onChange={rememberMeClicked}
                                                            />
                                                            <label
                                                                htmlFor="rememberme"
                                                                className={`custom-control-label ${parseInt(domainJobtypeId) === 5 && 'text-yum'}`}
                                                            // className="custom-control-label"
                                                            >
                                                                Remember Me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="text-end pt-4">
                                                        <p className="mb-0">
                                                            <a
                                                                href="#"
                                                                className={` ${parseInt(domainJobtypeId) === 5 ? 'text-yum' : 'text-primary'} ms-1`}
                                                                // className="text-primary ms-1"
                                                                onClick={forgotPassword}
                                                            >
                                                                Forgot Password?
                                                            </a>
                                                        </p>
                                                    </div>
                                                    <div className="container-login100-form-btn">
                                                        <a
                                                            href="#"
                                                            // className="login100-form-btn btn-primary"
                                                            className={` login100-form-btn ${parseInt(domainJobtypeId) === 5 ? 'btn-yum' : 'btn-primary'}`}
                                                            onClick={loginClicked}
                                                            onKeyPress={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    loginClicked(event);
                                                                }
                                                            }}
                                                        >
                                                            Login
                                                        </a>
                                                    </div>
                                                   {parseInt(domainJobtypeId) === 9 ?  '' :
                                                    <div className="text-center pt-3">
                                                        <p className="text-dark mb-0">
                                                            Not a member?
                                                            <a
                                                                href="#"
                                                                onClick={signUpClicked}
                                                                className={` ${parseInt(domainJobtypeId) === 5 ? 'text-yum' : 'text-primary'} ms-1`}
                                                            // className="text-primary "
                                                            >
                                                                Sign Up
                                                            </a>
                                                        </p>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> : ''}


        </div>
    );
};
