import TextField from "../../common/TextField/withLabel";
import React, { useEffect } from "react";
import { personalInfoSelector } from "./selector";
import { updatePersonalInfo } from "./action";
import { useDispatch, useSelector } from "react-redux";
import TextFieldFormatted from "../../common/TextFieldPhoneNumber";
import DropDown from "../../common/TextField/dropDown";
import { profileBuilderSelector } from "../selector";

export default (props) => {
    const { errors } = props;
    const { data, masterData } = useSelector(personalInfoSelector);
    const { profile_builder_flags } = useSelector(profileBuilderSelector);
    const dispatch = useDispatch();
    const [form, setForm] = React.useState(data);
    useEffect(() => {
        dispatch(updatePersonalInfo(form));
    }, [form]);
    const updateEmergencyForm = (target) => {
        setForm({
            ...data,
            emergencyContact: {
                ...data.emergencyContact,
                [target.name]: target.value
            }
        });
    };
    const updateRelationship = (target) => {
        setForm({
            ...data,
            emergencyContact: {
                ...data.emergencyContact,
                relationship: [target.value]
            }
        });
    };
    return (
        <div className="card-body">
            <div className="row">
                <TextField name={'firstName'} label={profile_builder_flags?.emergency_contact?.isRequired ? 'First Name*' : 'First Name'} placeholder={"First Name"}
                    updateValue={updateEmergencyForm} value={data.emergencyContact.firstName}
                    errors={errors['firstName']} />
                <TextField name={'lastName'} label={profile_builder_flags?.emergency_contact?.isRequired ? 'Last Name*' : 'Last Name'} placeholder={"Last Name"}
                    updateValue={updateEmergencyForm} value={data.emergencyContact.lastName}
                    errors={errors['lastName']} />
            </div>
            <div className="row">
                <TextField name={'phoneNumber'} label={profile_builder_flags?.emergency_contact?.isRequired ? 'Phone Number*' : 'Phone Number'} placeholder={"Phone Number"}
                    updateValue={updateEmergencyForm} value={data.emergencyContact.phoneNumber}
                    errors={errors['phoneNumber']} format={"phone"} />
                <DropDown name={"relationship"} label={profile_builder_flags?.emergency_contact?.isRequired ? 'Relationship*' : 'Relationship'}
                    placeholder={"Relationship"} updateValue={updateRelationship} className={'relationShip'}
                    value={data.emergencyContact.relationship ?? [{id: '', name: 'Select'}]}
                    options={masterData.relationships} showArrow={true} isObject={true} multiple={false}
                    errors={errors['relationship']} />


            </div>
        </div>
    )
}
