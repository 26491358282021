import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
const loginTime = moment().utc().format()
const initialState = {
    loadingFlag: 0,
    isError: false,
    isSuccess: false,
    errorMessage: '',
    data: {},
    profileFlag: {},
    globalJobCategory: '',
    trackingHistoryParams:{
        "appUserLoginHistoryId": 0,
        "userName": "",
        "memberType": 0,
        "appUserId": 0,
        "employerId": 0,
        "loginAttemptTime":loginTime,
        "loginResult": 0,
        "sourceIP": "string",
        "sourceAddress": "string",
        "sourceLocationCity": "string",
        "sourceState": "string",
        "sourceZip": "string",
        "sourceCountry": "string",
        "redirectPageUrl": "string",
        "browser": "string",
        "latitude": "string",
        "longitude": "string",
        "deviceType": "string",
        "deviceID": "string",
        "webAppVersion": 1,
        "mobileAppVersion": 0,
        "operatingSystem": "string"
      }
};

export const loginSlice = createSlice({
    name: 'login'
    , initialState,

    reducers: {
        loginRequest: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.errorMessage = '';
            data: {
            }
        },
        loginSuccess: (state, action) => {
            state.isError = false;
            state.isSuccess = true;
            state.errorMessage = '';
            state.data = action.payload;
            state.profileFlag = action.payload && action.payload.profileFlag;
            state.globalJobCategory = action.payload?.jobCategory
        },
        setProfileFlag: (state, action) => {
            const flagName = action.payload;
            const newProfileFlag = { ...state.profileFlag, [flagName]: true };
            state.profileFlag = newProfileFlag;
        },
        loginFailure: (state, action) => {
            state.isError = true;
            state.isSuccess = false;
            state.errorMessage = action.payload;
        },
        reset: state => {
            state.isError = false;
            state.isSuccess = false;
            state.errorMessage = '';
            state.data = {};
        },
        setJobCategory: (state, action) => {
            state.globalJobCategory = action.payload
        },
        setLoadingFlagIncrement: (state) => {
            state.loadingFlag = state.loadingFlag + 1;
        },
        setLoadingFlagDecrement: (state) => {
            if (state.loadingFlag !== 0) {
              state.loadingFlag = state.loadingFlag - 1;
            }
        },
    }
});

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
    setProfileFlag,
    reset,
    setJobCategory,
    setLoadingFlagIncrement,
    setLoadingFlagDecrement
} = loginSlice.actions;

export default loginSlice.reducer;
