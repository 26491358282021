import RadioGroup from "../../common/RadioGroup";
import {useDispatch, useSelector} from "react-redux";
import {preferenceSelector} from "./selector";
import {updateVaccination} from "./action";
import React from "react";
import { profileBuilderSelector } from "../selector";

export default (props) => {
    const dispatch = useDispatch();
    const {vaccination, masterDatas} = useSelector(preferenceSelector);
    const { profile_builder_flags } = useSelector(profileBuilderSelector);

    const setVaccination = (target) => {
        const value = target.value;
        dispatch(updateVaccination(value));
    };
    const errors = props.errors ? props.errors['vaccination'] : [];
    return (
        <div id="DivFor_Covid">
            <div id="largemodal_Covid_1">
                <div id="largemodal_Covid_2">
                    <div id="largemodal_Covid_3">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Covid 19 Vaccination Status?{profile_builder_flags?.covid_19?.isRequired ? '*' : ''} </h4>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    <RadioGroup options={masterDatas.covidVaccine} name={'vaccination'}
                                                value={vaccination} updateValue={setVaccination}/>
                                    {errors ? errors.map(error => (
                                        <div className="invalid-input">{error}</div>)) : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
