import { setLoading } from "../common/Loader/slice";
import {
  setPage,
  shiftLoadingFailed,
  updateShifts,
  updatePageSize,
  setSorting,
  updateSelectedJob,
  setMsgSendFlag,
  setChat,
  setClaimUnclaimSuccessMessageMyShift,
  setClaimUnclaimErrorMessageMyShift,
  setClaimUnclaimMsgResetMyShift,
  setUnclaimEmployerReasons,
} from "./slice";
import requestUtil from "../../helpers/requestUtil";
import * as Toasts from "../common/Toastify";
const createLoadRequest = (
  page,
  pageSize,
  candidateId,
  sortOrder,
  sortColumn
) => {
  const request = {
    page: page,
    pageSize: pageSize,
    candidateUniqueId: `${candidateId}`,
  };
  if (sortColumn && sortColumn.length > 0) {
    request.sortOrder = sortOrder;
    request.sortColumn = sortColumn;
  }
  return request;
};

export function loadShifts(url, candidateId, pageName) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const state = getState();
      const { page, pageSize, sortOrder, sortColumn } = state.myshift.myshift;
      const request = createLoadRequest(
        page,
        pageSize,
        candidateId,
        sortOrder,
        sortColumn
      );
      const candidateJobShifts = await requestUtil(
        `${url}/CandidatesJobs/get-my-shifts`,
        "POST",
        request
      );
      dispatch(
        updateShifts({
          data: candidateJobShifts,
          page: page,
          pageName,
        })
      );
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };
}
export function nextPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page, pageSize, data } = state.myshift[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: page + 1 > lastPage ? lastPage : page + 1,
      })
    );
  };
}
export function firstPage(pageName) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: 1,
      })
    );
  };
}
export function gotoPage(pageName, pageNumber) {
  return async (dispatch) => {
    dispatch(
      setPage({
        pageName,
        page: pageNumber,
      })
    );
  };
}
export function previousPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { page } = state.myshift[pageName];
    dispatch(
      setPage({
        pageName,
        page: page - 1 < 1 ? 1 : page - 1,
      })
    );
  };
}
export function lastPage(pageName) {
  return async (dispatch, getState) => {
    const state = getState();
    const { pageSize, data } = state.myshift[pageName];
    const lastPage = Math.ceil(data.totalCount / pageSize);
    dispatch(
      setPage({
        pageName,
        page: lastPage,
      })
    );
  };
}
export function pageSize(pageName, size) {
  return async (dispatch) => {
    dispatch(
      updatePageSize({
        pageName,
        pageSize: size,
      })
    );
  };
}

export function updateSort(pageName, order, column) {
  return async (dispatch) => {
    dispatch(
      setSorting({
        pageName,
        sortOrder: order,
        sortColumn: column,
      })
    );
  };
}

export function claim(
  url,
  candidateId,
  jobId,
  claimedstatus,
  pageName,
  occurranceDate,
  starttime,
  endtime
) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(`${url}/Candidates/claim`, "POST", {
        candidateUniqueId: candidateId,
        jobuniqueid: jobId,
        claimedstatus: claimedstatus,
        breakType: 1,
        occurranceDate: occurranceDate,
        starttime: starttime,
        endtime: endtime,
      });
      dispatch(setLoading(false));
      dispatch(setClaimUnclaimSuccessMessageMyShift(response.message));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setClaimUnclaimErrorMessageMyShift(error.message));
    }
  };
}
export function cancelclaim(
  url,
  jobsShiftUniqueId,
  unClaimReasonId,
  otherUnClaimReason
) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(
        `${url}/CandidatesJobs/unclaim`,
        "POST",
        {
          jobsShiftUniqueId: jobsShiftUniqueId,
          unClaimReasonId: unClaimReasonId,
          otherUnClaimReason: otherUnClaimReason,
        }
      );
      dispatch(setClaimUnclaimSuccessMessageMyShift(response.message));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setClaimUnclaimErrorMessageMyShift(error.message));
      dispatch(setLoading(false));
    }
  };
}

export const sendPersonal = ({ url, params }) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(`${url}`, "POST", { ...params });
      dispatch(setMsgSendFlag(true));
    } catch (error) {
      dispatch(setMsgSendFlag(false));
    }
    dispatch(setLoading(false));
  };
};

export const getPersonalMsg = ({ url, params }) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(`${url}`, "POST", { ...params });
      dispatch(setChat(response.appMessages));
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const updateMsgFlag = (flag) => {
  return async (dispatch) => {
    dispatch(setMsgSendFlag(flag));
  };
};

export const updateClaimandUnclaimMsgMyShift = (data) => {
  return async (dispatch) => {
    dispatch(setClaimUnclaimMsgResetMyShift(data));
  };
};

export const getUnClaimCandidateReasons = (url) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(
        `${url}/Employers/get-unclaim-candidate-reasons`,
        "GET"
      );
      dispatch(setLoading(false));
      dispatch(setUnclaimEmployerReasons(response));
    } catch (error) {
      console.log(error.message);
    }
  };
};
