export const CancelClaimHistoryTableHeaders = [
  {
    name: "Location",
    field: "location",
    type: "text",
    sort: true,
    filter: true,
    width: 190,
    minWidth: 190,
    maxWidth: 250,
  },
  {
    name: "Shift date",
    field: "shiftDateToDisplay",
    type: "text",
    sort: true,
    minWidth: 140,
  },
  {
    name: "Shift End Date",
    field: "jobEndDateToDisplay",
    type: "text",
    sort: true,
    minWidth: 170,
  },
  {
    name: "Shift",
    field: "multiShift",
    type: "icon",
    filter: false,
    minWidth: 130,
    hide: false,
  },
  {
    name: "Start time",
    field: "startTime",
    type: "text",
    sort: true,
    minWidth: 130,
  },
  {
    name: "End time",
    field: "endTime",
    type: "text",
    sort: true,
    minWidth: 190,
  },
  {
    name: "Cancel date",
    type: "text",
    field: "cancelledDateToDisplay",
    sort: true,
    minWidth: 190,
  },
  {
    name: "Ratings",
    field: "ratingChange",
    type: "RatingHistory_ratings",
    sort: false,
    minWidth: 190,
  },
  {
    name: "Penalty",
    type: "text",
    field: "penalty",
    sort: false,
  },
  {
    name: "Resolve On",
    field: "resolveDateToDisplay",
    type: "text",
    sort: true,
    minWidth: '140',
  },
  // {
  //   name: "View",
  //   field: "viewJobDetails",
  //   type: "icon",
  //   class: "fa fa-file",
  // },
];
