import TextField from "../../common/TextField/withLabel";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { personalInfoSelector } from "./selector";
import { getCityState, updatePersonalInfo, updateLanguageList } from "./action";
import useConfiguration from "../../../hooks/useConfiguration";
import DropDown from "../../common/TextField/dropDown";
import { profileBuilderSelector } from "../selector";
import DateField from '../../common/TextField/dateTime';
import moment from "moment";
import * as Toasts from '../../common/Toastify/index';
import { ToastContainer } from "react-toastify";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  // Hooks
  const dispatch = useDispatch();
  const config = useConfiguration();
  const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));

  // Error Message Showing
  const { errors } = props;

  // get the details from Personal Info Selector
  const { data, masterData } = useSelector(personalInfoSelector);
  // Profile flags
  const { profile_builder_flags } = useSelector(profileBuilderSelector);
  // data initialized
  const [form, setForm] = React.useState(data);

  // Update the Form value
  useEffect(() => {
    dispatch(updatePersonalInfo(form));
  }, [form]);

  // Handle Change for Input Text Box
  const updateFormValue = (target) => {
    setForm({
      ...data,
      [target.name]: target.value,
    });
    if (target.name === "zipcode" && target.value.length >= 5) {
      zipCodeUpdated(target.value);
    }
  };

  // Handle Change for Input Radio Box
  const updateCheckedValue = (event) => {
    if (event.target.id === "yes") {
      setForm({
        ...data,
        [event.target.name]: event.target.checked,
      });
    } else if (event.target.id === "no") {
      setForm({
        ...data,
        [event.target.name]: !event.target.checked,
      });
    }
  };

  // Handle Change for MultiDropdown Box
  const updateLangValue = (target) => {
    const value = target.value;
    const action = target.action || "add";
    dispatch(updateLanguageList(value, action));
  };

  // ZipCode Updated
  const zipCodeUpdated = (value) => {
    dispatch(
      getCityState(`${config.baseUrl}/Zipcodes/getzipcodeinfo/${value}`)
    );
  };

  


  const updateGenderValue = (target) => {
    setForm({
      ...data,
      [target.name]: [target.value],
    });
  }


  // const updateDateOfBirth = (target) => {
  //   console.log(new Date(target.value));
  //   if (new Date() > new Date(target.value)) {
  //     setForm({
  //       ...data,
  //       [target.name]: target.value,
  //     });
  //   } else {
  //     setForm({
  //       ...data,
  //       [target.name]: data?.dateOfBirth ? data?.dateOfBirth : null,
  //     });
  //     Toasts.warning('Please enter a valid date of birth. The date of birth cannot be in the future date.')
  //   }
  // }
  const updateDateOfBirth = (target) => {
  const inputValue = target.value;
  const dateValue = new Date(inputValue);

  if (!inputValue) {
    setForm({
      ...data,
      [target.name]: null,
    });
    console.log('Date cleared, set to null');
  } else if (new Date() > dateValue) {
    setForm({
      ...data,
      [target.name]: inputValue,
    });
    console.log(dateValue);
  } else {
    setForm({
      ...data,
      [target.name]: data?.dateOfBirth ? data?.dateOfBirth : null,
    });
    Toasts.warning('Please enter a valid date of birth. The date of birth cannot be in the future.');
  }
}



  return (
    <div className="card-body">
      {   mobileAuth &&
         <ToastContainer style={{ "zIndex": 999999}} />
      }
      <div className="row">
        {/* First Name */}
        <TextField
          name={"firstName"}
          label={"First Name*"}
          placeholder={"First Name"}
          updateValue={updateFormValue}
          value={data.firstName}
          errors={errors["firstName"]}
        />
        {/* Last Name */}
        <TextField
          name={"lastName"}
          label={"Last Name*"}
          placeholder={"Last Name"}
          updateValue={updateFormValue}
          value={data.lastName}
          errors={errors["lastName"]}
        />
      </div>
      <div className="row">
        {/* email */}
        <TextField
          name={"email"}
          label={"Email*"}
          placeholder={"Email"}
          updateValue={updateFormValue}
          value={data.email}
          errors={errors["email"]}
        />
        {/* Street Address */}
        <TextField
          label={"Street Address*"}
          name={"streetAddress"}
          placeholder={"Street Address"}
          updateValue={updateFormValue}
          value={data.streetAddress}
          errors={errors["streetAddress"]}
        />
      </div>
      <div className="row">
        {/* Zip Code */}
        <TextField
          label={"Zip Code*"}
          name={"zipcode"}
          placeholder={"Zip Code"}
          updateValue={updateFormValue}
          value={data.zipcode}
          errors={errors["zipcode"]}
        />
        {/* City */}
        <TextField
          label={"City*"}
          name={"city"}
          placeholder={"City"}
          updateValue={updateFormValue}
          value={data.city}
          errors={errors["city"]}
        />
      </div>
      <div className="row">
        {/* State */}
        <TextField
          label={"State*"}
          name={"stateName"}
          placeholder={"State"}
          updateValue={updateFormValue}
          value={data.stateName}
          errors={errors["stateName"]}
        />
       <DropDown
        name={"gender"} 
        label={'Gender'}
        placeholder={"gender"} 
        updateValue={updateGenderValue} 
        value={data.gender ?? [{id: null, name: 'Select'}]}
        className={'displayBlock gender_hide_close'}
        options={
          [{id: null, name: 'Select'},...masterData?.genderOptions]
        } 
        showArrow={true} 
        isObject={true} 
        multiple={false}
      />
      </div>
      <div className="row" style={{gap: mobileAuth ? '10px' : ''}}>
                <DateField    
                    name={'dateOfBirth'}
                    label={'Date Of Birth'}
                    id={'dateOfBirth'}
                    value={data?.dateOfBirth || ''}
                    updateValue={updateDateOfBirth}
                    includeTime={false}
                    // isMandatory={'yes'}
                  />
       {/* Cell Number */}
       <TextField
          label={"Cell Number*"}
          name={"cellPhoneNumber"}
          placeholder={"Cell Number"}
          updateValue={updateFormValue}
          value={data.cellPhoneNumber}
          errors={errors["cellPhoneNumber"]}
          format={"phone"}
        />
      </div>
      <div className="row">
        {/* Home Phone Number */}
        <TextField
          label={"Landline Phone Number"}
          name={"homePhoneNumber"}
          placeholder={"Landline Phone Number"}
          updateValue={updateFormValue}
          value={data.homePhoneNumber}
          errors={errors["homePhoneNumber"]}
          format={"phone"}
        />
        {/* Alternative Phone Number */}
        <TextField
          label={"Alternate Phone Number"}
          name={"alternatePhoneNumber"}
          format={"phone"}
          placeholder={"Alternate Phone Number"}
          updateValue={updateFormValue}
          value={data.alternatePhoneNumber}
          errors={errors["alternatePhoneNumber"]}
        />
      </div>
      <div className={"row"}>
         {/* Spoken Languages */}
          <label>Spoken Languages {profile_builder_flags?.spoken_Languages?.isRequired ? `*` : ''}</label>
          
            <DropDown
              name={"spokenLanguagesList"}
              label={profile_builder_flags?.spoken_Languages?.isRequired ? "Select all that apply*" : 'Select all that apply'}
              labelClassName="fw-bold"
              placeholder={"Spoken Languages"}
              updateValue={updateLangValue}
              value={data.spokenLanguagesList || []}
              options={masterData.languages}
              showArrow={true}
              isObject={true}
              multiple={true}
              errors={errors["spokenLanguagesList"]}
            />
            <div className="col-md-6 ">
              <div className="">
                <label >Willing to travel or relocate?*</label>
                <div className="d-flex gap-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="relocate"
                      id="yes"
                      value={true}
                      checked={data?.relocate === true ? true : false}
                      onChange={(e) => updateCheckedValue(e)}
                    />
                    <label class="form-check-label" for="yes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="relocate"
                      id="no"
                      value={false}
                      checked={data?.relocate === false ? true : false}
                      onChange={(e) => updateCheckedValue(e)}
                    />
                    <label class="form-check-label" for="no">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {errors["relocate"] &&
                errors["relocate"].map((error) => (
                  <div className="invalid-input">{error}</div>
                ))}
            </div>
         
      </div>
    </div>
  );
};
