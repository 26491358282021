import Loader from "../common/Loader";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { employerStatusSelector } from "./selector";
import { useNavigate } from "react-router-dom";
import { loadEmployerStatus } from "./action";
import StatusBoard from "./statusBoard";
import DataGrid from '../common/DataGrid';
import { OverlayTrigger, Popover } from 'react-bootstrap';
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();
    const config = useConfiguration();
    const storage = useSessionStorage();
    const navigate = useNavigate();
    const { data, masterStatus } = useSelector(employerStatusSelector);
    const login = storage.getItem('login');
    useEffect(() => {
        document.title = "Employer Status";
        if (config.baseUrl) {
            if (!login) {
                navigate("/");
            } else {
                dispatch(loadEmployerStatus(config.baseUrl, login.candidateId));
            }
        }
    }, [config]);

    const generateRowData = (data, masterStatus, headers) => {
        const rowData = {};
        headers.forEach((head, index) => {
            const DataValuePair = { locationName: data.locationName, employerName: data.employerName };
            if (index === 0) {

                rowData[head.name] = {
                    type: 'EmploerStatus_Data',
                    value: DataValuePair
                }
                //data.locationName;//data.employerName;               
            }
            else {

                if (head.name !== 'Favorite' && head.name !== 'Allowed Work Hrs/Week' && !head.name === 'Departments') {
                    rowData[head.name] = {

                        // type: 'icon',
                        // //class: 'fa fa-check lightgreen',
                        // class: 'fa fa-star lightgreen',
                        // title: head.name


                        type: 'icon',
                        class: 'fa fa-close indianred',
                        title: head.name
                    }

                }
                if (head.name === 'Allowed Work Hrs/Week') {
                    rowData[head.name] = {
                        type: 'OvertimeAllow',
                        value: data?.overtimeAllowed,
                    }
                }

                if (head.name === 'Departments') {

                    const popover = (
                        <Popover id="popover-basic">
                            <Popover.Body>
                                {data?.candidateDepartment?.length ? data?.candidateDepartment.map(item => <li>{item}</li>) : ''}
                            </Popover.Body>
                        </Popover>
                    );

                    // rowData[head.name] = <ul>
                    //     {data?.candidateDepartment.map(item => <li>{item}</li>)}
                    // </ul>
                    rowData[head.name] = (
                        <>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={popover}
                            >
                                <span variant="success">
                                    {data?.candidateDepartment?.length ? data?.candidateDepartment?.slice(0, 3).map(item => <li>{item}</li>) : ''}
                                </span>
                            </OverlayTrigger>
                        </>
                    );
                    // data?.candidateDepartment?.length ? data?.candidateDepartment.map(item => item).join(',') : ''
                }

            }
        });
        const statusHistory = data.statusHistory;
        if (statusHistory !== null) {
            statusHistory.forEach(history => {
                if (history !== null) {
                    const headerName = masterStatus.find(mstr => mstr.id === history).name;

                    if (headerName == 'Mentor') {
                        rowData[headerName] = {
                            type: 'icon',
                            // class: 'fa fa-star lightgreen',
                            title: headerName,
                            value: data?.isMentor
                        }
                    } else {

                    if (headerName == 'Favorite') {
                        rowData[headerName] = {
                            type: 'icon',
                            class: 'fa fa-star lightgreen',
                            title: headerName
                        }
                    }
                    else {
                        rowData[headerName] = {
                            type: 'icon',
                            class: 'fa fa-check lightgreen',
                            title: headerName
                        }
                    }
                }
                }
            });
        }
        return rowData;
    }
    const formatTableData = (dataList, masterStatus) => {

        const headers = masterStatus.length > 0 ? [
            {
                name: 'Location Name', type: 'EmploerStatus_Data', width: 270,
                minWidth: 170,
                maxWidth: 470,
            },
            {
                name: 'Departments', type: 'text', width: 270,
                minWidth: 170,
                maxWidth: 470,
            },
            ...masterStatus?.map(mstr => {
                // if (mstr?.id === 8) {
                //     return {
                //         name: "Allowed Work Hrs/Week", type: 'OvertimeAllow', width: 270,
                //         minWidth: 170,
                //         maxWidth: 470,
                //     }
                // } 
                if (mstr?.id === 7) {
                    return {
                        name: "Authorization Hold", type: 'icon', width: 270,
                        minWidth: 170,
                        maxWidth: 470,
                    }
                } else {
                    return {
                        name: `${mstr.name === 'Overtime Allowance' ? 'Allowed Work Hrs/Week' : mstr.name}`, 
                        type: `${mstr.name === 'Overtime Allowance' ? 'OvertimeAllow' : 'icon'}`, 
                        width: 210,
                        minWidth: `${mstr.name === 'Overtime Allowance' ? "200" : ''}`,
                        maxWidth: 410,
                    }
                }
            })] : [];
        const tableData = {
            headers: headers,
            rows: dataList && dataList.map(data => ({
                columns: generateRowData(data, masterStatus, headers)
            }
            )),
            options: {
                filter: false
            }
        };
        return tableData;
    }
    return (
        <div className="page">
            <Loader />
            <Header />
            <Sidebar />
            <div className="page-main">
                <div className="main-content app-content mt-0 main-background">
                    <div className="side-app">
                        <div className="main-container container-fluid">
                            <div className="page-header">
                                <h1 className="page-title">Location Status</h1>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-md-12 col-lg-12">
                                    <StatusBoard {...data} />
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className={'col-md-12 col-lg-12 col-xl-12 outer-div '}>
                                                    <DataGrid headerSpace={true} {...formatTableData(data.statusForEmployerList, masterStatus)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
