import { createSlice } from "@reduxjs/toolkit";
const nursetabOrder = ['personalInfo', 'resumeBuilder', 'preference', 'summary', 'success'];
const securityTabOrder = ['personalInfo', 'resumeBuilder', 'preference', 'qa', 'summary', 'success'];

const initialState = {
    loadingFlag: 0,
    activeTab: nursetabOrder[0],
    error: false,
    errorMessage: null,
    profile_builder_flags: {},
    login_roles_flags: {},
};
export const profileBuilderSlice = createSlice({
    name: 'profileBuilder',
    initialState,
    reducers: {
        gotoNextTab: (state, action) => {
            const tabOrder = action.payload ? securityTabOrder : nursetabOrder
            state.activeTab = tabOrder[tabOrder.indexOf(state.activeTab) + 1];
        },
        gotoPreviousTab: (state, action) => {
            const tabOrder = action.payload ? securityTabOrder : nursetabOrder
            state.activeTab = tabOrder[tabOrder.indexOf(state.activeTab) - 1];
        },
        setError: (state, action) => {
            state.error = true;
            state.errorMessage = action.payload;
        },
        resetError: (state) => {
            state.error = false;
            state.errorMessage = null;
        },
        setCurrentTab: (state, action) => {
            state.activeTab = action.payload;
        },
        set_profile_builder_flags: (state, {payload}) => {
            state.profile_builder_flags = payload
        },
        set_login_roles_flags : (state, {payload}) => {
            state.login_roles_flags = payload
        },
        setLoadingFlagIncrement: (state) => {
            state.loadingFlag = state.loadingFlag + 1;
        },
        setLoadingFlagDecrement: (state) => {
            if (state.loadingFlag !== 0) {
              state.loadingFlag = state.loadingFlag - 1;
            }
        },
    },
});
export const { gotoNextTab, gotoPreviousTab, setError, resetError, setCurrentTab, set_profile_builder_flags, set_login_roles_flags, setLoadingFlagIncrement, setLoadingFlagDecrement } = profileBuilderSlice.actions;
export default profileBuilderSlice.reducer;
