import { configureStore } from "@reduxjs/toolkit";
import bankDetailReducer from './components/BankDetails/slice';
import cancelclaimReducer from './components/CancelClaimHistory/slice';
import loaderReducer from "./components/common/Loader/slice";
import dashboardReducer from "./components/Dashboard/slice";
import employerStatusReducer from './components/EmployerStatus/slice';
import jobDetailsReducer from "./components/JobDetails/slice";
import loginReducer from './components/Login/slice';
import messagesReucer from './components/Messages/slice';
import messagesheaderReucer from './components/MessagesHeader/slice';
import multishiftjobinfoReducer from "./components/MultiShiftJobInfo/slice";
import encounterjobinfoReducer from "./components/EncounterJobInfo/slice";
import myshiftReducer from './components/MyShift/slice';
import personalInfoReducer from "./components/ProfileBuilder/PersonalInfo/slice";
import preferenceReducer from "./components/ProfileBuilder/Preference/slice";
import qandaReducer from './components/ProfileBuilder/QandA/slice';
import resumeBuilderReducer from "./components/ProfileBuilder/ResumeBuilder/slice";
import profileBuilderReducer from "./components/ProfileBuilder/slice";
import ratingsReducer from "./components/RatingHistory/slice";
import shiftsReducer from './components/Shifts/slice';
import signUpReducer from "./components/Signup/slice";
import unpaidReducer from './components/UnPaid/slice';
import headerInfoReducer from './components/common/Header/slice';
import appInsightMiddleware from "./app/AppInsightMiddleware";

export const store = configureStore({
    reducer: {
        login: loginReducer,
        dashboard: dashboardReducer,
        loader: loaderReducer,
        signUp: signUpReducer,
        headerInfo: headerInfoReducer,
        personalInfo: personalInfoReducer,
        profileBuilder: profileBuilderReducer,
        resumeBuilder: resumeBuilderReducer,
        preference: preferenceReducer,
        bankDetail: bankDetailReducer,
        employerStatus: employerStatusReducer,
        shifts: shiftsReducer,
        jobDetails: jobDetailsReducer,
        cancelclaim: cancelclaimReducer,
        ratings: ratingsReducer,
        unpaid: unpaidReducer,
        myshift: myshiftReducer,
        messages: messagesReucer,
        messagesheader: messagesheaderReucer,
        qandaInfo: qandaReducer,
        multishiftjobinfo: multishiftjobinfoReducer,
        encounterjobinfo: encounterjobinfoReducer
    },
    middleware:getDefaultMiddleware => getDefaultMiddleware().concat(appInsightMiddleware)
});
