import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import configJson from '../conf/application.json';

const environment = process.env.NODE_ENV || 'development';
const browserHistory = createBrowserHistory({basename:''});
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config:{
        instrumentationKey:configJson?.[environment]?.InstrumentationKey ?? '',
        extensions:[reactPlugin],
        extensionConfig:{
            [reactPlugin.identifier]:{history:browserHistory}
        }
    }
})

appInsights.loadAppInsights();

export {reactPlugin, appInsights};