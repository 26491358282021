import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    loadingFlag: 0,
    multishiftjobinfo: {
        page: 0,
        data: {},
        totalPages: 0,
        pageSize: 0,
        sortColumn: [],
        occurrenceList:[],
        headerMsgData: {},
        sortOrder: 'Acs'
    },
    selectedJob: '',
    shiftsLoadingError: null,
    claimUnclaimErrorMessageMultiShiftJobInfo: null,
    claimUnclaimSuccessMessageMultiShiftJobInfo: null,
    chat: [],
    msgSendFlag: false,
}
const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}
export const multishiftjobinfoSlice = createSlice({
    name: 'multishiftjobinfo',
    initialState,
    reducers: {  
        updateMultishiftjobinfo: (state, action) => {
            const data = action.payload.data;
            state[action.payload.pageName].data = data;
            state[action.payload.pageName].page = Number(action.payload.page);
            state.claimUnclaimSuccessMessageMultiShiftJobInfo = null;
            state.claimUnclaimErrorMessageMultiShiftJobInfo = null;
            state[action.payload.pageName].occurrenceList =  data?.occurrenceListInfo;
            state[action.payload.pageName].headerMsgData = data?.shiftsInfo
        },
        shiftLoadingFailed: (state, action) => {
            state.shiftsLoadingError = action.payload;
        },
        updatePageSize: (state, action) => {
            state[action.payload.pageName].pageSize = Number(action.payload.pageSize);
            state[action.payload.pageName].page = 1;
        },
        setPage: (state, action) => {
            state[action.payload.pageName].page = Number(action.payload.page);
        },
        setSorting: (state, action) => {
            
            const currentSortColumns = state[action.payload.pageName].sortColumn;
            state[action.payload.pageName].sortOrder = action.payload.sortOrder;
            state[action.payload.pageName].sortColumn = action.payload.sortColumn;//[...currentSortColumns, action.payload.sortColumn].filter(onlyUnique);
        },
        setMsgSendFlag: (state, action) => {
            state.msgSendFlag = action.payload
        },
        setChat: (state, action) => {
            state.chat = action.payload
        },
        setClaimUnclaimMsgResetMultiShiftJobInfo: (state, action) => {
            state.claimUnclaimSuccessMessageMultiShiftJobInfo = action.payload;
            state.claimUnclaimErrorMessageMultiShiftJobInfo = action.payload;
        },
        setClaimUnclaimSuccessMessageMultiShiftJobInfo: (state, action) => {
            state.claimUnclaimSuccessMessageMultiShiftJobInfo = action.payload;
        },
        setClaimUnclaimErrorMessageMultiShiftJobInfo: (state, action) => {
            state.claimUnclaimErrorMessageMultiShiftJobInfo = action.payload;
        },
        updateOccurrenceData:(state,action) => {
            if(action.payload.jobOpenshifts.length > 0){
                state.occurrenceList = action.payload?.jobOpenshifts[0]?.scheduleOccurrenceDateWithInfo
            }
            // state.occurrenceList = action.payload?.jobOpenshifts?.map(item => item[0])
        },
        reset: () => initialState,
        setLoadingFlagIncrement: (state) => {
            state.loadingFlag = state.loadingFlag + 1;
        },
        setLoadingFlagDecrement: (state) => {
            if (state.loadingFlag !== 0) {
              state.loadingFlag = state.loadingFlag - 1;
            }
        },
    }
});

export const {
    shiftLoadingFailed,
    updatePageSize,
    updateMultishiftjobinfo,
    setPage,
    setSorting,setChat, setMsgSendFlag,
    setClaimUnclaimMsgResetMultiShiftJobInfo,
    setClaimUnclaimSuccessMessageMultiShiftJobInfo,
    setClaimUnclaimErrorMessageMultiShiftJobInfo,
    updateOccurrenceData,
    reset,
    setLoadingFlagIncrement,
    setLoadingFlagDecrement
} = multishiftjobinfoSlice.actions;

export default multishiftjobinfoSlice.reducer;
