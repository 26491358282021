import { paginationCalculator } from "pagination-calculator";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { ChatWindow } from "../common/chatWindow";
import DataGrid from "../common/DataGrid";
import Header from "../common/Header";
import Loader from "../common/Loader";
import Sidebar from "../common/Sidebar";
import JobDetails from "../JobDetails/jobDetails";
import { updateSelectedJobDetails } from "../Shifts/action";
import { shiftsSelector } from "../Shifts/selector";
import { headersData } from "./MultishiftTableHeader";
import {
  firstPage,
  getPersonalMsg,
  gotoPage,
  lastPage,
  loadShifts,
  nextPage,
  pageSize,
  previousPage,
  sendPersonal,
  updateMsgFlag,
  updateSort,
} from "./action";
import { unpaidSelector } from "./selector";
import { EncounterTableHeaders } from "./EncounterTableHeader";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const config = useConfiguration();
  const storage = useSessionStorage();
  const navigate = useNavigate();
  const { unpaid, chat, msgSendFlag } = useSelector(unpaidSelector);
  const { selectedJob } = useSelector(shiftsSelector);
  const login = storage.getItem("login");
  const [LocationUniqueId] = useState("");
  const [FromDateTime] = useState("string");
  const [ToDateTime] = useState("string");
  const [SearchText] = useState("string");
  const [paymentStatus] = useState("1");

  const [jobId, setJobId] = useState("");
  const [msgParams, setmsgParams] = useState({
    webPageId: 0,
    shiftUniqueId: "",
    candidateUniqueId: "",
    employerUniqueId: "",
  });
  const [openMessaging, setOpenMessaging] = useState(false);
  const [chatData, setChatData] = useState([]);

  const Createoverlays = (arrayData) => {
    // const renderTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>

    //     </Tooltip>
    // );
    const statusColorMap = {
      1: "green",
      2: "red",
      0: "black",
    };
    const popover = (
      <Popover id="popover-basic">
        <Popover.Body>
          {arrayData.map((item) => (
            <div style={{ color: statusColorMap[item.isExists] }}>
              {item.name}
            </div>
          ))}
        </Popover.Body>
      </Popover>
    );

    return (
      <>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={popover}
        >
          <span variant="success">
            {arrayData.slice(0, 3).map((item) => (
              <div
                className="criteria"
                style={{ color: statusColorMap[item.isExists] }}
              >
                {item.name}
              </div>
            ))}
          </span>
        </OverlayTrigger>
      </>
    );
  };
  // const Listfunction= (data) =>
  // {
  //     // return data.map(item => {
  //     //     return <span style={{color: item.isApproved === true ? 'green' : 'red'}}>color: item.isApproved === true ? 'Y' : 'N'</span>
  //     // });

  // }
  const headers = [
    {
      name: "Location",
      field: "locationName",
      type: "text",
      sort: true,
      filter: true,
      width: 190,
      minWidth: 190,
      maxWidth: 250,
    },
    {
      name: "Department",
      field: "candidateDepartmentName",
      type: "text",
      sort: true,
      filter: true,
      width: 190,
      minWidth: 190,
      maxWidth: 250,
    },
    {
      name: "Shift Date",
      field: "shiftDate",
      type: "text",
      sort: true,
      width: 270,
      minWidth: 170,
      maxWidth: 470,
    },
    {
      name: "Shift End Date",
      field: "jobEndDateToDisplay",
      type: "text",
      sort: true,
      minWidth: 170,
    },
    {
      name: "Shift",
      field: "multiShift",
      type: "icon",
      filter: false,
      minWidth: 130,
      hide: false,
    },
    {
      name: "Start time",
      field: "shiftStartTime",
      type: "text",
      sort: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "End time",
      field: "shiftEndTime",
      type: "text",
      sort: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Checkin",
      field: "isCheckedIn",
      type: "Unpaidis_checkedin",
    },
    {
      name: "Checkout",
      field: "isCheckedOut",
      type: "Unpaidis_checkedout",
    },
    {
      // name: 'Approver',
      // field: "approversListDetails",
      // id: 'approverName',
      // Cell: ({ row }) => {
      //     return row.original.approversList?.length > 0 ? Createoverlays(row.original.approversList) : ''
      // }
      name: "Approver",
      field: "approversList",
      type: "color",
    },
    {
      name: "Paid",
      field: "isPaid",
      type: "Unpaidis_checkedout",
    },
    {
      name: "Rate Per Hour",
      field: "ratePerHourToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Total Shift Amount",
      field: "totalShiftAmountToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Work Hours",
      field: "totalWorkedHours",
      type: "text",
    },
    {
      name: "Amount",
      field: "paymentAmountToDisplayUnpaid",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Incentive",
      field: "incentiveToDisplayUnpaid",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "checked In Time",
      field: "checkedInToDisplay",
      type: "text",
      minWidth:200,
      maxWidth: 400,
    },
    {
      name: "checked Out Time",
      field: "checkedOutToDisplay",
      type: "text",
      minWidth:200,
      maxWidth: 400,
    },
    // {
    //   name: "View",
    //   field: "viewJobDetails",
    //   type: "icon",
    //   class: "fa fa-file",
    // },
    // {
    //   name: "Message",
    //   field: "messagepopup",
    //   type: "icon",
    //   class: "fa fa-commenting-o",
    // },
  ];

  const sendMsg = (text) => {
    const newparams = {
      ...msgParams,
      fromCandidate: true,
      messageText: text,
    };
    dispatch(
      sendPersonal({
        url: `${config.baseUrl}/Messages/SendMessageToEmployer`,
        params: newparams,
      })
    );
  };

  useEffect(() => {
    document.title = "Unpaid";
    if (config.baseUrl) {
      if (!login) {
        navigate("/");
      } else {
        dispatch(
          loadShifts(
            config.baseUrl,
            login.candidateUniqueId,
            LocationUniqueId,
            FromDateTime,
            ToDateTime,
            SearchText,
            paymentStatus,
            "unpaid"
          )
        );
      }
    }
  }, [config]);

  useEffect(() => {
    const newData = JSON.parse(JSON.stringify(chat));
    buildGroupMsg(newData);
  }, [chat]);

  const buildGroupMsg = (data) => {
    const groups = data.reduce((groups, messages) => {
      const date = messages.messageDateTime.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(messages);
      return groups;
    }, {});

    const groupMsg = Object.keys(groups).map((date) => {
      return {
        date,
        messages: groups[date],
      };
    });
    setChatData(groupMsg);
  };

  const clearJobDetailsView = () => {
    dispatch(updateSelectedJobDetails(""));
  };

  // Multishift occurrence Page Info Function
  const MultiShiftJobInfo = (params) => {
    navigate("/multishiftjobinfo", {
      state: {
        params: params,
        unpaid: false,
        headersData: headersData,
        subUrl: "CandidatesJobs/payment-history-occurrences",
      },
    });
  };

  // Encounter Occuerence Page Info Function
  const EncounterJobInfo = (params) => {
    navigate("/encounterjobinfo", {
      state: {
        params: params,
        subUrl: "CandidatesJobs/get-paymentHistory-encounter-occurrences",
        EncounterTableHeaders: EncounterTableHeaders,
      },
    });
  };

  const generateRowData = (data, headers) => {
    const rowData = {};
    headers.forEach((header) => {
      if (header.type === "text") {
        rowData[header.name] = data[header.field];
      } else {
        if (header.field === "isCheckedIn") {
          rowData[header.name] = {
            type: "Unpaidis_checkedin",
            value: data[header.field],
          };
        } else if (header.field === "isCheckedOut") {
          // const isCheckedOut = data[header.field];
          // const iconClass = isCheckedOut ? 'fa fa-check lightgreen' : 'fa fa-close indianred'
          // rowData[header.name] = {
          //     type: 'icon',
          //     class: `fa fa-check ${iconClass}`
          // }
          rowData[header.name] = {
            type: "Unpaidis_checkedout",
            value: data[header.field],
          };
        } else if (header.field === "approversList") {
          rowData[header.name] = {
            type: "color",
            value: data[header.field],
          };
        } else if (header.field === "viewJobDetails") {
          rowData[header.name] = {
            type: "icon",
            class: `icon-align ${header.class}`,
            onClick: () => {
              dispatch(updateSelectedJobDetails(data["jobUniqueId"]));
            },
          };
        } else if (header.field === "isPaid") {
          rowData[header.name] = {
            type: "Unpaidis_Paid",
            value: data[header.field],
          };
          // const isPaid = data[header.field];
          // const iconClass = isPaid ? 'fa fa-check lightgreen' : 'fa fa-close indianred'
          // rowData[header.name] = {
          //     type: 'icon',
          //     class: `fa fa-check ${iconClass}`
          // }
        } else if (header.field === "messagepopup") {
          rowData[header.name] = {
            type: "icon",
            class: `icon-align ${header.class}`,
            onClick: () => {
              openChantWindow(data.jobsShiftUniqueId);
            },
          };
        } else {
          rowData[header.name] = {
            type: "icon",
            class: `icon-align ${header.class}`,
          };
        }
      }
      if (header.field === "shiftDate") {
        const shiftType = data?.shiftType;
        if (shiftType === 3) {
          if (data?.shiftType === 3) {
            rowData[header.name] = (
              <span className="text-wrap" style={{ width: "6rem" }}>
                {data?.recurrenceDetail}
              </span>
            );
          } else {
            rowData[header.name] = data?.shiftDate;
          }
        } else {
          rowData[header.name] = data?.shiftDate;
        }
      }
      if (header.field === "multiShift") {
        const shiftType = data?.shiftType;
        if (shiftType === 3) {
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-binoculars icon-align`,
            onClick: () => {
              const params = {
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              };
              MultiShiftJobInfo(params);
            },
          };
        } else if (shiftType === 2) {
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-hourglass icon-align`,
            onClick: () => {
              const params = {
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              };
              EncounterJobInfo(params);
            },
          };
        } else {
          rowData[header.name] = {
            type: "icon",
            class: ``,
            onClick: () => {},
          };
        }
      }
    });
    return rowData;
  };

  const openChantWindow = (jobsShiftUniqueId) => {
    if (jobsShiftUniqueId) {
      setmsgParams({
        webPageId: 0,
        shiftUniqueId: jobsShiftUniqueId,
        candidateUniqueId: login.candidateUniqueId,
        employerUniqueId: null,
      });
      const newparams = {
        webPageId: 0,
        shiftUniqueId: jobsShiftUniqueId,
        candidateUniqueId: login.candidateUniqueId,
        employerUniqueId: null,
      };
      dispatch(
        getPersonalMsg({
          url: `${config.baseUrl}/Messages/GetCandidateMessages`,
          params: newparams,
        })
      );
      setOpenMessaging(!openMessaging);
    }
  };

  useEffect(() => {
    if (msgSendFlag && config.baseUrl) {
      dispatch(
        getPersonalMsg({
          url: `${config.baseUrl}/Messages/GetCandidateMessages`,
          params: msgParams,
        })
      ).then(() => {
        dispatch(updateMsgFlag(false));
      });
    }
  }, [msgSendFlag]);

  const createPageData = (page, pageSize, totalCount) => {
    if (page === 1) {
      return {
        from: page,
        to: pageSize,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    } else {
      const pageData = paginationCalculator({
        total: totalCount,
        current: page,
        pageSize: pageSize,
      });
      return {
        from: pageData.showingStart,
        to: pageData.showingEnd,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    }
  };
  const gotoFirstPage = () => {
    dispatch(firstPage("unpaid")).then(() => {
      dispatch(
        loadShifts(
          config.baseUrl,
          login.candidateUniqueId,
          LocationUniqueId,
          FromDateTime,
          ToDateTime,
          SearchText,
          paymentStatus,
          "unpaid"
        )
      );
    });
  };
  const gotoNextPage = () => {
    dispatch(nextPage("unpaid")).then(() => {
      dispatch(
        loadShifts(
          config.baseUrl,
          login.candidateUniqueId,
          LocationUniqueId,
          FromDateTime,
          ToDateTime,
          SearchText,
          paymentStatus,
          "unpaid"
        )
      );
    });
  };
  const gotoPreviousPage = () => {
    dispatch(previousPage("unpaid")).then(() => {
      dispatch(
        loadShifts(
          config.baseUrl,
          login.candidateUniqueId,
          LocationUniqueId,
          FromDateTime,
          ToDateTime,
          SearchText,
          paymentStatus,
          "unpaid"
        )
      );
    });
  };
  const gotoLastPage = () => {
    dispatch(lastPage("unpaid")).then(() => {
      dispatch(
        loadShifts(
          config.baseUrl,
          login.candidateUniqueId,
          LocationUniqueId,
          FromDateTime,
          ToDateTime,
          SearchText,
          paymentStatus,
          "unpaid"
        )
      );
    });
  };
  const gotoSelectedPage = (page) => {
    dispatch(gotoPage("unpaid", page)).then(() => {
      dispatch(
        loadShifts(
          config.baseUrl,
          login.candidateUniqueId,
          LocationUniqueId,
          FromDateTime,
          ToDateTime,
          SearchText,
          paymentStatus,
          "unpaid"
        )
      );
    });
  };
  const setPageSize = (size) => {
    dispatch(pageSize("unpaid", size)).then(() => {
      dispatch(
        loadShifts(
          config.baseUrl,
          login.candidateUniqueId,
          LocationUniqueId,
          FromDateTime,
          ToDateTime,
          SearchText,
          paymentStatus,
          "unpaid"
        )
      );
    });
  };

  const formatTableData = (dataList) => {
    const tableData = {
      headers,
      rows: dataList?.data?.candidateJobShifts?.map((data) => ({
        columns: generateRowData(data, headers),
      })),
      options: {
        sortable: false,
        filter: false,
        pagination: true,
        pageSize: dataList?.pageSize || 10,
      },
      pageData: createPageData(
        dataList?.page,
        dataList?.pageSize,
        dataList?.data?.totalPages
      ),
      firstPage: gotoFirstPage,
      nextPage: gotoNextPage,
      previousPage: gotoPreviousPage,
      lastPage: gotoLastPage,
      updatePageSize: setPageSize,
      gotoPage: gotoSelectedPage,
      headerComponentParams: {
        sort: (order, column) => {
          dispatch(updateSort("unpaid", order, column)).then(() => {
            dispatch(
              loadShifts(
                config.baseUrl,
                login.candidateUniqueId,
                LocationUniqueId,
                FromDateTime,
                ToDateTime,
                SearchText,
                paymentStatus,
                "unpaid"
              )
            );
          });
        },
        headers,
      },
    };
    return tableData;
  };
  return (
    <div className="page">
      <Loader />
      <Header />
      <Sidebar />
      {selectedJob !== "" ? (
        <JobDetails
          jobId={selectedJob}
          returnToGrid={clearJobDetailsView}
          isReadOnly={true}
        />
      ) : (
        <div className="page-main">
          <div className="main-content app-content mt-0 main-background">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <h1 className="page-title">My Payments</h1>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div
                            className={
                              "col-md-12 col-lg-12 col-xl-12 outer-div"
                            }
                          >
                            <DataGrid
                              {...formatTableData(unpaid)}
                              headerSpace={true}
                            />
                          </div>
                          {
                            <div className="message-overlay">
                              {openMessaging && (
                                <ChatWindow
                                  closeChatWindow={() => {
                                    setOpenMessaging(!openMessaging);
                                  }}
                                  chatData={chatData}
                                  sendMsg={sendMsg}
                                />
                              )}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
