import {setLoading} from "../common/Loader/slice";
import {setPage, shiftLoadingFailed, updateShifts, updatePageSize, setSorting,setMsgSendFlag,setChat} from "./slice";
import requestUtil from "../../helpers/requestUtil";

const createLoadRequest = (page, pageSize, candidateId,LocationUniqueId,FromDateTime,ToDateTime,SearchText, paymentStatus, sortOrder, sortColumn) => {
    const request = {
        "page": page,
        "pageSize": pageSize,
        "candidateUniqueId": `${candidateId}`,
        // "LocationUniqueId": `${LocationUniqueId}`,
        // "FromDateTime": "string",
        // "ToDateTime": "string",      
        // "SearchText": `${SearchText}`,
        // "paymentStatus": 1,
    }
    if(sortColumn && sortColumn.length > 0){
        request.sortOrder = sortOrder;
        request.sortColumn = sortColumn;
    }
    return request;
}


export function loadShifts(url, candidateId,LocationUniqueId,FromDateTime,ToDateTime,SearchText, paymentStatus, pageName) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const state = getState();
            const { page, pageSize, sortOrder, sortColumn } = state.unpaid.unpaid;
            const request = createLoadRequest(page, pageSize, candidateId,LocationUniqueId,FromDateTime,ToDateTime,SearchText, paymentStatus, sortOrder, sortColumn);
            const candidateJobShifts = await requestUtil(`${url}/CandidatesJobs/payment-history`, 'POST', request);
            dispatch(updateShifts({
                data: candidateJobShifts,
                page: page,
                pageName
            }));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(shiftLoadingFailed(e.message));
            dispatch(setLoading(false));
        }
    }
}
export function nextPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { page, pageSize, data } = state.unpaid[pageName];
        const lastPage = Math.ceil(data.totalCount/pageSize)
        dispatch(setPage({
            pageName,
            page: (page + 1) > lastPage ? lastPage : (page + 1)
        }));
    }
}
export function firstPage(pageName) {
    return async (dispatch) => {
        dispatch(setPage({
            pageName,
            page: 1
        }));
    }
}
export function gotoPage(pageName, pageNumber) {
    return async (dispatch) => {
        dispatch(setPage({
            pageName,
            page: pageNumber
        }));
    }
}
export function previousPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { page } = state.shifts[pageName];
        dispatch(setPage({
            pageName,
            page: (page - 1) < 1 ? 1 : (page-1)
        }));
    }
}
export function lastPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { pageSize, data } = state.unpaid[pageName];
        const lastPage = Math.ceil(data.totalCount/pageSize)
        dispatch(setPage({
            pageName,
            page: lastPage
        }));
    }
}
export function pageSize(pageName, size){
    return async (dispatch) => {
        dispatch(updatePageSize({
            pageName,
            pageSize: size
        }));
    }
}

export function updateSort(pageName, order, column) {
    return async (dispatch) => {
        dispatch(setSorting({
            pageName,
            sortOrder: order,
            sortColumn: column
        }));
    }
}


export const sendPersonal = ({ url, params }) => {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setMsgSendFlag(true));
        } catch (error) {
            dispatch(setMsgSendFlag(false));
        }
        dispatch(setLoading(false));
    }
}


export const getPersonalMsg = ({ url, params }) => {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setChat(response.appMessages));
        } catch (error) {

        }
        dispatch(setLoading(false));
    }
}

export const updateMsgFlag = (flag) => {
    return async dispatch => {
        dispatch(setMsgSendFlag(flag));
    }
}
