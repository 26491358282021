import React, { useRef } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const dateTimeRef = useRef(null);
    const onChange = (event) => {
        const value = event.target.value;
        props.updateValue({ name: props.name, value: value });
    }
    return (
        <div className={`${props.divClass || 'col-lg-6 col-md-12'}`}>
            <label htmlFor={props.name}>{props.label}</label>
            <div className="input-group">
                <div className="input-group-text">
                    <label htmlFor={props.name}></label>
                    <i className="fa fa-calculator tx-16 lh-0 op-6" style={{color: '#fff'}} onClick={(event) => {
                        event.preventDefault();
                        dateTimeRef?.current?.showPicker();
                    }}></i>
                </div>
                <input className="form-control" id={`${props.id || 'datepicker-date'}`} placeholder={props.placeholder}
                    type={props.includeTime ? "datetime-local" : "date"}
                    name={props.name} value={props.value} ref={dateTimeRef} onChange={onChange} />
            </div>
        </div>
    )
}
