import { createSlice } from "@reduxjs/toolkit";

const initialData = {
    candidatesAccountId: 0,
    candidateId: '',
    bankAccountNumber: '',
    routingNumber: '',
    accountType: [],
    accountTypeOther: '',
    bankName: '',
    bankAddress: '',
    accountHolderName: '',
    appUserId: '',    
    paymentMode:'',
    socialSecurityNumber:''
}

const paymentInitialData = {
    candidatesAccountId: 0,
    candidateId: '',
    paymentMode: '',
    //onlineAccountType: [],
    username: '',
    accountEmailId: '',
    accountPhoneNumber: '',
    onlineAccountHolderName: '',
    onlineAccountType:''
}

const initialState = {
    data: initialData,
    masterData: {
        accountTypes: [],
    },
    paymentModedetails: {
        PaymentMode: [],
    },
    paymentData:paymentInitialData,
    masterOnlineAccountTypeData: {
        onlineAccountTypes: [],
    },
    error: false,
    errorMessage: null,
    result: false,
    resultMessage: null
};

export const bankDetailSlice = createSlice({
    name: 'bankDetail',
    initialState,
    reducers: {
        setBankDetail: (state, action) => {
            state.data = action.payload;
            if (state.masterData.accountTypes.length > 0) {
                if (typeof state.data.accountType !== 'object') {
                    state.data.accountType = state.masterData.accountTypes.filter(item => item.id === state.data.accountType)
                }
            }
        },
        setError: (state, action) => {
            state.error = true;
            state.errorMessage = action.payload;
        },
        resetError: (state) => {
            state.error = false;
            state.errorMessage = null;
        },
        setMasterData: (state, action) => {
            if (action.payload.accountTypes) {
                state.masterData.accountTypes = action.payload.accountTypes;
            }
        },
        setPaymentMode: (state, action) => {
            if (action.payload.PaymentMode) {
                state.paymentModedetails.PaymentMode = action.payload.PaymentMode;
            }            
        },
        setResult: (state, action) => {
            state.result = true;
            state.resultMessage = action.payload;
        },
        resetResult: (state) => {
            state.result = false;
            state.resultMessage = null;
        },
        setOnlinePaymentDetail: (state, action) => {
            state.paymentData = action.payload;
            // if (state.masterData.accountTypes.length > 0) {
            //     if (typeof state.data.accountType !== 'object') {
            //         state.data.accountType = state.masterData.accountTypes.filter(item => item.id === state.data.accountType)
            //     }
            // }
        },
        setMasterOnlineAccountTypeData: (state, action) => {
            console.log(action.payload.onlineAccountTypes);
            if (action.payload.onlineAccountTypes) {
                state.masterOnlineAccountTypeData.onlineAccountTypes = action.payload.onlineAccountTypes;                
            }
        },
        setOnlinePaymentError: (state, action) => {
            state.onlinePaymenterror = true;
            state.onlinePaymenterrorMessage = action.payload;
        },
        resetOnlinePaymentError: (state) => {
            state.onlinePaymenterror = false;
            state.onlinePaymenterrorMessage = null;
        },
        setOnlinePaymentResult: (state, action) => {
            state.onlinePaymentresult = true;
            state.onlinePaymentresultMessage = action.payload;
        },
        resetOnlinePaymentResult: (state) => {
            state.onlinePaymentresult = false;
            state.onlinePaymentresultMessage = null;
        },
        updateBankForm:(state,{payload:{name,value}}) => {
            state[name] = value;
        }
    }
});

export const { setBankDetail, setError, resetError, setMasterData,setPaymentMode, setResult, resetResult,setOnlinePaymentDetail,setMasterOnlineAccountTypeData,setOnlinePaymentError,resetOnlinePaymentError,setOnlinePaymentResult,resetOnlinePaymentResult,updateBankForm} = bankDetailSlice.actions;
export default bankDetailSlice.reducer;