import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const UserNotificationModal = ({
  htmlData,
  headerText,
  id,
  handleUserNotificationClose,
  handleUserNotificationSave,
  files,
  downloadFile
}) => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => {
    setShowModal(!showModal);
    handleUserNotificationClose(id);
  };

  function createMarkup() {
    return { __html: htmlData };
  }

  return (
    <>
      <div id="userLocationModal">
        <Modal
          size="lg"
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>{headerText}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div dangerouslySetInnerHTML={createMarkup()} />
            <div className='mt-2'>
              {
                files?.map(item => (<span>
                  <span
                    style={{ cursor: 'pointer', color: '#20851a' }}
                    onClick={() => downloadFile(item.attachedFileName, item.featuresNotifyAttachmentId)}
                  >
                    <i>{item.attachedFileName}</i>
                    {item.attachedFileName ? (
                      <i
                        className="mdi mdi-arrange-bring-forward"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="mdi-arrange-bring-forward"
                        aria-label="mdi-arrange-bring-forward"
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </span>))
              }

            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => handleUserNotificationSave(id)}
            >
              Ok
            </Button>
            <Button variant="secondary" onClick={handleClose}>
            Remind Me Later
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default UserNotificationModal;
