import {useState} from "react";
import Modal from "./modal";
import EmrehrForm from '../ProfileBuilder/ResumeBuilder/emrehr';
import { useDispatch, useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import useSessionStorage from "../../hooks/useSessionStorage";
import { resumeBuilderSelector } from "../ProfileBuilder/ResumeBuilder/selector";
import useConfiguration from "../../hooks/useConfiguration";
import { validateResume } from "../ProfileBuilder/ResumeBuilder/customResumeValidation";
import { saveResumeBuilder } from "../ProfileBuilder/ResumeBuilder/action";

export default (props) => {
    const { login_roles_flags, profile_builder_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const resumeForm = useSelector(resumeBuilderSelector);
    const config = useConfiguration();
    const dispatch = useDispatch();
    const login = storage.getItem('login');
    const [resumeBuilderError, setResumeBuilderError] = useState({});
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const editEmrEhr = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('resumeBuilder');
        }
    };
    const saveEmrEhr = (event) => {
        event.preventDefault();
        // if (props.saveContinue('resumeBuilder', false)) {
        //     setShowModal(!showModal);
        // }

        let valid = true;
        const resumeFormValid = validateResume(resumeForm, login.jobTypeInfo, profile_builder_flags);
        if (resumeFormValid.isValid) {
        dispatch(
            saveResumeBuilder({
              url: `${config.baseUrl}`,
              candidateId: login.candidateId,
              userId: login.appUserId,
              gotoNextTab: false,
              flag: login?.jobTypeInfo?.showQATab,
            })
          );
        setShowModal(!showModal);
        setResumeBuilderError({});
        } else {
            setResumeBuilderError(resumeFormValid.errors);
            valid = false;
            return;
        }



    };
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal &&
                    <Modal showHide={editEmrEhr} saveFunction={saveEmrEhr} label={"EMR/EHR Systems"}>
                        <EmrehrForm errors={resumeBuilderError}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            <h4 className="text-wrap mr-2">EMR/EHR Systems</h4>
                            {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                            <a id="EditEHREMR" href="#" onClick={editEmrEhr}>
                                <i className="fe fe-edit" title="Edit EMR/EHR Systems"></i>
                            </a> : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        {
                            props.primary.map(prim => (
                                <div className="col-lg-12 col-md-12">
                                    <label htmlFor="exampleInputname1">{prim.name}</label>
                                </div>
                            ))
                        }
                    </div>
                    <hr className="Line" style={{ display: "none" }}/>
                    <br/>
                    <div className="row" style={{ display: "none" }}>
                        <div className="col-lg-4 col-md-4">
                            <h3>Other EMR/EHR Systems </h3>
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <a id="EditCertification" href="#" onClick={editEmrEhr}>
                                <i className="fe fe-edit" title="Edit Certifications, Licenses, Permits Form"></i>
                            </a>
                        </div>
                    </div>
                    <div className="form-group" style={{ display: "none" }}>
                        {
                            props.others.map(other => (
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="exampleInputname1">{other}</label>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
