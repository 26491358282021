import requestUtil from '../../../helpers/requestUtil';
import { setLoading } from "../../common/Loader/slice";
import { updateProfileFlag } from '../../Login/action';
import { nextTab } from "../action";
import { resetError, setError, setLoadingFlagDecrement, setLoadingFlagIncrement } from "../slice";
import { addSpokenLanguagesList, removeSpokenLanguagesList, setCityState, setMasterData, setPersonalInfo } from './slice';

export function updatePersonalInfo(data) {
    return async dispatch => {
        dispatch(setPersonalInfo(data));
    };
}

export function resetErrorStatus() {
    return async dispatch => {
        dispatch(resetError());
    };
}

export function getCurrentPersonalInfo({ url, userId }) {
    return async dispatch => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const response = await requestUtil(`${url}/${userId}`, 'GET');
            dispatch(setPersonalInfo(response));
            // dispatch(setLoading(false));
            return response;
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    };
}

export function savePersonalInfo({ url, candidateId, userId, gotoNextTab = true, flag }) {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        const { data } = getState().personalInfo;
        try {
            await requestUtil(`${url}`, 'POST', {
                appUserId: userId,
                candidateId,
                ...data,
                spokenLanguagesList: data.spokenLanguagesList.map(item => item.id),
                referenceSourceId: data.referenceSourceId[0].id,
                gender: data?.gender?.length ? data?.gender[0]?.id : null,
                emergencyContact: {
                    firstName: data?.emergencyContact?.firstName === null ? '' : data.emergencyContact?.firstName,
                    lastName: data?.emergencyContact?.lastName === null ? '' : data?.emergencyContact?.lastName,
                    phoneNumber: data?.emergencyContact?.phoneNumber === null ? '' : data?.emergencyContact?.phoneNumber,
                    relationship: data?.emergencyContact?.relationship === null ? '' : data.emergencyContact.relationship?.length ? data.emergencyContact.relationship[0].name : '',
                    // ...data.emergencyContact,
                    // relationship: data.emergencyContact.relationship[0].name
                }
            });
            if (gotoNextTab) {
                dispatch(nextTab(flag));
                dispatch(updateProfileFlag('isPersonalInfoTabCompleted'));
            }
        } catch (error) {
            dispatch(setError(error.message));
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    };
}

export function getCityState(url) {
    return async dispatch => {
        try {
            const response = await requestUtil(url, 'GET');
            dispatch(setCityState(response));
        } catch (error) {
            dispatch(setError(error.message))
        }
    };
}

export function loadMasterData(baseUrl) {
    return async dispatch => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const languages = await requestUtil(`${baseUrl}/Dropdown/GetSpokenLanguages`, 'GET');
            const relationships = await requestUtil(`${baseUrl}/Dropdown/GetEmergencyContactRelationships`, 'GET');
            const referencesource = await requestUtil(`${baseUrl}/Dropdown/get-reference-sources/2`, 'GET');
            const genderOptions = await requestUtil(`${baseUrl}/Dropdown/getgenderdetails`, 'GET');
            dispatch(setMasterData({ languages, relationships, referencesource, genderOptions }));
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    };
}

export function updateLanguageList(lang, action) {
    return (dispatch) => {
        switch (action) {
            case 'add':
                dispatch(addSpokenLanguagesList(lang));
                break;
            case 'remove':
                dispatch(removeSpokenLanguagesList(lang));
                break;
            default:
                break;
        }
    };
}


export const saveMapLatAndLng = ({ url, val }) => {
    return async dispatch => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const response = await requestUtil(`${url}`, 'POST', val);
        } catch (error) {

        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    }
}
