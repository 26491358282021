import requestUtil from "../../helpers/requestUtil";
import { setLoading } from "../common/Loader/slice";
import { setChat, setClaimUnclaimErrorMessage, setClaimUnclaimSuccessMessage, setClaimUnclaimMsgReset, setMsgSendFlag, setPage, setSorting, shiftLoadingFailed, updatePageSize, updateSelectedJob, updateShifts } from "./slice";

const createLoadRequest = (page, pageSize, candidateId, sortOrder, sortColumn) => {
    const request = {
        "page": page,
        "pageSize": pageSize,
        "candidateUniqueId": `${candidateId}`,
        "jobUniqueId": "string"
    }
    if (sortColumn && sortColumn.length > 0) {
        request.sortOrder = sortOrder;
        request.sortColumn = sortColumn;
    }
    return request;
}

export function updateSelectedJobDetails(jobId) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            dispatch(updateSelectedJob({
                data: jobId
            }));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(shiftLoadingFailed(e.message));
            dispatch(setLoading(false));
        }
    }

}

export function loadShifts(url, candidateId, pageName) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const state = getState();
            const { page, pageSize, sortOrder, sortColumn } = state.shifts.unclaimed;
            const request = createLoadRequest(page, pageSize, candidateId, sortOrder, sortColumn);
            const unclaimedShifts = await requestUtil(`${url}/CandidatesJobs/get-open-shifts`, 'POST', request);
            dispatch(updateShifts({
                data: unclaimedShifts,
                page: page,
                pageName
            }));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(shiftLoadingFailed(e.message));
            dispatch(setLoading(false));
        }
    }
}
export function nextPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { page, pageSize, data } = state.shifts[pageName];
        const lastPage = Math.ceil(data.totalCount / pageSize)
        dispatch(setPage({
            pageName,
            page: (page + 1) > lastPage ? lastPage : (page + 1)
        }));
    }
}
export function firstPage(pageName) {
    return async (dispatch) => {
        dispatch(setPage({
            pageName,
            page: 1
        }));
    }
}
export function gotoPage(pageName, pageNumber) {
    return async (dispatch) => {
        dispatch(setPage({
            pageName,
            page: pageNumber
        }));
    }
}
export function previousPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { page } = state.shifts[pageName];
        dispatch(setPage({
            pageName,
            page: (page - 1) < 1 ? 1 : (page - 1)
        }));
    }
}
export function lastPage(pageName) {
    return async (dispatch, getState) => {
        const state = getState();
        const { pageSize, data } = state.shifts[pageName];
        const lastPage = Math.ceil(data.totalCount / pageSize)
        dispatch(setPage({
            pageName,
            page: lastPage
        }));
    }
}
export function pageSize(pageName, size) {
    return async (dispatch) => {
        dispatch(updatePageSize({
            pageName,
            pageSize: size
        }));
    }
}

export function updateSort(pageName, order, column) {
    return async (dispatch) => {
        dispatch(setSorting({
            pageName,
            sortOrder: order,
            sortColumn: column
        }));
    }
}

export function claimJob(url, jobShiftUniqueId) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}/CandidatesJobs/claim/${jobShiftUniqueId}`, "POST");
                if(response?.result){
                dispatch(setClaimUnclaimSuccessMessage(response.message));
                } else {
                dispatch(setClaimUnclaimErrorMessage(response.message));
                }
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setClaimUnclaimErrorMessage(error.message));
        }
    }
}
export function unClaimJob(url, candidateId, jobId, claimedstatus, pageName, JobsClaimId, occurranceDate, starttime, endtime, isParent) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}/Candidates/unclaim`, "POST", {
                "candidateUniqueId": candidateId,
                "jobuniqueid": jobId,
                "claimedstatus": claimedstatus,
                "jobsClaimId": JobsClaimId ? JobsClaimId : 0,
                "occurranceDate": occurranceDate,
                "breakType": 1,
                "starttime": starttime,
                "endtime": endtime,
                "isParent": isParent
            });
            dispatch(setClaimUnclaimSuccessMessage(response.message));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setClaimUnclaimErrorMessage(error.message));
        }
    }
}

export const getPersonalMsg = ({ url, params }) => {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setChat(response.appMessages));
        } catch (error) {

        }
        dispatch(setLoading(false));
    }
}

export const updateMsgFlag = (flag) => {
    return async dispatch => {
        dispatch(setMsgSendFlag(flag));
    }
}

export const sendPersonal = ({ url, params }) => {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setMsgSendFlag(true));
        } catch (error) {
            dispatch(setMsgSendFlag(false));
        }
        dispatch(setLoading(false));
    }
}

export const updateClaimandUnclaimMsg = (data) => {
    return async dispatch => {
        dispatch(setClaimUnclaimMsgReset(data));
    }
}
