import CryptoJS from 'crypto-js';

export const encryptionString = (encryptionKey, iv, encryptionValue) => {
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(encryptionValue),
    CryptoJS.enc.Utf8.parse(encryptionKey),
    {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return encrypted.toString();
};

export const decryptionString = (decString, iv, encryptionKey) => {
  const decrypted = CryptoJS.AES.decrypt(decString, CryptoJS.enc.Utf8.parse(encryptionKey),
    {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
  return decrypted.toString(CryptoJS.enc.Utf8);
}
