import React from 'react'

const Spinner = () => {
    return (
        <div id="global-loader">
            <img src="assets/images/loader.svg" className="loader-img" alt="Loader" />
        </div>
    )
}

export default Spinner;
