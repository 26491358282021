import React, { useState } from 'react';
import QandA from '../ProfileBuilder/QandA/qanda';
import './index.css';
import Modal from "./modal";
import { useSelector } from 'react-redux';
import { profileBuilderSelector } from '../ProfileBuilder/selector';
import useSessionStorage from '../../hooks/useSessionStorage';

const QandASummary = (props) => {
    const { login_roles_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);

    const editQandA = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('aq');
        }
    };

    const saveQandA = (event) => {
        event.preventDefault();
        const container = document.querySelector('.modal');
        container.scrollTop = 0;
        if (props.saveContinue('qa', false)) {
            setShowModal(!showModal);
        }
    };

    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal &&
                    <Modal label={"Q&A"} showHide={editQandA} saveFunction={saveQandA}>
                        <QandA qandAErrors={props.errors} />
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                                <h4 className='text-wrap mr-2'>{'Q&A'}</h4>
                                {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                                <a id="EditRegisterForm" href="#" onClick={editQandA}>
                                    <i className="fe fe-edit" title="Edit Registration Form"></i>
                                </a> : ''
                                } 
                        </div>
                        <div className="card-body">
                            Please click edit icon to view and edit Q & A section
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QandASummary;