import { useSelector } from "react-redux";
import { jobDetailsSelector } from "./selector";

export default (props) => {
  const { jobDetails } = useSelector(jobDetailsSelector);

  return (
    <div className="col-md-12 col-xl-12">
      <div className="card">
        <div className="col-lg-12 col-md-12">
          <div className="row">
            <div className="card-header">
              <div className="col-lg-4 col-md-4 jobheader">
                <b>
                  <h4 className="headerfontbold">Location & Shift Info</h4>
                </b>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            {props.jobLocations.map((location) => (
              <>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="d-flex gap-4">
                      <div>
                        <label className="form-label">Job Code :</label>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="Location Name">
                          {location?.jobCode}
                        </label>
                      </div>
                    </div>

                    <div className="d-flex gap-4">
                      <div>
                        <label className="form-label">Job Date :</label>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="Location Name">
                          {location?.jobDateToDisplay}
                        </label>
                      </div>
                    </div>

                    <div className="d-flex gap-3">
                      <div>
                        <label className="form-label">Start Time :</label>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="Location Name">
                          {location?.shiftStartTime}
                        </label>
                      </div>
                    </div>

                    <div className="d-flex gap-4">
                      <div>
                        <label className="form-label">End Time :</label>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="Location Name">
                          {location?.shiftEndTime}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7">
                  <div className="d-flex gap-5">
                      <div>
                        <label className="form-label">Job Title :</label>
                      </div>
                      <div className="mt-2 mx-2">
                        <label htmlFor="Location Name">
                          {jobDetails?.jobInfo?.jobTitle}
                        </label>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div>
                        <label className="form-label">Location Name :</label>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="Location Name">
                          {location?.locationName}
                        </label>
                      </div>
                    </div>
                    <div className="d-flex gap-5">
                      <div>
                        <label className="form-label text-nowrap">
                          Address :{" "}
                        </label>
                      </div>
                      <div className="mt-2 mx-3">
                        <label htmlFor="Address">
                          {location?.streetAddress} , {location.city} ,{" "}
                          {location?.stateName} , {location?.zipcode}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">       

                         <div className="col-lg-2 col-md-2">
                            <label className="form-label">Job Code :</label></div>
                            <div className="col-lg-4 col-md-4 mt-2">
                            <label htmlFor="Location Name">{location?.jobCode}</label>
                        </div></div>

                        <div className="row">                              
                         <div className="col-lg-2 col-md-2">
                            <label className="form-label">Job Date :</label></div>
                            <div className="col-lg-4 col-md-4 mt-2">
                            <label htmlFor="Location Name">{location?.jobDateToDisplay}</label>
                        </div></div>
                        <div className="row">                              
                         <div className="col-lg-2 col-md-2 ">
                            <label className="form-label">Start Time :</label></div>
                            <div className="col-lg-4 col-md-4 mt-2">
                            <label htmlFor="Location Name">{location?.shiftStartTime}</label>
                        </div></div>
                        <div className="row">                              
                         <div className="col-lg-2 col-md-2">
                            <label className="form-label">End Time :</label></div>
                            <div className="col-lg-4 col-md-4 mt-2">
                            <label htmlFor="Location Name">{location?.shiftEndTime}</label>
                        </div></div> */}

                  {/* <div className="row">
                                 <div className="col-lg-2 col-md-2">
                                    <label className="form-label">Location Name :</label> </div>
                                    <div className="col-lg-6 col-md-6 mt-2">
                                    <label htmlFor="Location Name">{location?.locationName}</label></div>
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-2 col-md-2">
                                        <label className="form-label">Address :</label></div>
                                        <div className="col-lg-10 col-md-10 mt-2">
                                        <label htmlFor="Address">{location?.streetAddress} , {location.city} , {location?.stateName} , {location?.zipcode}</label>
                                    </div>   </div> */}
                  {/* <div className="row">
                                    <div className="col-lg-2 col-md-2">
                                        <label className="form-label">City :</label></div>
                                        <div className="col-lg-6 col-md-6">
                                        <label htmlFor="City"></label>
                                    </div></div>
                                    <div className="row">
                                    <div className="col-lg-2 col-md-2">
                                        <label className="form-label">State:</label></div>
                                         <div className="col-lg-6 col-md-6">
                                        <label htmlFor="State">{location?.stateName}</label>
                                    </div></div>
                                    <div className="row">
                                    <div className="col-lg-2 col-md-2">
                                        <label className="form-label">Country:</label></div>
                                        <div className="col-lg-6 col-md-6">
                                        <label htmlFor="Country">{location?.country}</label>
                                    </div></div> */}

                  {/* <div className="col-lg-5">
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <label className="form-label">Location Name :</label>{" "}
                      </div>
                      <div className="col-lg-6 col-md-6 mt-2">
                        <label htmlFor="Location Name">
                          {location?.locationName}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <label className="form-label">Address :</label>
                      </div>
                      <div className="col-lg-10 col-md-10 mt-2">
                        <label htmlFor="Address">
                          {location?.streetAddress} , {location.city} ,{" "}
                          {location?.stateName} , {location?.zipcode}
                        </label>
                      </div>{" "}
                    </div>
                  </div> */}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
