export default (props) => {
    return (
        <div className="page-header">
            <h1 className="page-title">Profile Builder</h1>
            <div>

            </div>
        </div>
    )
}
