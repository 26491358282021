import { appInsights } from "./AppInsight"

const appInsightMiddleware = store => next => action => {
    if(action.type ===  '@@router/LOCATION_CHANGE'){
        appInsights.trackPageView({name:action.payload.location.pathname})
    } else {
        // appInsights.trackEvent({ name: action.type, properties: action.payload });
    }
    return next(action);
};

export default appInsightMiddleware;