import {Modal} from "react-bootstrap";

export default (props) => {
    return (
        <Modal show={true} contentLabel="Example Modal" size={props.size || "xl"} className={props.modalClass || ''}>
            <Modal.Header>
                <Modal.Title>{props.label}</Modal.Title>
                <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.showHide}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={props.showHide}>Close</button>
                <button className="btn btn-primary" onClick={props.saveFunction}>Save changes</button>
            </Modal.Footer>
        </Modal>
    )
}
