import {useState} from "react";
import Modal from "./modal";
import EducationLevelForm from '../ProfileBuilder/ResumeBuilder/educationLevel';
import { useDispatch, useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import useSessionStorage from "../../hooks/useSessionStorage";
import { resumeBuilderSelector } from "../ProfileBuilder/ResumeBuilder/selector";
import useConfiguration from "../../hooks/useConfiguration";
import { validateResume } from "../ProfileBuilder/ResumeBuilder/customResumeValidation";
import { saveResumeBuilder } from "../ProfileBuilder/ResumeBuilder/action";

export default (props) => {
    const { login_roles_flags, profile_builder_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const resumeForm = useSelector(resumeBuilderSelector);
    const config = useConfiguration();
    const dispatch = useDispatch();
    const login = storage.getItem('login');
    const [resumeBuilderError, setResumeBuilderError] = useState({});
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const editEducationLevel = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('resumeBuilder');
        }
    };
    const saveEducationLevel = (event) => {
        event.preventDefault();
        // if (props.saveContinue('resumeBuilder', false)) {
        //     setShowModal(!showModal);
        // }
        let valid = true;
        const resumeFormValid = validateResume(resumeForm, login.jobTypeInfo, profile_builder_flags);
        if (resumeFormValid.isValid) {
        dispatch(
            saveResumeBuilder({
              url: `${config.baseUrl}`,
              candidateId: login.candidateId,
              userId: login.appUserId,
              gotoNextTab: false,
              flag: login?.jobTypeInfo?.showQATab,
            })
          );
        setShowModal(!showModal);
        setResumeBuilderError({});
        } else {
            setResumeBuilderError(resumeFormValid.errors);
            valid = false;
            return;
        }


    };
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal &&
                    <Modal saveFunction={saveEducationLevel} showHide={editEducationLevel} label={"Level Of Education"}>
                        <EducationLevelForm errors={resumeBuilderError}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                                <h4 className="text-wrap mr-2">Highest Level of Education</h4>
                                {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                                <a id="EditLevelEducation" href="#" onClick={editEducationLevel}>
                                    <i className="fe fe-edit" title="Edit Other Education"></i>
                                </a> : ''
                                }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="col-lg-4 col-md-4">
                            <label
                                htmlFor="exampleInputname1">{props?.educationLevel && props?.masterEducationLevel?.find(edu => edu?.id === props?.educationLevel)?.name}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
