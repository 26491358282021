import React, { useState, useEffect } from 'react';
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Loader from "../common/Loader";
import Footer from "../common/Footer";
import BankDetailForm from './BankDetailForm';
import OnlinePaymentForm from './OnlinePaymentForm';
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../hooks/useValidation';
import { bankDetailSelector } from './selector';
import { resetErrorStatus, saveBankDetail, getCurrentUserBankDetail, loadMasterData, loadPaymentmode, resetResultMsg, getCurrentUserOnlinePaymentDetail, saveOnlinePaymentDetail, resetOnlinePaymentResultMsg, loadMasterOnlineAccountTypeData } from './action';
import useSessionStorage from '../../hooks/useSessionStorage';
import useConfiguration from '../../hooks/useConfiguration';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { updateBankForm } from './slice';
import { addSSNFormat } from '../../utils/regEx';
import { domainMappedId, tenantIdList } from '../../helpers/enum';
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();
    const validate = useValidation();
    const { data, result, resultMessage, paymentData, paymentModedetails, onlinePaymentresult, onlinePaymentresultMessage, masterOnlineAccountTypeData } = useSelector(bankDetailSelector)
    const [bankDetailErrors, setBankDetailErrors] = useState({});
    const [PaymentModeshow, setPaymentModeshow] = useState(data?.paymentModeText);
    const [PaymentModeId, setPaymentModeId] = useState(data?.paymentMode);
    const [onlinePaymentDetailErrors, setOnlinePaymentDetailErrors] = useState({});
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    const navigate = useNavigate();
    const [form, setForm] = useState(data);
    const [OnlineAccountTypeId, setOnlineAccountTypeId] = useState('');
    const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
    const domainJobtypeId = sessionStorage.getItem('domainJobtypeId');
    const tenantId = login?.tenantId;
    useEffect(() => {
        document.title = "Bank Detail";
    }, []);
    useEffect(() => {
        if (data?.paymentModeText) {
            setPaymentModeshow(data?.paymentModeText);
            setPaymentModeId(data?.paymentMode);
        }
    }, [data?.paymentModeText, data?.paymentMode]);
    useEffect(() => {
        if (!login || login?.employerOvertureStatus === 0) {
            navigate("/");
        }
        config.baseUrl && dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
            dispatch(loadMasterOnlineAccountTypeData(`${config.baseUrl}`));
            dispatch(getCurrentUserBankDetail({
                url: `${config.baseUrl}/Candidates/getaccountinfo`, userId: login.candidateId
            }));
            dispatch(getCurrentUserOnlinePaymentDetail({
                url: `${config.baseUrl}/Candidates/getonlinepaymentinfo`, userId: login.candidateId
            }));
        })

    }, [config]);
    useEffect(() => {
        config.baseUrl && dispatch(loadPaymentmode(`${config.baseUrl}`)).then(() => {
        });
        // config.baseUrl && dispatch(loadMasterOnlineAccountTypeData(`${config.baseUrl}`)).then(() => {           
        // });

    }, [config]);
    const validateBankDetail = () => {
        let keys = Object.keys(data);
        if (data['accountType'] && data['accountType'].length > 0 && data['accountType'][0].name !== 'Other') {
            keys = keys.filter(item => item !== 'accountTypeOther');
        }
        const bankDetailResult = validate("bankdetail", keys.map(key => {
            return {
                name: key, data: data[key]
            }
        }));
        if (bankDetailResult.valid) {
            setBankDetailErrors({});
        } else {
            setBankDetailErrors(bankDetailResult.errors);
        }
        return bankDetailResult.valid;
    }

    if (result && resultMessage && (resultMessage.message === 'Updated' || resultMessage.message === 'Saved')) {
        dispatch(resetResultMsg());
        if (!mobileAuth) {
            navigate('/dashboard');
        }
    }

    const saveChange = () => {
        window.scrollTo(0, 0);
        let valid = true;
        dispatch(resetErrorStatus())
        if (validateBankDetail()) {
            dispatch(saveBankDetail({
                url: `${config.baseUrl}/Candidates/SaveAccountInfo`,
                candidateId: login.candidateId,
                userId: login.appUserId,
                paymentModeText: PaymentModeshow,
                paymentMode: PaymentModeId ? PaymentModeId : paymentModedetails?.PaymentMode[0]?.id,
                onlineAccountType: '0'
            })).then((response) => {
                setOnlinePyamentMessage(response.message);
                setTimeout(() => {
                    setOnlinePyamentMessage(false)
                }, 2000)
            })

        } else {
            valid = false;
        }
        return valid;
    }
    const updateFormValue = (target) => {
        setForm({
            ...data,
            [target.name]: target.value
        });
    }

    const validateOnlinePaymentDetail = () => {
        const paymentNewData = {...paymentData, "socialSecurityNumber":data?.socialSecurityNumber}
        let keys = Object.keys(paymentNewData);
        // if (data['accountType'] && data['accountType'].length > 0 && data['accountType'][0].name !== 'Other') {
        //     keys = keys.filter(item => item !== 'accountTypeOther');
        // }
        const onlinePaymentDetailResult = validate("onlinepaymentdetail", keys.map(key => {
            return {
                name: key, data: paymentNewData[key]
            }
        }));

        if (onlinePaymentDetailResult.valid) {
            setOnlinePaymentDetailErrors({});
        } else {
            setOnlinePaymentDetailErrors(onlinePaymentDetailResult.errors);
        }

        return onlinePaymentDetailResult.valid;
    }


    if (onlinePaymentresult && onlinePaymentresultMessage && (onlinePaymentresultMessage.message === 'Updated' || onlinePaymentresultMessage.message === 'Saved')) {
        dispatch(resetOnlinePaymentResultMsg());
        if (!mobileAuth) {
            navigate('/dashboard');
        }
    }
    const [onlinePyamentMessage, setOnlinePyamentMessage] = useState('')

    const saveOnlinePaymentChange = () => {
        let valid = true;
        dispatch(resetErrorStatus())
        if (validateOnlinePaymentDetail()) {
            dispatch(saveOnlinePaymentDetail({
                url: `${config.baseUrl}/Candidates/SaveOnlinePaymentInfo`,
                candidateId: login.candidateId,
                userId: login.appUserId,
                paymentModeText: PaymentModeshow,
                paymentMode: PaymentModeId,
                onlineAccountType: OnlineAccountTypeId,
                socialSecurityNumber:data?.socialSecurityNumber ?? ""
            }))
                .then((response) => {
                    setOnlinePyamentMessage(response.message);
                    setTimeout(() => {
                        setOnlinePyamentMessage(false)
                    }, 2000)
                })

        } else {
            valid = false;
        }
        return valid;
    }
    const changevalue = (name, id) => {
        setPaymentModeshow(name);
        setPaymentModeId(id);
        //dispatch(updatePaymentDetail(form));
    }

    const changeOnlineAccountTypeValue = (id) => {
        setOnlineAccountTypeId(id);
    }
    console.log(!mobileAuth, '===========>');
    const ssnChangeHandler = e => {
        const maxElements = 9;
        const {name,value} = e.target;
        const replacedValue = value;
        const updatedValue = replacedValue.replace(/-/g, '');
        if(updatedValue?.length > maxElements){
            return;
        }
        const updatedData = {...data,[name]:value}
        dispatch(updateBankForm({name:"data",value:updatedData}));
    }
    return (
        <div className="page">
            <div className="page-main">
                <Loader />
                {!mobileAuth && <Header />}
                {!mobileAuth && (login.employerOvertureStatus !== 0 ? <Sidebar /> : login?.profileFlag.isProfileSubmitted && <Sidebar />)}

                <div className={mobileAuth ? 'main-content mt-0 main-background' : login.employerOvertureStatus !== 0 ? 'main-content app-content mt-0 main-background' : !login?.profileFlag.isProfileSubmitted ? 'main-content mt-0 main-background' : 'main-content app-content mt-0 main-background'}>
                    <div className="side-app">

                        <div className={mobileAuth ? "main-container container" : login.employerOvertureStatus !== 0 ? 'main-container container-fluid' : !login?.profileFlag.isProfileSubmitted ? 'main-container container' : 'main-container container-fluid'}>

                            {onlinePyamentMessage ?
                                <div className={onlinePyamentMessage ? 'bg-success p-2 rounded-3 mt-2 text-white fw-bold' : ''}>{onlinePyamentMessage}</div> : ''
                            }
                            <div className="page-header">
                                <h1 className="page-title">Bank Details</h1>
                                <div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                {
                                    (+tenantId === tenantIdList.nursesEtc || +tenantId === tenantIdList.fiveStar ) ? null :
                                    <div className="card">
                                    <div className="card-header border-bottom-1">
                                            <label htmlFor='socialSecurityNumber' className="card-title">Social Security Number<span>*</span> </label>
                                    </div>
                                    <div className="card-body">
                                        <input
                                            placeholder="XXX-XX-XXXX"
                                            style={{width:"300px"}}
                                            className={`form-control ${(bankDetailErrors?.["socialSecurityNumber"]?.length> 0) && 'has-errors'}`}
                                            name='socialSecurityNumber'
                                            value={addSSNFormat(data?.socialSecurityNumber) ?? ""}
                                            onChange={ssnChangeHandler}
                                        />
                                        {PaymentModeshow === 'Bank' && bankDetailErrors?.["socialSecurityNumber"]?.length > 0 ? bankDetailErrors?.["socialSecurityNumber"].map(error => (<div className="invalid-input">{error}</div>)):"" }
                                        {PaymentModeshow === 'Online Payment' && onlinePaymentDetailErrors?.["socialSecurityNumber"]?.length > 0 ? onlinePaymentDetailErrors?.["socialSecurityNumber"].map(error => (<div className="invalid-input">{error}</div>)):"" }
                                    </div>
                                </div>
                                }
                                    <div className="card">
                                        <div className="card-header border-bottom-1">
                                            <h3 className="card-title">Payment Method</h3>
                                        </div>

                                        <br></br>
                                        <label className="custom-control">Please choose one*</label>
                                        {<>
                                            <div className='row'>
                                                {
                                                    paymentModedetails?.PaymentMode?.map((item) => (
                                                        <div className="col-md-2">
                                                            <label className="custom-control custom-radio" style={item.name === PaymentModeshow ? { color: "black" } : { color: "grey" }}>
                                                                <input type="radio" name={item.name}
                                                                    checked={item.name === PaymentModeshow}
                                                                    onClick={() => { changevalue(item.name, item.id) }}
                                                                    updateValue={updateFormValue} value={item.name}
                                                                />
                                                                {<span>{item.name}</span>}
                                                            </label>
                                                        </div>
                                                    ))}
                                            </div>
                                        </>}
                                    </div>{PaymentModeshow === 'Bank' &&
                                        <div className="card">
                                            <div className="card-header border-bottom-1">
                                                <h3 className="card-title">Bank Account Details</h3>
                                            </div>

                                            <BankDetailForm errors={bankDetailErrors} paymentMode={PaymentModeshow} />
                                            <div className="card-footer text-end">
                                                <button className="btn btn-primary my-1" onClick={() => { saveChange() }}>Save changes</button>&nbsp;
                                                <button className="btn btn-danger my-1">Cancel</button>
                                            </div>

                                        </div>}
                                    {PaymentModeshow === 'Online Payment' &&
                                        <div className="card">
                                            <div className="card-header border-bottom-1">
                                                <h3 className="card-title">Online Payment </h3>
                                            </div>
                                            <OnlinePaymentForm errors={onlinePaymentDetailErrors} paymentMode={PaymentModeshow} changeOnlineAccountTypeValuefn={changeOnlineAccountTypeValue} />

                                            <div className="card-footer text-end">
                                                <button className="btn btn-primary my-1" onClick={() => { saveOnlinePaymentChange() }}>Save changes</button>&nbsp;
                                                <button className="btn btn-danger my-1">Cancel</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
