import React, { useState, useEffect } from "react";
import { Device } from "twilio-client";
import states from "./states";
import requestUtil from "../../helpers/requestUtil";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { toast } from "react-toastify";

const Phone = () => {
  const config = useConfiguration();
  const [state, setState] = useState(states.CONNECTING);
  const [conn, setConn] = useState(null);
  const [device, setDevice] = useState(null);
  const [token, setToken] = useState(null);
  const storage = useSessionStorage();
  const login = storage.getItem("login");
  const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
  const [callerId, setCallerId] = useState('');
  const [callerIdError, setCallerIdError] = useState(false);

  useEffect(async () => {
    if (config.baseUrl) {
      const data = await requestUtil(
        `${config.baseUrl}/Voice/get-twilio-callerid?jobsShiftUniqueId=${mobileAuth.jobsShiftUniqueId ? mobileAuth.jobsShiftUniqueId : null}&employerUniqueId=${null}`,
        "GET"
      );
      setCallerId(data?.twilioCallerId);
      if (!data?.twilioCallerId) {
        setCallerIdError(true);
        toast.warning('CALL faciality not mapped to this employer.')
      }
    }
  }, [config]);

  useEffect(() => {
    if (token) {
      const device = new Device();
      device.setup(token?.token, {
        logLevel: 1,
        codecPreferences: ["opus", "pcmu"],
      });

      device.on("ready", (device) => {
        setDevice(device);
        setState(states.READY);
      });

      device.on("connect", (connection) => {
        setConn(connection);
        setState(states.ON_CALL);
      });

      device.on("disconnect", () => {
        setState(states.READY);
        setConn(null);
      });

      device.on("incoming", (connection) => {
        setState(states.INCOMING);
        setConn(connection);
        connection.on("reject", () => {
          setState(states.READY);
          setConn(null);
        });
      });

      device.on("cancel", () => {
        setState(states.READY);
        setConn(null);
      });
      device.on("reject", () => {
        setState(states.READY);
        setConn(null);
      });
      return () => {
        device.destroy();
        setDevice(null);
        setState(states.OFFLINE);
      };
    }
  }, [token]);

  useEffect(() => {
    if (config.baseUrl) {
      getToken()
    }
  }, [config]);

  const getToken = async () => {
    const data = await requestUtil(
      `${config.baseUrl}/Voice/get-twilio-token`,
      "GET"
    );
    if (data) {
      setToken(data);
    }
  }

  const handleCall = () => {
    if (mobileAuth?.toNumber) {
      const params = {
        callingDeviceIdentity: token?.identity,
        appUserUniqueId: login.appUserUniqueId,
        candidateUniqueId: login.candidateUniqueId,
        toNumber: mobileAuth?.toNumber,
        isFromAdmin: false,
			  isFromCandidate: true,
			  jobsShiftUniqueId: mobileAuth.jobsShiftUniqueId,
			  jobsClaimId: parseInt(mobileAuth.jobsClaimId),
        twilioCallerId: callerId,
        websiteDomainName: window.location.hostname
      };
      console.log(params);
      device.connect({ ...params });
    }
  };

  // handle hangup
  const handleHangup = () => {
    device.disconnectAll();
  };

  return (
    <div className="d-flex gap-5 justify-content-center flex-column">
      {callerIdError && (
                        <>
                          <div
                            className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-bs-autohide="false"
                          >
                            <div className="">
                              <div className="toast-body">
                                  CALL faciality not mapped to this employer.
                              </div>
                            </div>
                          </div>
                          <div>&nbsp;</div>
                        </>
                      )}
      <div className="d-flex flex-column fw-bold">
        <div>{mobileAuth?.userName}</div>
      </div>
      <div className="d-flex justify-content-around">
        <button
          onClick={handleCall}
          className="border border-0"
          disabled={!callerId}
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "70%",
            backgroundColor: "green",
          }}
        >
          <i
            class="fa fa-phone "
            style={{ color: "white", fontSize: "20px" }}
          ></i>
        </button>

        <button
          onClick={handleHangup}
          className="border border-0"
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "70%",
            backgroundColor: "red",
          }}
        >
          <i
            class="fa fa-phone"
            style={{ color: "white", fontSize: "20px" }}
          ></i>
        </button>
      </div>
    </div>
  );
};

export default Phone;
