import './sidebar.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import BankAlert from "../Alert/Bankalert";
import useSessionStorage from "../../../hooks/useSessionStorage";
// '../../hooks/useSessionStorage'
import { updateSelectedJobDetails } from '../../Shifts/action';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    const [bankalertshow, setbankalertshow] = useState(false);
    const jobidPages = ['/cancelclaimhistory', '/myshift', '/unclaimedshifts', '/ratinghistory'];
    const dispatch = useDispatch();

    const menuItems_1 = [
        {
            title: 'Profile Builder',
            path: '/profile',
            icon: 'side-menu__icon fa fa-user-plus'
        }
        ,
        {
            title: 'Location Status',
            path: '/status',
            icon: 'side-menu__icon fa fa-building-o'
        },
        {
            title: 'Unclaimed Open Shifts',
            path: '/unclaimedshifts',
            icon: 'side-menu__icon fa fa-briefcase'
        },
        {
            title: 'My Shifts',
            path: '/myshift',
            icon: 'side-menu__icon fa fa-bookmark'
        },
        {
            title: 'My Unclaim Shift History',
            path: '/cancelclaimhistory',
            icon: 'side-menu__icon fa fa-user-times'
        },
        {
            title: 'My Ratings',
            path: '/ratinghistory',
            icon: 'side-menu__icon fa fa-star'
        },
        {
            title: 'My Notifications',
            path: '/messages',
            icon: 'side-menu__icon fa fa-comments'
        },
        {
            title: 'My Payments',
            path: '/unpaid',
            icon: 'side-menu__icon fa fa-dollar'
        },


    ]
    const menuItems_2 = [
        {
            title: 'My Account',
            path: '/account',
            icon: 'side-menu__icon fa fa-user'
        },
        {
            title: 'Bank Detail',
            path: '/bankdetail',
            icon: 'side-menu__icon fa fa-bank'
        },
        {
            title: 'Update Password',
            path: '/updatepassword',
            icon: 'side-menu__icon fa fa-eye'
        },


    ]
    const navigate = useNavigate();
    const navigateToPage = (event, page) => {
        event.preventDefault();
        //console.log(page, jobidPages.includes(page))
        if (jobidPages.includes(page)) {
            dispatch(updateSelectedJobDetails(""));
        }
        navigate(`${page}`);
        // hide sibar in mobile view
        // const sidebarGone = "sidebar-gone";
        // const sidebarToggle = "sidenav-toggled";
        // const bodyClassList = document.body.classList;
        // if(bodyClassList.contains(sidebarGone) && bodyClassList.contains(sidebarToggle)){
        //     bodyClassList.remove(sidebarToggle)
        // }
        }
    const handleClosefalse = () => {
        setbankalertshow(false);
    }
    const domainJobtypeId = sessionStorage.getItem("domainJobtypeId");
    const domainLogos = JSON.parse(sessionStorage.getItem('domainLogos'));
    const domainIcons = JSON.parse(sessionStorage.getItem('domainIcons'));
    return (
        <>
            {/* { bankalertshow &&
               
               <BankAlert 
                      showDelete={true} handleDeleteClose={handleClosefalse}/>
         
               } */}
            <div className="sticky sticky-margin">

                <div className="app-sidebar scrollablenav">
                    <div className="side-header">
                        <a className="header-brand1" href="#">
                            <img src="assets/images/brand/logo.png" className="header-brand-img desktop-logo"
                                alt="logo" />
                            <img src="assets/images/brand/logo-1.png" className="header-brand-img toggle-logo"
                                alt="logo" />
                            <img src={domainIcons[domainJobtypeId]} className="header-brand-img light-logo"
                                alt="logo" />
                            <img   src={domainLogos[domainJobtypeId]} className="header-brand-img light-logo1"
                                alt="logo" />
                        </a>
                    </div>
                    <div className="main-sidemenu">
                        <div className="slide-left disabled" id="slide-left">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24"
                                viewBox="0 0 24 24">
                                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                            </svg>
                        </div>
                        <ul className="side-menu side-menu-margin">
                            <li className="sub-category">
                                <h3>Main</h3>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item" data-bs-toggle="slide" href="#"
                                    onClick={(event) => {
                                        navigateToPage(event, '/dashboard');
                                    }}>
                                    <i className="side-menu__icon fe fe-home"></i>
                                    <span className="side-menu__label">Dashboard</span>
                                </a>
                            </li>
                            <li className="sub-category">
                                <h3>Profiles</h3>
                            </li>
                            {
                                menuItems_1.map(item => (
                                    <li className="slide">
                                        <a className="side-menu__item" data-bs-toggle="slide" href="#"
                                            onClick={(event) => navigateToPage(event, item.path)}><i
                                                className={item.icon}></i><span className="side-menu__label">{item.title}</span></a>
                                    </li>
                                ))
                            }
                            <li className="sub-category">
                                <h3>Account</h3>
                            </li>
                            {
                                menuItems_2.map(item => {
                                    if (item.path === "/bankdetail") {
                                        if (!login || login.employerOvertureStatus !== 0) {
                                            return <li className="slide">
                                                <a className="side-menu__item" data-bs-toggle="slide" href="#"
                                                    onClick={(event) => navigateToPage(event, item.path)}><i
                                                        className={item.icon}></i><span className="side-menu__label">{item.title}</span></a>
                                            </li>
                                        }
                                    }
                                    else {
                                        return <li className="slide">
                                            <a className="side-menu__item" data-bs-toggle="slide" href="#"
                                                onClick={(event) => navigateToPage(event, item.path)}><i
                                                    className={item.icon}></i><span className="side-menu__label">{item.title}</span></a>
                                        </li>
                                    }


                                })
                            }
                            <br></br>
                        </ul>
                        <div className="slide-right" id="slide-right">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24"
                                viewBox="0 0 24 24">
                                <path
                                    d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="ps__rail-x rail">
                        <div className="ps__thumb-x railthumb" tabIndex="0"></div>
                    </div>
                    <div className="ps__rail-y railright">
                        <div className="ps__thumb-y railrightthumb" tabIndex="0"></div>
                    </div>
                </div>
            </div>
            <div className="jumps-prevent jump-padding"></div>
            <br></br>
        </>
    )
}
