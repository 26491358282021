import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { signUpSelector } from "../Signup/selector";
import requestUtil from '../../helpers/requestUtil';
import useConfiguration from "../../hooks/useConfiguration";
import { notShowOvertureMsg } from '../../helpers/domainUrl';
import { domainMappedId } from '../../helpers/enum';


const { useNavigate } = require('react-router-dom');
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const navigate = useNavigate();
    const config = useConfiguration();
    const domainJobtypeId = sessionStorage.getItem("domainJobtypeId");
    const domainLogos = JSON.parse(sessionStorage.getItem('domainLogos'));
    const { email } = useSelector(signUpSelector);
    const [emailchange, setemailchange] = useState();
    const notShowOverture = notShowOvertureMsg?.indexOf(+domainJobtypeId) > -1 ? true : false;
    const gotoLogin = (e) => {
        e.preventDefault();
        navigate('/');
    }
    useEffect(() => {
        document.title = "Overture Candidate Login";
    }, []);
    useEffect(() => {
        if (domainJobtypeId === "2") {
            setemailchange('outreach@overturesec.com');
        } else if (domainJobtypeId === "5") {
            setemailchange('outreach@overtureyum.com');
        } else if (+domainJobtypeId === 7) {
            setemailchange('info@5starmedicalstaffing.com');
        } else if (+domainJobtypeId === 9) {
            setemailchange('info@nursesetc.net');
        } else if (+domainJobtypeId === domainMappedId.pureHeart) {
            setemailchange('nate@pureheartstaffing.com');
        } else {
            setemailchange('outreach@overturexl.com');
        }
        config.baseUrl && requestUtil(`${config.baseUrl}/Candidates/sendmail/${email}/${window.location.hostname}`, 'GET').then(() => {

        });
    }, [config]);

    console.log(domainJobtypeId)

    // if(domainJobtypeId === "2")
    // {       
    //     setemailchange('outreach@overturesec.com');
    // }
    // else{
    //    setemailchange('outreach@overturexl.com');
    // }

    console.log(emailchange)
    return (
        <div className="page">
            <div className="">
                <div className="modal d-block pos-static">
                    <div className="container-login100">
                        <div className="wrap-login100 p-6">
                            <form className="login100-form validate-form">
                                <span className="login100-form-title pb-5">
                                    <a href="/">
                                        <img
                                           style={{ height:  '62px' }}
                                            src={`/${domainLogos[domainJobtypeId]}`} className="header-brand-img" alt="" />
                                    </a>
                                </span>
                                <div className="">
                                    <div className="modal-body text-center p-4 pb-5">

                                        {/* <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i> */}
                                        <h4>
                                            <p >
                                                {notShowOverture ? null : 'Overture ' }Confirmation Pending / Action Required <br /> <br />
                                            </p>
                                        </h4>
                                        <h4>
                                            You will now receive a confirmation email to verify your account email.<br />
                                            Please check your inbox and follow the instructions. <br />
                                            If the confirmation email is not in your inbox, please check your junk folder.<br />
                                            If you do not receive your confirmation email, please let us know here:<br />
                                            <a href={`mailto:${emailchange}`}>{emailchange}</a>

                                        </h4>
                                        <p></p>
                                        <a className="btn btn-success pd-x-25" href="#" onClick={gotoLogin}>OK</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
