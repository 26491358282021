import { useState } from "react";

export default (props) => {
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">

                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            <div className="col-lg-4 col-md-4 jobheader">
                                <b><h4 className="headerfontbold">Medical Specialties</h4></b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <>
                        <div className="row">
                            <div className="col-lg-2 col-md-2">
                                <label className="form-label">Medical Specialties :</label> </div>
                                {
                                    props?.speciality?.specialityDetails.map(item => (
                                        <div className="col-lg-4 col-md-4 mt-2"> <label>{item.name}</label> </div>
                                    ))
                                }
                            </div>
{ props?.speciality?.otherSpecialityDetails.length ?
                            <div className="row">
                            <div className="col-lg-2 col-md-2">
                                <label className="form-label">Other Specialty :</label> </div>
                                {
                                    props?.speciality?.otherSpecialityDetails.map(item => (
                                        <div className="col-lg-4 col-md-4 mt-2"> <label>{item.name}</label> </div>
                                    ))
                                }
                            </div>:'' }
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
