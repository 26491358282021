import { employerStatusData, employerStatusDataFailed } from './slice';
import { setLoading } from "../common/Loader/slice";
import requestUtil from "../../helpers/requestUtil";
import './index.css';

export function loadEmployerStatus(url, candidateId) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
          const statusReference = await requestUtil(`${url}/Dropdown/get-candidate-status-for-employer`, 'GET');
          const response = await requestUtil(`${url}/candidates/get-employers-status-tracking-list/${candidateId}`, 'POST', null);
          dispatch(employerStatusData({
              data: response,
              masterStatus: statusReference
          }));
          dispatch(setLoading(false));
        } catch (error) {
            dispatch(employerStatusDataFailed(error.message));
            dispatch(setLoading(false));
        }
    }
}
