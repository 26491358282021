import {useState} from "react";
import Categories from "../ProfileBuilder/ResumeBuilder/categories";
import useSessionStorage from "../../hooks/useSessionStorage";
import Modal from "./modal";
import { useDispatch, useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import { validateResume } from "../ProfileBuilder/ResumeBuilder/customResumeValidation";
import { saveResumeBuilder } from "../ProfileBuilder/ResumeBuilder/action";
import { resumeBuilderSelector } from "../ProfileBuilder/ResumeBuilder/selector";
import useConfiguration from "../../hooks/useConfiguration";


export default (props) => {
    const { login_roles_flags, profile_builder_flags } = useSelector(profileBuilderSelector);
    const [showModal, setShowModal] = useState(false);
    const storage = useSessionStorage();
    const resumeForm = useSelector(resumeBuilderSelector);
    const config = useConfiguration();
    const dispatch = useDispatch();
    const login = storage.getItem('login');
    const [resumeBuilderError, setResumeBuilderError] = useState({});
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const editCategories = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('resumeBuilder');
        }
    };
    const saveCategories = (event) => {
        event.preventDefault();
        // if (props.saveContinue('resumeBuilder', false)) {
        //     setShowModal(!showModal);
        // }


        let valid = true;
        const resumeFormValid = validateResume(resumeForm, login.jobTypeInfo, profile_builder_flags);
        if (resumeFormValid.isValid) {
        dispatch(
            saveResumeBuilder({
              url: `${config.baseUrl}`,
              candidateId: login.candidateId,
              userId: login.appUserId,
              gotoNextTab: false,
              flag: login?.jobTypeInfo?.showQATab,
            })
          );
        setShowModal(!showModal);
        setResumeBuilderError({});
        } else {
            setResumeBuilderError(resumeFormValid.errors);
            valid = false;
            return;
        }



    };

    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal && <Modal label={"Categories"} showHide={editCategories} saveFunction={saveCategories}>
                        <Categories errors={resumeBuilderError}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            {   
                                login.jobTypeInfo.jobPositionLabel && 
                                            <>  
                            <h4 className="text-wrap mr-2"> {login.jobTypeInfo.jobPositionLabel }*</h4>
                            </>
                        }
                            {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                                <a id="EditCategories" href="#" onClick={editCategories}>
                                    <i className="fe fe-edit" title="Edit Categories Form"></i>
                                </a> : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        {
                            props?.categories?.map(cat => (
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="exampleInputname1">{cat.name}</label>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
