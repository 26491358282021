import requestUtil from "../../../helpers/requestUtil";
import { setLoading } from "../../common/Loader/slice";
import { updateProfileFlag } from "../../Login/action";
import { nextTab } from "../action";
import { setError, setLoadingFlagDecrement, setLoadingFlagIncrement } from "../slice";
import {
    addPreferredShifts, loadPreferencedata,
    removePreferredShifts, setHolidayPreference, setMasterDatas, setPreferredWorkDays,
    setTravelDistance,
    setVaccination,
    addPreferredShiftTimeOfTheDay, removePreferredShiftTimeOfTheDay
} from './slice';
import { stateToServer } from "./transformer";

export function updateHolidayPreference(value) {
    return (dispatch) => {
        dispatch(setHolidayPreference(value));
    };
}

export function updatePreferredWorkDays(value) {
    return (dispatch) => {
        dispatch(setPreferredWorkDays(value));
    };
}

export function updateTravelDistance(value) {
    return (dispatch) => {
        dispatch(setTravelDistance(value));
    };
}

export function updateVaccination(value) {
    return (dispatch) => {
        dispatch(setVaccination(value));
    };
}

export function updatePreferredShifts(value, action) {
    return (dispatch) => {
        switch (action) {
            case 'add':
                dispatch(addPreferredShifts(value));
                break;
            case 'remove':
                dispatch(removePreferredShifts(value));
                break;
            default:
                break;
        }
    };
}

export function updatePreferredShiftTimeOfDays(value, action) {
    return (dispatch) => {
        switch (action) {
            case 'add':
                dispatch(addPreferredShiftTimeOfTheDay(value));
                break;
            case 'remove':
                dispatch(removePreferredShiftTimeOfTheDay(value));
                break;
            default:
                break;
        }
    };
}

export function getMasterData(baseUrl) {
    return async (dispatch) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const shifts = await requestUtil(`${baseUrl}/Dropdown/GetPreferredShifts`, 'GET');
            const travelDistance = await requestUtil(`${baseUrl}/Dropdown/GetTravelDistances`, 'GET');
            const preferredWorkDays = await requestUtil(`${baseUrl}/Dropdown/GetPreferredWorkDays`, 'GET');
            const holidayPreference = await requestUtil(`${baseUrl}/Dropdown/GetWillingtoworkholidays`, 'GET');
            const covidVaccine = await requestUtil(`${baseUrl}/Dropdown/GetCovidvaccinated`, 'GET');
            const shiftTimeOfTheDay = await requestUtil(`${baseUrl}/Dropdown/GetPreferredShiftTimeOfTheDaysList`, 'GET');

            let keyIndex = 0;
            // console.log(holidayPreference);
            const formattedHolidays = holidayPreference.reduce((acc, curr, index) => {
                if (index % 5 === 0) {
                    keyIndex++;
                    acc[`row${keyIndex}`] = [curr];
                } else {
                    acc[`row${keyIndex}`].push(curr);
                }

                return acc;
            }, {});

            const formattedWorkdays = preferredWorkDays.reduce((acc, curr, index) => {
                if (index % 3 === 0) {
                    keyIndex++;
                    acc[`row${keyIndex}`] = [curr];
                } else {
                    acc[`row${keyIndex}`].push(curr);
                }
                return acc;
            }, {});

            dispatch(setMasterDatas({
                shifts,
                travelDistance,
                preferredWorkDays: formattedWorkdays,
                preferredWorkDaysRaw: preferredWorkDays,
                holidayPreference: formattedHolidays,
                covidVaccine,
                holidayPreferenceRaw: holidayPreference,
                shiftTimeOfTheDay
            }));
        } catch (error) {
            // dispatch(setLoading(false));
            dispatch(setError(error.message));
        } finally {
            // dispatch(setLoading(false));
            dispatch(setLoadingFlagDecrement())
        }
    };
}

export function savePreference({ url, userId, candidateId, gotoNextTab = true, flag }) {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        const stateData = getState().preference;
        const domainJobtypeId = sessionStorage.getItem("domainJobtypeId");
        const data = stateToServer(stateData, candidateId, userId);
        try {
            await requestUtil(`${url}/Candidates/savepreferenceinfo`, 'POST', data);
            if (gotoNextTab) {
                dispatch(nextTab(flag));
                dispatch(updateProfileFlag('isPreferenceInfoCompleted'));
            }
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    };
}

export function getPreference(baseUrl, userId) {
    return async (dispatch) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const data = await requestUtil(`${baseUrl}/Candidates/getpreferenceinfo/${userId}`, 'GET');
            // dispatch(setLoading(false));
            if (data) {
                dispatch(loadPreferencedata(data));
            }
            return data;
        } catch (error) {
            // dispatch(setLoading(false));
            dispatch(setError(error.message));
        } finally {
            dispatch(setLoadingFlagDecrement())
            // dispatch(setLoading(false));   
        }
    };
}
