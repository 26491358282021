import { useState } from "react";
import { useEffect } from "react";
import requestUtil from "../../helpers/requestUtil";
import useConfiguration from "../../hooks/useConfiguration";
import { setLoading } from "../common/Loader/slice";
import { signUpError } from "./slice";
import { useDispatch } from "react-redux";

const SignupQa = ({ setIsAllowedSignup, setAllowedSignupModal }) => {
  const [qaState, setQaState] = useState([]);
  const [isAllAnswered, setIsAllAnswered] = useState(false);
  const dispatch = useDispatch();
  const config = useConfiguration();
  const getQasingupData = async ({ url }) => {
    dispatch(setLoading(true));
    try {
      const result = await requestUtil(url);
      return result;
    } catch (error) {
      dispatch(signUpError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (config.baseUrl) {
      getQasingupData({
        url: `${config.baseUrl}/Dropdown/get-QualifyingQuestions`,
      }).then((data) => {
        if (data) {
          const result = data?.map((qa) => ({ ...qa, status: "" }));
          setQaState(result);
        }
      });
    }
  }, [config.baseUrl]);

  const qaSignupStatusHandler = ({ qaSignup, radioName }) => {
    setQaState((prev) =>
      prev.map((qa) =>
        qa.id === qaSignup.id
          ? { ...qa, status: radioName === "YES" ? true : false }
          : qa
      )
    );
  };
  const submitQAHandler = () => {
    const allAnswered = qaState && qaState.every((qa) => typeof qa.status === "boolean");
    if (allAnswered) {
      const allowSignIn = qaState && qaState.every((qa) => qa.status === true);
      if (allowSignIn) {
        setIsAllowedSignup(true);
      } else {
        setAllowedSignupModal(true);
      }
    } else {
      setIsAllAnswered(true);
    }
  };

  return (
    <>
      {isAllAnswered && (
        <>
          <div
            className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="false"
          >
            <div className="d-flex">
              <div className="toast-body">
                Please answer the qualifying questions
              </div>
            </div>
          </div>
          <div>&nbsp;</div>
        </>
      )}
      {qaState &&
        qaState.map((qa) => {
          return (
            <div key={qa.id} className="py-2">
              <label>{qa.name}</label>
              <div className="d-flex gap-2 align-items-center justify-content-center">
                <div
                  onClick={() =>
                    qaSignupStatusHandler({ qaSignup: qa, radioName: "YES" })
                  }
                  class="form-check"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name={`${qa.id}_yes`}
                    id={`${qa.id}_yes`}
                    checked={qa.status === true && true}
                  />
                  <label
                    htmlFor={`${qa.id}_yes`}
                    class="form-check-label"
                    for="yes"
                  >
                    Yes
                  </label>
                </div>
                <div
                  onClick={() =>
                    qaSignupStatusHandler({ qaSignup: qa, radioName: "NO" })
                  }
                  class="form-check"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name={`${qa.id}_no`}
                    id={`${qa.id}_no`}
                    checked={qa.status === false && true}
                  />
                  <label
                    htmlFor={`${qa.id}_no`}
                    class="form-check-label"
                    for="no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      {qaState.length > 0 && (
        <div className="d-flex justify-content-center">
          <span onClick={submitQAHandler} className="btn btn-primary ">
            Submit
          </span>
        </div>
      )}
    </>
  );
};

export default SignupQa;
