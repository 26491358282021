import { toast } from "react-toastify";
import requestUtil from "../../../helpers/requestUtil";
import { setLoading } from "../../common/Loader/slice";
import { updateProfileFlag } from '../../Login/action';
import { nextTab } from "../action";
import { setError, setLoadingFlagDecrement, setLoadingFlagIncrement } from "../slice";
import { setDownloadUrl, setformBaseDetail, setFormData, setQaDataList } from './slice';


export const getDynamicFormData = ({ url, jobLevel }) => {
    return async dispatch => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const response = await requestUtil(`${url}/CandidatesJobs/get-qa-tab-dynamic-data`, 'POST', { jobLevel });
            const res = JSON.parse(JSON.stringify(response));
            delete res.sections;

            if (response?.sections) {
                dispatch(setFormData(response?.sections));
                dispatch(setformBaseDetail(res));
            }
            if (response?.downloadUrl) {
                dispatch(setDownloadUrl(response?.downloadUrl))
            }
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    };
}

export const updateQADetail = (data) => {
    return async dispatch => {
        dispatch(setFormData(data));
    };
}

export const saveQandADetail = ({ url, candidateUniqueId, gotoNextTab = true, flag }) => {
    // console.log('save call');
    return async (dispatch, getState) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        const { formBaseDetail, formdata } = getState().qandaInfo;
        const newData = { ...formBaseDetail, sections: formdata, candidateUniqueId: candidateUniqueId }
        //console.log(newData)
        try {
            const response = await requestUtil(`${url}/Candidates/save-qa-tab-data`, 'POST', { ...newData });
            //console.log(response)
            if (gotoNextTab) {
                dispatch(nextTab(flag));
                dispatch(updateProfileFlag('isQACompleted'));
            }

        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    }
}

export function uploadFile(url, candidateUniqueId, file, type, sectionId, fieldId, fileId) {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const { formdata } = getState().qandaInfo;
            const newVal = JSON.parse(JSON.stringify(formdata));
            const filedata = new FormData();
            filedata.append('file', file);

            const response = await requestUtil(`${url}/Candidates/FormAttachmentsUploadWithTracking?CandidateUniqueId=${candidateUniqueId}`, 'POST', filedata, null, true);
            if (response?.blobFileName) {
                newVal.forEach(item => {
                    if (item.sectionId === sectionId) {
                        item.fields.forEach(val => {
                            if (val.id === fieldId) {
                                val.noOfAttachments.forEach(files => {
                                    if (files.id === fileId) {
                                        files.name = file.name;
                                        files.blobName = response?.blobFileName
                                    }
                                })
                            }
                        })
                    }
                });
                dispatch(updateQADetail(newVal))
            }
            toast.success("Uploaded Successfully")
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    };
}

export const deleteFile = (url,candidateUniqueId, sectionId, fieldId, fileId) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const { formdata } = getState().qandaInfo;
            const newVal = JSON.parse(JSON.stringify(formdata));
            const response = await requestUtil(`${url}/Candidates/delete-qa-tab-formattachment`, 'POST', { candidateProfileFormValueAttachmentId: fileId,candidateUniqueId });
            // console.log(response);
            if (response) {
                newVal.forEach(item => {
                    if (item.sectionId === sectionId) {
                        item.fields.forEach(val => {
                            if (val.id === fieldId) {
                                val.noOfAttachments.forEach(files => {
                                    if (files.id === fileId) {
                                        files.name = '';
                                        files.blobName = ''
                                        files.expirationDate = null;
                                        files.id = "-1"
                                    }
                                })
                            }
                        })
                    }
                });
                dispatch(updateQADetail(newVal))
                dispatch(setQaDataList(newVal))
                toast.success("Uploaded file has been removed")
            }
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    }
}


export const updateExpirationDate = (expirationDate,sectionId,fieldId,attachmentId) => {
    return async(dispatch,getState) => {
        const { formdata } = getState().qandaInfo;
        const newVal = JSON.parse(JSON.stringify(formdata));
        if (expirationDate) {
            newVal.forEach(item => {
                if (item.sectionId === sectionId) {
                    item.fields.forEach(val => {
                        if (val.id === fieldId) {
                            val.noOfAttachments.forEach(files => {
                                if (files.id === attachmentId) {
                                    files.expirationDate = expirationDate;
                                }
                            })
                        }
                    })
                }
            });
            dispatch(updateQADetail(newVal))
        }
    }
};

export const downloadUploadedDocument = (url,candidateUniqueId, sectionId, fieldId, fileId,blobName,fileName,accessToken) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true));
        console.log({fileId,blobName,fileName});
        dispatch(setLoadingFlagIncrement())
        const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
        try {
            const requestHeaders = {
                'Content-Type': 'application/json',
                'Authorization': accessToken ? `Bearer ${accessToken}` : '',
                'Website-Domain-Name': window.location.hostname === 'localhost' ? 'nursejobdev.overturexl.com' : window.location.hostname,
            };
            const params = {
                "blobName": blobName ?? "",
                "attachedFileName": fileName ?? "",
                "blobNameAttachmentId": parseInt(fileId),
                "isNotSavedFile": parseInt(fileId) >= 0 ? true : false
            }
            if(mobileAuth){
                const response = await fetch(`${url}/Candidates/get-QA-file-download-mobilebrowser`, {
                    headers:{...requestHeaders},
                    method:"POST",
                    body:JSON.stringify(params)
                })
                const mobileURL = await response.json();
                window.location.href = `${mobileURL}?${fileName}`
            } 
           else {
            const response = await fetch(`${url}/Candidates/get-QA-file-download`, {
                headers:{...requestHeaders},
                method:"POST",
                body:JSON.stringify(params)
            })
            const blob = await response.blob();
            let downnloadURL = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = downnloadURL;
            a.download = fileName;
            a.click();
           }
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    }
}
