import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FromMonths from '../../common/FromMonths';
import FromYears from '../../common/FromYears';
import Months from '../../common/Months';
import TextField from '../../common/TextField/withLabel';
import Years from '../../common/Years';
import { updateWorkHistory } from './action';
import { resumeBuilderSelector } from './selector';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const dispatch = useDispatch();
  const { workHistory } = useSelector(resumeBuilderSelector);
  const [history, setHistory] = React.useState(workHistory);
  useEffect(() => {
    dispatch(updateWorkHistory(history));
  }, [history]);

  const fieldChanged = (index, target) => {
    const { name, value } = target;
    setHistory((prevState) => {
      const newHistory = prevState.map((item) => ({ ...item }));
      if (value === 'Present') {
        newHistory[index]['toMonth'] = 'Present';
        newHistory[index]['toYear'] = 'Present';
      }
      newHistory[index][name] = value;
      return newHistory;
    });
  };
  const newRow = (event) => {
    event.preventDefault();
    setHistory((prevState) => {
      const newHistory = prevState.map((item) => ({ ...item }));
      newHistory.push({
        employer: '',
        fromMonth: '',
        fromYear: '',
        toMonth: '',
        toYear: '',
      });
      return newHistory;
    });
  };
  const removeRow = (index) => {
    setHistory((prevState) => {
      const newHistory = [...prevState];
      newHistory.splice(index, 1);
      if (newHistory.length === 0) {
        newHistory.push({
          employer: '',
          fromMonth: '',
          fromYear: '',
          toMonth: '',
          toYear: '',
        });
      }
      return newHistory;
    });
  };
  const selectToMonthAndToYear = (index, e) => {
    if (e.target.checked) {
      setHistory((prevState) => {
        const newHistory = prevState.map((item) => ({ ...item }));
        newHistory[index]['toMonth'] = 'Present';
        newHistory[index]['toYear'] = 'Present';
        newHistory[index]['flag'] = true;
        return newHistory;
      });
    } else {
      setHistory((prevState) => {
        const newHistory = prevState.map((item) => ({ ...item }));
        newHistory[index]['toMonth'] = '';
        newHistory[index]['toYear'] = '';
        newHistory[index]['flag'] = false;
        return newHistory;
      });
    }
  };
  return (
    <div className="col-lg-12 col-md-12" id="divresumeshow">
      <hr className="new4" />
      <div className="col-lg-12 col-md-12">
        <div class="col-lg-4 col-md-4">
          <h4>Work History</h4>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-md-block d-none">
              <label className="card-title">Employer</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-none d-md-block">
              <label className="card-title">From Month</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-none d-md-block">
              <label className="card-title">From Year</label>
            </div>
          </div>
          {/* <div className="col-lg-1 col-md-1">
                        <div className="form-group">
                            <div className="col-sm"></div>
                        </div>
                    </div> */}
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-md-block d-none">
              <label className="card-title">To Month</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <div className="form-group d-md-block d-none">
              <label className="card-title">To Year</label>
            </div>
          </div>
          <div className="col-lg-1 col-md-1">
            <div className="form-group d-md-block d-none">
              <label className="card-title">Delete</label>
            </div>
          </div>
        </div>
        <div className="row" id="divWkClone">
          {workHistory.map((h, index) => (
            <div className="row">
                <div className="form-group d-md-none">
              <label className="card-title">Employer</label>
            </div>
              <TextField
                divClass={'col-lg-2 col-md-2'}
                noLabel={true}
                name="employer"
                placeholder={'Name'}
                value={h.employer}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['employer']}
              />
               <div className="form-group d-md-none">
              <label className="card-title">From Month</label>
            </div>
              <FromMonths
                name={'fromMonth'}
                value={h.fromMonth}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['fromMonth']}
              />
               <div className="form-group d-md-none">
              <label className="card-title">From Year</label>
            </div>
              <FromYears
                name={'fromYear'}
                value={h.fromYear}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['fromYear']}
              />
              {/* <div className="col-lg-1 col-md-1">
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" onClick={(e) => selectToMonthAndToYear(index, e)}
                                        checked={h.toMonth === 'Present' && h.toYear === 'Present' ? true : false}/>
                                    </div>
                                </div></div> */}
              {/* flag={h.flag || (h.toMonth === 'Present' && h.toYear === 'Present') ? true : false} */}
              <div className="form-group d-md-none ">
              <label className="card-title">To Month</label>
            </div>
              <Months
                name={'toMonth'}
                value={h.toMonth}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['toMonth']}
              />
              {/* flag={h.flag || (h.toYear === 'Present' && h.toMonth === 'Present') ? true : false} */}
              <div className="form-group d-md-none ">
              <label className="card-title">To Year</label>
            </div>
              <Years
                name={'toYear'}
                value={h.toYear}
                updateValue={(target) => fieldChanged(index, target)}
                errors={props.errors[index] && props.errors[index]['toYear']}
              />
              {/* delete */}
              <div className="form-group  d-block d-md-none">
              <a
                      onClick={(event) => {
                        event.preventDefault();
                        removeRow(index);
                      }}
                    >
                      <i className="fe fe-trash-2"></i>
                      <label style={{marginLeft:"0.5rem"}} className="card-title d-md-none">Delete</label>
                    </a>
                </div>
              <div className="col-lg-1 col-md-1  d-none d-md-block">
                <div className="form-group">
                  <div className="col-sm">
                    <a
                      onClick={(event) => {
                        event.preventDefault();
                        removeRow(index);
                      }}
                    >
                      <i className="fe fe-trash-2"></i>
                    </a>
                  </div>
                  <label className="card-title d-md-none">Delete</label>
                </div>
              </div>
              <div className="row">&nbsp;</div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-2">
            <a id="btnWKH" href="#" onClick={newRow}>
              <div className="row">
                <div className="d-flex align-items-center ">
                  <i
                    className="icon-2x icon-plus"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="icon-plus"
                    aria-label="Add Work History"
                  ></i>
               <div  style={{marginLeft:"0.5rem"}}>Add New</div>
                </div>
             
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
