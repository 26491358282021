import React, { useRef, useState } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const [ascSort, setAscSort] = useState('inactive');
    const [descSort, setDescSort] = useState('inactive');
    const [noSort, setNoSort] = useState('inactive');
    const refButton = useRef(null);

    const onMenuClicked = () => {
        props.showColumnMenu(refButton.current);
    };

    const onSortChanged = (order) => {
        const isAscending = order === 'Asc';
        const isDescending = order === 'Desc';
        setAscSort(isAscending ? 'active' : 'inactive');
        setDescSort(isDescending ? 'active' : 'inactive');
        setNoSort(
            !isAscending && !isDescending
                ? 'active'
                : 'inactive'
        );
    };

    const getFieldName = (displayName) => {
        return props.headers.find(header => header.name.toLowerCase() === displayName.toLowerCase()).field;
    }
    const onSortRequested = (order) => {
        props.sort(order, getFieldName(props.displayName));
       // onSortChanged(order);
    };

    let menu = null;
    if (props.enableMenu) {
        menu = (
            <div
                ref={refButton}
                className="customHeaderMenuButton"
                onClick={() => onMenuClicked()}
            >
                <i className={`fa fa-bars`} ></i>
            </div>
        );
    }

    let sort = null;
    if (props.enableSorting) {
        sort = (
            <div style={{ display: 'inline-block' }}>
                <div
                    onClick={(event) => onSortRequested('Asc', event)}
                    onTouchEnd={(event) => onSortRequested('Asc', event)}
                    className={`customSortDownLabel ${ascSort}`}
                >
                    <i class="fa fa-arrow-down"></i>
                </div>
                <div
                    onClick={(event) => onSortRequested('Desc', event)}
                    onTouchEnd={(event) => onSortRequested('Desc', event)}
                    className={`customSortUpLabel ${descSort}`}
                >
                    <i class="fa fa-arrow-up"></i>
                </div>
                <div
                    onClick={(event) => onSortRequested('', event)}
                    onTouchEnd={(event) => onSortRequested('', event)}
                    className={`customSortRemoveLabel ${noSort}`}
                >
                    <i class="fa fa-close"></i>
                </div>
            </div>
        );
    }

    return (
        <div>
            {menu}
            <div className="customHeaderLabel">{props.displayName}</div>
            {sort}
        </div>
    );
};
