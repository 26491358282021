import React, { useEffect, useState } from "react";
import useSessionStorage from "../../../hooks/useSessionStorage";
import "./index.css";

export const ChatWindow = (props) => {
  const storage = useSessionStorage();
  const user = storage.getItem("login");
  const [text, settext] = useState("");
  useEffect(() => {
    setTimeout(() => window.setUpChatWindow(), 2000);
    const container = document.querySelector("#ChatBody");
    container.scrollTop = container.scrollHeight - container.clientHeight;
  });

  const sendText = () => {
    if (text) {
      props.sendMsg(text);
      settext("");
    }
  };

  const formatAMPM = (date) => {
    const newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  return (
    // <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5">
    //     <div className="card">
    //         <div className="main-content-app pt-0">
    //             <div className="main-content-body main-content-body-chat h-100">
    //                 <div className="main-chat-header pt-3 d-block d-sm-flex">
    //                     <div className="main-img-user online"><img alt="avatar" src="../assets/images/users/1.jpg" />
    //                     </div>
    //                     <div className="main-chat-msg-name mt-2">
    //                         <h6>{user.fullName}</h6>
    //                         <span className="dot-label bg-success"></span><small className="me-3">online</small>
    //                     </div>
    //                     <button className={"btn btn-secondary close-button"} onClick={() => {
    //                         props.closeChatWindow()
    //                     }}>X</button>
    //                 </div>
    //                 <div className="main-chat-body flex-2" id="ChatBody">
    //                     <div className="content-inner">
    //                         {
    //                             props.chatData.map(item => {
    //                                 return (
    //                                     <>
    //                                         <label className="main-chat-time"><span>{item?.date}</span></label>
    //                                         {
    //                                             item?.messages.map(val => {
    //                                                 return (
    //                                                     !val?.fromCandidate ?
    //                                                         <div className="media chat-left">
    //                                                             <div className="main-img-user online"><img alt="avatar"
    //                                                                 src="../assets/images/users/1.jpg" /></div>
    //                                                             <div className="media-body">
    //                                                                 <div className="main-msg-wrapper">
    //                                                                     {val?.messageText}
    //                                                                 </div>
    //                                                                 <div>
    //                                                                     <span>{formatAMPM(val?.messageDateTime)}</span> <a href=""><i
    //                                                                         className="icon ion-android-more-horizontal"></i></a>
    //                                                                 </div>
    //                                                             </div>
    //                                                         </div>
    //                                                         : <div className="media flex-row-reverse chat-right">
    //                                                             <div className="main-img-user online"><img alt="avatar"
    //                                                                 src="../assets/images/users/21.jpg" />
    //                                                             </div>
    //                                                             <div className="media-body">
    //                                                                 <div className="main-msg-wrapper">
    //                                                                     {val?.messageText}
    //                                                                 </div>
    //                                                                 <div>
    //                                                                     <span>{formatAMPM(val?.messageDateTime)}</span> <a href=""><i
    //                                                                         className="icon ion-android-more-horizontal"></i></a>
    //                                                                 </div>
    //                                                             </div>
    //                                                         </div>

    //                                                 )
    //                                             })
    //                                         }
    //                                     </>
    //                                 )
    //                             })
    //                         }
    //                     </div>
    //                 </div>
    //                 <div className="main-chat-footer">
    //                     <input className="form-control" value={text} placeholder="Type your message here..." onChange={(e) => settext(e.target.value)} type="text" />
    //                     {/* <a className="nav-link" data-bs-toggle="tooltip" href="" title="Attach a File"><i
    //                         className="fe fe-paperclip"></i></a> */}
    //                     <button type="button" className="btn btn-icon  btn-primary brround" onClick={sendText}><i
    //                         className="fa fa-paper-plane-o"></i></button>
    //                     <nav className="nav">
    //                     </nav>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    <div className="container">
      <div
        className="card"
        style={{
          height: "400px",
          width: "350px",
          bottom: "10px",
          right: "10px",
          position: "fixed",
        }}
      >
        <div
          className="card-header d-flex justify-content-between"
          style={{ height: "70px" }}
        >
          <div className="d-flex ">
            <div
              className="border text-center rounded bg-secondary"
              style={{ width: "45px", height: "45px" }}
            >
              <span className="text-white fw-bold">{user.fullName[0]}</span>
            </div>

            <div className="main-chat-msg-name mt-2">
              <h6>{user.fullName}</h6>
              <span className="dot-label bg-success"></span>
              <small className="me-3">online</small>
            </div>
          </div>
          <div className="mt-0" onClick={() => props.closeChatWindow()}>
            <button
              className="btn fw-bold text-white"
              style={{ background: "#d11a2a" }}
            >
              X
            </button>
          </div>
        </div>
        <div className="card-body main-chat-body"  id="ChatBody">
          {props.chatData.map((item) => {
            return (
              <>
                <label className="main-chat-time">
                  <span>{item?.date}</span>
                </label>
                {item?.messages.map((val) => {
                  return !val?.fromCandidate ? (
                    <div className="d-flex gap-3">
                      <div className="main-img-user online">
                        <div
                          className="border text-center rounded bg-warning"
                          style={{ width: "45px", height: "45px" }}
                        >
                          <span className="text-white fw-bold">
                            {val?.senderName[0]}
                          </span>
                        </div>
                      </div>
                      <div className="media-body">
                        <div
                          className="main-msg-wrapper"
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomRightRadius: "0px",
                          }}
                        >
                          {val?.messageText}
                        </div>
                        <div>
                          <span style={{ fontSize: "10px" }}>
                            {formatAMPM(val?.messageDateTime)}
                          </span>{" "}
                          <a href="">
                            <i className="icon ion-android-more-horizontal"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="media d-flex gap-1 text-end flex-row-reverse">
                      <div
                        className="border text-center rounded bg-secondary"
                        style={{ width: "45px", height: "45px" }}
                      >
                        <span className="text-white fw-bold">
                          {val?.senderName[0]}
                        </span>
                      </div>

                      <div className="media-body">
                        <div
                          className="main-msg-wrapper border bg-light"
                          style={{
                            borderBottomLeftRadius: "0px",
                            borderTopRightRadius: "0px",
                          }}
                        >
                          {val?.messageText}
                        </div>
                        <div>
                          <span style={{ fontSize: "10px" }}>
                            {formatAMPM(val?.messageDateTime)}
                          </span>{" "}
                          <a href="">
                            <i className="icon ion-android-more-horizontal"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
        <div className="card-footer d-flex gap-3">
          <input
            className="form-control"
            value={text}
            placeholder="Type your message here..."
            onChange={(e) => settext(e.target.value)}
            type="text"
          />
          <button
            type="button"
            className="btn btn-icon  btn-success brround"
            onClick={sendText}
          >
            <i className="fa fa-paper-plane-o"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
