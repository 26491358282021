import React, { useState } from 'react';
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker
} from 'react-google-maps';

const Map = withScriptjs(withGoogleMap((props) => {

    const [lat, setLat] = useState(parseFloat(props.lat))
    const [lng, setLng] = useState(parseFloat(props.lng))
    const onMarkerDragEnd = (e) => {
        if (e.latLng.lat() && e.latLng.lng()) {
            setLat(e.latLng.lat())
            setLng(e.latLng.lng())
        }
    }
    return (
        <GoogleMap
            defaultZoom={4}
            defaultCenter={{ lat, lng }}
        >
            <Marker
                position={{ lat, lng }}
                draggable
                onDragEnd={e => {onMarkerDragEnd(e); props.setLatLng(e)}}
            />
        </GoogleMap>
    )
}))

export default Map;