import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userNotifyList: null,
};

export const headerInfoSlice = createSlice({
  name: "headerInfo",
  initialState,
  reducers: {
    userNotifyList: (state, action) => {
      state.userNotifyList = action.payload;
    },
  },
});

export const { userNotifyList } = headerInfoSlice.actions;
export default headerInfoSlice.reducer;
