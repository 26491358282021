import { useState } from "react";

export default (props) => {
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">

                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                        <div className="col-lg-4 col-md-4 jobheader">
                                <b><h4 className="headerfontbold">Covid 19 Vaccination Status?</h4></b>                     
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <>   <div className="row">
                        <div className="col-lg-8 col-md-8">
                            {
                                props?.vaccination.map(vacc => (
                                    <div className="col-lg-8 col-md-8"> <label>{vacc.name}</label> </div>
                                ))
                              
                            }
                        </div> </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
