export const randomString = (length, word) => {
  const wordList = word.split(',');
  if (!wordList.length) return '';
  let randomWord = '';
  for (let index = 0; index < length; index++) {
    // generate random index from the array list
    let randomNumber = Math.floor(Math.random() * wordList.length);
    randomWord += wordList[randomNumber];
  }
  return randomWord;
};
