import requestUtil from '../../helpers/requestUtil';
import useSessionStorage from '../../hooks/useSessionStorage';
import { getCurrentPersonalInfo, loadMasterData } from '../ProfileBuilder/PersonalInfo/action';
import { setPersonalInfo } from '../ProfileBuilder/PersonalInfo/slice';
import { getMasterData, getPreference } from '../ProfileBuilder/Preference/action';
import { loadPreferencedata } from '../ProfileBuilder/Preference/slice';
import { getCurrentResumeBuilder, getLevelBasedAPIDatas, loadMasterDatas } from '../ProfileBuilder/ResumeBuilder/action';
import { setResumeBuilderData } from '../ProfileBuilder/ResumeBuilder/slice';
import { gotoTab } from '../ProfileBuilder/action';
import { set_login_roles_flags } from '../ProfileBuilder/slice';
import { setLoading } from '../common/Loader/slice';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  reset,
  setProfileFlag,
} from './slice';

export function login({ url, email, password }) {
  return async (dispatch) => {
    dispatch(loginRequest());
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(url, 'POST', {
        username: email,
        password,
      });
      dispatch(loginSuccess(response));
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
    dispatch(setLoading(false));
  };
}

export function loginV1({ url, email, encryptPassword, iv, baseUrl }) {
  return async (dispatch) => {
    dispatch(loginRequest());
    dispatch(setLoading(true));
    const storage = useSessionStorage();
    try {
      const response = await requestUtil(url, 'POST', {
        username: email,
        encryptPassword: encryptPassword,
        iv: iv,
        websiteDomainName: window.location.hostname === 'localhost' ? 'stagingcandidate.overturexl.com' : window.location.hostname
      });

      dispatch(loginSuccess(response));
      dispatch(setLoading(true));
      {response?.loginRoles?.tenantRoles?.at(0)?.status === false &&
       
        dispatch(
        getCurrentResumeBuilder({
          url: `${baseUrl}/Candidates/getjobinfo`,
          userId: response.candidateId,
        }))

      //   .then((res) => {
      //     if(response.jobTypeInfo.showCertifications && response.jobTypeInfo.showEmr && response.jobTypeInfo.showSpecialties) {
      //       dispatch(getLevelBasedAPIDatas(baseUrl, response.jobTypeId,response.candidateUniqueId, res?.jobCategoryIdList, response))
      //       .then(() => {
      //           dispatch(setResumeBuilderData(res))
      //       })
      //     }
      //   })

      //   .then(() => {
      //     dispatch(loadMasterData(`${baseUrl}`)).then(() => {
      //       dispatch(
      //         getCurrentPersonalInfo({
      //           url: `${baseUrl}/Candidates/getpersonalinfo`,
      //           userId: response.candidateId,
      //         })
      //       )
      //     })
      //    })

      // .then(() => {
      //     dispatch(
      //       loadMasterDatas(
      //        baseUrl,
      //         response.jobTypeId,
      //         response.candidateUniqueId
      //   ))
      // })
             
      //  .then(() => {
      //   dispatch(getMasterData(baseUrl))
      //  })

      //  .then((data) => {
      //   dispatch(getPreference(baseUrl, response.candidateId))
      // })

      //  .then(() => {
      //   dispatch(
      //     getCurrentResumeBuilder({
      //       url: `${baseUrl}/Candidates/getjobinfo`,
      //       userId: response.candidateId,
      //  }))
      //  })
        
      }

      // Is Admin comes from true means triggerd this API after set a flags for Session Storage 
      if (response?.isFromAdmin) {
       const adminFlags = await requestUtil(
          `${baseUrl}/Candidates/getCandidatepreferenceinfo/${response?.candidateId}`,
          'GET'
       );
      sessionStorage.setItem('profileBuilderTabFlagsFromAdmin', JSON.stringify(adminFlags));
      }


      const login_roles_fields = await requestUtil(
        `${baseUrl}/Dropdown/get-login-roles`,
        'GET'
      );

      sessionStorage.setItem('login_roles', JSON.stringify(login_roles_fields));

      let can_edit_profile_filter;
      // Create Object for respective names
      response?.loginRoles?.tenantRoles?.forEach(element => {
      if (element?.roleName === login_roles_fields?.CanEditProfileFilter) {
      can_edit_profile_filter = element
      }
      })
      // Set a object for Login Roles
      const login_roles_types = {
        can_edit_profile_filter
      }

      dispatch(set_login_roles_flags(login_roles_types));
      sessionStorage.setItem('login_roles_flags_list', JSON.stringify(login_roles_types));
      return response;
    } catch (error) {
      dispatch(loginFailure(error.message));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function logOut() {
  return async (dispatch) => {
    dispatch(reset());
  };
}

export function resetError() {
  return async (dispatch) => {
    dispatch(reset());
  };
}

export function updateProfileFlag(flagName) {
  return async (dispatch) => {
    dispatch(setProfileFlag(flagName));
  };
}

export const getDynamicFormData = (url) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(
        `${url}/Dropdown/get-controltypeenum-asobject`,
        'GET'
      );
      sessionStorage.setItem('inputTypes', JSON.stringify(response));
      dispatch(setLoading(false));
    } catch (error) { }
    dispatch(setLoading(false));
  };
};

const getIPAddress = async () => {
  const addressIP = await fetch("https://api.ipify.org/?format=json");
  const result = await addressIP.json();
  return result?.ip ? result?.ip : "string";
}
const getOsName = () => {
  const userAgent = navigator.userAgent;
  const userAgentData = navigator?.userAgentData;
  const osName = userAgentData?.platform
  let operatingSystem;
  if (userAgent.includes("Windows")) {
    operatingSystem = "Windows";
  } else if (userAgent.includes("Mac OS")) {
    operatingSystem = "Mac OS";
  } else if (userAgent.includes("Linux")) {
    operatingSystem = "Linux";
  } else if (userAgent.includes("Android")) {
    operatingSystem = "Android";
  } else if (userAgent.includes("iOS")) {
    operatingSystem = "iOS";
  }
  return osName ?? operatingSystem ?? "Unknown"
}
const getBrowserName = () => {
  const userAgent = navigator.userAgent;
  const userAgentData = navigator?.userAgentData;
  let browserName;
  const dataBrowserName = userAgentData?.brands?.at(-1)?.brand;
  if (userAgent.includes("Firefox")) {
    browserName = "Firefox";
  } else if (userAgent.includes("Chrome")) {
    browserName = "Google Chrome";
  } else if (userAgent.includes("Safari")) {
    browserName = "Safari";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
    browserName = "Internet Explorer";
  } else if (userAgent.includes("Edge")) {
    browserName = "Microsoft Edge";
  }
  return dataBrowserName ?? browserName ?? "Unknown"
}
export const getTrackingParams = (url, params, response) => {
  return async (dispatch) => {
    try {
      const { appUserId, employerId, result } = response;
      const sourceIP = await getIPAddress();
      const browser = getBrowserName();
      const operatingSystem = getOsName();
      const newParams = {
        ...params,
        browser,
        sourceIP,
        operatingSystem,
        appUserId: appUserId ?? null,
        employerId: employerId ?? null,
        loginResult: result ?? 0,
      };
      await requestUtil(`${url}/Tracking/userloginhistory`, "POST", {
        ...newParams,
      });
    } catch (error) {
      return error;
    }
  };
};

export const getProfileBuilderFlagsFields = (url) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(
        `${url}/Dropdown/get-fields-asobject`,
        'GET'
      );
      sessionStorage.setItem('profile_builder_fields', JSON.stringify(response));
    } catch (error) { }
    dispatch(setLoading(false));
  };
};

export const get_login_roles = (url) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await requestUtil(
        `${url}/Dropdown/get-login-roles`,
        'GET'
      );
      sessionStorage.setItem('login_roles', JSON.stringify(response));
      return response;
    } catch (error) { }
    dispatch(setLoading(false));
  };
};

