import { useEffect } from 'react';
import configJson from '../../conf/application.json';

const AppClearCache = () => {
  useEffect(() => {
    clearCache();
  });

  const clearCache = async () => {
    try {
      const environment = process.env.NODE_ENV || 'development';
      const baseUrl = configJson[environment].baseUrl;
      const currentVersion = await (
        await fetch(`${baseUrl}/Tracking/get-latest-app-version/1`)
      ).json();
      const appVersion = sessionStorage.getItem('appVersion');
      if (!appVersion) {
        sessionStorage.setItem(
          'appVersion',
          currentVersion?.data?.appVersion
            ? currentVersion?.data?.appVersion
            : ''
        );
      } else {
        if (
          parseFloat(currentVersion?.data?.appVersion) > parseFloat(appVersion)
        ) {
          if (caches) {
            caches.keys().then(function (names) {
              for (let name of names) caches.delete(name);
            });
          }
          console.log('set current version in the session storage');
          sessionStorage.setItem(
            'appVersion',
            currentVersion?.data?.appVersion
              ? currentVersion?.data?.appVersion
              : ''
          );
          window.location.reload(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return null;
};

export default AppClearCache;
