import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactStars from 'react-rating-stars-component';
import CustomHeader from './customHeader';
import IconRenderer from './iconRenderer';
import './index.css';
import PaginationBottom from './paginationBottom';
import PaginationTop from './paginationTop';
import HtmlRenderer from './htmlRenderer';
import StarsRenderer from "./starsRenderer";
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const options = props.options || {};
  const pageData = props.pageData || {};
  const gridRef = useRef();
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);
  const createStar = (count) => {
    let color = '';
    const count1 = count.value;
    const val = count1;
    if (val >= 4) {
      color = 'green';
    } else if (val >= 3) {
      color = 'yellow';
    } else if (val < 3 && val >= 0) {
      color = 'red';
    } else if (val <= 0) {
      color = 'black ';
    }
    const star = {
      size: 15,
      count1: 5,
      value: count1,
      edit: false,
      color: color,
      activeColor: color,
      a11y: true,
      isHalf: true,
      emptyIcon: <i className="fa fa-star-o" />,
      halfIcon: <i className="fa fa-star-half-o" />,
      filledIcon: <i className="fa fa-star" />,
    };
    return <ReactStars {...star} />;
  };
  const Listfunction = (params) => {
    return (
      Array.isArray(params.value.value) &&
      params.value.value.map((item) => {
        return (
          <span
            style={{
              background: item.isApproved === true ? '#00ff21' : '#ff0a0a',
              justifyContent: 'space-between',
              margin: '0 0 0 10px',
            }}
          >
            {' '}
            <b>{item.isApproved === true ? 'Y' : 'N'}</b>
          </span>
        );
      })
    );
  };
  const gridtextcolor = (params) => {
    return (
      Array.isArray(params.value.value) &&
      params.value.value.map((item) => {
        return (
          <span
            style={{
              background: item.isApproved === true ? '#00ff21' : '#ff0a0a',
              justifyContent: 'space-between',
              margin: '0 0 0 10px',
            }}
          >
            {' '}
            <b>{item.isApproved === true ? 'Y' : 'N'}</b>
          </span>
        );
      })
    );
  };
  const Unpaidis_Paid = (paramsval) => {
    return (
      <span
        style={{
          background: paramsval.value.value === true ? '#00ff21' : '#ff0a0a',
          justifyContent: 'space-between',
          margin: '0 0 0 10px',
        }}
      >
        {' '}
        <b>{paramsval.value.value === true ? 'Y' : 'N'}</b>
      </span>
    );
  };
  const RatingHistory_ratings = (paramsratings) => {
    const valuepair = paramsratings.value;
    return createStar(valuepair);
  };
  const Cancelclaim_ratings = (paramsratings) => {
    const valuepair = paramsratings.value;
    return createStar(parseInt(valuepair));
  };
  const Unpaidis_checkedin = (paramsval) => {
    return (
      <span
        style={{
          background: paramsval.value.value === true ? '#00ff21' : '#ff0a0a',
          justifyContent: 'space-between',
          margin: '0 0 0 10px',
        }}
      >
        {' '}
        <b>{paramsval.value.value === true ? 'Y' : 'N'}</b>
      </span>
    );
  };
  const Unpaidis_checkedout = (paramsval) => {
    return (
      <span
        style={{
          background: paramsval.value.value === true ? '#00ff21' : '#ff0a0a',
          justifyContent: 'space-between',
          margin: '0 0 0 10px',
        }}
      >
        {' '}
        <b>{paramsval.value.value === true ? 'Y' : 'N'}</b>
      </span>
    );
  };
  const RH_checkedin = (paramsval) => {
    return (
      <span
        style={{
          background: paramsval.value.value === true ? '#00ff21' : '#ff0a0a',
          justifyContent: 'space-between',
          margin: '0 0 0 10px',
        }}
      >
        {' '}
        <b>{paramsval.value.value === true ? 'Y' : 'N'}</b>
      </span>
    );
  };
  const RH_checkedout = (paramsval) => {
    return (
      <span
        style={{
          background: paramsval.value.value === true ? '#00ff21' : '#ff0a0a',
          justifyContent: 'space-between',
          margin: '0 0 0 10px',
        }}
      >
        {' '}
        <b>{paramsval.value.value === true ? 'Y' : 'N'}</b>
      </span>
    );
  };
  const Listmyshiftfunction = (params) => {
    return (
      Array.isArray(params.value.value) &&
      params.value.value.map((item) => {
        //return <span style={{background: item.isApproved === true ? '#00ff21' : 'red' ,justifyContent: "space-between",margin: "0 0 0 10px"  } }> <b>{item.isApproved === true ? 'Y' : 'N'}</b></span>
        return (
          <span
            style={{
              background: item.isApproved === true ? '#00ff21' : '#ff0a0a',
              justifyContent: 'space-between',
              margin: '0 0 0 10px',
            }}
          >
            {' '}
            {item.isApproved === true ? 'Y' : 'N'}
          </span>
        );
      })
    );
  };
  const Myshift_color = (paramsval) => {
    return (
      <span
        style={{
          background: Object.keys(paramsval.value).length === 0 ? '' : paramsval.value.value === true ? '#00ff21' : '#ff0a0a',
          justifyContent: 'space-between',
          margin: '0 0 0 10px',
        }}
      >
        {' '}
        <b>{Object.keys(paramsval.value).length === 0 ? '' : paramsval.value.value === true ? 'Y' : 'N'}</b>
      </span>
    );
  };
  const EmploerStatus_Data = (paramsval) => {
    return (
      <>
        <div style={{ height: '100px' }}>
          {paramsval.value.value?.locationName} <br />{' '}
          {paramsval.value.value?.employerName}
        </div>
      </>
    );
    //return '';
    // return Array.isArray(paramsval.value.value) && paramsval.value.value.map(item => {
    //     console.log(item.locationName , item.employerName )
    //     return item.locationName
    //     //return paramsval.value.locationName;//<span style={{background: paramsval.value.locationName === true ? '#00ff21' : '#ff0a0a' ,justifyContent: "space-between",margin: "0 0 0 10px"  } }> {paramsval.value.locationName === true ? 'Y' : 'N'}</span>
    // });

    // return '';//<span style={{background: paramsval.value.value === true ? '#00ff21' : '#ff0a0a' ,justifyContent: "space-between",margin: "0 0 0 10px"  } }> <b>{paramsval.value.value === true ? 'Y' : 'N'}</b></span>;
  };

  //  const ChangeColorcheckin=(params)=>{
  //     console.log(params.value);
  //     //return(params.value);
  //     return <span style={{background: params.value === true ? '#00ff21' : 'red' ,justifyContent: "space-between",margin: "0 0 0 10px" }}><b>{params.value === true ? 'Y' : 'N'}</b></span>;

  //  }

  //  const ChangeColorcheckout=(params)=>{
  //     console.log(params.value);
  //     return <span style={{background: params.value === true ? '#00ff21' : 'red' ,justifyContent: "space-between",margin: "0 0 0 10px" }}><b>{params.value === true ? 'Y' : 'N'}</b></span>;

  //  }

  //  const ChangeColorpaid=(params)=>{
  //     console.log(params.value);
  //     return <span style={{background: params.value === true ? '#00ff21' : 'red' ,justifyContent: "space-between",margin: "0 0 0 10px" }}><b>{params.value === true ? 'Y' : 'N'}</b></span>;

  //  }
  const OvertimeAllow = (paramsval) => {
    return (
      <>
        <div>
          {/* {paramsval.value.value?.locationName} <br />{' '}
          {paramsval.value.value?.employerName} */}
          {paramsval?.value?.value ? `Authorized up to ${paramsval?.value?.value} hrs`:""}
        </div>
      </>
    )};


  const Menflag = (data) => {
    return (
      <>
      <span>{data?.value?.value ? 
      <svg
      width="15"
      height="19"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        points="10,190 10,10 60,10 100,90 140,10 190,10 190,190 150,190 150,60 100,140 50,60 50,190"
        fill={"#90EE90"}
        stroke="black"
        stroke-width={"0.5rem"}
      />
    </svg> 
      : 
      ''
      }</span>
      </>
    )
  }

  const columnDefs = props?.headers?.map((header) => {
    const columnDef = {
      field: header.name,
    };
    if (header.type === 'icon') {
      columnDef.cellRenderer = IconRenderer;
    }
    if (header.type === 'OvertimeAllow') {
      columnDef.cellRenderer = OvertimeAllow;
    }
    if (header.type === 'color') {
      columnDef.cellRenderer = Listfunction;
    }
    if (header.type === 'Unpaidis_Paid') {
      columnDef.cellRenderer = Unpaidis_Paid;
    }
    if (header.type === 'Unpaidis_checkedin') {
      columnDef.cellRenderer = Unpaidis_checkedin;
    }
    if (header.type === 'Unpaidis_checkedout') {
      columnDef.cellRenderer = Unpaidis_checkedout;
    }
    if (header.type === 'RH_checkedin') {
      columnDef.cellRenderer = RH_checkedin;
    }
    if (header.type === 'RH_checkedout') {
      columnDef.cellRenderer = RH_checkedout;
    }
    if (header.type === 'RatingHistory_ratings') {
      columnDef.cellRenderer = RatingHistory_ratings;
    }
    if (header.type === 'ratings_new') {
      columnDef.cellRenderer = StarsRenderer
    }
    if (header.type === 'Cancelclaim_ratings') {
      columnDef.cellRenderer = Cancelclaim_ratings;
    }
    if (header.type === 'gridtextcolor') {
      columnDef.cellRenderer = gridtextcolor;
    }

    if (header.type === 'Myshift_color') {
      columnDef.cellRenderer = Myshift_color;
    }
    if (header.type === 'EmploerStatus_Data') {
      columnDef.cellRenderer = EmploerStatus_Data;
    }
    if (header.type === 'text') {
      columnDef.cellRenderer = HtmlRenderer
    }

    if (header.name === 'Mentor') {
      columnDef.cellRenderer = Menflag
    }

    if (header?.autoHieght) {
      columnDef.autoHieght = header.autoHieght;
    }
    if (header?.wrapText) {
      columnDef.wrapText = header.wrapText;
    }
    if (header?.maxWidth) {
      columnDef.maxWidth = header.maxWidth;
    }
    if (header?.width) {
      columnDef.width = header.width;
    }
    if (header?.suppressSizeToFit) {
      columnDef.suppressSizeToFit = header.suppressSizeToFit;
    }
    columnDef.minWidth = header.minWidth || 100;

    //  if (header.type === 'checkout') {

    //     columnDef.cellRenderer = ChangeColorcheckout
    //  }

    //  if (header.type === 'paid') {

    //     columnDef.cellRenderer = ChangeColorpaid
    //  }

    columnDef.filter = header.filter;
    columnDef.sortable = header.sort;
    columnDef.comparator = header.comparator;
    return columnDef;
  });
  const gridReady = (params) => {
    const api = params.api;
    // if (!props.headerSpace) {
    //   api.sizeColumnsToFit();
    // }
    api.setDomLayout(options.layout || 'autoHeight');
    api.sizeColumnsToFit();
    api.setDomLayout(options.layout || "normal");
  };
  const rowData = props?.rows?.map((row) => {
    const columns = row.columns;
    const keys = Object.keys(columns);
    const rowData = {};
    keys.forEach((key) => {
      rowData[key] = columns[key];
    });
    return rowData;
  });
  const defaultColDef = {
    resizable: options.resizable || false,
    filter: options.filter || false,
    headerComponentParams: props.headerComponentParams,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  useEffect(() => {
    window.addEventListener("resize", handler, false);
    return () => {
      window.removeEventListener("resize", handler);
    }
  }, [])

  const handler = e => {
    //console.log(window.innerWidth)
    if (window.innerWidth && window.innerWidth > 3000) {
      sizeToFit()
    } else {
      autoSizeAll()
    }
  };

  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    // const allColumnIds = [];
    // console.log(gridRef.current);
    // console.log(gridRef.current.columnApi.getColumns());
    // gridRef.current.columnApi.getColumns().forEach((column) => {
    //   allColumnIds.push(column.getId());
    // });
    gridRef.current.columnApi.autoSizeColumns([], skipHeader);
  }, []);

  const containerStyle = useMemo(() => ({ width: '100%', height: options.height ? '' : '400px', }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%', overflow: 'scroll',}), []);

  return (
    <>
      {options.pagination && (
        <PaginationTop
          pageData={pageData}
          updatePageSize={props.updatePageSize}
        />
      )}
      
      <div 
      style={containerStyle}
      >
      <div 
      style={gridStyle} 
      className="ag-theme-alpine">
      <AgGridReact
        ref={gridRef}
        // className={'ag-theme-alpine'}
        rowData={rowData?.length ? rowData : 'No Data'}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={options.animate || true}
        onGridReady={gridReady}
        onGridColumnsChanged={gridReady}
        pagination={options.pagination}
        suppressPaginationPanel={true}
        rowHeight={120}
        scrollbarWidth={8}
        components={components}
        noRowsOverlayComponent={TableRowDataMessage}
        overlayNoRowsTemplate={"."}
        onFirstDataRendered={() => {
          if (props.headerSpace)
            gridRef.current.columnApi.autoSizeAllColumns(true);
        }}
      />
      </div>
      </div>
      {options.pagination && <PaginationBottom {...props} />}
    </>
  );
};



const TableRowDataMessage = () => {
  return (
    <div>
      No Jobs To Show
    </div>
  )
}