import { gotoNextTab, gotoPreviousTab, resetError, setCurrentTab, setError } from "./slice";

export function nextTab(flag) {
    return async dispatch => {
        dispatch(gotoNextTab(flag));
    };
}

export function previousTab(flag) {
    return async dispatch => {
        dispatch(gotoPreviousTab(flag));
    };
}

export function profileError(error) {
    return async dispatch => {
        dispatch(setError(error));
    };
}

export function resetProfileError() {
    return async dispatch => {
        dispatch(resetError());
    };
}

export function gotoTab(tab) {
    return async dispatch => {
        dispatch(setCurrentTab(tab));
    };
}
