import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    unclaimed: {
        page: 1,
        data: {},
        totalPages: 0,
        pageSize: 10,
        sortColumn: [],
        sortOrder: 'Acs'
    },
    selectedJob: '',
    shiftsLoadingError: null,
    claimUnclaimErrorMessage: null,
    claimUnclaimSuccessMessage: null,
    chat: [],
    msgSendFlag: false
}
const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}
export const shiftsSlice = createSlice({
    name: 'shifts',
    initialState,
    reducers: {
        updateSelectedJob: (state, action) => {
            state['selectedJob'] = action.payload.data;
        },
        updateShifts: (state, action) => {
            state[action.payload.pageName].data = action.payload.data;
            state[action.payload.pageName].page = Number(action.payload.page);
            state.claimUnclaimSuccessMessage = null;
            state.claimUnclaimErrorMessage = null;
        },
        shiftLoadingFailed: (state, action) => {
            state.shiftsLoadingError = action.payload;
        },
        updatePageSize: (state, action) => {
            state[action.payload.pageName].pageSize = Number(action.payload.pageSize);
            state[action.payload.pageName].page = 1;
        },
        setPage: (state, action) => {
            state[action.payload.pageName].page = Number(action.payload.page);
        },
        setSorting: (state, action) => {
            const currentSortColumns = state[action.payload.pageName].sortColumn;
            state[action.payload.pageName].sortOrder = action.payload.sortOrder;
            state[action.payload.pageName].sortColumn = action.payload.sortColumn;//[...currentSortColumns, action.payload.sortColumn].filter(onlyUnique);
        },
        setClaimUnclaimSuccessMessage: (state, action) => {
            state.claimUnclaimSuccessMessage = action.payload;
        },
        setClaimUnclaimErrorMessage: (state, action) => {
            state.claimUnclaimErrorMessage = action.payload;
        },
        setChat: (state, action) => {
            state.chat = action.payload
        },
        setMsgSendFlag: (state, action) => {
            state.msgSendFlag = action.payload
        },
        setClaimUnclaimMsgReset: (state, action) => {
            state.claimUnclaimSuccessMessage = action.payload;
            state.claimUnclaimErrorMessage = action.payload;
        }
    }
});

export const {
    shiftLoadingFailed,
    updatePageSize,
    updateShifts,
    setPage,
    setSorting,
    updateSelectedJob,
    setClaimUnclaimSuccessMessage,
    setClaimUnclaimErrorMessage,
    setChat, setMsgSendFlag, setClaimUnclaimMsgReset
} = shiftsSlice.actions;

export default shiftsSlice.reducer;
