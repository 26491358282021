import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    masterData: {
        loctionList: [],
        readList: []
    },
    error: false,
    errorMessage: null,
    params: {
        "page": 0,
        "pageSize": 0,
        "sortOrder": "",
        "sortColumn": "",
        "webPageId": 0,
        "shiftUniqueId": null,
        "candidateUniqueId": "",
        "employerUniqueId": null,
        "locationId": 0,
        "fromDateRange": null,
        "toDateRange": null,
        "messageStatus": 0,
        'searchText': ''
    },
    totalCount: 0
}

export const messagesheaderSlice = createSlice({
    name: 'messagesheader',
    initialState,
    reducers: {
        setMessages: (state, action) => {
            state.data = action.payload
        },
        setError: (state, action) => {
            state.error = true;
            state.errorMessage = action.payload;
        },
        resetError: (state) => {
            state.error = false;
            state.errorMessage = null;
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload;
        }
    }
});


export const { setMessages, setError, resetError,setTotalCount } = messagesheaderSlice.actions;

export default messagesheaderSlice.reducer;