import { useEffect, useState } from 'react';
import configJson from '../conf/application.json';

function useConfiguration() {
  const [config, setConfig] = useState({});

  useEffect(() => {
    const environment = process.env.NODE_ENV || 'development';
    setConfig({
      validations: configJson.validations || {},
      mapURL: configJson.mapURL,
      SaltKeyForJSandAPI: configJson.SaltKeyForJSandAPI,
      ...configJson[environment],
    });
  }, []);

  return config;
}

export default useConfiguration;
