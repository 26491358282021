/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useConfiguration from '../../hooks/useConfiguration';
import useSessionStorage from '../../hooks/useSessionStorage';
import useValidation from '../../hooks/useValidation';
import Footer from '../common/Footer';
import Header from '../common/Header';
import Loader from '../common/Loader';
import Sidebar from '../common/Sidebar';
import { loginSelector } from '../Login/selector';
import SaveSuccess from '../SaveSuccess';
import Summary from '../Summary';
import { gotoTab, previousTab } from './action';
import './index.css';
import PageHeader from './pageHeader';
import PersonalInfo from './PersonalInfo';
import {
  getCurrentPersonalInfo,
  loadMasterData,
  resetErrorStatus,
  savePersonalInfo,
} from './PersonalInfo/action';
import { personalInfoSelector } from './PersonalInfo/selector';
import Preference from './Preference';
import {
  getMasterData,
  getPreference,
  savePreference,
} from './Preference/action';
import { preferenceSelector } from './Preference/selector';
import './profilebuilder.css';
import { getDynamicFormData, saveQandADetail } from './QandA/action';
import QandA from './QandA/qanda';
import { qandAlInfoSelector } from './QandA/selector';
import ResumeBuilder from './ResumeBuilder';
import {
  getCurrentResumeBuilder,
  getLevelBasedAPIDatas,
  loadMasterDatas,
  saveResumeBuilder,
} from './ResumeBuilder/action';
import { validateResume } from './ResumeBuilder/customResumeValidation';
import { resumeBuilderSelector } from './ResumeBuilder/selector';
import { profileBuilderSelector } from './selector';
import { toast } from 'react-toastify';
import { setQaDataList } from './QandA/slice';
import { ModalBox } from '../common/Modal/ModalBox';
import { setCurrentTab, set_login_roles_flags, set_profile_builder_flags } from './slice';
import { setResumeBuilderData } from './ResumeBuilder/slice';
import { setLoading } from '../common/Loader/slice';
import Spinner from '../common/Spinner/spinner';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [registrationErrors, setRegistrationErrors] = useState({});
  const [emergencyContactErrors, setEmergencyContactErrors] = useState({});
  const [referencesErrors, setReferencesErrors] = useState([]);
  const [aboutUsErrors, setAboutUsErrors] = useState({});
  const [workHistoryErrors, setWorkHistoryErrors] = useState([]);
  const [educationErrors, setEducationErrors] = useState([]);
  const [resumeBuilderError, setResumeBuilderError] = useState({});
  const [preferenceErrors, setPreferenceErrors] = useState({});
  const [qandAErrors, setQandAErrorss] = useState({});
  const dispatch = useDispatch();
  const config = useConfiguration();
  const storage = useSessionStorage();
  const validate = useValidation();
  const navigate = useNavigate();
  const { activeTab, error, errorMessage } = useSelector(
    profileBuilderSelector
  );
  const { data } = useSelector(personalInfoSelector);
  const resumeForm = useSelector(resumeBuilderSelector);
  const {categories, resumeBuilderData} = useSelector(resumeBuilderSelector);
  const preferenceForm = useSelector(preferenceSelector);
  const { profileFlag, globalJobCategory } = useSelector(loginSelector);
  const { formdata } = useSelector(qandAlInfoSelector);
  const login = storage.getItem('login');
  const profileBuilderTabFlags = storage.getItem('profileBuilderTabFlags');
  const profileBuilderTabFlagsFromAdmin = storage.getItem('profileBuilderTabFlagsFromAdmin');
  const profile_builder_fields = storage.getItem('profile_builder_fields');
  const login_roles_flags_list = storage.getItem('login_roles_flags_list');
  const successStage = activeTab === 'success';
  const inputTypes = storage.getItem('inputTypes');
  const [jobId, setJobId] = useState(1);
  const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
  const { profile_builder_flags, login_roles_flags, loadingFlag } = useSelector(profileBuilderSelector);

  // Profile builder flags comes to array format
  // const convert_profile_builder_flags = login?.profileBuilderRequiredfield ? login?.profileBuilderRequiredfield : [];
  const [convert_profile_builder_flags, set_convert_profile_builder_flags] = useState([])


  // Initilaize variables
  let spoken_Languages;
  let emergency_contact;
  let resume_upload;
  let specialties_year_of_experience;
  let certifications_upload_expire_date;
  let certifications_upload_alert;
  let high_level_of_education;
  let emr_ehr_systems;
  let preferred_workdays;
  let working_holiday_preferences;
  let covid_19
  let education;
  let work_history;
  let references;

  // Create an Object for respective names
  convert_profile_builder_flags.forEach(element => {
     if (element?.field === profile_builder_fields?.SpokenLanguages) {
      spoken_Languages = element
     }
     if (element?.field === profile_builder_fields?.EmergencyContact) {
      emergency_contact = element
     }
     if (element?.field === profile_builder_fields?.ResumeUpload) {
      resume_upload = element
     }
     if (element?.field === profile_builder_fields?.SpecialtiesExperience) {
      specialties_year_of_experience = element
     }
     if (element?.field === profile_builder_fields?.CertificationsUploadExpiredDateAndTogether) {
      certifications_upload_expire_date = element
     }
     if (element?.field === profile_builder_fields?.CertificationsUploadAlert) {
      certifications_upload_alert = element
     }
     if (element?.field === profile_builder_fields?.EducationLevel) {
      high_level_of_education = element
     }
     if (element?.field === profile_builder_fields?.EMREHRSystems) {
      emr_ehr_systems = element
     }
     if (element?.field === profile_builder_fields?.PreferredWorkdays) {
      preferred_workdays = element
     }
     if (element?.field === profile_builder_fields?.WorkingHolidayPreferences) {
      working_holiday_preferences = element
     }
     if (element?.field === profile_builder_fields?.COVID19VaccinationStatus) {
      covid_19 = element
     }
     if (element?.field === profile_builder_fields?.Education) {
      education = element
     }
     if (element?.field === profile_builder_fields?.WorkHistory) {
      work_history = element
     }
     if (element?.field === profile_builder_fields?.References) {
      references = element
     }
  });

  const profile_builder_flags_types = {
    spoken_Languages,
    emergency_contact,
    resume_upload,
    specialties_year_of_experience,
    certifications_upload_expire_date,
    certifications_upload_alert,
    high_level_of_education,
    emr_ehr_systems,
    preferred_workdays,
    working_holiday_preferences,
    covid_19,
    education,
    work_history,
    references
  }

  // Object store a profile builder redux
  useEffect(() => {
      if (config.baseUrl) {
      dispatch(set_profile_builder_flags(profile_builder_flags_types));
    }

  },[convert_profile_builder_flags])

  
  // Q & A Section Part Say Yes and Not Uploaded a Certificate Showing the Soft Alert bellow filterd:
  // Maping a data
  const qandAData = formdata.map((item) => item.fields);
  // including same array
  const combindSingleArray = qandAData.flat();
  // Filter a Selected Value
  const filterYes = combindSingleArray.filter((item) => item.selectedValue === "Yes");
  // Filter a isAttachment
  const filterAttachmentNeeded = filterYes.filter((item) => item.isAttachmentNeeded === true);
  // get a no of Attachments
  const noOfAttachmentsArray = filterAttachmentNeeded.map((item) => item.noOfAttachments.map((item) => item.name));
  // get a empty String value 
  const checkEmptyStringValue = noOfAttachmentsArray.map((item) => item.every(v => v === ""));
  // filter final Value true or false
  const finalValueQandA = checkEmptyStringValue.every((v => v === false));

  // While Not upload a certificate that time showing a alert box
  const [certificationNotUploadAlertBox, setCertificationNotUploadAlertBox] = useState(null);
  // While showing a alert box that time click ok button
  const [certificationAlertOk, setCertificationAlertOk] = useState(null);

  // Certification Modal Box Close
  const certificationModalCloseBtn = () => {
    setCertificationNotUploadAlertBox(null);
    setCertificationAlertOk(false);
    saveContinue(activeTab);
  }


  useEffect(() => {
    document.title = 'Profile Builder';
    if (login_roles_flags_list?.can_edit_profile_filter?.status === false) {
      navigateToTab('summary');
    } 
  }, []);

  useEffect(() => {
    if (config.baseUrl) {
      if (!login) {
        navigate('/');
      }
    }
  }, [config]);

      // profile not submitted flags (This flags initially comes to login API)
      const profileBuilderSubmittedFlags = {
        isJobInfoCompleted: login?.profileFlag?.isJobInfoCompleted,
        isPersonalInfoTabCompleted: login?.profileFlag?.isPersonalInfoTabCompleted,
        isPreferenceInfoCompleted: login?.profileFlag?.isPreferenceInfoCompleted,
        isProfileSubmitted: login?.profileFlag?.isProfileSubmitted,
        isQACompleted: login?.profileFlag?.isQACompleted
      }

  useEffect(() => {
    if (config.baseUrl) {
      // Profile Builder Flags
      set_convert_profile_builder_flags(login?.profileBuilderRequiredfield);
      // Profile builder Tab navigation tag red color flags
  
      if (login_roles_flags_list?.can_edit_profile_filter?.status === false) {
        console.log('IN');

      } else {
      console.log('OUT');



      if (!profileBuilderTabFlags?.isProfileSubmitted) {
        if (!profileBuilderTabFlags?.isPersonalInfoTabCompleted) {
          navigateToTab('personalInfo');
        } else if (!profileBuilderTabFlags?.isJobInfoCompleted) {
          navigateToTab('resumeBuilder');
        } else if (!profileBuilderTabFlags?.isPreferenceInfoCompleted) {
          navigateToTab('preference');
        } else if (login?.jobTypeInfo?.showQATab && !profileBuilderTabFlags?.isQACompleted) {
          navigateToTab('qa');
          if (!login?.profileFlag?.isProfileSubmitted && !login?.profileFlag?.isQACompleted && profileBuilderTabFlags?.isPreferenceInfoCompleted) {
            dispatch(
              getCurrentResumeBuilder({
                url: `${config.baseUrl}/Candidates/getjobinfo`,
                userId: login.candidateId,
              })
            ).then((res) => {
              dispatch(getDynamicFormData({ url: config.baseUrl, jobLevel: res?.jobCategoryIdList }));
            })
          }

        } else {
          navigateToTab('summary');
        }
      }


        if (
          profileFlag.isJobInfoCompleted === true &&
          profileFlag.isPersonalInfoTabCompleted === true &&
          profileFlag.isPreferenceInfoCompleted === true
        ) {
          config.baseUrl &&
            dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
              dispatch(
                getCurrentPersonalInfo({
                  url: `${config.baseUrl}/Candidates/getpersonalinfo`,
                  userId: login.candidateId,
                })
              );
            });
          dispatch(
            loadMasterDatas(
              config.baseUrl,
              login.jobTypeId,
              login.candidateUniqueId
            )
          ).then(() => {
            dispatch(
              getCurrentResumeBuilder({
                url: `${config.baseUrl}/Candidates/getjobinfo`,
                userId: login.candidateId,
              })
            );
          });
          config.baseUrl &&
            dispatch(getMasterData(config.baseUrl)).then(() => {
              dispatch(getPreference(config.baseUrl, login.candidateId));
            });
        }
      }


      }

  }, [config]);

  useEffect(() => {
    validatePersonalInfo();
    validateEmergencyContact();
    validateAboutus();
    if (!profileBuilderTabFlags) {
      sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(login?.isFromAdmin ? profileBuilderTabFlagsFromAdmin : profileBuilderSubmittedFlags));
      }
  }, [data]);

  const tabStyle = (tabName) => {
    return activeTab === tabName ? 'nav-item active' : 'nav-item';
  };
  const mobileTabStyle = (tabName) => {
    return activeTab === tabName ? ' active' : '';
  };


  const validatePersonalInfo = () => {
    const keys = Object.keys(data);
    const registrationResult = validate(
      'personalInfo',
      keys.map((key) => {
        return {
          name: key,
          data: data[key],
        };
      })
    );
    if (registrationResult.valid) {
      setRegistrationErrors({});
    } else {
      setRegistrationErrors(registrationResult.errors);
    }
    return registrationResult.valid;
  };
  const validateEmergencyContact = () => {
    const keys = Object.keys(data.emergencyContact);
    const emergencyResult = validate(
      'emergencyContact',
      keys.map((key) => {
        return {
          name: key,
          data: data.emergencyContact[key],
        };
      })
    );
    if (emergencyResult.valid) {
      setEmergencyContactErrors({});
    } else {
      setEmergencyContactErrors(emergencyResult.errors);
    }
    return emergencyResult.valid;
  };
  const validateReferences = () => {
    const validationResults = data.references.map((ref, index) => {
      let keys = Object.keys(ref);
      const anyFilled = keys.some((key) => ref[key]);
      //if(index === 0 || anyFilled) {
      if (anyFilled) {
        return validate(
          'references',
          keys.map((key) => {
            return {
              name: key,
              data: ref[key],
            };
          })
        );
      } else {
        return { valid: true };
      }
    });

    const valid = validationResults.every((result) => result.valid);
    if (valid) {
      setReferencesErrors([]);
    } else {
      setReferencesErrors(validationResults.map((result) => result.errors));
    }
    return valid;
  };

  const validateAboutus = () => {
    let aboutus = ['referenceSourceId', 'referenceSourceOther'];
    if (
      data['referenceSourceId'] &&
      data['referenceSourceId'].length > 0 &&
      data['referenceSourceId'][0].name !== 'Other'
    ) {
      aboutus = aboutus.filter((item) => item !== 'referenceSourceOther');
    } else if (!data['referenceSourceId']) {
      aboutus = aboutus.filter((item) => item !== 'referenceSourceOther');
    }
    const aboutusValidation = validate(
      'references',
      aboutus.map((key) => {
        return {
          name: key,
          data: data[key],
        };
      })
    );
    if (aboutusValidation.valid) {
      setAboutUsErrors({});
    } else {
      setAboutUsErrors(aboutusValidation.errors);
    }
    return aboutusValidation.valid;
  };

  const validatePreferences = () => {
    const preferenceKeys = Object.keys(preferenceForm);
    const validationResult = validate(
      'preferences',
      preferenceKeys.map((key) => {
        return {
          name: key,
          data: preferenceForm[key],
        };
      })
    );
    if (validationResult.valid) {
      setPreferenceErrors({});
    } else {
      setPreferenceErrors(validationResult.errors);
    }
    return validationResult.valid;
  };

  const validateNoResume = () => {
    if (resumeForm.data.noResume) {
      const workHistoryValidationResult = resumeForm.workHistory.map(
        (ref, index) => {
          const keys = Object.keys(ref);
          const anyFilled = keys.some((key) => ref[key]);
          if (index === 0 || anyFilled) {
            return validate(
              'workHistory',
              keys.map((key) => {
                return {
                  name: key,
                  data: ref[key],
                };
              })
            );
          } else {
            return {
              valid: true,
            };
          }
        }
      );
      const educationValidationResult = resumeForm.educationList.map(
        (ref, index) => {
          const keys = Object.keys(ref);
          const anyFilled = keys.some((key) => ref[key]);
          if (index === 0 || anyFilled) {
            return validate(
              'education',
              keys.map((key) => {
                return {
                  name: key,
                  data: ref[key],
                };
              })
            );
          } else {
            return {
              valid: true,
            };
          }
        }
      );
      const valid =
        workHistoryValidationResult.every((result) => result.valid) &&
        educationValidationResult.every((result) => result.valid);
      if (valid) {
        setEducationErrors([]);
        setWorkHistoryErrors([]);
      } else {
        setEducationErrors(
          educationValidationResult.map((result) => result.errors)
        );
        setWorkHistoryErrors(
          workHistoryValidationResult.map((result) => result.errors)
        );
      }
      return valid;
    } 
    else {
      return true;
    }
  };

  const validateQandA = () => {
    const errors = {};
    let valid = true;
    formdata.forEach((item) => {
      item.fields.forEach((val) => {
        if (inputTypes.CheckboxMulti === val.type) {
          if (val.required) {
            if (
              val.selectedValueOptions &&
              val.selectedValueOptions.length === 0
            ) {
              valid = false;
              errors[item.sectionId] = { [val.id]: 'This field is required' };
            }
          }
        } else if (inputTypes.YesNoRadio === val.type) {
          if (val.required) {
            if (val.selectedValue === null || val.selectedValue === '') {
              valid = false;
              errors[item.sectionId] = {
                ...errors[item.sectionId],
                [val.id]: 'This field is required',
              };
            }
          }
        }
        if (val?.isExpirationDateNeeded) {
          if (val?.noOfAttachments.length) {
            val?.noOfAttachments?.forEach(attachments => {
              if (attachments?.blobName) {
                if (!attachments?.expirationDate) {
                  valid = false;
                  errors[item.sectionId] = {
                    ...errors[item.sectionId],
                    [val.id]: {
                      ...errors[item.sectionId]?.[val.id],
                      [attachments?.id]: 'please select Expiration Date'
                    },
                  };

                } else if (moment(attachments.expirationDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                  valid = false;
                  errors[item.sectionId] = {
                    ...errors[item.sectionId],
                    [val.id]: {
                      ...errors[item.sectionId]?.[val.id],
                      [attachments?.id]: 'Expiration date should be greater than current date'
                    },
                  };
                }
              }
            })
          }
        }
      });
    });
    setQandAErrorss(errors);
    return valid;
  };



  const saveContinue = (currentTab, gotoNextTab = true) => {
    window.scrollTo(0, 0);
    dispatch(resetErrorStatus());
    let valid = true;
    const validation_array = [
      validateAboutus(),
      validatePersonalInfo(),
      validateEmergencyContact(),
      validateReferences()
    ]
  
    const validation_array_flag = validation_array.every(value => value === true);
  
    if (currentTab === 'personalInfo') {
      if (
        validateAboutus() &&
        validatePersonalInfo() &&
        validateEmergencyContact() &&
        validateReferences()
      ) {
        dispatch(
          savePersonalInfo({
            url: `${config.baseUrl}/Candidates/savepersonalinfo/`,
            candidateId: login.candidateId,
            userId: login.appUserId,
            gotoNextTab,
            flag: login?.jobTypeInfo?.showQATab,
          })
        );
      // Once Complted a Personal Info page change to flags
      // setIsProfileBuilderTabFlags({...isProfileBuilderTabFlags, isPersonalInfoTabCompleted: true})
      const isPersonalInfo = {
        ...profileBuilderTabFlags, 
        isPersonalInfoTabCompleted: true
      }
      sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(isPersonalInfo));
      } else {
        valid = false;
      }
    } else if (currentTab === 'resumeBuilder') {
      const resumeFormValid = validateResume(resumeForm, login.jobTypeInfo, profile_builder_flags);
      if (validateNoResume() && resumeFormValid.isValid) {
        // Certifications message true false
        let certificationAlertFlag = null;
        // While Certificate Not Uploaded Showing the Soft Alert
        let showAlert = true;
        for (let item of resumeForm.certifications.primary) {
          if (item.expirationDate === null && item.name !== "None" && showAlert) {
            showAlert = false;
            certificationAlertFlag = true
          }
        }

          // certifications alert
          if (certificationAlertFlag === true && profile_builder_flags?.certifications_upload_alert?.isRequired) {
            if (certificationAlertOk === false) {
              dispatch(
                saveResumeBuilder({
                  url: `${config.baseUrl}`,
                  candidateId: login.candidateId,
                  userId: login.appUserId,
                  gotoNextTab,
                  flag: login?.jobTypeInfo?.showQATab,
                })
              );
              setResumeBuilderError({});
              setCertificationAlertOk(null);
      // Once Complted a Personal Info page change to flags
      // setIsProfileBuilderTabFlags({...isProfileBuilderTabFlags, isJobInfoCompleted: true})
      const isResumeBuilder = {
        ...profileBuilderTabFlags, 
        isJobInfoCompleted: true
      }
      sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(isResumeBuilder));
            } 
            else {
              setCertificationNotUploadAlertBox(true);
              setCertificationAlertOk(false);
            }
          } 
          else {
            dispatch(
              saveResumeBuilder({
                url: `${config.baseUrl}`,
                candidateId: login.candidateId,
                userId: login.appUserId,
                gotoNextTab,
                flag: login?.jobTypeInfo?.showQATab,
              })
            );
            setResumeBuilderError({});
      // Once Complted a Personal Info page change to flags
      // setIsProfileBuilderTabFlags({...isProfileBuilderTabFlags, isJobInfoCompleted: true})
      const isResumeBuilder = {
        ...profileBuilderTabFlags, 
        isJobInfoCompleted: true
      }
      sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(isResumeBuilder));
          }  

        // Certifications alert end



      } else {
        setResumeBuilderError(resumeFormValid.errors);
        valid = false;
        return;
      }
    } else if (currentTab === 'preference') {
      if (validatePreferences()) {
        dispatch(
          savePreference({
            url: `${config.baseUrl}`,
            candidateId: login.candidateId,
            userId: login.appUserId,
            gotoNextTab,
            flag: login?.jobTypeInfo?.showQATab,
          })
        );
      // Once Complted a Personal Info page change to flags
      // setIsProfileBuilderTabFlags({...isProfileBuilderTabFlags, isPreferenceInfoCompleted: true})
      const isPreference = {
        ...profileBuilderTabFlags, 
        isPreferenceInfoCompleted: true
      }
      sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(isPreference));
      } else {
        valid = false;
      }
    } else if (currentTab === 'qa') {
      if (validateQandA()) {

        if (finalValueQandA === false) {
          if (certificationAlertOk === false) {
            setCertificationNotUploadAlertBox(null);
            dispatch(
              saveQandADetail({
                url: `${config.baseUrl}`,
                candidateUniqueId: login.candidateUniqueId,
                gotoNextTab,
                flag: login?.jobTypeInfo?.showQATab,
              })
            );
            dispatch(setQaDataList([]))
            setCertificationAlertOk(null);
      // Once Complted a Personal Info page change to flags
      // setIsProfileBuilderTabFlags({...isProfileBuilderTabFlags, isQACompleted: true})
      const isQa = {
        ...profileBuilderTabFlags, 
        isQACompleted: true
      }
      sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(isQa));
          } else {
            setCertificationNotUploadAlertBox(true);
            setCertificationAlertOk(false);
            // toast.warn("Please come back and upload the files")
          }
        } else {
          dispatch(
            saveQandADetail({
              url: `${config.baseUrl}`,
              candidateUniqueId: login.candidateUniqueId,
              gotoNextTab,
              flag: login?.jobTypeInfo?.showQATab,
            })
          );
          dispatch(setQaDataList([]))
      // Once Complted a Personal Info page change to flags
      // setIsProfileBuilderTabFlags({...isProfileBuilderTabFlags, isQACompleted: true})
      const isQa = {
        ...profileBuilderTabFlags, 
        isQACompleted: true
      }
      sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(isQa));
        }
      } else {
        valid = false;
      }
    }
    return valid;
  };



  const cancelSummaryEdit = (currentTab) => {
    if (currentTab === 'personalInfo') {
      dispatch(
        getCurrentPersonalInfo({
          url: `${config.baseUrl}/Candidates/getpersonalinfo`,
          userId: login.candidateId,
        })
      );
    } else if (currentTab === 'resumeBuilder') {
      dispatch(
        getCurrentResumeBuilder({
          url: `${config.baseUrl}/Candidates/getjobinfo`,
          userId: login.candidateId,
        })
      );
    } else if (currentTab === 'preference') {
      dispatch(getPreference(config.baseUrl, login.candidateId));
    } else if (currentTab === 'qa') {
      dispatch(
        getDynamicFormData({
          url: config.baseUrl,
          candidateUniqueId: login.candidateUniqueId,
        })
      );
    }
  };

  const gotoPrevious = () => {
    window.scrollTo(0, 0);
    dispatch(previousTab(login?.jobTypeInfo?.showQATab));
  };

  const navigateToTab = (tabName) => {
    window.scrollTo(0, 0);
    dispatch(gotoTab(tabName));
  };


  const previousDisabled = activeTab === 'personalInfo';
  return (
    <div className={"page"}>
      <div className={"page-main"}>
        {/* <Loader /> */}

        {loadingFlag > 0 && <Spinner />}
        {!mobileAuth && <Header />}
        {!mobileAuth && (login.employerOvertureStatus !== 0 ? <Sidebar /> : login?.profileFlag.isProfileSubmitted && <Sidebar />)}
        { profile_builder_flags?.certifications_upload_alert?.isRequired &&
          certificationNotUploadAlertBox &&
          <ModalBox
            show={true}
            size="lg"
            center={true}
            label={<div><i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> Note :</div>}
            headerCloseButton={true}
            closebtnclassName="btn btn-primary"
            onlyClose={true}
            closeButtonName="Ok"
            showHide={certificationModalCloseBtn}
            children={
              <div className='d-flex flex-column'>
                <h5>
                  You can continue to create and submit your profile without uploading
                  the associated support documentation.
                </h5>
                <h5>
                  To fully complete your profile,
                  you will need to come back soon and upload documentation where
                  necessary so that our employers have all of your qualifications and
                  support documentation in your profile for their review.
                </h5>
              </div>
            }
          />
        }
        <div className={mobileAuth ? 'main-content mt-0 main-background' : login.employerOvertureStatus !== 0 ? 'main-content app-content mt-0 main-background' : !login?.profileFlag.isProfileSubmitted ? 'main-content mt-0 main-background' : 'main-content app-content mt-0 main-background'}>
          <div className="side-app">
            <div className={mobileAuth ? "main-container container" : login.employerOvertureStatus !== 0 ? 'main-container container-fluid' : !login?.profileFlag.isProfileSubmitted ? 'main-container container' : 'main-container container-fluid'}>
              <PageHeader previous={'Dashboard'} current={'Profile Builder'} />
              {
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {!successStage && (
                        <>
                          <div className="card-header border-bottom-0">
                            <h3 className="card-title">Profile Builder</h3>
                          </div>
                          <span style={{ padding: '0px 25px', fontWeight: 'bolder', fontSize: '1.5rem' }}>{globalJobCategory}</span>
                        </>
                      )}
                      {error && (
                        <>
                          <div
                            className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            data-bs-autohide="false"
                          >
                            <div className="d-flex col-6">
                              <div className="toast-body">
                                {errorMessage ||
                                  'Save error! Please try again.'}
                              </div>
                            </div>
                          </div>
                          <div>&nbsp;</div>
                        </>
                      )}
                      <div className="card-body">
                        <div
                          id="smartwizard-3"
                          className={'sw-main sw-theme-dots'}
                        >





                        { login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                          <>
                          {/* step form 1-mobile style */}
                          {!successStage && (
                            <ul className='builder-progress-bar d-md-none'>
                              <li
                                onFocus={(e) => e.preventDefault()}
                                onClick={(event) => {
                                  event.preventDefault();
                                  profileFlag &&
                                    profileFlag.isPersonalInfoTabCompleted &&
                                    navigateToTab('personalInfo');
                                }}
                                className={mobileTabStyle('personalInfo')} >
                                <span 
                                className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isPersonalInfoTabCompleted ? 'flagRed' : 'flagBlue'}` : 'flagBlue'}
                                ></span>
                                <a
                                  href="#"
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isPersonalInfoTabCompleted ? 'flagRedActive' : 'flagGreen'}` : ''}
                                >
                                  Personal Info
                                </a>
                              </li>
                              <li
                                onFocus={(e) => e.preventDefault()}
                                onClick={(event) => {
                                  event.preventDefault();
                                  profileFlag &&
                                    profileFlag.isJobInfoCompleted &&
                                    navigateToTab('resumeBuilder');
                                }}
                                className={mobileTabStyle('resumeBuilder')} >
                                <span
                                className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isJobInfoCompleted ? 'flagRed' : 'flagBlue'}`: 'flagBlue'}
                                ></span>
                                <a
                                  href="#"
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isJobInfoCompleted ? 'flagRedActive' : 'flagGreen'}`: ''}
                                >
                                  Resume Builder
                                </a>
                              </li>
                              <li
                                onFocus={(e) => e.preventDefault()}
                                onClick={(event) => {
                                  event.preventDefault();
                                  profileFlag &&
                                    profileFlag.isPreferenceInfoCompleted &&
                                    navigateToTab('preference');
                                }}
                                className={mobileTabStyle('preference')}>
                                <span
                                 className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isPreferenceInfoCompleted ? 'flagRed' : 'flagBlue'}` : 'flagBlue'}
                                ></span>
                                <a
                                  href="#"
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isPreferenceInfoCompleted ? 'flagRedActive' : 'flagGreen'}` : ''}
                                >
                                  Preferences
                                </a>
                              </li>
                              {login?.jobTypeInfo?.showQATab && (
                                <li
                                  onFocus={(e) => e.preventDefault()}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    profileFlag &&
                                      profileFlag.isQACompleted &&
                                      navigateToTab('qa');
                                  }}
                                  className={mobileTabStyle('qa')}>
                                  <span
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isQACompleted ? 'flagRed' : 'flagBlue'}` : 'flagBlue'}
                                  ></span>
                                  <a
                                    href="#"
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isQACompleted ? 'flagRedActive' : 'flagGreen'}` : ''}
                                  >
                                    Q&A
                                  </a>
                                </li>
                              )}
                              {login?.jobTypeInfo?.showQATab ? (
                                <li
                                  onFocus={(e) => e.preventDefault()}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    profileFlag &&
                                      profileFlag.isPersonalInfoTabCompleted &&
                                      profileFlag.isJobInfoCompleted &&
                                      profileFlag.isPreferenceInfoCompleted &&
                                      profileFlag.isQACompleted &&
                                      navigateToTab('summary');
                                  }}
                                  className={mobileTabStyle('summary')}>
                                  <span
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isProfileSubmitted ? ' flagRed' : 'flagBlue'}`: 'flagBlue'}
                                  ></span>
                                  <a
                                    href="#"
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isProfileSubmitted ? 'flagRedActive' : 'flagGreen'}`: ''}
                                  >
                                    Summary
                                  </a>
                                </li>
                              ) : (
                                <li
                                  onFocus={(e) => e.preventDefault()}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    profileFlag &&
                                      profileFlag.isPersonalInfoTabCompleted &&
                                      profileFlag.isJobInfoCompleted &&
                                      profileFlag.isPreferenceInfoCompleted &&
                                      navigateToTab('summary');
                                  }}
                                  className={mobileTabStyle('summary')} >
                                  <span
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isProfileSubmitted ? 'flagRed' : 'flagBlue'}`: 'flagBlue'}
                                  ></span>
                                  <a
                                    href="#"
                                    className={!profileBuilderTabFlags?.isProfileSubmitted ? `${!profileBuilderTabFlags?.isProfileSubmitted ? 'flagRedActive' : 'flagGreen'}`: ''}
                                  >
                                    Summary
                                  </a>
                                </li>
                              )}
                            </ul>
                          )} 
                          {/* step form 2-desktop style */}
                          {!successStage && (
                            <ul className={'nav nav-tabs step-anchor d-md-flex d-none'}>
                              <li className={tabStyle('personalInfo')}>
                                <a
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `nav-link ${!profileBuilderTabFlags?.isPersonalInfoTabCompleted ? 'flagRed flagLink' : 'flagBlue'}` : 'nav-link flagBlue'}
                                 
                                  href="#"
                                  onFocus={(e) => e.preventDefault()}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    profileFlag &&
                                      profileFlag.isPersonalInfoTabCompleted &&
                                      navigateToTab('personalInfo');
                                  }}
                                >
                                  Personal Info
                                </a>
                              </li>
                              <li className={tabStyle('resumeBuilder')}>
                                <a
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `nav-link ${!profileBuilderTabFlags?.isJobInfoCompleted ? 'flagRed flagLink' : 'flagBlue'}`: 'nav-link flagBlue'}
                                  
                                  href="#"
                                  onFocus={(e) => e.preventDefault()}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    profileFlag &&
                                      profileFlag.isJobInfoCompleted &&
                                      navigateToTab('resumeBuilder');
                                  }}
                                >
                                  Resume Builder
                                </a>
                              </li>
                              <li className={tabStyle('preference')}>
                                <a
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `nav-link ${!profileBuilderTabFlags?.isPreferenceInfoCompleted ? 'flagRed  flagLink' : 'flagBlue'}` : 'nav-link flagBlue'}
                                  
                                  href="#"
                                  onFocus={(e) => e.preventDefault()}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    profileFlag &&
                                      profileFlag.isPreferenceInfoCompleted &&
                                      navigateToTab('preference');
                                  }}
                                >
                                  Preferences
                                </a>
                              </li>
                              {login?.jobTypeInfo?.showQATab && (
                                <li className={tabStyle('qa')}>
                                  <a
                                  className={!profileBuilderTabFlags?.isProfileSubmitted ? `nav-link ${!profileBuilderTabFlags?.isQACompleted ? 'flagRed flagLink' : 'flagBlue'}` : 'nav-link flagBlue'}
                                  
                                  href="#"
                                    onFocus={(e) => e.preventDefault()}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      profileFlag &&
                                        profileFlag.isQACompleted &&
                                        navigateToTab('qa');
                                    }}
                                  >
                                    Q&A
                                  </a>
                                </li>
                              )}
                              {login?.jobTypeInfo?.showQATab ? (
                                <li className={tabStyle('summary')}>
                                  <a
                                    className={!profileBuilderTabFlags?.isProfileSubmitted ? `nav-link ${!profileBuilderTabFlags?.isProfileSubmitted ? 'flagRed  flagLink' : 'flagBlue'}`: 'nav-link flagBlue'}
                                    
                                    href="#"
                                    onFocus={(e) => e.preventDefault()}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      profileFlag &&
                                        profileFlag.isPersonalInfoTabCompleted &&
                                        profileFlag.isJobInfoCompleted &&
                                        profileFlag.isPreferenceInfoCompleted &&
                                        profileFlag.isQACompleted &&
                                        navigateToTab('summary');
                                    }}
                                  >
                                    Summary
                                  </a>
                                </li>
                              ) : (
                                <li className={tabStyle('summary')}>
                                  <a
                                    className={!profileBuilderTabFlags?.isProfileSubmitted ? `nav-link ${!profileBuilderTabFlags?.isProfileSubmitted ? 'flagRed  flagLink' : 'flagBlue'}`: 'nav-link flagBlue'}
                                     
                                    href="#"
                                    onFocus={(e) => e.preventDefault()}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      profileFlag &&
                                        profileFlag.isPersonalInfoTabCompleted &&
                                        profileFlag.isJobInfoCompleted &&
                                        profileFlag.isPreferenceInfoCompleted &&
                                        navigateToTab('summary');
                                    }}
                                  >
                                    Summary
                                  </a>
                                </li>
                              )}
                            </ul>
                          )}
</> : ''
}




                          <div className={'tab-content sw-container'}>
                            {successStage && <SaveSuccess />}
                            {activeTab === 'personalInfo' && (
                              <div id={'step-personalInfo'}>
                                <PersonalInfo
                                  registrationErrors={registrationErrors}
                                  emergencyContactErrors={
                                    emergencyContactErrors
                                  }
                                  referencesErrors={referencesErrors}
                                  aboutUsErrors={aboutUsErrors}
                                />
                              </div>
                            )}
                            {activeTab === 'resumeBuilder' && (
                              <div id={'step-resumeBuilder'}>
                                <ResumeBuilder
                                  resumeBuilderError={resumeBuilderError}
                                  educationErrors={educationErrors}
                                  workHistoryErrors={workHistoryErrors}
                                />
                              </div>
                            )}
                            {activeTab === 'preference' && (
                              <div id={'step-preference'}>
                                <Preference
                                  preferenceErrors={preferenceErrors}
                                />
                              </div>
                            )}
                            {activeTab === 'qa' && login?.jobTypeInfo?.showQATab && (
                              <div id={'step-qa'}>
                                <QandA qandAErrors={qandAErrors} />
                              </div>
                            )}
                            {activeTab === 'summary' && (
                              <div id={'step-summary'}>
                                <Summary
                                  registrationErrors={registrationErrors}
                                  emergencyContactErrors={
                                    emergencyContactErrors
                                  }
                                  referencesErrors={referencesErrors}
                                  resumeBuilderError={resumeBuilderError}
                                  educationErrors={educationErrors}
                                  workHistoryErrors={workHistoryErrors}
                                  preferenceErrors={preferenceErrors}
                                  aboutUsErrors={aboutUsErrors}
                                  cancel={cancelSummaryEdit}
                                  saveContinue={saveContinue}
                                  qandAErrors={qandAErrors}
                                />
                              </div>
                            )}
                            {!successStage && activeTab !== 'summary' && (
                              <>
                                <div className='d-flex justify-content-between flex-wrap'>
                                  <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-left">
                                    <div
                                      className="btn-group me-2 sw-btn-group"
                                      role="group"
                                    >
                                      <button
                                        className={`btn btn-danger sw-btn-prev ${previousDisabled ? 'disabled' : ''
                                          }`}
                                        type="button"
                                        onClick={gotoPrevious}
                                      >
                                        Previous
                                      </button>
                                    </div>
                                  </div>
                                  <div className="d-flex btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                                    <div
                                      className="btn-group me-2 sw-btn-group"
                                      role="group"
                                    >
                                      <button
                                        className="btn btn-primary sw-btn-next"
                                        type="button"
                                        onClick={() => {
                                          saveContinue(activeTab)
                                        }}
                                      >
                                        Save and Continue
                                      </button>
                                    </div>
                                    <div
                                      className="btn-group mr-2 sw-btn-group-extra"
                                      role="group"
                                    >
                                      <button className="btn btn-danger">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>


                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
