import { setBankDetail, setError, resetError, setMasterData,setPaymentMode, setResult, resetResult,setOnlinePaymentDetail,setMasterOnlineAccountTypeData,setOnlinePaymentError,resetOnlinePaymentError,setOnlinePaymentResult,resetOnlinePaymentResult} from './slice';
import { setLoading } from '../../components/common/Loader/slice';
import requestUtil from '../../helpers/requestUtil';
import { toast } from 'react-toastify';

export const updateBankDetail = (data) => {
    return async dispatch => {
        dispatch(setBankDetail(data));
    };
}

export const resetErrorStatus = () => {
    return async dispatch => {
        dispatch(resetError());
    };
}

export const resetResultMsg = () => {
    return async dispatch => {
        dispatch(resetResult())
    }
}

export function getCurrentUserBankDetail({ url, userId }) {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}/${userId}`, 'GET');
            // console.log('----res--==', response);
            dispatch(setBankDetail(response[0]));
        } catch (error) {
            dispatch(setError(error.message))
        }
        dispatch(setLoading(false));
    };
}

export function loadMasterData(baseUrl) {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const accountTypes = await requestUtil(`${baseUrl}/Dropdown/getaccounttypes`, 'GET');

            dispatch(setMasterData({ accountTypes }));
        } catch (error) {
            dispatch(setError(error.message))
        }
        dispatch(setLoading(false));
    };
}
export function loadPaymentmode(baseUrl) {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const PaymentMode = await requestUtil(`${baseUrl}/Dropdown/getpaymentmodes`, 'GET');
            console.log('PaymentMode',PaymentMode);
            dispatch(setPaymentMode({ PaymentMode }));
        } catch (error) {
            dispatch(setError(error.message))
        }
        dispatch(setLoading(false));
    };
}
export const saveBankDetail = ({ url, candidateId, userId,paymentMode,onlineAccountType,paymentModeText }) => {
    console.log('save call');
    return async (dispatch, getState) => {
     
        const { data } = getState().bankDetail;
        const newData = { ...data }
        newData.accountType = newData.accountType[0]?.id;
        newData.appUserId = userId;
        newData.candidateId = candidateId;
        newData.paymentMode = paymentMode;
        newData.paymentModeText
        = paymentModeText;
        newData.onlineAccountType = onlineAccountType;         
        try {
            dispatch(setLoading(true));
           const response = await requestUtil(`${url}`, 'POST', { ...newData });           
           
           if (response && (response.message === 'Updated' || response.message === 'Saved')) {            
            dispatch(setResult(response));
           }
           dispatch(setLoading(false));
           return response;
        } catch (error) {
            dispatch(setError(error.message))
        }
        dispatch(setLoading(false));
    }
}

export function loadMasterOnlineAccountTypeData(baseUrl) {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const onlineAccountTypes = await requestUtil(`${baseUrl}/Dropdown/getonlineaccounttypes`, 'GET'); 
            console.log(onlineAccountTypes);           
            dispatch(setMasterOnlineAccountTypeData({ onlineAccountTypes }));
        } catch (error) {
            dispatch(setOnlinePaymentError(error.message))
        }
        dispatch(setLoading(false));
    };
}

export const resetOnlinePaymentErrorStatus = () => {
    return async dispatch => {
        dispatch(resetOnlinePaymentError());
    };
}

export const resetOnlinePaymentResultMsg = () => {
    return async dispatch => {
        dispatch(resetOnlinePaymentResult())
    }
}

export function getCurrentUserOnlinePaymentDetail({ url, userId }) {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}/${userId}`, 'GET');
            // console.log('----res--==', response);
            dispatch(setOnlinePaymentDetail(response[0]));
        } catch (error) {
            dispatch(setOnlinePaymentError(error.message))
        }
        dispatch(setLoading(false));
    };
}

export const saveOnlinePaymentDetail = ({ url, candidateId, userId ,paymentMode,onlineAccountType,paymentModeText, socialSecurityNumber}) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        const { paymentData } = getState().bankDetail;
        const newData = { ...paymentData,"socialSecurityNumber":socialSecurityNumber }
        //newData.accountType = newData.accountType[0].id;
        newData.appUserId = userId;
        newData.candidateId = candidateId;
        newData.paymentMode = paymentMode;
        newData.onlineAccountType = onlineAccountType;
        newData.paymentModeText
        = paymentModeText;

        try {
           const response = await requestUtil(`${url}`, 'POST', { ...newData });
        
           if (response && (response.message === 'Updated' || response.message === 'Saved')) {
            dispatch(setOnlinePaymentResult(response));
           }
           dispatch(setLoading(false));
           return response;

        } catch (error) {
            dispatch(setOnlinePaymentError(error.message))
           
        }
        dispatch(setLoading(false));
    }
}

export const updatePaymentDetail = (data) => {
    return async dispatch => {
        dispatch(setOnlinePaymentDetail(data));
    };
}