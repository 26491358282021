import WorkHistory from "./workHistory";
import Education from "./education";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNoResume, updateResumeFile, uploadResume, deleteResumeFile } from "./action";
import { resumeBuilderSelector } from "./selector";
import useConfiguration from "../../../hooks/useConfiguration";
import useSessionStorage from "../../../hooks/useSessionStorage";
import { profileBuilderSelector } from "../selector";
import { setError, setLoadingFlagDecrement, setLoadingFlagIncrement } from "../slice";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { errors, educationErrors, workHistoryErrors } = props;
    const dispatch = useDispatch();
    const { data, resumeDownloadLink } = useSelector(resumeBuilderSelector);
    const { profile_builder_flags } = useSelector(profileBuilderSelector);
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    const noResume = data && data.noResume;
    const resumeFileExists = data && data.resumeFile && data.resumeFile.name && data.resumeFile.name.length > 0;
    const clickNoResume = () => {
        dispatch(updateNoResume(!data.noResume));
    }
    const deleteResume = (event) => {
        event.preventDefault();
        if (window.confirm("Do you want to delete?") === true) {
            dispatch(deleteResumeFile(config.baseUrl, login.candidateId, login.appUserId, data.resumeFile.name)).then(() => {
                alert("Uploaded file is deleted");
            });
        }
    }
    const fileChange = (e) => {
        const file = e.target.files[0];
        const resumeFile = {
            name: file.name,
        }
        dispatch(updateResumeFile(resumeFile));
        dispatch(uploadResume(config.baseUrl, login.candidateId, file));
    }

    const downloadUploadDoc = async () => {
        const requestHeaders = {
            'Content-Type': 'application/json',
            'Authorization': login.accessToken  ? `Bearer ${login.accessToken }` : '',
            'Website-Domain-Name': window.location.hostname === 'localhost' ? 'nursejobdev.overturexl.com' : window.location.hostname,
        };
        const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
        try {
        dispatch(setLoadingFlagIncrement())
        if(mobileAuth){
            const response = await fetch(`${config.baseUrl}/Candidates/get-candidate-resume-download-mobilebrowser`, {
                headers:{...requestHeaders},
                method:"GET"
            });
            const url = await response.json();
            window.location.href = `${url}?${data.resumeFile.displayToName || data.resumeFile.name}`
        } else {
            const response = await fetch(`${config.baseUrl}/Candidates/get-candidate-resume-download`, {
                headers:{...requestHeaders},
                method:"GET"
            });
            if(response){
                const blob = await response.blob();
                let downnloadURL = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = downnloadURL;
                a.download = data.resumeFile.displayToName || data.resumeFile.name;
                a.click();
            }
        }
        } catch (error) {
            dispatch(setError(error.message))
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
    };
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <form>
                            <div>Resume File{profile_builder_flags?.resume_upload?.isRequired ? '*' : '' }</div>
                            {
                                !resumeFileExists &&
                                <>
                                    <input className="form-control" type="file"
                                        accept={"application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                        disabled={noResume}
                                        onChange={fileChange} />
                                    {errors['resume'] && errors['resume'].map(error => (
                                        <div className="invalid-input">{error}</div>))}
                                </>
                            }

                            {/* href={`${config.baseUrl}/Candidates/getresumefile/${login.candidateId}`} */}
                            {
                                resumeFileExists &&
                                <>
                                    <a  style={{cursor:"pointer"}}
                                    onClick={downloadUploadDoc}
                                           target="_blank" 
                                        // onClick={downloadUploadDoc}
                                        // className="resume-link " 
                                        // download
                                        >
                                        <i>{data.resumeFile.displayToName || data.resumeFile.name} -</i>
                                        <i className="mdi mdi-arrange-bring-forward" data-bs-toggle="tooltip" title=""
                                            data-bs-original-title="mdi-arrange-bring-forward"
                                            aria-label="mdi-arrange-bring-forward"></i>
                                    </a>
                                    &nbsp;
                                    <a href="#" onClick={deleteResume}>
                                        <i className="mdi mdi-delete" data-bs-toggle="tooltip" title=""
                                            data-bs-original-title="mdi-delete" aria-label="mdi-delete"></i>
                                    </a>
                                </>
                            }
                        </form>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12" htmlFor="chknoresume">
                    <div className="form-group">
                        <div>&nbsp;</div>
                        <div className="custom-control custom-checkbox">
                            <input className="custom-control-input custom-control-input-danger" type="checkbox"
                                id="chknoresume" onClick={clickNoResume} checked={noResume}
                                disabled={resumeFileExists} />
                            <label htmlFor="chknoresume" className="custom-control-label"><h4><b>No Resume</b>
                            </h4></label>
                        </div>
                    </div>
                </div>
            </div>
            {noResume && <WorkHistory errors={workHistoryErrors} />}
            {noResume && <Education errors={educationErrors} />}
        </>
    )
}
