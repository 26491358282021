export const addSSNFormat = value => {
    const digits = value.replace(/\D/g, '');

    // Split the input into groups based on the desired format
    const match = digits.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);

    // Join the groups with hyphens
    if (match) {
      return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
    }

    return value;
}