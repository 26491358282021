import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSessionStorage from "../../../hooks/useSessionStorage";
import DropDown from "../../common/TextField/dropDown";
import {getLevelBasedAPIDatas, resetLevelBasedDatasList, resetLevelBasedDropdown, updateCategory } from "./action";
import { resumeBuilderSelector } from "./selector";
import useConfiguration from "../../../hooks/useConfiguration";

export default (props) => {
    const dispatch = useDispatch();
    const config = useConfiguration();
    const { categories, masterDatas } = useSelector(resumeBuilderSelector);
    const storage = useSessionStorage();
    const login = storage.getItem("login");

    const updateCategories = (target) => {
        const value = target.value;
        dispatch(updateCategory(value));
        // dispatch(resetLevelBasedDropdown())
        // dispatch(resetLevelBasedDatasList())
    };

    useEffect(()=> {
        if(login.jobTypeInfo.showCertifications && login.jobTypeInfo.showEmr && login.jobTypeInfo.showSpecialties) {
         if(config.baseUrl && categories?.length && categories[0].id){
            config.baseUrl &&
            dispatch(getLevelBasedAPIDatas(config.baseUrl, login.jobTypeId,login.candidateUniqueId, [categories[0].id]))
        }
    }
    },[config.baseUrl, categories])
    return (
        <div className="card">
            <div className="col-lg-12 col-md-12">
                <div className="row">
                    <div className="card-header">
                        <div className="col-lg-4 col-md-4">
                        {   
                                login.jobTypeInfo.jobPositionLabel && 
                                            <>  
                            <h4> {login.jobTypeInfo.jobPositionLabel }*</h4>
                            </>
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <DropDown name={'categories'}
                                label={login?.jobTypeId === 1 ? 'Please select the highest nursing level that you are qualified as*' : ''}
                                id={'categories'}
                                options={masterDatas.jobCategories} multiple={false} 
                                // name={'category'}
                                value={categories}
                                updateValue={updateCategories} isObject={true}
                                errors={props.errors ? props.errors['categories'] : []} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
