import {useState} from "react";
import './index.css';
import RegistrationForm from "../ProfileBuilder/PersonalInfo/registrationForm";
import Modal from "./modal";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import { useSelector } from "react-redux";
import useSessionStorage from "../../hooks/useSessionStorage";
import moment from "moment";

export default (props) => {
    const { login_roles_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const editRegistration = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('personalInfo')
        }
    };
    const saveRegistration = (event) => {
        event.preventDefault();
        if (props.saveContinue('personalInfo', false)) {
            setShowModal(!showModal);
        }
    };
    return (<div className="col-md-12 col-xl-12">
        <div className="card">
            {
                showModal &&
                <Modal label={"Registration Form"} showHide={editRegistration} saveFunction={saveRegistration}>
                    <RegistrationForm errors={props.errors}/>
                </Modal>
            }
            <div className="col-lg-12 col-md-12 ">
                <div className="row">
                    <div className="card-header">
                        <h4 className="text-wrap mr-2">{props.title || 'Summary'}</h4>
                        {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                        <a id="EditRegisterForm" href="#" onClick={editRegistration}>
                            <i className="fe fe-edit" title="Edit Registration Form"></i>
                        </a> : ''
                        }
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <label htmlFor="exampleInputname">{props.firstName} {props.lastName}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <label htmlFor="exampleInputname">{props.email}</label>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label htmlFor="exampleInputname">{props.streetAddress}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <label htmlFor="exampleInputname1">{props.zipcode}</label>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label htmlFor="exampleInputname">{props.city}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <label htmlFor="exampleInputname1">{props.stateName}</label>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label htmlFor="exampleInputname1">{props.country}</label>
                        </div>
                    </div>


                    <div className="row">
                    <div className="col-lg-5 col-md-4">
                    <label className="form-label"> Date Of Birth:</label>
                    <label htmlFor={props.dateOfBirth}> {props.dateOfBirth ? moment(props.dateOfBirth).format('MM/DD/yyyy') : ''}</label>
                    </div>
                    <div className="col-lg-4 col-md-4">
                    <label className="form-label"> Gender:</label>
                    {
                        props?.gender?.length ? 
                        props?.gender?.map((item) => (
                            <label>{item?.id ? item?.name : ''}</label>
                        )) : ''
                    }
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <label className="form-label"> Cell Number:</label>
                            <label htmlFor={props.cellPhoneNumber}> {props.cellPhoneNumber}</label>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label className="form-label"> Landline Phone Number:</label>
                            <label htmlFor={props.homePhoneNumber}> {props.homePhoneNumber}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <label className="form-label">Alternate Phone Number:</label>
                            <label htmlFor={props.alternatePhoneNumber}> {props.alternatePhoneNumber}</label>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-3">
                            <label className="form-label">Spoken Languages</label>
                            {
                                props.spokenLanguagesList.map(cat => (
                                    <div className="col-lg-12 col-md-4">
                                        <label htmlFor="exampleInputname1">{cat.name}</label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row mb-2">
                    <div className="col-lg-12 col-md-3">
                    <label className="form-label">Willing to travel or relocate ?</label>
                    <div className="col-lg-12 col-md-4">
                                        <label htmlFor="exampleInputname1">{props?.relocate === true ? "Yes" : "No"}</label>
                                    </div>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}
