export const serverToState = (serverData, masterDatas) => {
  const data = {};
  data.data = {
    noResume: serverData.noResume,
    resumeFile: {
      name: serverData.resumeFileName,
    },
  };
  data.workHistory = serverData?.workHistoryList?.map((work) => {
    return {
      employer: work.name,
      fromMonth: work.fromMonth,
      fromYear: work.fromYear,
      toMonth: work.toMonth,
      toYear: work.toYear,
    };
  });
  if (!data.workHistory || data.workHistory.length === 0) {
    data.workHistory = [
      {
        employer: '',
        fromMonth: '',
        fromYear: '',
        toMonth: '',
        toYear: '',
      },
    ];
  } else {
    data.data.noResume = true;
  }
  data.educationList = serverData.educationList;
  if (!data.educationList || data.educationList.length === 0) {
    data.educationList = [
      {
        name: '',
        fromMonth: '',
        fromYear: '',
        toMonth: '',
        toYear: '',
      },
    ];
  }
  data.emrehr = {};
  const primaryEmrehr = serverData?.emrehrSystemList?.filter(
    (emrehr) => emrehr.isOther === false
  );
  const otherEmrehr = serverData?.emrehrSystemList?.filter(
    (emrehr) => emrehr.isOther === true
  );
  const otherDropdown = masterDatas?.specialities?.find(
    (data) => data.name === 'Other'
  );
  const otherSkillId = otherDropdown && otherDropdown.id;
  data.emrehr.primary = primaryEmrehr
    ? primaryEmrehr.map((emrehr) => {
        const eh = masterDatas.emrehr.find((em) => em.id === emrehr.id);
        return { ...eh };
      })
    : [];
  data.emrehr.others = otherEmrehr
    ? otherEmrehr.map((emrehr) => {
        return emrehr.otherName;
      })
    : [''];
  if (!data.emrehr.others || data.emrehr.others.length === 0) {
    data.emrehr.others = [''];
  }
  data.categories = serverData?.jobCategoryIdList?.map((category) => {
    return { ...masterDatas.jobCategories.find((cat) => cat.id === category) };
  });
  data.specialities = serverData?.specialtyList?.map((speciality) => {
    return {
      skill: speciality.isOther ? otherSkillId : speciality.specialtyId,
      years: speciality.yearsOfExperience === 0 ? '' : speciality.yearsOfExperience,
      isOther: speciality.isOther,
      otherName: speciality.otherName,
    };
  });
  // if (!data.specialities || data.specialities.length === 0) {
  //     data.specialities = [{
  //         skill: '',
  //         years: '',
  //         isOther: false,
  //         otherName: ''
  //     }];
  // }
  const primarycert = serverData?.certificationsList?.filter(
    (cert) => cert.isOther === false
  );
  const othercert = serverData?.certificationsList?.filter(
    (cert) => cert.isOther === true
  );
  data.certifications = {};
  // data.certifications.primary = primarycert ? primarycert.map(cert => {
  //     return { ...masterDatas.certifications.find(em => em.id === cert.id) };
  // }) : [];
  data.certifications.primary = primarycert;
  data.certifications.primary = data?.certifications?.primary?.map((element) => {
    const masterDataList = masterDatas?.certifications?.find(
      (masterDataList) => masterDataList.id === element.id
    );

    return {
      ...masterDataList,
      expirationDate: element.expirationDate,
      fileName: element.otherName,
      disabled:element.expirationDate ? true : false,
      uploadedCertificatesList: element.uploadedCertificatesList
        ? element.uploadedCertificatesList
        : [{ id: 0, name: null, blobName: null }],
    };
  });
  data.certifications.others =
    othercert && othercert.length > 0
      ? othercert.map((cert) => {
          return cert.otherName;
        })
      : [''];
  data.educationLevel = serverData.levelofEducation;

  return data;
};
export const stateToServer = (stateData, candidateId, appUserId) => {
  const data = { candidateId, appUserId };
  data.levelofEducation = stateData.educationLevel;
  data.jobCategoryIdList = stateData.categories.map((cat) => cat.id);
  const primaryCert = stateData.certifications.primary.map((cert) => {
    return {
      id: cert.id,
      isOther: false,
      otherName: cert.fileName,
      expirationDate: cert.expirationDate ? cert.expirationDate : null,
      uploadedCertificatesList:
        !!cert.uploadedCertificatesList.length &&
        cert.uploadedCertificatesList[0].name
          ? cert.uploadedCertificatesList
          : [],
    };
  });
  const otherCert = stateData.certifications.others.map((cert) => {
    return {
      otherName: cert,
      isOther: true,
    };
  });
  data.certificationsList = [...primaryCert,
    //  ...otherCert
    ];
  const primaryEmrehr = stateData.emrehr.primary.map((emrehr) => {
    return {
      id: emrehr.id,
      isOther: false,
    };
  });
  const otherEmrehr = stateData.emrehr.others.map((emrehr) => {
    return {
      otherName: emrehr,
      isOther: true,
    };
  });
  data.emrehrSystemList = [...primaryEmrehr, 
    // ...otherEmrehr
  ];
  data.specialityList = stateData.specialities.map((speciality) => {
    return {
      specialtyId: speciality.isOther ? null : speciality.skill,
      yearsOfExperience: speciality.years === '' ? null : speciality.years,
      isOther: speciality.isOther,
      otherName: speciality.otherName,
    };
  });
  data.workHistoryList = stateData.workHistory.map((work) => {
    return {
      name: work.employer,
      fromMonth: work.fromMonth,
      fromYear: work.fromYear,
      toMonth: work.toMonth,
      toYear: work.toYear,
    };
  });
  data.educationList = stateData.educationList;
  data.noResume = stateData.data.noResume;
  return data;
};
