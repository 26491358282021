import requestUtil from '../../../helpers/requestUtil';
import { setLoading } from '../Loader/slice';
import * as Toasts from '../Toastify';
import { userNotifyList } from './slice';

export function getUserNotifyList(url, userNotifyJson) {
    return async (dispatch) => {
      dispatch(setLoading(true));
      requestUtil(`${url}/Notify/get-candidate-notify-list`, 'POST', userNotifyJson)
        .then((response) => {
          dispatch(setUserNotifyList(response));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
        });
        dispatch(setLoading(false));
    };
  }
  
  export const setUserNotifyList = (data) => {
    return async (dispatch) => {
      dispatch(userNotifyList(data));
    };
  };
  
  export const saveUserNotifyList = (url, userNotifyJson) => {
    return async (dispatch) => {
      dispatch(setLoading(true));
      requestUtil(`${url}/Notify/mark-read-user-notify?FeatureNotifyId=${userNotifyJson?.featuresNotifyId}`, 'POST', userNotifyJson)
        .then((response) => {
          Toasts.success(response.message);
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
        });
    };
  };
  