import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    chat: [],
    masterData: {
        loctionList: [],
        readList: [
        {name:"Read", value:1},
        {name:"Unread",value:0}
    ]
    },
    msgSendFlag: false,
    error: false,
    errorMessage: null,
    params: {
        "page": 0,
        "pageSize": 0,
        "sortOrder": "",
        "sortColumn": "",
        "webPageId": 0,
        "shiftUniqueId": null,
        "candidateUniqueId": "",
        "employerUniqueId": null,
        "locationId": 0,
        "fromDateRange": null,
        "toDateRange": null,
        "messageStatus": 0,
        'searchText': ''
    }
}

export const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setMessages: (state, action) => {
            state.data = action.payload
        },
        setError: (state, action) => {
            state.error = true;
            state.errorMessage = action.payload;
        },
        resetError: (state) => {
            state.error = false;
            state.errorMessage = null;
        },
        setParams: (state, action) => {
            state.params = action.payload;
        },
        setChat: (state, action) => {
            state.chat = action.payload
        },
        setMsgSendFlag: (state, action) => {
            state.msgSendFlag = action.payload
        },
        updateFilterParams:(state,{payload:{name,value}}) => {
            state.params[name] = value;
        },
    }
});


export const { setMessages, setError, resetError, setParams, setChat, setMsgSendFlag, updateFilterParams } = messagesSlice.actions;

export default messagesSlice.reducer;