import {useState} from "react";
import ReferenceForm from "../ProfileBuilder/PersonalInfo/referenceForm";
import Modal from "./modal";
import { useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import useSessionStorage from "../../hooks/useSessionStorage";

export default (props) => {
    const { login_roles_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const editReference = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('personalInfo');
        }
    };
    const saveReference = (event) => {
        event.preventDefault();
        if (props.saveContinue('personalInfo', false)) {
            setShowModal(!showModal);
        }
    };
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    
                    showModal && <Modal showHide={editReference} saveFunction={saveReference} label={"References"}>
                        <ReferenceForm errors={props.errors} aboutusError={props.aboutusError}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            <h4 className="text-wrap mr-2">Your References</h4>
                            {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                            <a id="EditReferences" href="#" onClick={editReference}>
                                <i className="fe fe-edit" title="Edit References"></i>
                            </a> : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        {
                            <>
                            {props?.references?.map(ref => <div className="row">
                                <div className="col-lg-3 col-md-3">
                                    <label htmlFor="exampleInputname1">{ref.firstName} {ref.lastName}</label>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <label htmlFor="exampleInputname1">{ref.phoneNumber}</label>
                                </div>
                            </div>)}
                            <div className="row">
                            <div className="col-lg-3 col-md-3">
                                    <label htmlFor="exampleInputname1">How did you hear about us?</label>
                                </div>
                                <div className="col-lg-2 col-md-2">
                                    <label htmlFor="exampleInputname1">{
                                     
                                     props?.referenceSourceId !== null ?

                                    (props && props?.referenceSourceId && props?.referenceSourceId?.length && props?.referenceSourceId[0]?.name === 'Other' )
                                        ? props.referenceSourceOther: props?.referenceSourceId[0]?.name
                                     
                                        : ''
                                     
                                     }</label>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
