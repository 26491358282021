import React, { useState, useEffect } from 'react'
import { Rating } from 'react-simple-star-rating';
import './ratings.css'

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const [rating, setRating] = useState(0);
    const [color, setColor] = useState('gray');
    const colors = ['#FF0000', '#FF0000', '#FFFF00', '#00FF00', '#00FF00']
    // useEffect(() => {

    //     if (props?.initialValue <= 2) {
    //         setColor('#FF0000')
    //     } else if (props?.initialValue > 2 && props?.initialValue < 4) {
    //         setColor('#FF0000')
    //     } else {
    //         setColor('#FF0000')
    //     }

    // }, [props?.initialValue])


    const handleRating = (rate) => {
        props?.getStarValue(rate);
        if (rate <= 2) {
            setColor('#FF0000')
        } else if (rate > 2 && rate < 4) {
            setColor('#FF0000')
        } else {
            setColor('#FF0000')
        }
    }
    const fillColorArray = [
        "#FF0000", // 0
        "#FF0000", // 0.25
        "#FF0000", // 0.5
        "#FF0000", // 0.75
        "#FF0000", // 1.0
        "#FF0000", // 1.25
        "#FF0000", // 1.5
        "#FF0000", // 1.75
        "#FF0000", // 2.0
        "#FFFF00", // 2.25
        "#FFFF00", // 2.5
        "#FFFF00", // 2.75
        "#FFFF00", // 3.0
        "#008000", // 3.25
        "#008000", // 3.5
        "#008000", // 3.75
        "#008000", // 4.0
        "#008000", // 4.25
        "#008000", // 4.75
        "#008000" // 5.0
      ];
      

      const finalColor = (value) => {
        if (value <= 2) {
            return  "#FF0000"
        } else if (value > 2 && value <= 3) {
           return  "#FFFF00"
        } else if (value >= 3 && value <= 5) {
            return "#008000"
        }
      }


    return (
        <>
            <div className='clearfix' />
            <div className='ratingspan'>
                <Rating
                    showTooltip={props?.showTooltip}
                    tooltipDefaultText='Your Rating'
                    initialValue={props?.initialValue ? props?.initialValue : 0}
                    onClick={handleRating}
                    ratingValue={rating}
                    // fillColor={color}
                    readonly={props?.readonly ? props?.readonly : false}
                    allowHalfIcon={props?.halfIcon ? props?.halfIcon : true}
                    allowHover={props?.hover ? props?.hover : true}
                    // fillColorArray={fillColorArray}
                    transition
                    allowFraction
                    size={props?.size}
                    fillColor={finalColor(props.initialValue)}
                />
            </div>
        </>

    )
}