import {useState} from "react";
import Modal from "./modal";
import PreferredDaysForm from "../ProfileBuilder/Preference/preferredDaysForm";
import { useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import useSessionStorage from "../../hooks/useSessionStorage";

export default (props) => {
    const { login_roles_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const editHoliday = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('preference');
        }
    };
    const saveHoliday = (event) => {
        event.preventDefault();
        if (props.saveContinue('preference', false)) {
            setShowModal(!showModal);
        }
    };
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal && <Modal label={"Preferred Work Days"} showHide={editHoliday} saveFunction={saveHoliday}>
                        <PreferredDaysForm  name ="workingHolidays" showModal = {showModal} />
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                                <h4 className="text-wrap mr-2">Working Holiday Preferences</h4>
                                {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                                <a id="EditWeek" href="#" onClick={editHoliday}>
                                    <i className="fe fe-edit" title="Edit Week Days"></i>
                                </a> : ''
                                }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="form-group">
                            {
                                props.preference === 0 && <label>No Holidays</label>
                            }
                            {
                                props.preference === 1 && <label>All Holidays</label>
                            }
                            {
                                props.preference === 2 && <label>Willing to work on the following holidays</label>
                            }
                        </div>
                        <div className="form-group">
                            {
                                props.options.map(option => (
                                    <div className="col-lg-4 col-md-4">
                                        <label
                                            htmlFor="exampleInputname1">{props.masterHolidays.find(hol => hol.id === option).name}</label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
