import { useState } from "react";
import React, { useEffect } from "react";
export default (props) => {
    

    return (
    
        
        <div className="col-md-12 col-xl-12">
            <div className="card">

                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                        <div className="col-lg-4 col-md-4 jobheader">
                                <b><h4 className="headerfontbold">Certifications, Licenses, Permits</h4></b>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <>  
                        <div className="row" >
                            <div className="col-lg-4 col-md-4">
                                <label className="form-label">Certifications, Licenses, Permits :</label></div>
                                {
                                    props?.certifications?.definedCertifications.map(cert => (
                                        <div className="row">
                                        <div className="col-lg-8 col-md-8"> <label>{cert.name}</label> </div> </div>
                                    ))
                                }
                           </div>
                           { props?.certifications?.otherCertifications.length ? <div className="row" >
                            <div className="col-lg-4 col-md-4">
                                <label className="form-label">Other Certifications, Licenses, Permits :</label>  </div>
                                {
                                    props?.certifications?.otherCertifications.map(cert => (
                                        <div className="row">
                                        <div className="col-lg-8 col-md-8">  <label>{cert.name}</label></div> </div>
                                    ))
                                }
                            </div>:''}
                           
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
                            
}
