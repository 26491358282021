import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const success = (msg, options = {}) => {
        return toast.success(msg, {
          ...options,
        });
}

export const error = (msg, options = {}) => {
    return toast.error(msg, {
      ...options,
    });
}

export const info = (msg, options = {}) => {
    return toast.info(msg, {
      ...options,
    });
}

export const warning = (msg, options = {}) => {
    return toast.warning(msg, {
      ...options,
    });
}