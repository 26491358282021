import moment from 'moment';
import React from 'react';
import useSessionStorage from '../../../hooks/useSessionStorage';

const DynamicForm = (props) => {
    const { fields, updateVal, sectionId, errors, fileUploade, downloadUrl, removeFile,updateDate , dataCopy, downloadUploadDoc} = props
    const storage = useSessionStorage();
    // console.log(downloadUrl);
    const inputTypes = storage.getItem('inputTypes');
    // console.log(inputTypes);

   
    return (
        <>
            {
                fields && inputTypes &&
                fields?.map((item,index) => (
                    <>
                        {
                            inputTypes.CheckboxMulti === item.type ? (<Checkbox data={item}
                                type={'CheckboxMulti'}
                                error={errors && errors?.[item.id] ? errors?.[item.id] : ''} 
                                sectionId={sectionId} updateDate={updateDate} removeFile={removeFile} downloadUrl={downloadUrl}
                                    updateVal={updateVal} fileUploade={fileUploade}  copyData = {dataCopy &&dataCopy?.[index]}
                                    downloadUploadDoc = {downloadUploadDoc}
                                />) :
                                inputTypes.YesNoRadio === item.type ? (<YesNoRadio data={item}
                                    type={'YesNoRadio'} error={errors && errors?.[item.id] ? errors?.[item.id] : ''}  
                                    sectionId={sectionId} updateDate={updateDate} removeFile={removeFile} downloadUrl={downloadUrl}
                                    updateVal={updateVal} fileUploade={fileUploade} copyData = {dataCopy && dataCopy?.[index]}
                                    downloadUploadDoc = {downloadUploadDoc}
                                    />)
                                    : ('')
                        }
                    </>
                ))
            }
        </>
    )
}
// fields, updateVal, sectionId, errors, fileUploade, downloadUrl, removeFile,updateDate
const YesNoRadio = ({ data, type, error,sectionId, updateDate ,removeFile,downloadUrl,updateVal,fileUploade,copyData,downloadUploadDoc}) => {
    const UploadFile = ({ type, sectionId, fieldId, fileId }) => {
        return (
            <>
                <input className="form-control"
                    type="file"
                    accept={"application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg"}
                    onChange={(e) => { fileUploade(e, type, sectionId, fieldId, fileId) }} />
            </>
        )
    }

    return (
        <div className={"row"}>
            <div className='expanel expanel-default'>
                <div className='expanel-body mb-0'>
                    {
                        <>
                            <div className='row'>
                                <div className='col-lg-7 col-md-7'>
                                    <span className=''><b>{data?.required ? `${data?.label}*` : data?.label}</b></span>
                                    {error && (typeof error) === 'object' ? "": <div className="invalid-input">{error}</div>}
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <div className='d-flex'>
                                        <label class="custom-control custom-radio">
                                            <input type="radio" class="custom-control-input" name={data?.name} value="yes"
                                                onChange={(event => updateVal(event.target, type, sectionId, data?.id))}
                                                checked={data?.selectedValue === "Yes"}
                                            />
                                            <span class="custom-control-label">Yes</span>
                                        </label>
                                        <label class="custom-control custom-radio ms-3">
                                            <input type="radio" class="custom-control-input" name={data?.name} value="no"
                                                onChange={(event => updateVal(event.target, type, sectionId, data?.id))}
                                                checked={data?.selectedValue === "No"}
                                            />
                                            <span class="custom-control-label">No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='row mt-3'>
                        {data?.isAttachmentNeeded &&
                            data?.noOfAttachments?.map(item => {
                                if (!item.name) {
                                    return <div className='col-lg-3 col-md-3 mb-5'>
                                        <UploadFile type={type} sectionId={sectionId} fieldId={data?.id} fileId={item?.id} />
                                    </div>
                                } else {
                                    return <div style={{width:"50vw"}} className='col-lg-3 col-md-3 mb-5'>
                                        {/* href={`${config.baseUrl}/Candidates/getresumefile/${login.candidateId}`} target="_blank"  */}
                                        {item?.blobName && <> <a
                                        style={{cursor:"pointer"}}
                                            // target="_blank" 
                                            onClick={(e) => { downloadUploadDoc(sectionId, data?.id, item?.id, item?.blobName,item?.name) }}
                                            rel="noreferrer" >
                                            <i>{item.name} -</i>
                                            <i className="mdi mdi-arrange-bring-forward" data-bs-toggle="tooltip" title=""
                                                data-bs-original-title="mdi-arrange-bring-forward"
                                                aria-label="mdi-arrange-bring-forward"></i>
                                        </a>
                                            &nbsp;
                                            <a href="#" onClick={(e) => { removeFile(sectionId, data?.id, item?.id) }}>
                                                <i className="mdi mdi-delete" data-bs-toggle="tooltip" title=""
                                                    data-bs-original-title="mdi-delete" aria-label="mdi-delete"></i>
                                            </a>
                                        {/* {error && (typeof error) === 'object'? <div className="invalid-input">{error[item?.id]}</div>:""} */}
                                        </>
                                        }
                                    </div>
                                }
                            })
                        }
                    </div>
                    <div className='row mt-3'>
                        {data?.isExpirationDateNeeded ? <p className='fw-bold'>Expiration Date:</p>:""}
                        {data?.isExpirationDateNeeded &&
                            data?.noOfAttachments?.map((item,index) => {
                                return (
                
                                    <div className='col-lg-3 col-md-3 mb-5'>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <label htmlFor={`expirationDate-${sectionId}-${data?.id}-${item.id}`}></label>
                                                <i
                                                className="fa fa-calculator tx-16 lh-0 op-6"
                                                style={{color:"#fff"}}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    document
                                                    .getElementById(`expirationDate-${sectionId}-${data?.id}-${item.id}`)
                                                    .showPicker();
                                                }}
                                                ></i>
                                            </div>
                                            <input 
                                                className="form-control"
                                                id= {`expirationDate-${sectionId}-${data?.id}-${item.id}`}
                                                type="date" 
                                                name={`expirationDate-${sectionId}-${data?.id}-${item.id}`}
                                                value={item?.expirationDate?moment(item?.expirationDate).format(
                                                        'YYYY-MM-DD'
                                                    ):""}
                                                disabled={!item?.blobName || (copyData && copyData?.noOfAttachments?.[index]?.expirationDate)}
                                                onChange={e => {
                                                    e.preventDefault()
                                                    const {value} = e.target
                                                   updateDate(value,sectionId, data?.id, item?.id )
                                                }}
                                            />
                                        </div>
                                        {error && (typeof error) === 'object'? <div className="invalid-input">{error[item?.id]}</div>:""}
                                    </div>
                                )
                            }) 
                        }
                    </div>
                 
                </div>
            </div>
        </div>
    )
}

const Checkbox = ({ data, type, error ,sectionId, updateDate ,removeFile,downloadUrl,updateVal,fileUploade,copyData,downloadUploadDoc }) => {
    console.log({data,copyData});
    const UploadFile = ({ type, sectionId, fieldId, fileId }) => {
        return (
            <>
                <input className="form-control"
                    type="file"
                    accept={"application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg"}
                    onChange={(e) => { fileUploade(e, type, sectionId, fieldId, fileId) }} />
            </>
        )
    }
    return (
        <div className={"row"}>
            {
                data?.options?.map((item, index) => (
                    <div className={""} key={index}>
                        <label className="custom-control custom-checkbox mt-4">
                            <input type="checkbox"
                                className="custom-control-input"
                                name={item.name}
                                value={item.id}
                                checked={data?.selectedValueOptions.indexOf(item.id) > -1}
                                onChange={(event) => updateVal(event.target, type, sectionId, data?.id)} />
                            <span className="custom-control-label">
                                {item.name}
                            </span>
                        </label>
                    </div>
                ))
            }
            {/* {error && <div className="invalid-input">{error}</div>} */}
            {error && (typeof error) === 'object' ? "": <div className="invalid-input">{error}</div>}

            {data?.isAttachmentNeeded &&
                data?.noOfAttachments?.map((item, index) => {
                    if (!item.name) {
                        return <div className='col-lg-3 col-md-3 mb-5'>
                            <UploadFile type={type} sectionId={sectionId} fieldId={data?.id} fileId={item?.id} />
                        </div>
                    } else {
                        return <div className='col-lg-3 col-md-3 mb-5' key={index}>
                            {item?.blobName && <> <a href={"#"}
                                onClick={(e) => { downloadUploadDoc(sectionId, data?.id, item?.id,item?.blobName,item?.name) }}>
                                <i>{item?.name} -</i>
                                <i className="mdi mdi-arrange-bring-forward" data-bs-toggle="tooltip" title=""
                                    data-bs-original-title="mdi-arrange-bring-forward"
                                    aria-label="mdi-arrange-bring-forward"></i>
                            </a>
                                &nbsp;
                                <a href="#" onClick={(e) => { removeFile(sectionId, data?.id, item?.id) }}>
                                    <i className="mdi mdi-delete" data-bs-toggle="tooltip" title=""
                                        data-bs-original-title="mdi-delete" aria-label="mdi-delete"></i>
                                </a>
                                {/* {error && <div className="invalid-input">{error[item?.id]}</div>} */}
                                {/* {error && (typeof error) === 'object'? <div className="invalid-input">{error[item?.id]}</div>:""} */}
                            </>
                            }
                        </div>
                    }
                })
            }
            <div className='row mt-3'>
                        {data?.isExpirationDateNeeded ? <p className='fw-bold'>Expiration Date:</p>:""}
                        {data?.isExpirationDateNeeded &&
                            data?.noOfAttachments?.map((item,index) => {
                                return (
                                <div className='col-lg-3 col-md-3 mb-5'>
                                    <div className="input-group">
                                        <div className="input-group-text">
                                            <label htmlFor={`expirationDate-${sectionId}-${data?.id}-${item.id}`}></label>
                                            <i
                                            className="fa fa-calculator tx-16 lh-0 op-6"
                                            style={{color:"#fff"}}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                document
                                                .getElementById(`expirationDate-${sectionId}-${data?.id}-${item.id}`)
                                                .showPicker();
                                            }}
                                            ></i>
                                        </div>
                                        <input 
                                            className="form-control"
                                            id= {`expirationDate-${sectionId}-${data?.id}-${item.id}`}
                                            type="date" 
                                            name={`expirationDate-${sectionId}-${data?.id}-${item.id}`}
                                            value={item?.expirationDate?moment(item?.expirationDate).format(
                                                    'YYYY-MM-DD'
                                                ):""}
                                            disabled={!item?.blobName ||(copyData && copyData?.noOfAttachments?.[index]?.expirationDate)}
                                            onChange={e => updateDate(e.target.value,sectionId, data?.id, item?.id )}
                                        />
                                    </div>
                                    {error && (typeof error) === 'object'? <div className="invalid-input">{error[item?.id]}</div>:""}
                                </div>
                                )
                            }) 
                        }
            </div>
        </div>
    )
}


export default DynamicForm;
