import {setLoading} from "../common/Loader/slice";
import requestUtil from '../../helpers/requestUtil';
import {signUpSuccess, signUpError, resetError,setMasterData,setsignupAuthData} from './slice';

export function signup({url, formData}) {
    return async dispatch => {
        try {
            dispatch(setLoading(true));
            const data = await requestUtil(url, 'POST', formData);
            dispatch(signUpSuccess(formData.email));
            return data
        } catch (error) {
            //console.log(error);
            dispatch(signUpError(error.message || "Sign up failed"));
        }
        finally{
            dispatch(setLoading(false));
        }
    };
}
export function loadMasterData(baseUrl) {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const Jobtype = await requestUtil(`${baseUrl}/Dropdown/getjobtype`, 'GET');

            dispatch(setMasterData({ Jobtype }));
        } catch (error) {
            dispatch("Record Not Found")
        }
        finally{
            dispatch(setLoading(false));
        }
    };
}
export function signupauth({url,param}) {
    return async (dispatch) => {       
        dispatch(setLoading(true));
        try {
            const signupAuthData = await requestUtil(url, 'POST', param);
            dispatch(setsignupAuthData(signupAuthData));
        } catch (error) {
            dispatch("Record Not Found")
        }
       finally{
        dispatch(setLoading(false));
       }
    };
}
export function reset() {
    return async dispatch => {
        dispatch(resetError());
    };
}
export const getMarketingAppAccessToken = ({baseUrl}) => {
    return async(dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${baseUrl}/Authenticate/get-accesss-token`);
            return response;
        } catch (error) {
            // dispatch(signUpError(error.message ));
        } finally {
            dispatch(setLoading(false));
            }
    }
}

export const careersAdTracking = ({url,params, accessToken}) => {
    return async(dispatch) => {
        dispatch(setLoading(true));
        try {
            const requestHeaders = {
                'Content-Type': 'application/json',
                'Accept':'application/json',
                'Authorization': accessToken ? `Bearer ${accessToken}` : ''
            };
           await fetch(url, {
                headers:{...requestHeaders},
                method:"POST",
                body:JSON.stringify(params)
            })
        } catch (error) {
        // dispatch(signUpError(error.message ));
        }
        finally {
        dispatch(setLoading(false));
        }
    }
}