// Import Files
import { getValue } from "@testing-library/user-event/dist/utils";
import { paginationCalculator } from "pagination-calculator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { ChatWindow } from "../common/chatWindow";
import DataGrid from "../common/DataGrid";
import Header from "../common/Header";
import Loader from "../common/Loader";
import RadioGroup from "../common/RadioGroup";
import Sidebar from "../common/Sidebar";
import JobDetails from "../JobDetails/jobDetails";
import { headersData } from "./MultishiftTableHeader";
import {
  updateSelectedJobDetails,
  updateClaimandUnclaimMsg,
} from "../Shifts/action";
import { shiftsSelector } from "../Shifts/selector";
import {
  cancelclaim,
  claim,
  firstPage,
  getPersonalMsg,
  getUnClaimCandidateReasons,
  gotoPage,
  lastPage,
  loadShifts,
  nextPage,
  pageSize,
  previousPage,
  sendPersonal,
  updateClaimandUnclaimMsgMyShift,
  updateMsgFlag,
  updateSort,
} from "./action";
import { myshiftSelector } from "./selector";
import { Modal } from "react-bootstrap";
import { ModalBox } from "../common/Modal/ModalBox";
import { EncounterTableHeaders } from "./EncounterTableHeader";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // React Hooks
  const dispatch = useDispatch();
  const config = useConfiguration();
  const storage = useSessionStorage();
  const navigate = useNavigate();

  // MyShift Selector
  const {
    myshift,
    chat,
    msgSendFlag,
    claimUnclaimErrorMessageMyShift,
    claimUnclaimSuccessMessageMyShift,
    unClaimEmployerReasons,
  } = useSelector(myshiftSelector);

  // Unclaim Open Shift Selector
  const { selectedJob, claimUnclaimErrorMessage, claimUnclaimSuccessMessage } =
    useSelector(shiftsSelector);

  // Claim Unclaim Modal Box Show and SetShow
  const [claimUnclaimModalShow, setClaimUnclaimModalShow] = useState(false);
  const [unclaimBtnIsDiabled, setUnclaimBtnIsDiabled] = useState(false);

  // Claim Unclaim get a data from Function
  const [claimUnclaimUniqueData, setClaimUnclaimUniqueData] = useState(null);

  // get details from Login Page
  const login = storage.getItem("login");

  // Confirmation Modal Box Dropdown Form initial data
  const [ModalFormData, setModalFormData] = useState({
    reason: "",
    comments: "",
  });

  // Confirmation Modal Box Dropdown Form Error initial data
  const [ErrorsModalFormData, setErrorsModalFormData] = useState({
    reasonError: "",
    commentsError: "",
  });

  // Confirmation Modal Box Dropdown Form HandleChange Function
  const ConfirmationModalBoxHandleChange = (e) => {
    setModalFormData({
      ...ModalFormData,
      [e.target.name]: e.target.value,
    });
  };

  // Message Params
  const [msgParams, setmsgParams] = useState({
    webPageId: 0,
    shiftUniqueId: "",
    candidateUniqueId: "",
    employerUniqueId: "",
  });

  // Open and close Message Box
  const [openMessaging, setOpenMessaging] = useState(false);

  // Message Chat data
  const [chatData, setChatData] = useState([]);

  // Table Headers Data
  const headers = [
    {
      name: "Location",
      field: "locationName",
      type: "text",
      sort: true,
      width: 270,
      minWidth: 190,
      maxWidth: 470,
      filter: true,
    },
    {
      name: "Department",
      field: "candidateDepartmentName",
      type: "text",
      sort: true,
      filter: true,
      width: 190,
      minWidth: 190,
      maxWidth: 250,
    },
    {
      name: "Date",
      field: "shiftDate",
      type: "text",
      sort: true,
      width: 270,
      minWidth: 170,
      maxWidth: 470,
    },
    {
      name: "Shift End Date",
      field: "jobEndDateToDisplay",
      type: "text",
      sort: true,
      minWidth: 170,
    },
    {
      name: "Shift",
      field: "multiShift",
      type: "icon",
      filter: false,
      with: 100,
      minWidth: 50,
      minWidth: 100,
    },
    {
      name: "Start",
      field: "shiftStartTime",
      type: "text",
      sort: true,
    },
    {
      name: "End",
      field: "shiftEndTime",
      type: "text",
      sort: true,
    },
    {
      name: "Checkin",
      field: "isCheckedIn",
      type: "Myshift_color",
      filter: false,
      sort: false,
    },
    {
      name: "Checkout",
      field: "isCheckedOut",
      type: "Myshift_color",
      filter: false,
      sort: false,
    },
    {
      name: "Approval",
      field: "approversList",
      type: "gridtextcolor",
      filter: false,
      sort: false,
    },
    {
      name: "Paid",
      field: "isPaid",
      type: "Myshift_color",
      filter: false,
      sort: false,
    },
    {
      name: "Rate Per Hour",
      field: "ratePerHourToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Total Shift Amount",
      field: "totalShiftAmountToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Incentive",
      field: "incentiveToDisplay",
      type: "text",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "Claim/Cancel",
      field: "isClaimed",
      type: "icon",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      name: "checked In Time",
      field: "checkedInToDisplay",
      type: "text",
      minWidth:200,
      maxWidth: 400,
    },
    {
      name: "checked Out Time",
      field: "checkedOutToDisplay",
      type: "text",
      minWidth:200,
      maxWidth: 400,
    },
    // {
    //   name: "View",
    //   field: "viewJobDetails",
    //   type: "icon",
    //   class: "fa fa-file",
    // },
    // {
    //   name: "Message",
    //   field: "messagepopup",
    //   type: "icon",
    //   class: "fa fa-commenting-o",
    // },
  ];

  const sendMsg = (text) => {
    const newparams = {
      ...msgParams,
      fromCandidate: true,
      messageText: text,
    };
    dispatch(
      sendPersonal({
        url: `${config.baseUrl}/Messages/SendMessageToEmployer`,
        params: newparams,
      })
    );
  };

  // MyShift Main Page API Method
  useEffect(() => {
    document.title = "MyShift";
    if (config.baseUrl) {
      if (!login) {
        navigate("/");
      } else {
        dispatch(
          loadShifts(config.baseUrl, login.candidateUniqueId, "myshift")
        );
        // Unclaim Reasons API Method
        dispatch(getUnClaimCandidateReasons(`${config.baseUrl}`));
      }
    }
  }, [config]);

  useEffect(() => {
    const newData = JSON.parse(JSON.stringify(chat));
    buildGroupMsg(newData);
  }, [chat]);

  const buildGroupMsg = (data) => {
    const groups = data.reduce((groups, messages) => {
      const date = messages.messageDateTime.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(messages);
      return groups;
    }, {});

    const groupMsg = Object.keys(groups).map((date) => {
      return {
        date,
        messages: groups[date],
      };
    });
    setChatData(groupMsg);
  };

  const clearJobDetailsView = () => {
    dispatch(updateSelectedJobDetails(""));
  };

  // Multishift Job Info Page Navigate with parameters
  const MultiShiftJobInfo = (params) => {
    navigate("/multishiftjobinfo", {
      state: {
        params: params,
        myShiftPage: false,
        headersData: headersData,
        subUrl: "CandidatesJobs/get-myshifts-multishift-occurrences",
        pageName: 'myShift'
      },
    });
  };

  // Encounter Occuerence Page Info Function
  const EncounterJobInfo = (params) => {
    navigate("/encounterjobinfo", {
      state: {
        params: params,
        myShiftPage: false,
        subUrl: "CandidatesJobs/get-myshifts-encounter-occurrences",
        EncounterTableHeaders: EncounterTableHeaders,
      },
    });
  };

  // Claim or Cancel Modal Box open Button function
  const claimUnclaimModalOpenBtn = ({
    jobsShiftUniqueId,
    locationName,
    isClaimed,
  }) => {
    setClaimUnclaimModalShow(true);
    setClaimUnclaimUniqueData({
      jobsShiftUniqueId,
      locationName,
      isClaimed,
    });
  };

  // Claim or Cancel Modal Box Close Button function
  const claimUnclaimModalCloseBtn = () => {
    setErrorsModalFormData({
      commentsError: "",
      reasonError: "",
    });
    setModalFormData({
      reason: "",
      comments: "",
    });
    setClaimUnclaimModalShow(!claimUnclaimModalShow);
  };

  // Claim or Cancel Modal Box Function
  const claimUnclaimSave = () => {
    // Error Handling for Unclaim Reasons Dropdown
    if (!ModalFormData.reason) {
      setErrorsModalFormData({
        ...ErrorsModalFormData,
        reasonError: "Please choose at least one Reason",
      });
      return;
    } else if (ModalFormData.reason) {
      setErrorsModalFormData({
        ...ErrorsModalFormData,
        reasonError: "",
      });
      if (parseInt(ModalFormData.reason) === 0) {
        if (!ModalFormData.comments) {
          setErrorsModalFormData({
            ...ErrorsModalFormData,
            commentsError: "Please Enter the comments",
          });
          return;
        } else if (ModalFormData.comments) {
          setErrorsModalFormData({
            reasonError: "",
            commentsError: "",
          });
        }
      }
    }
    // Unclaim API Method
    if (claimUnclaimUniqueData.isClaimed) {
       // API call before disable unclaim btn
      setUnclaimBtnIsDiabled(true);
      dispatch(
        cancelclaim(
          config.baseUrl,
          claimUnclaimUniqueData.jobsShiftUniqueId,
          ModalFormData.reason,
          ModalFormData.comments
        )
      ).then(() => {
        setModalFormData({
          reason: "",
          comments: "",
        });
        claimUnclaimModalCloseBtn();
         // API call before disable unclaim btn
      setUnclaimBtnIsDiabled(false);
      });
    }
  };

  const generateRowData = (data, headers) => {
    const rowData = {};
    headers.forEach((header) => {
      if (header.type === "text") {
        rowData[header.name] = data[header.field];
      } else if (header.field === "isCheckedIn") {
        if (data.shiftType === 1 || data.shiftType === 4) {
          rowData[header.name] = {
            type: "Myshift_color",
            value: data[header.field],
          };
        } else {
          rowData[header.name] = "";
        }
      } else if (header.field === "isCheckedOut") {
        if (data.shiftType === 1 || data.shiftType === 4) {
          rowData[header.name] = {
            type: "Myshift_color",
            value: data[header.field],
          };
        } else {
          rowData[header.name] = "";
        }
      } else if (header.field === "isPaid") {
        if (data.shiftType === 1 || data.shiftType === 4) {
          rowData[header.name] = {
            type: "Myshift_color",
            value: data[header.field],
          };
        } else {
          rowData[header.name] = "";
        }
      } else if (header.field === "isClaimed") {
        const isClaimed = data[header.field];
        const iconClass = isClaimed ? "green-icon" : "grey-icon";
        rowData[header.name] = {
          type: "icon",
          class: `fa fa-check-circle icon-align ${iconClass}`,
          onClick: () => {
            // Confirmation Modal Box for UnClaim
            claimUnclaimModalOpenBtn({
              jobsShiftUniqueId: data.jobsShiftUniqueId,
              locationName: data.locationName,
              isClaimed: data.isClaimed,
            });
          },
        };
      } else if (header.field === "messagepopup") {
        rowData[header.name] = {
          type: "icon",
          class: `icon-align ${header.class}`,
          onClick: () => {
            openChantWindow(data.jobsShiftUniqueId);
          },
        };
      } else if (header.field === "approversList") {
        if (data.shiftType === 1 || data.shiftType === 4) {
          rowData[header.name] = {
            type: "gridtextcolor",
            value: data[header.field],
          };
        } else {
          rowData[header.name] = "";
        }
      }
      if (header.field === "shiftDate") {
        const shiftType = data?.shiftType;
        if (shiftType === 3) {
          if (data?.shiftType === 3) {
            rowData[header.name] = (
              <span className="text-wrap" style={{ width: "6rem" }}>
                {data?.recurrenceDetail}
              </span>
            );
          } else {
            rowData[header.name] = data?.shiftDate;
          }
        } else {
          rowData[header.name] = data?.shiftDate;
        }
      }
      if (header.field === "viewJobDetails") {
        rowData[header.name] = {
          type: "icon",
          class: `icon-align ${header.class}`,
          onClick: () => {
            dispatch(updateSelectedJobDetails(data?.jobUniqueId));
          },
        };
      }
      // rate per hour column
      if (header.field === "ratePerHourToDisplayMultishift") {
        if (
          data.ratePerHourToDisplayMultishift === "$0" ||
          data.ratePerHourToDisplayMultishift === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.ratePerHourToDisplayMultishift;
        }
      }
      // Incentive column
      if (header.field === "IncentiveToDisplay") {
        if (
          data.incentiveToDisplay === "$0" ||
          data.incentiveToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.incentiveToDisplay;
        }
      }
      // Total Shift Amount
      if (header.field === "totalShiftAmountToDisplay") {
        if (
          data.totalShiftAmountToDisplay === "$0" ||
          data.totalShiftAmountToDisplay === "$"
        ) {
          rowData[header.name] = "";
        } else {
          rowData[header.name] = data?.totalShiftAmountToDisplay;
        }
      }
      if (header.field === "multiShift") {
        const shiftType = data?.shiftType;
        if (shiftType === 3) {
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-binoculars icon-align`,
            onClick: () => {
              const params = {
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              };
              MultiShiftJobInfo(params);
            },
          };
        } else if (shiftType === 2) {
          rowData[header.name] = {
            type: "icon",
            class: `fa fa-hourglass icon-align`,
            onClick: () => {
              const params = {
                jobsShiftUniqueId: data.jobsShiftUniqueId,
              };
              EncounterJobInfo(params);
            },
          };
        } else {
          rowData[header.name] = {
            type: "icon",
            class: ``,
            onClick: () => { },
          };
        }
      }
    });
    return rowData;
  };

  const openChantWindow = (jobsShiftUniqueId) => {
    if (jobsShiftUniqueId) {
      setmsgParams({
        webPageId: 0,
        shiftUniqueId: jobsShiftUniqueId,
        candidateUniqueId: login.candidateUniqueId,
        employerUniqueId: null,
      });
      const newparams = {
        webPageId: 0,
        shiftUniqueId: jobsShiftUniqueId,
        candidateUniqueId: login.candidateUniqueId,
        employerUniqueId: null,
      };
      dispatch(
        getPersonalMsg({
          url: `${config.baseUrl}/Messages/GetCandidateMessages`,
          params: newparams,
        })
      );
      setOpenMessaging(!openMessaging);
    }
  };

  useEffect(() => {
    if (msgSendFlag && config.baseUrl) {
      dispatch(
        getPersonalMsg({
          url: `${config.baseUrl}/Messages/GetCandidateMessages`,
          params: msgParams,
        })
      ).then(() => {
        dispatch(updateMsgFlag(false));
      });
    }
  }, [msgSendFlag]);

  const createPageData = (page, pageSize, totalCount) => {
    if (page === 1) {
      return {
        from: page,
        to: pageSize,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    } else {
      const pageData = paginationCalculator({
        total: totalCount,
        current: page,
        pageSize: pageSize,
      });
      return {
        from: pageData.showingStart,
        to: pageData.showingEnd,
        total: totalCount,
        totalPages: Math.ceil(totalCount / pageSize),
        currentPage: page,
        pageSize: pageSize,
      };
    }
  };
  const gotoFirstPage = () => {
    dispatch(firstPage("myshift")).then(() => {
      dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    });
  };
  const gotoNextPage = () => {
    dispatch(nextPage("myshift")).then(() => {
      dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    });
  };
  const gotoPreviousPage = () => {
    dispatch(previousPage("myshift")).then(() => {
      dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    });
  };
  const gotoLastPage = () => {
    dispatch(lastPage("myshift")).then(() => {
      dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    });
  };
  const gotoSelectedPage = (page) => {
    dispatch(gotoPage("myshift", page)).then(() => {
      dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    });
  };
  const setPageSize = (size) => {
    dispatch(pageSize("myshift", size)).then(() => {
      dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    });
  };

  const formatTableData = (dataList) => {
    const tableData = {
      headers,
      rows: dataList?.data?.candidateJobShifts?.map((data) => ({
        columns: generateRowData(data, headers),
      })),
      options: {
        sortable: false,
        filter: false,
        pagination: true,
        pageSize: dataList?.pageSize || 10,
      },
      pageData: createPageData(
        dataList?.page,
        dataList?.pageSize,
        dataList?.data?.totalPages
      ),
      firstPage: gotoFirstPage,
      nextPage: gotoNextPage,
      previousPage: gotoPreviousPage,
      lastPage: gotoLastPage,
      updatePageSize: setPageSize,
      gotoPage: gotoSelectedPage,
      headerComponentParams: {
        sort: (order, column) => {
          dispatch(updateSort("myshift", order, column)).then(() => {
            dispatch(
              loadShifts(config.baseUrl, login.candidateUniqueId, "myshift")
            );
          });
        },
        headers,
      },
    };
    return tableData;
  };

  // Message Modal Box after Load data for Job Details Page
  const refreshJobList = () => {
    dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    dispatch(updateClaimandUnclaimMsg(null));
  };

  // Message Modal Box after Load data for MyShift Page
  const refreshJobListMyShift = () => {
    dispatch(loadShifts(config.baseUrl, login.candidateUniqueId, "myshift"));
    dispatch(updateClaimandUnclaimMsgMyShift(null));
  };

  // Job Details Page Error and Success message
  const errorMessage = claimUnclaimErrorMessage !== null;
  const successMessage = claimUnclaimSuccessMessage !== null;

  // MyShift Page Error and Success Message
  const errorMessageMyShift = claimUnclaimErrorMessageMyShift !== null;
  const successMessageMyShift = claimUnclaimSuccessMessageMyShift !== null;

  return (
    <div className="page">
      <Loader />
      <Header />
      <Sidebar />
      {/* Claim or Cancel Confirmation Modal Box */}
      {claimUnclaimModalShow && (
        <ModalBox
          size={"md"}
          label={"Choose the Unclaim Reason"}
          children={
            <div>
              <div>
                Are you sure want to proceed with unclaim for this{" "}
                {claimUnclaimUniqueData.locationName} job?
              </div>
              {/* UnClaim Reasons Dropdown */}
              <div className="mt-5">
                <label for="reason" className="mx-2">
                  Choose a Reason :
                </label>
                <select
                  name="reason"
                  id="reason"
                  className="form-select form-control"
                  required
                  onChange={ConfirmationModalBoxHandleChange}
                >
                  <option selected disabled>
                    Choose any one
                  </option>
                  {unClaimEmployerReasons.map((reasonLists, index) => {
                    return (
                      <option key={index} value={reasonLists.id}>
                        {reasonLists.name}
                      </option>
                    );
                  })}
                </select>
                {
                  <span className="text-danger mx-2">
                    {ErrorsModalFormData?.reasonError}
                  </span>
                }
                {parseInt(ModalFormData.reason) === 0 ? (
                  <div className="mt-2">
                    <textarea
                      className="form-control"
                      name="comments"
                      value={ModalFormData.comments}
                      onChange={ConfirmationModalBoxHandleChange}
                    ></textarea>
                    {
                      <span className="text-danger mx-2">
                        {ErrorsModalFormData?.commentsError}
                      </span>
                    }
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          }
          disabled={unclaimBtnIsDiabled}
          showHide={claimUnclaimModalCloseBtn}
          buttonName={"UnClaim"}
          saveFunction={claimUnclaimSave}
        />
      )}
      {/* Job Details Claim or UnClaim Modal Box */}
      <Modal
        show={errorMessage || successMessage}
        size={"lg"}
        centered={true}
      // title="Error"
      >
        <Modal.Header>
          <Modal.Title>{errorMessage ? "Error" : "Success"}</Modal.Title>
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={refreshJobList}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <div
              className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-autohide="false"
            >
              <div className="toast-body">{claimUnclaimErrorMessage}</div>
            </div>
          )}
          {successMessage && (
            <div className="modal-body text-center p-4 pb-5">
              <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
              <h4>{claimUnclaimSuccessMessage}</h4>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={refreshJobList}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* MyShift Page Claim and Unclaim Success or Error Message Modal Box */}
      <Modal
        show={errorMessageMyShift || successMessageMyShift}
        size={"lg"}
        centered={true}
      // title="Error"
      >
        <Modal.Header>
          <Modal.Title>{errorMessageMyShift ? "Error" : "Success"}</Modal.Title>
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={refreshJobListMyShift}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {errorMessageMyShift && (
            <div
              className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-autohide="false"
            >
              <div className="toast-body">
                {claimUnclaimErrorMessageMyShift}
              </div>
            </div>
          )}
          {successMessageMyShift && (
            <div className="modal-body text-center p-4 pb-5">
              <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block"></i>
              <h4>{claimUnclaimSuccessMessageMyShift}</h4>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={refreshJobListMyShift}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {selectedJob !== "" ? (
        <JobDetails
          jobId={selectedJob}
          returnToGrid={clearJobDetailsView}
          isReadOnly={true}
          myShiftPage={true}
        />
      ) : (
        <div className="page-main">
          <div className="main-content app-content mt-0 main-background">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <h1 className="page-title">My Shifts</h1>
                </div>
                {/* <div className="card">
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="card-header">
                                            <h4>My Shifts</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">

                                    <div className="row">
                                    <div >
        <input type="radio" value="All" name="status" /> ALL
        &nbsp; &nbsp; <input type="radio" value="Upcoming" name="status" /> Upcoming
        &nbsp; &nbsp; <input type="radio" value="Past" name="status" />  Past
        &nbsp; &nbsp; <input type="radio" value="Current" name="status" /> Current
      </div>
      <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                                <div className="btn-group me-2 sw-btn-group" role="group">
                                    <button className="btn btn-primary sw-btn-next" type="button">Filter</button>
				                </div>
				                <div className="btn-group mr-2 sw-btn-group-extra" role="group">
                                    <button className="btn btn-danger">Clear</button>
				                </div>
			                </div>
                                    </div>


                </div>
</div> */}

                <div className="row">
                  <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div
                            className={
                              "col-md-12 col-lg-12 col-xl-12 outer-div"
                            }
                          >
                            <DataGrid
                              {...formatTableData(myshift)}
                              headerSpace={true}
                            />
                          </div>

                          {openMessaging && (
                            <div className="">
                              <ChatWindow
                                closeChatWindow={() => {
                                  setOpenMessaging(!openMessaging);
                                }}
                                chatData={chatData}
                                sendMsg={sendMsg}
                              />
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
