import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useConfiguration from '../../../hooks/useConfiguration';
import useSessionStorage from '../../../hooks/useSessionStorage';
import Autocomplete from '../../common/Autocomplete';
import DateField from '../../common/TextField/dateTime';
import DropDown from '../../common/TextField/dropDown';
import {
  deleteUploadCertificate,
  downloadUploadedDocumentResumeBuilder,
  updateCertification,
  updatePrimaryCertification,
  uploadCertificate,
} from './action';
import { resumeBuilderSelector } from './selector';
import { updatePrimaryCertificationDate } from './slice';
import { profileBuilderSelector } from '../selector';
import { ToastContainer } from 'react-toastify';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const dispatch = useDispatch();
  const certificationSelect = useRef(null);
  const dateTimeRef = useRef(null);
  const { certifications, masterDatas } = useSelector(resumeBuilderSelector);
  const [certs, setCerts] = useState(certifications);
  const [certImageUpload, setCertImageUpload] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [filteredCertifications, setFilteredCertifications] = useState([]);
  const [isCertificationUlVisble, setIsCertificationUlVisble] = useState(false);
  const config = useConfiguration();
  const storage = useSessionStorage();
  const login = storage.getItem('login');
  const mobileAuth = JSON.parse(sessionStorage.getItem("mobileAuth"));
  const multiselectRef = useRef()
  const { profile_builder_flags } = useSelector(profileBuilderSelector);
  useEffect(() => {
    dispatch(updateCertification(certs));
  }, [certs]);
useEffect(() =>{
if(certifications?.primary.length === 0){
  multiselectRef.current.resetSelectedValues();
}
},[certifications?.primary.length])
  const updateCertValue = (target) => {
    const value = target.value;
    const action = target.action || 'add';
    dispatch(updatePrimaryCertification(value, action));
  };

  useEffect(() => {
    setFilteredCertifications(masterDatas.certifications);
  }, [masterDatas.certifications]);

  useEffect(() => {
    document.addEventListener('click', certificationOutside, false);
    return () => {
      document.removeEventListener('click', certificationOutside, false);
    };
  }, [isCertificationUlVisble]);

  const certificationOutside = (event) => {
    const parent1 = document.querySelectorAll(
      '.certification-select .displayNone'
    );
    if (
      certificationSelect.current &&
      !certificationSelect.current.contains(event.target)
    ) {
      if (
        isCertificationUlVisble &&
        parent1[0]?.style?.display !== 'none' &&
        event.target.id !== 'cerification'
      ) {
        const parent = document.querySelectorAll(
          '.certification-select .optionListContainer'
        );
        parent[0].classList.remove('displayBlock');
        parent[0].classList.add('displayNone');
        setIsCertificationUlVisble(false);
      }
    }
  };

  const addCertification = (certifaction) => {
    dispatch(updatePrimaryCertification(certifaction, 'add'));
  };

  const removeCertification = (certifaction) => {
    dispatch(updatePrimaryCertification(certifaction, 'remove'));
    if (certifaction?.uploadedCertificatesList?.length) {
      const requestJson = {
        candidateId: login.candidateId,
        appUserId: login.appUserId,
        candidateCertificationAttachmentId:
        certifaction.uploadedCertificatesList[0].id,
          // candidateUniqueId: login.candidateUniqueId 
      };
      dispatch(deleteUploadCertificate(config.baseUrl, requestJson));
    }
  };

  const updateOtherCertValue = (target, index) => {
    const newCerts = [...certifications.others];
    newCerts[index] = target.value;
    setCerts({
      ...certifications,
      others: newCerts,
    });
  };

  const removeRow = (index) => {
    const newCerts = [...certifications.others];
    newCerts.splice(index, 1);
    setCerts({
      ...certifications,
      others: newCerts,
    });
  };

  const addRow = (event) => {
    event.preventDefault();
    const newCerts = [...certifications.others];
    newCerts.push('');
    setCerts({
      ...certifications,
      others: newCerts,
    });
  };

  const searchCertification = () => {
    if (userInput) {
      const parent = document.querySelectorAll(
        '.certification-select .optionListContainer'
      );
      parent[0].classList.remove('displayNone');
      parent[0].classList.add('displayBlock');
      let filteredData = masterDatas.certifications.filter(
        (suggestion) =>
          suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      filteredData = [...filteredData, ...certifications.primary];
      setFilteredCertifications(filteredData);
      setIsCertificationUlVisble(true);
    }
  };

  const clearCertification = () => {
    setUserInput('');
    setFilteredCertifications(masterDatas.certifications);
  };

  const Tabs = () => {
    return (
      <div>
        {certifications.primary.length
          ? certifications.primary.map((item) => (
              <span class="tag label label-info">
                <span>{item.name}</span>
                <a>
                  <i
                    class="remove glyphicon glyphicon-remove-sign glyphicon-white"
                    onClick={() => removeCertification(item)}
                  ></i>
                </a>
              </span>
            ))
          : ''}
      </div>
    );
  };

  const updateDate = (e, element, index) => {
    dispatch(
      updatePrimaryCertificationDate({
        ...element,
        expirationDate: e.target.value,
        index,
      })
    );
  };

  const fileChange = async (e, element, index) => {
    const file = e.target.files[0];
    // const resumeFile = {
    //   name: file.name,
    // };


    const response = await dispatch(
      uploadCertificate(
        config.baseUrl,
        login.candidateUniqueId,
        element?.id,
        file
      )
    );
    dispatch(
      updatePrimaryCertificationDate({
        ...element,
        uploadedCertificatesList: [
          {
            id: 0,
            name: file.name,
            blobName: response.blobFileName ? response.blobFileName : '',
            downloadUrl:response.downloadUrl ? response.downloadUrl : '',
          },
        ],
        fileName: file.name,
        index,
      })
    );
  };

  const downloadUploadedDoc = (sectionId, fieldId, fileId,blobFileName,fileName) => {
    dispatch(downloadUploadedDocumentResumeBuilder(config.baseUrl,  login.candidateUniqueId , sectionId, fieldId, fileId,blobFileName,fileName,login.accessToken));
  }

  const removeFile = async (element, index) => {
    const requestJson = {
      candidateId: login.candidateId,
      appUserId: login.appUserId,
      candidateCertificationAttachmentId:
        element.uploadedCertificatesList[0].id,
        // candidateUniqueId: login.candidateUniqueId 
    };
    await dispatch(deleteUploadCertificate(config.baseUrl, requestJson));

    dispatch(
      updatePrimaryCertificationDate({
        ...element,
        expirationDate:null,
        fileName: null,
        disabled:false,
        uploadedCertificatesList: [
          {
            id: 0,
            name: null,
            blobName: null,
          },
        ],
        index,
      })
    );
  };
  return (
    <div id="DivFor_Certification">
      <div id="largemodal_Certification_1">
        <div id="largemodal_Certification_2">
          <div id="largemodal_Certification_3">
            <div className="card">
              <div className="col-lg-12 col-md-12">
                <div className="row">
                  {   mobileAuth &&
                      <ToastContainer style={{ "zIndex": 999999 }} />
                  }
                  <div className="card-header" style={{ display: 'block' }}>
                    <div className="col-lg-4 col-md-4">
                      <h4>Certifications, Licenses, Permits*</h4>
                    </div>
                    <div className="ms-3" style={{ color: '#13aeeb' }}>
                      Use the search Certifications text box and enter a keyword
                      to search for Certifications containing it or, use the
                      select dropdown box to see all Certifications in
                      alphabetical order.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
              <div style={{border:"none"}} className="form-control border-none">
                 <div style={{gap:"1rem"}} className="d-flex flex-sm-row flex-column mb-5">
                  <input
                    type="text"
                    className="form-control me-2"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Search Certifications, Licenses, Permits"
                  />
                  {/* <span className="me-2">Loadding...</span> */}
                  <div style={{rowGap:"0.5rem"}} className="d-flex align-items-center">
                  <button
                    className="btn btn-primary sw-btn-next me-2"
                    type="button"
                    id="cerification"
                    onClick={searchCertification}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-primary sw-btn-next"
                    type="button"
                    onClick={clearCertification}
                  >
                    Clear
                  </button>
                </div>
                </div>
              </div>
                <span
                  className="certification-select"
                  ref={certificationSelect}
                >
                  <DropDown
                    name={'certifications'}
                    label={'Select all that apply*'}
                    id={'certifications'}
                    labelClassName="fw-bold"
                    options={filteredCertifications}
                    multiple={true}
                    // name={'primary'}
                    // id={'certification-select'}
                    multiselectRef = {multiselectRef}
                    value={certifications.primary}
                    updateValue={updateCertValue}
                    isObject={true}
                    divClass={'col-lg-12 col-md-12'}
                    errors={
                      props.errors && props.errors.certifications
                        ? props.errors.certifications['primary']
                        : []
                    }
                  />
                </span>
                {/* <Autocomplete suggestions={masterDatas.certifications}
                                    keyVal={'name'} placeholder={'Search Certifications'} getSuggestion={addCertification} />
                                {(props.errors && props.errors.certifications) ? props.errors.certifications['primary'].map(error => (<div className="invalid-input">{error}</div>)) : ''}
                                <Tabs /> */}
              </div>
              <div className="card-body" style={{ display: 'none' }}>
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <h4>Other Certifications </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ display: 'none' }}>
                <div className="col-lg-12 col-md-12">
                  {certifications.others.map((cert, index) => (
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        id="divcloneCertifications"
                      >
                        <div className="row">
                          <div className="col-sm">
                            <input
                              type="text"
                              value={cert}
                              className="form-control"
                              id="othercertifyname"
                              placeholder="List other certifications"
                              onChange={(event) =>
                                updateOtherCertValue(event.target, index)
                              }
                            />
                          </div>
                          <div className="col-sm">
                            <a
                              onClick={(event) => {
                                event.preventDefault();
                                removeRow(index);
                              }}
                            >
                              <i className="fe fe-trash-2"></i>
                            </a>
                          </div>
                          <div className="row">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-lg-3 col-md-3">
                      <a id="btnAddothCertifications" href="#" onClick={addRow}>
                        <div className="row">
                          <div className="col-lg-1 col-md-3">
                            <i
                              className="icon-2x icon-plus"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="icon-plus"
                              aria-label="Add References"
                              style={{ 'font-size': '20px' }}
                            ></i>
                          </div>
                          <div className="col-lg-6 col-md-3">Add New</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body desktop-view">
                {/* {JSON.stringify(certifications.primary)} */}
                <div className="col-lg-12 col-md-12">
                 <div className="row">
                 <div className="card-title col-sm-3">Selected Certifications, Licenses, Permits</div>
                  <div className="card-title col-sm-3"></div>
                  <div className="card-title col-sm-3">Expiration Date</div>
                 </div>
                  {certifications.primary.map((element, index) => (
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        id="divcloneSpecialities"
                      >
                        <div className="row">
                          <div className="col-sm-3">
                            <p>{element.name}</p>
                          </div>
                          <div className="col-sm-3">
                            {!!element.uploadedCertificatesList.length &&
                            element.uploadedCertificatesList[0].name ? (
                              <>
                              <a
                                style={{cursor: 'pointer'}}
                                onClick={(e) => { downloadUploadedDoc(element.uploadedCertificatesList[0].sectionId, element.uploadedCertificatesList[0].id, element.uploadedCertificatesList[0].id, element.uploadedCertificatesList[0].blobName,element.uploadedCertificatesList[0]?.name) }}
                                // href={`${element.uploadedCertificatesList[0].downloadUrl}/${element.uploadedCertificatesList[0].blobName}`}
                                // target="_blank"
                                rel="noreferrer"
                              >
                              <i>
                                  {element.uploadedCertificatesList[0].name} -
                                </i>
                                <i
                                  className="mdi mdi-arrange-bring-forward"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  data-bs-original-title="mdi-arrange-bring-forward"
                                  aria-label="mdi-arrange-bring-forward"
                                ></i>
                              </a>

                                <i
                                  className="mdi mdi-delete"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  data-bs-original-title="mdi-delete"
                                  aria-label="mdi-delete"
                                  onClick={() => removeFile(element, index)}
                                ></i>
                              </>
                            ) : (
                              <input
                                type="file"
                                //   accept={
                                //     'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                //   }
                                disabled={element?.id === 0}
                                className="form-control"
                                id="exampleInputname"
                                placeholder="Years of Experience"
                                onChange={(e) => fileChange(e, element, index)}
                                style={{display: element?.id === 0 && 'none'}}
                              /> 
                            )}
                          </div>
                          <div className="col-sm-5 d-flex flex-column gap-2">
                            <div className="input-group">
                              <div className="input-group-text" style={{display: element?.id === 0 && 'none'}}>
                                <label htmlFor={element.id}></label>
                                <i
                                  className={`fa fa-calculator tx-16 lh-0 op-6`}
                                  style={{color:"#fff", display: element?.id === 0 && 'none'}}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    document
                                      .getElementById(`${element.id}`)
                                      .showPicker();
                                  }}
                                ></i>
                              </div>
                              <input
                                className="form-control"
                                id={element.id}
                                type={'date'}
                                name={element.id}
                                disabled={
                                  profile_builder_flags?.certifications_upload_expire_date?.isRequired ?
                                  element?.id === 0 ||  element?.uploadedCertificatesList.length === 0 || !element?.uploadedCertificatesList[0]?.blobName || element?.disabled
                                : ''
                                }
                                value={
                                  element.expirationDate
                                    ? moment(element.expirationDate).format(
                                        'YYYY-MM-DD'
                                      )
                                    : ""
                                }
                                onChange={(e) => updateDate(e, element, index)}
                                style={{display: element?.id === 0 && 'none'}}
                              />
                            </div>
                            <div>
                            {props.errors &&
                            props.errors.certificationsFileUpload &&
                            !!props.errors.certificationsFileUpload.primary
                              .length &&
                            props.errors.certificationsFileUpload.primary.map(
                              (error) => {
                                if (error.id === element.id) {
                                  return (
                                    <div key={element.id}>
                                      <div className="invalid-input">
                                        {error.message}
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )}
                            </div>
                          </div>
                          <div className="col-sm-1">
                            <i
                              onClick={() => {
                                removeCertification(element);
                              }}
                              className="fe fe-trash-2"
                            ></i>
                          </div>
                          
                         
                            
                            <div className="row">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="card-body mobile-view">
                {/* {JSON.stringify(certifications.primary)} */}
                <div className="col-lg-12 col-md-12">
                  {certifications.primary.map((element, index) => (
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        id="divcloneSpecialities"
                      >
                        <div className="row">
                          <div className="col-sm-3">
                          <div className="card-title">Selected Certifications, Licenses, Permits</div>
                            <p>{element.name}</p>
                          </div>
                          <div className="col-sm-3">
                            {!!element.uploadedCertificatesList.length &&
                            element.uploadedCertificatesList[0].name ? (
                              <>
                              <a
                                // href={`${element.uploadedCertificatesList[0].downloadUrl}/${element.uploadedCertificatesList[0].blobName}`}
                                onClick={(e) => { downloadUploadedDoc(element.uploadedCertificatesList[0].sectionId, element.uploadedCertificatesList[0].id, element.uploadedCertificatesList[0].id, element.uploadedCertificatesList[0].blobName,element.uploadedCertificatesList[0]?.name) }}
                                // target="_blank"
                                rel="noreferrer"
                              >
                              <i>
                                  {element.uploadedCertificatesList[0].name} -
                                </i>
                                <i
                                  className="mdi mdi-arrange-bring-forward"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  data-bs-original-title="mdi-arrange-bring-forward"
                                  aria-label="mdi-arrange-bring-forward"
                                ></i>
                              </a>

                                <i
                                  className="mdi mdi-delete"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  data-bs-original-title="mdi-delete"
                                  aria-label="mdi-delete"
                                  onClick={() => removeFile(element, index)}
                                ></i>
                              </>
                            ) : (
                              <input
                                type="file"
                                //   accept={
                                //     'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                //   }
                                disabled={element?.id === 0}
                                className="form-control"
                                id="exampleInputname"
                                placeholder="Years of Experience"
                                onChange={(e) => fileChange(e, element, index)}
                              />
                            )}
                          </div>
                          <div className="col-sm-6">
                          <div className="card-title">Expiration Date</div>
                            <div className="input-group">
                              <div className="input-group-text">
                                <label htmlFor={element.id}></label>
                                <i
                                  className="fa fa-calculator tx-16 lh-0 op-6"
                                  style={{color:"#fff"}}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    document
                                      .getElementById(`${element.id}`)
                                      .showPicker();
                                  }}
                                ></i>
                              </div>
                              <input
                                className="form-control"
                                id={element.id}
                                type={'date'}
                                name={element.id}
                                disabled={element?.id === 0 ||  element?.uploadedCertificatesList.length === 0 || !element?.uploadedCertificatesList[0]?.blobName || element?.disabled}
                                value={
                                  element.expirationDate
                                    ? moment(element.expirationDate).format(
                                        'YYYY-MM-DD'
                                      )
                                    : ""
                                }
                                onChange={(e) => updateDate(e, element, index)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-1 mt-4">
                            <i
                              onClick={() => {
                                removeCertification(element);
                              }}
                              className="fe fe-trash-2"
                            ></i>
                          </div>
                          {props.errors &&
                            props.errors.certificationsFileUpload &&
                            !!props.errors.certificationsFileUpload.primary
                              .length &&
                            props.errors.certificationsFileUpload.primary.map(
                              (error) => {
                                if (error.id === element.id) {
                                  return (
                                    <div key={element.id}>
                                      <div className="invalid-input">
                                        {error.message}
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )}
                          <div className="row">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
