import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const initialData = {
    firstName: '',
    lastName: '',
    email: '',
    zipcode: '',
    streetAddress: '',
    city: '',
    stateName: '',
    dateOfBirth: '',
    gender: '',
    cellPhoneNumber: '',
    homePhoneNumber: '',
    relocate: null,
    alternatePhoneNumber: '',
    approxLatitude: '',
    approxLongitude: '',
    emergencyContact: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationship: []
    },
    references: [
        {
            firstName: '',
            lastName: '',
            phoneNumber: '',
        }
    ],
    spokenLanguagesList: [],
    referenceSourceId: [],
    referenceSourceOther:'',
    jobDomainId: 1,
    jobTypeId: 1
}
const initialState = {
    data: initialData,
    masterData: {
        languages: [],
        relationships: [],
        referencesource: [],
        genderOptions: []
    },
    summaryData: initialData
};
export const personalInfoSlice = createSlice({
    name: 'personalInfo',
    initialState,
    reducers: {
        setPersonalInfo: (state, action) => {
            state.data = {
                ...action.payload, 
                dateOfBirth: action.payload?.dateOfBirth ? moment(action.payload?.dateOfBirth).format('YYYY-MM-DD') : null, 
            };

            if (state.masterData.genderOptions.length > 0) {

                if (typeof state?.data?.gender !== 'object') {
                    state.data.gender = state?.masterData?.genderOptions?.filter(sources =>
                        sources.id === state?.data?.gender
                    );
                }

            }


            if (state.masterData.languages.length > 0) {
                state.data.spokenLanguagesList = state.masterData.languages.filter(language => state.data.spokenLanguagesList.find(lang => {
                    if (lang.id) {
                        return lang.id === language.id
                    } else {
                        return lang === language.id
                    }
                }));
            }
            if (state.masterData.relationships.length > 0) {
                if (typeof state.data.emergencyContact.relationship !== 'object') {
                    state.data.emergencyContact.relationship = state.masterData.relationships.filter(relation =>
                        relation.name === state.data.emergencyContact.relationship
                    );
                }
            }

            if (!state.data.references || state.data.references.length === 0) {
                state.data.references = [{
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                }]
            }
            if (state.masterData.referencesource.length > 0) {

                if (typeof state?.data?.referenceSourceId !== 'object') {
                    state.data.referenceSourceId = state?.masterData?.referencesource?.filter(sources =>
                        sources.id === state?.data?.referenceSourceId
                    );
                }

            }
            state.personalInfoError = false;
        },
        setCityState: (state, action) => {
            state.data.city = action.payload.city;
            state.data.stateName = action.payload.state;
        },
        setMasterData: (state, action) => {
            if (action.payload.languages) {
                state.masterData.languages = action.payload.languages;
                state.masterData.relationships = action.payload.relationships;
                state.masterData.referencesource = action.payload.referencesource;
                state.masterData.genderOptions = action.payload.genderOptions
            }
        },
        addSpokenLanguagesList: (state, action) => {
            const newLanguage = state.data.spokenLanguagesList ? [...state.data.spokenLanguagesList, action.payload] : [action.payload];
            state.data.spokenLanguagesList = newLanguage;
        },
        removeSpokenLanguagesList: (state, action) => {
            const newLanguage = state.data.spokenLanguagesList.filter(item => item.id !== action.payload.id);
            state.data.spokenLanguagesList = [...newLanguage];
        },
    }
});
export const {
    setPersonalInfo,
    setCityState,
    setMasterData,
    addSpokenLanguagesList,
    removeSpokenLanguagesList
} = personalInfoSlice.actions;
export default personalInfoSlice.reducer;
