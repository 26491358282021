import { loadCandidateDashboard, loadCandidateDashboardError, setLoadingFlagDecrement, setLoadingFlagIncrement } from './slice';
import { setLoading } from "../common/Loader/slice";
import requestUtil from "../../helpers/requestUtil";
import { getCurrentResumeBuilder, loadMasterDatas } from "../ProfileBuilder/ResumeBuilder/action";
import { getMasterData, getPreference } from "../ProfileBuilder/Preference/action";

export function loadDashboard(url, candidateId,jobtypeId,candidateUniqueId) {
    return async (dispatch) => {
        // dispatch(setLoading(true));
        dispatch(setLoadingFlagIncrement())
        try {
            const response = await requestUtil(`${url}/Dashboard/mydashboardprofileinfo/${candidateId}?requestfrom=web`, "GET");
            dispatch(loadCandidateDashboard(response));
            dispatch(loadMasterDatas(url, jobtypeId,candidateUniqueId)).then(() => {
                dispatch(getCurrentResumeBuilder({
                    url: `${url}/Candidates/getjobinfo`, userId: candidateId
                }));
            });
            dispatch(getMasterData(url)).then(() => {
                dispatch(getPreference(url, candidateId));
            });
            // dispatch(setLoading(false));
        } catch (error) {
            dispatch(loadCandidateDashboardError(error.message));
            // dispatch(setLoading(false));
            dispatch(setLoadingFlagDecrement());
        } finally {
            dispatch(setLoadingFlagDecrement())
        }
        // dispatch(setLoading(false));
    };
}
