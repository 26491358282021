import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    encounterjobinfo: {
        page: 1,
        data: {},
        totalPages: 0,
        pageSize: 10,
        sortColumn: [],
        occurrenceList:[],
        headerMsgData: {},
        sortOrder: 'Acs'
    },
    selectedJob: '',
    shiftsLoadingError: null,
    claimUnclaimErrorMessageEncounterJobInfo: null,
    claimUnclaimSuccessMessageEncounterJobInfo: null,
    chat: [],
    msgSendFlag: false,
}

export const encounterjobinfoSlice = createSlice({
    name: 'encounterjobinfo',
    initialState,
    reducers: {  
        updateEncounterjobinfo: (state, action) => {
            const data = action.payload.data;
            state[action.payload.pageName].data = data;
            state[action.payload.pageName].page = Number(action.payload.page);
            state.claimUnclaimSuccessMessageEncounterJobInfo = null;
            state.claimUnclaimErrorMessageEncounterJobInfo = null;
            state[action.payload.pageName].occurrenceList =  data?.occurrenceListInfo;
            state[action.payload.pageName].headerMsgData = data?.shiftsInfo
        },
        shiftLoadingFailed: (state, action) => {
            state.shiftsLoadingError = action.payload;
        },
        updatePageSize: (state, action) => {
            state[action.payload.pageName].pageSize = Number(action.payload.pageSize);
            state[action.payload.pageName].page = 1;
        },
        setPage: (state, action) => {
            state[action.payload.pageName].page = Number(action.payload.page);
        },
        setSorting: (state, action) => {
            const currentSortColumns = state[action.payload.pageName].sortColumn;
            state[action.payload.pageName].sortOrder = action.payload.sortOrder;
            state[action.payload.pageName].sortColumn = action.payload.sortColumn;
        },
        setMsgSendFlag: (state, action) => {
            state.msgSendFlag = action.payload
        },
        setChat: (state, action) => {
            state.chat = action.payload
        },
        setClaimUnclaimMsgResetEncounterJobInfo: (state, action) => {
            state.claimUnclaimSuccessMessageEncounterJobInfo = action.payload;
            state.claimUnclaimErrorMessageEncounterJobInfo = action.payload;
        },
        setClaimUnclaimSuccessMessageEncounterJobInfo: (state, action) => {
            state.claimUnclaimSuccessMessageEncounterJobInfo = action.payload;
        },
        setClaimUnclaimErrorMessageEncounterJobInfo: (state, action) => {
            state.claimUnclaimErrorMessageEncounterJobInfo = action.payload;
        },
        updateOccurrenceData:(state,action) => {
            if(action.payload.jobOpenshifts.length > 0){
                state.occurrenceList = action.payload?.jobOpenshifts[0]?.scheduleOccurrenceDateWithInfo
            }
            // state.occurrenceList = action.payload?.jobOpenshifts?.map(item => item[0])
        }
    }
});

export const {
    shiftLoadingFailed,
    updatePageSize,
    updateEncounterjobinfo,
    setPage,
    setSorting,setChat, setMsgSendFlag,
    setClaimUnclaimMsgResetEncounterJobInfo,
    setClaimUnclaimSuccessMessageEncounterJobInfo,
    setClaimUnclaimErrorMessageEncounterJobInfo,
    updateOccurrenceData
} = encounterjobinfoSlice.actions;

export default encounterjobinfoSlice.reducer;
