import requestUtil from '../../helpers/requestUtil';
import { setLoading } from '../common/Loader/slice';
import { resetError, setChat, setError, setMessages, setMsgSendFlag, setParams } from './slice';
import * as Toasts from '../common/Toastify';

export function resetErrorStatus() {
    return async dispatch => {
        dispatch(resetError());
    };
}

export function getMessagesData({ url, params }) {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setMessages(response.candidateMessages));
        } catch (error) {
            dispatch(setError(error.message))
        }
        dispatch(setLoading(false));
    };
}

export const updateParams = (data) => {
    return async dispatch => {
        dispatch(setParams(data));
    };
}

export const getPersonalMsg = ({ url, params }) => {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setChat(response.appMessages));
        } catch (error) {
            dispatch(setError(error.message))
        }
        dispatch(setLoading(false));
    }
}

export const sendPersonal = ({ url, params }) => {
    return async dispatch => {
        dispatch(setLoading(true));
        try {
            const response = await requestUtil(`${url}`, 'POST', { ...params });
            dispatch(setMsgSendFlag(true));
        } catch (error) {
            dispatch(setMsgSendFlag(false))
            dispatch(setError(error.message))
        }
        dispatch(setLoading(false));
    }
}

export const updateMsgFlag = (flag) => {
    return async dispatch => {
        dispatch(setMsgSendFlag(flag));
    }
}

export const updateMessageStatus = ({url,messageStatusParams}) => {
    return async (dispatch,getState) => {
        dispatch(setLoading(true));
        const state = getState()
    const {isMessageRead,messageId} = messageStatusParams
    const {data} = state.messages;
    const newMessageData = JSON.parse(JSON.stringify(data));
        try {
            const response = await requestUtil(`${url}/Messages/MarkMessageAsRead`,'POST', { ...messageStatusParams });
            if(response){
                const updateMessageData = newMessageData?.map(item => {
                    if(item?.messageId === messageId){
                        return {...item,isMessageRead:isMessageRead}
                    }
                    return item
                })
                dispatch(setMessages(updateMessageData));
            }
        } catch (error) {
            dispatch(setError(error.message))
            Toasts.error(error.message)
        }
        finally {
        dispatch(setLoading(false));
        }
    }
}

export const deleteMessageData = ({url,messageId}) => {
    return async (dispatch,getState) => {
        dispatch(setLoading(true));
      const state = getState();
      const {data} = state.messages;
      const newMessageData = JSON.parse(JSON.stringify(data));
    try {
        const response = await requestUtil(`${url}/Messages/Delete-AppMessage-Details?AppMessageId=${messageId}`, 'DELETE');
        if(response){
            const updateMessageData = newMessageData.filter( messageData => messageData?.messageId !== messageId)
            dispatch(setMessages(updateMessageData));
            Toasts.success(response.message)
        }
    } catch (error) {
        dispatch(setError(error.message))
        Toasts.error(error.message)
    }
    finally {
        dispatch(setLoading(false));
    }
}
}
