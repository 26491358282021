import React, {useEffect, useState} from "react";
import './index.css';
import Certifications from "./certifications";
import Specialities from "./specialities";
import EducationLevel from "./educationLevel";
import Emrehr from "./emrehr";
import ResumeForms from "./resumeForms";
import Categories from "./categories";
import {getCurrentResumeBuilder, getLevelBasedAPIDatas, loadMasterDatas} from "./action";
import useConfiguration from "../../../hooks/useConfiguration";
import useSessionStorage from "../../../hooks/useSessionStorage";
import {useSelector, useDispatch} from "react-redux";
import { resumeBuilderSelector } from "./selector";
import { setResumeBuilderData } from "./slice";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const config = useConfiguration();
    const storage = useSessionStorage();
    const dispatch = useDispatch();
    const {categories, resumeBuilderData} = useSelector(resumeBuilderSelector);
    const [jobtypeId, setJobtypeId] = useState(0);
    const login = storage.getItem("login");  
    useEffect(() => {  if (config.baseUrl) {
        config.baseUrl &&

        dispatch(
            getCurrentResumeBuilder({
              url: `${config.baseUrl}/Candidates/getjobinfo`,
              userId: login.candidateId,
        })).then((res) => {
            dispatch(loadMasterDatas(config.baseUrl, login.jobTypeId,login.candidateUniqueId)).then(() => {
                if(!login.jobTypeInfo.showCertifications && !login.jobTypeInfo.showEmr && !login.jobTypeInfo.showSpecialties) {
                dispatch(setResumeBuilderData(res))
            }
            })
        })



        if(login.jobTypeInfo.showCertifications && login.jobTypeInfo.showEmr && login.jobTypeInfo.showSpecialties) {
            dispatch(
                getCurrentResumeBuilder({
                  url: `${config.baseUrl}/Candidates/getjobinfo`,
                  userId: login.candidateId,
            })).then((res) => {
                dispatch(getLevelBasedAPIDatas(config.baseUrl, login.jobTypeId,login.candidateUniqueId, categories.length ?  
                    [categories[0].id] : 
                    resumeBuilderData.jobCategoryIdList ? resumeBuilderData.jobCategoryIdList : res.jobCategoryIdList, login))
                .then(() => {
                    dispatch(setResumeBuilderData(res))
                    // dispatch(
                    //     getCurrentResumeBuilder({
                    //       url: `${config.baseUrl}/Candidates/getjobinfo`,
                    //       userId: login.candidateId,
                    // }))
                })
            })
           
    }
        window.scrollToTop && window.scrollToTop();
    }}, [config]);


    return (
        <div id="step-11" className="tab-pane step-content display-block">
            <form>
                <div id="DivFor_RU">
                    <div id="largemodal_RU_1">
                        <div id="largemodal_RU_2">
                            <div id="largemodal_RU_3">
                                <div className="card">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="row">
                                            <div className="card-header">
                                                <div className="col-lg-4 col-md-4">
                                                    <h4>Resume Upload</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type={"hidden"} name={"jobId"} value={jobtypeId}/>
                                    <div className="card-body">
                                        <ResumeForms errors={props.resumeBuilderError}
                                                     educationErrors={props.educationErrors}
                                                     workHistoryErrors={props.workHistoryErrors}/></div>
                                </div>
                                <Categories errors={props.resumeBuilderError}/>
                                {   
                                            login.jobTypeInfo.showSpecialties && 
                                            <>  
                                <Specialities errors={props.resumeBuilderError}/>
                                </>  
                                            }
                                             {   
                                            login.jobTypeInfo.showCertifications && 
                                            <>
                                <Certifications errors={props.resumeBuilderError}/>  
                                </>  
                                            }                            
                                <EducationLevel errors={props.resumeBuilderError}/>
                                {   
                                            login.jobTypeInfo.showEmr && 
                                            <>
                                <Emrehr errors={props.resumeBuilderError}/>
                                </>  
                                            } 
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
