import React, { useEffect } from "react";
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom";

export const usePageViews = () => {
    const dispatch = useDispatch();
    const location = useLocation();
   useEffect(() => {
    dispatch({ type: '@@router/LOCATION_CHANGE', payload: { location } });
   },[dispatch,location])
}