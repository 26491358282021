import { useDispatch, useSelector } from "react-redux";
import { preferenceSelector } from "./selector";
import DropDown from "../../common/TextField/dropDown";
import { updatePreferredShiftTimeOfDays } from "./action";

export default (props) => {
  const dispatch = useDispatch();
  const {preferredShiftTimeOfTheDay, masterDatas } = useSelector(preferenceSelector);
  const updatePreferedShiftOfTheDay = (target) => {
    const value = target.value;
    const action = target.action || "add";
    dispatch(updatePreferredShiftTimeOfDays(value, action));
  };
  return (
    <div id="DivFor_Shifts">
      <div id="largemodal_Shifts_1">
        <div id="largemodal_Shifts_2">
          <div id="largemodal_Shifts_3">
            <div className="card">
              <div className="card-header">
                <h2 className="card-title">
                  Preferred Shift Time Of The Day *
                </h2>
              </div>
              <div className="card-body">
                <DropDown
                  name={"preferredShiftTimeOfTheDay"}
                  label={"Select all that apply*"}
                  labelClassName="fw-bold"
                  options={masterDatas.shiftTimeOfTheDay}
                  value={preferredShiftTimeOfTheDay}
                  multiple={true}
                  id={"preferredShiftTimeOfTheDay"}
                  updateValue={updatePreferedShiftOfTheDay}
                  isObject={true}
                  errors={props.errors ? props.errors['preferredShiftTimeOfTheDay'] : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
