import React from "react";

export default (props) => {
    return (
        <div className="card">
            <div className="col-lg-12 col-md-12">
                <div className="row">
                    <div className="card-header">
                        <h4>Overture Status</h4>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        Overture Status
                    </div>
                    <div className="col-lg-6 col-md-3">

                        {
                            (props && props.candidateOvertureStatusValue === 2 || props && props.candidateOvertureStatusValue === 1)  ? 
                            <>
                                <i className="fa fa-check approved-color" data-bs-toggle="tooltip" title=""
                                   data-bs-original-title="fa fa-check" aria-label="fa fa-check"
                                ></i> {props.candidateOvertureStatusName}</> 
                                : 
                            <>
                                <i className="fa fa-close rejected-color" data-bs-toggle="tooltip" title=""
                                data-bs-original-title="fa fa-close"
                                aria-label="fa fa-check" ></i>{props.candidateOvertureStatusName}
                            </>
                        }

                        {/* {
                            (props && props.candidateOvertureStatusValue === 2) && <>
                                <i className="fa fa-check approved-color" data-bs-toggle="tooltip" title=""
                                   data-bs-original-title="fa fa-check" aria-label="fa fa-check"
                                ></i> {props.candidateOvertureStatusName}
                            </>
                        }
                        {       (props && props.candidateOvertureStatusValue === 1) && <>
                                <i className="fa fa-close rejected-color" data-bs-toggle="tooltip" title=""
                                   data-bs-original-title="fa fa-close"
                                   aria-label="fa fa-check" ></i>
                                {props.candidateOvertureStatusName}
                            </>
                        } */}


                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        Status Updated Date
                    </div>
                    <div className="col-lg-6 col-md-3">
                        {props.actionDate}
                    </div>
                </div>
            </div>
        </div>
    )
}
