import Phone from "./Phone";

export const Call = () => {
  const domainJobtypeId = sessionStorage.getItem("domainJobtypeId");
  const domainLogos = JSON.parse(sessionStorage.getItem("domainLogos"));

  return (
    <div className="bg-white" style={{ height: "100vh" }}>
      <div className="text-center">
        <img
          src={domainLogos[domainJobtypeId]}
          alt="logo"
          style={{ width: "200px" }}
          className="mt-5"
        />
      </div>
      <div className="row justify-content-center">
        <div className="col-10 text-center" style={{ marginTop: "150px" }}>
          <Phone />
        </div>
      </div>
    </div>
  );
};
