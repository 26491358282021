import {useState} from "react";
import EmergencyContactForm from "../ProfileBuilder/PersonalInfo/emergencyContactForm";
import Modal from "./modal";
import { useSelector } from "react-redux";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import useSessionStorage from "../../hooks/useSessionStorage";

export default (props) => {
    const { login_roles_flags } = useSelector(profileBuilderSelector);
    const storage = useSessionStorage();
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const [showModal, setShowModal] = useState(false);
    const saveEmergencyContact = (event) => {
        event.preventDefault();
        if (props.saveContinue('personalInfo', false)) {
            setShowModal(!showModal);
        }
    };
    const edieEmergencyContact = (event) => {
        event.preventDefault();
        setShowModal(!showModal);
        if (showModal) {
            props.cancel('personalInfo');
        }
    }
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">
                {
                    showModal && <Modal label={'Emergency Contact'} showHide={edieEmergencyContact}
                                        saveFunction={saveEmergencyContact}>
                        <EmergencyContactForm errors={props.errors}/>
                    </Modal>
                }
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            <h4 className="text-wrap mr-2">Emergency Contact</h4>
                            {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                            <a id="EditEC" href="#" onClick={edieEmergencyContact}>
                                <i className="fe fe-edit" title="Edit Emergency Contact"></i>
                            </a> : ''
                            }
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <label
                                    htmlFor="exampleInputname1">{props.emergencyContact.firstName} {props.emergencyContact.lastName}</label>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <label htmlFor="exampleInputname1">{props.emergencyContact.phoneNumber}</label>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <label htmlFor="exampleInputname1">{props.emergencyContact.relationship?.length ? props.emergencyContact.relationship[0].name : ''}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
