import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {updateEducationLevel, loadMasterDatas} from "./action";
import {resumeBuilderSelector} from "./selector";
import RadioGroup from "../../common/RadioGroup";
import useConfiguration from '../../../hooks/useConfiguration';
import { profileBuilderSelector } from '../selector';

export default (props) => {
    const {educationLevel: educationLevelState, masterDatas} = useSelector(resumeBuilderSelector);
    const [educationLevel, setEducationLevel] = useState(educationLevelState);
    const { profile_builder_flags } = useSelector(profileBuilderSelector);
    const dispatch = useDispatch();
    const config = useConfiguration();
    useEffect(() => {
        dispatch(updateEducationLevel(educationLevel));
    }, [educationLevel]);
    const updateEducationLevelChange = (target) => {
        setEducationLevel(target.value);
    };

    const errors = props.errors ? props.errors['educationLevel'] : [];
    return (
        <div id="DivFor_LE">
            <div id="largemodal_LE_1">
                <div id="largemodal_LE_2">
                    <div id="largemodal_LE_3">
                        <div className="card">
                            <div className="card-header">
                                <div className="col-lg-12 col-md-12">
                                    <h4>Highest Level of Education{profile_builder_flags?.high_level_of_education?.isRequired ? '*' : '' }</h4>
                                </div>
                                <div className="card-options">
                                    <a href="#" className="card-options-collapse"
                                       data-bs-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    
                                    <RadioGroup options={masterDatas.educationLevel} name={'educationLevel'}
                                                value={educationLevelState} updateValue={updateEducationLevelChange}/>
                                    {errors ? errors.map(error => (
                                        <div className="invalid-input">{error}</div>)) : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
