import TextField from "../../common/TextField/withLabel";
import React from "react";
import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {personalInfoSelector} from "./selector";
import {updatePersonalInfo} from "./action";
import DropDown from "../../common/TextField/dropDown";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {

    

    const {errors, aboutusError} = props;
    const {data, masterData} = useSelector(personalInfoSelector);
    const dispatch = useDispatch();
    const [form, setForm] = React.useState(data);
    useEffect(() => {
        dispatch(updatePersonalInfo(form));
    }, [form]);
    const updateReferenceValue = (target, index) => {
        const name = target.id.split('-')[0];
        const newReference = [...data.references];
        const toUpdate = newReference.map((ref, i) => {
            if (i == index) {
                return {
                    ...ref,
                    [name]: target.value
                }
            }
            return ref;
        });
        setForm({
            ...data,
            references: toUpdate
        });
    };
    const removereferences = (index) => {
        const newReferenses = [...data.references];
        newReferenses.splice(index, 1);
        setForm({
            ...data,
            references: newReferenses
        });
    };
    const addNewRow = () => {
        console.log(form)
        if(form.references && form.references.length !== 3)
        {
        setForm({
                    ...data,
                    references: [
                        ...data.references,
                        {
                            firstName: '',
                            lastName: '',
                            phoneNumber: ''
                        }
                    ]
                });
        }
        
    };
    const updateRefrenceVal = (target) => {
        if (target.value.name === "Other") {
            setForm({
                ...data,
                [target.name]: [target.value],
            });
        } else {
            setForm({
                ...data,
                [target.name]: [target.value],
                ['referenceSourceOther']: ''
            })
        }
    }

    const updateFormValue = (target) => {
        setForm({
            ...data,
            [target.name]: target.value
        });
    }
    return (
        <div className="card-body">
            <div className="col-lg-12 col-md-12">
                {
                    data.references.map((reference, index) => (
                        <div className="row" id="direferences" key={index}>
                            <div className="row">
                                <TextField name={`firstName-${index}`} label={'First Name'}
                                           placeholder={"First Name"} noLabel={false}
                                           divClass={"col-lg-3 col-md-3"}
                                           value={reference.firstName}
                                           updateValue={(target) => updateReferenceValue(target, index)}
                                           errors={errors[index] && errors[index]['firstName']}/>
                                <TextField name={`lastName-${index}`} label={'Last Name'}
                                           placeholder={"Last Name"} noLabel={false}
                                           divClass={"col-lg-3 col-md-3"}
                                           value={reference.lastName}
                                           updateValue={(target) => updateReferenceValue(target, index)}
                                           errors={errors[index] && errors[index]['lastName']}/>
                                <TextField name={`phoneNumber-${index}`} label={'Phone Number'}
                                           placeholder={"Phone Number"} noLabel={false}
                                           divClass={"col-lg-3 col-md-3"}
                                           value={reference.phoneNumber} format={"phone"}
                                           updateValue={(target) => updateReferenceValue(target, index)}
                                           errors={errors[index] && errors[index]['phoneNumber']}/>
                                <div className="col-sm deleteIconMargin">
                                    <a onClick={() => removereferences(index)}><i
                                        className="fe fe-trash-2"></i></a>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <a id="btnreferences">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 deleteIconMargin">
                                    <i className="icon-2x icon-plus" data-bs-toggle="tooltip" title=""
                                       data-bs-original-title="icon-plus"
                                       aria-label="Add References" style={{"font-size": "20px"}}
                                       onClick={addNewRow}></i>
                                    <span className="pl-2" onClick={addNewRow}>
                                        Add New
                                    </span>
                                </div>
                                
                            </div>
                        </a>
                    </div>
                </div>
                <br/>
                <div className="row">
                            
                                <DropDown name={"referenceSourceId"} label={'How did you hear about us ?*'}
                                    placeholder={"Hearaboutus"} updateValue={updateRefrenceVal} className={'refereneID'}
                                    value={data.referenceSourceId ?? [{id: '', name: 'Select'}]}
                                    options={masterData.referencesource} showArrow={true} isObject={true} multiple={false}
                                    errors={aboutusError && aboutusError['referenceSourceId']} />


                            
                            {data && data.referenceSourceId && data.referenceSourceId.length && data.referenceSourceId[0].name === 'Other' ?<TextField name={'referenceSourceOther'} label={'Other'} placeholder={"Other"}
                                    value={data.referenceSourceOther}
                                    errors={aboutusError && aboutusError['referenceSourceOther']} updateValue={updateFormValue}/> : ''}
                           
                        </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
        
    )
}
