export const getFilePath = url => {
    let filePath = '';
    if(url){
        filePath = url.substring(0,url?.lastIndexOf('/')+1);
    }
    return filePath ?? "";
};

export const getFileName = url => {
    let fileName = '';
    if(url){
        fileName = url.substring(url?.lastIndexOf('/')+1);
    }
    return fileName ?? "";
}