import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    formdata: [],
    formBaseDetail: {},
    downloadUrl: '',
    qaDataList:[]
}

export const qandAInfoSlice = createSlice({
    name: 'qandaInfo',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.formdata = action.payload
        },
        setformBaseDetail: (state, action) => {
            state.formBaseDetail = action.payload;
        },
        setDownloadUrl: (state, action) => {
            state.downloadUrl = action.payload
        },
        setQaDataList:(state,action) => {
            state.qaDataList = action.payload
        }
    }
})

export const { setFormData, setformBaseDetail, setDownloadUrl, setQaDataList } = qandAInfoSlice.actions;

export default qandAInfoSlice.reducer;