import moment from "moment/moment";

export function validateResume(formValues, jobTypeInfo, profile_builder_flags) {

  const errorObj = {
    isValid: true,
    errors: {},
  };


  // Validation Stop 19.03.24
  if (profile_builder_flags?.resume_upload?.isRequired) {
  if (!formValues.data.noResume) {
    if (
      formValues.data.resumeFile &&
      isEmpty(formValues.data.resumeFile.name)
    ) {
      errorObj.isValid = false;
      errorObj.errors.resume = [];
      errorObj.errors.resume.push('Please upload/enter a resume details');
    }
  }
}
  // Validation Stop 19.03.24


  if (!formValues.categories || formValues.categories.length === 0) {
    errorObj.errors.categories = [];
    errorObj.errors.categories.push('Please select at least one category');
    errorObj.isValid = false;
  }

  if (formValues.certifications) {
    if (jobTypeInfo.showCertifications) {
      const certifications = formValues.certifications;
      const isPrimaryPresent = isPrimaryCertEntered(certifications);
      if (!isPrimaryPresent) {
        if (!isOtherCertEntered(certifications)) {
          errorObj.errors.certifications = {};
          errorObj.errors.certifications.primary = [];
          errorObj.errors.certifications.primary.push(
            'Please select primary certification or none'
          );
          errorObj.isValid = false;
        }
      }
    }
    if (
      formValues.certifications.primary &&
      formValues.certifications.primary.length
    ) {
      errorObj.errors.certificationsFileUpload = {};
      errorObj.errors.certificationsFileUpload.primary = [];
      formValues.certifications.primary.forEach((element) => {
        if (
          !!element.uploadedCertificatesList.length &&
          element.uploadedCertificatesList[0].name &&
          !element.expirationDate
        ) {
          errorObj.errors.certificationsFileUpload.primary.push({
            message: 'Please select Expiration date',
            id: element.id,
          });
          errorObj.isValid = false;
        }
       if(element.expirationDate){
        if (
          moment(element.expirationDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")
         ) {
           errorObj.errors.certificationsFileUpload.primary.push({
             message: 'Expiration date should be greater than current date',
             id: element.id,
           });
           errorObj.isValid = false;
         }
       }
      });
    }
  }

  // Validation Stop 19.03.24
  if (profile_builder_flags?.emr_ehr_systems?.isRequired) {
  if (formValues.emrehr) {
    if (jobTypeInfo.showEmr) {
      const emrehr = formValues.emrehr;
      const isPrimaryPresent = isPrimaryEmrehrEntered(emrehr);
      if (!isPrimaryPresent) {
        if (!isOtherEmrehrEntered(emrehr)) {
          errorObj.errors.emrehr = {};
          errorObj.errors.emrehr.primary = [];
          errorObj.errors.emrehr.primary.push('Please select primary Emr/Ehr');
          errorObj.isValid = false;
        }
      }
    }
  }
}
  // Validation Stop 19.03.24

  if (jobTypeInfo.showSpecialties) {
    if (formValues.specialities.length) {
      let primarySpecialities = [];
      // const otherEmptySpecialities = formValues.specialities.filter((item) => item.isOther && (item.otherName === '' || item.years === ''));
      // const primarySpecialities = formValues.specialities.filter((item) => (item.isOther === undefined || item.isOther === false) && (item.skill === '' || item.years === ''));
      
      
      
      // Validation Stop 19.03.24
      if (profile_builder_flags?.specialties_year_of_experience?.isRequired) {
      primarySpecialities = formValues.specialities.map((item) => {
        if (item.skill === 290 || item.skill === 1023) {
          return { valid: true };
        } else if (item.years === '') {
          return { year: 'Enter number of years of experience', valid: false };
        } else {
          return { valid: true };
        }
      });
      }
      // Validation Stop 19.03.24




      //const primarySpecialities = formValues.specialities.filter((item) =>  (item.skill === '' || item.years === ''));
      // const isOtherSpecialityPresent = otherEmptySpecialities.length !== 0;
      if (!primarySpecialities.every((result) => result.valid)) {
        errorObj.errors.specialities = primarySpecialities;
        errorObj.isValid = false;
      }
      // if (primarySpecialities.length !== 0 && !isOtherSpecialityPresent) {
      //     errorObj.errors.specialities = [];
      //     errorObj.errors.specialities.push({ type: 2, message: 'Enter number of years of experience' });
      //     errorObj.isValid = false;
      // } else if (otherEmptySpecialities.length !== 0) {
      //     errorObj.errors.specialities = [];
      //     errorObj.errors.specialities.push({ type: 1, message: 'Please enter other skill and years' });
      //     errorObj.isValid = false;
      // }
    } else {
      errorObj.errors.mustspecialities = [];
      errorObj.errors.mustspecialities.push('Please select Speciality');
      errorObj.isValid = false;
    }
  }

  // Validation Stop 21.02.24
  if (profile_builder_flags?.high_level_of_education?.isRequired) {
  if (!formValues.educationLevel || formValues.educationLevel === '') {
    errorObj.errors.educationLevel = [];
    errorObj.errors.educationLevel.push('Please select education level');
    errorObj.isValid = false;
  }
  }
  // Validation Stop 21.02.24
  return errorObj;
}

const isPrimaryCertEntered = (certifications) => {
  if (certifications.primary && certifications.primary.length > 0) {
    return true;
  }
  return false;
};
const isOtherCertEntered = (certifications) => {
  if (
    certifications.others &&
    certifications.others.find((oth) => !isEmpty(oth))
  ) {
    return true;
  }
  return false;
};

const isPrimaryEmrehrEntered = (emrehr) => {
  if (emrehr.primary && emrehr.primary.length > 0) {
    return true;
  }
  return false;
};
const isOtherEmrehrEntered = (emrehr) => {
  if (emrehr.others && emrehr.others.find((oth) => !isEmpty(oth))) {
    return true;
  }
  return false;
};

function isEmpty(str) {
  return !str || str.length === 0;
}
