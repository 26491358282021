import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  myshift: {
    page: 1,
    data: {},
    totalPages: 0,
    pageSize: 10,
    sortColumn: [],
    sortOrder: "Acs",
  },
  selectedJob: "",
  shiftsLoadingError: null,
  claimUnclaimErrorMessageMyShift: null,
  claimUnclaimSuccessMessageMyShift: null,
  chat: [],
  msgSendFlag: false,
  unClaimEmployerReasons: [],
};
const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};
export const myshiftSlice = createSlice({
  name: "myshift",
  initialState,
  reducers: {
    updateShifts: (state, action) => {
      state[action.payload.pageName].data = action.payload.data;
      state[action.payload.pageName].page = Number(action.payload.page);
      state.claimUnclaimSuccessMessageMyShift = null;
      state.claimUnclaimErrorMessageMyShift = null;
    },
    shiftLoadingFailed: (state, action) => {
      state.shiftsLoadingError = action.payload;
    },
    updatePageSize: (state, action) => {
      state[action.payload.pageName].pageSize = Number(action.payload.pageSize);
      state[action.payload.pageName].page = 1;
    },
    setPage: (state, action) => {
      state[action.payload.pageName].page = Number(action.payload.page);
    },
    setSorting: (state, action) => {
      const currentSortColumns = state[action.payload.pageName].sortColumn;
      state[action.payload.pageName].sortOrder = action.payload.sortOrder;
      state[action.payload.pageName].sortColumn = action.payload.sortColumn; //[...currentSortColumns, action.payload.sortColumn].filter(onlyUnique);
    },
    setClaimUnclaimSuccessMessageMyShift: (state, action) => {
      state.claimUnclaimSuccessMessageMyShift = action.payload;
    },
    setClaimUnclaimErrorMessageMyShift: (state, action) => {
      state.claimUnclaimErrorMessageMyShift = action.payload;
    },
    setMsgSendFlag: (state, action) => {
      state.msgSendFlag = action.payload;
    },
    setChat: (state, action) => {
      state.chat = action.payload;
    },
    setClaimUnclaimMsgResetMyShift: (state, action) => {
      state.claimUnclaimSuccessMessageMyShift = action.payload;
      state.claimUnclaimErrorMessageMyShift = action.payload;
    },
    setUnclaimEmployerReasons: (state, action) => {
      state.unClaimEmployerReasons = action.payload;
    },
  },
});

export const {
  shiftLoadingFailed,
  updatePageSize,
  updateShifts,
  setPage,
  setSorting,
  setChat,
  setMsgSendFlag,
  setClaimUnclaimSuccessMessageMyShift,
  setClaimUnclaimErrorMessageMyShift,
  setClaimUnclaimMsgResetMyShift,
  setUnclaimEmployerReasons,
} = myshiftSlice.actions;

export default myshiftSlice.reducer;
