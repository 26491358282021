import React from "react";

export default (params) => {
    return <div className={"icon-align"}>
        <a href="#" onClick={(event) => {
            event.preventDefault();
            params?.value?.onClick();
        }}>
        <i className={params?.value?.class} title={params?.value?.title} />
        </a>
    </div>
}
