export const RatingHistoryTableHeaders = [
  {
    name: "Location",
    field: "locationName",
    type: "text",
    sort: true,
    filter: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "Date",
    field: "shiftDate",
    type: "text",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "Shift End Date",
    field: "jobEndDateToDisplay",
    type: "text",
    sort: true,
    minWidth: 170,
  },
  {
    name: "Shift",
    field: "multiShift",
    type: "icon",
    filter: false,
  },
  {
    name: "Ratings",
    field: "ratingNumber",
    type: "ratings_new",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "Job Title",
    field: "jobTitle",
    type: "text",
    sort: true,
    minWidth: 250,
    maxWidth: 480,
  },
  {
    name: "Start Time",
    field: "shiftStartTime",
    type: "text",
    sort: true,
    minWidth: 120,
    maxWidth: 150,
  },
  {
    name: "End Time",
    field: "shiftEndTime",
    type: "text",
    sort: true,
    minWidth: 120,
    maxWidth: 150,
  },
  {
    name: "Check In",
    field: "isCheckedIn",
    type: "RH_checkedin",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "Check Out",
    field: "isCheckedOut",
    type: "RH_checkedout",
    sort: true,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    name: "checked In Time",
    field: "checkedInToDisplay",
    type: "text",
    minWidth:200,
    maxWidth: 400,
  },
  {
    name: "checked Out Time",
    field: "checkedOutToDisplay",
    type: "text",
    minWidth:200,
    maxWidth: 400,
  },
  // {
  //     name: "View",
  //     field: "viewJobDetails",
  //     type: "icon",
  //     class: "fa fa-file",
  // },
];
