import TextField from "../common/TextField/withLabel";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bankDetailSelector } from './selector';
import { updateBankDetail } from './action';
import DropDown from "../common/TextField/dropDown";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { errors, paymentMode } = props;
    const dispatch = useDispatch();
    const { data, masterData } = useSelector(bankDetailSelector);
    const [form, setForm] = useState(data);
    useEffect(() => {
        dispatch(updateBankDetail(form));
    }, [form]);
    //  useEffect(() => {
    //         setForm({
    //             ...data           

    //         });
    //     }, [data]);
    const updateFormValue = (target) => {
        setForm({
            ...data,
            [target.name]: target.value
        });
    }
    const updateAccountType = (target) => {
        if (target.value.name === "Other") {
            setForm({
                ...data,
                [target.name]: [target.value],
            });
        } else {
            setForm({
                ...data,
                [target.name]: [target.value],
                ['accountTypeOther']: ''
            })
        }
    }
    return (
        <div className="card-body">
            <div className="row">
                <TextField name={'bankName'} label={'Bank Name*'} placeholder={"Bank Name"}
                    updateValue={updateFormValue} value={data.bankName} errors={errors['bankName']} />
                <TextField name={'bankAddress'} label={'Bank Address'} placeholder={"Bank Address"}
                    updateValue={updateFormValue} value={data.bankAddress} errors={errors['bankAddress']} />
            </div>
            <div className="row">
                <TextField name={'accountHolderName'} label={'Name of the Account Holder *'} placeholder={"Name of the Account Holder"}
                    updateValue={updateFormValue} value={data.accountHolderName} errors={errors['accountHolderName']} />
                <TextField name={'bankAccountNumber'} label={'Bank Account Number*'} placeholder={"Bank Account Number"}
                    updateValue={updateFormValue} value={data.bankAccountNumber} errors={errors['bankAccountNumber']} />
            </div>
            <div className="row">
                <TextField name={'routingNumber'} label={'Routing Number*'} placeholder={"Routing Number"}
                    updateValue={updateFormValue} value={data.routingNumber} errors={errors['routingNumber']} />
                <DropDown name={"accountType"} label={'Type of Account*'}
                    placeholder={"Type of Account"} updateValue={updateAccountType}
                    value={data.accountType}
                    options={masterData.accountTypes} showArrow={true} isObject={true} multiple={false}
                    errors={errors['accountType']} />
            </div>
            <div className="row">
                {data && data.accountType && data.accountType.length && data.accountType[0].name === 'Other' ? <TextField name={'accountTypeOther'} label={'Other Type of Account*'} placeholder={"Other type of account"}
                    updateValue={updateFormValue} value={data.accountTypeOther} errors={errors['accountTypeOther']} /> : ''}
            </div>
        </div>
    )

}