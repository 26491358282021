import { useState } from "react";

export default (props) => {
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">

                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                            <div className="col-lg-4 col-md-4 jobheader">
                                <b><h4 className="headerfontbold">Job Info</h4></b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                    <> 
                        {/* <div className="row" >
                         <div className="col-lg-2 col-md-2">
                            <label className="form-label">Job Template :</label></div>
                            <div className="col-lg-4 col-md-4">
                            <label htmlFor="Location Name">{props?.jobInfo?.jobTemplateName}</label>
                        </div></div> */}

                        <div className="row" >
                         <div className="col-lg-2 col-md-2">
                            <label className="form-label">Job Level :</label></div>
                            <div className="col-lg-6 col-md-6 mt-2">
                            <label htmlFor="Location Name">{props?.jobInfo?.jobCategory}</label>
                        </div></div>

                        {/* <div className="row" >
                         <div className="col-lg-2 col-md-2">
                            <label className="form-label">Job Title :</label></div>
                            <div className="col-lg-6 col-md-6">
                            <label htmlFor="Location Name">{props?.jobInfo.jobTitle}</label>
                        </div></div> */}
                        <div className="row" >
                         <div className="col-lg-2 col-md-2">
                            <label className="form-label">Job descriptions :</label></div>
                            <div className="col-lg-6 col-md-6 mt-2">
                            <label dangerouslySetInnerHTML={{__html: props?.jobInfo.jobDescription }}></label>
                           
                        </div></div>
                          
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
