import moment from 'moment';
import { useState } from 'react';
import Certifications from '../ProfileBuilder/ResumeBuilder/certifications';
import Modal from './modal';
import { useDispatch, useSelector } from 'react-redux';
import { profileBuilderSelector } from '../ProfileBuilder/selector';
import useSessionStorage from '../../hooks/useSessionStorage';
import { downloadUploadedDocumentResumeBuilder, saveResumeBuilder } from '../ProfileBuilder/ResumeBuilder/action';
import { resumeBuilderSelector } from '../ProfileBuilder/ResumeBuilder/selector';
import useConfiguration from '../../hooks/useConfiguration';
import { ModalBox } from '../common/Modal/ModalBox';
import { validateResume } from '../ProfileBuilder/ResumeBuilder/customResumeValidation';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { login_roles_flags, profile_builder_flags } = useSelector(profileBuilderSelector);
  const resumeForm = useSelector(resumeBuilderSelector);
  const storage = useSessionStorage();
  const dispatch = useDispatch();
  const config = useConfiguration();
  const login = storage.getItem('login');
  const login_roles_flags_list = storage.getItem('login_roles_flags_list');
  const [certificationNotUploadAlertBox, setCertificationNotUploadAlertBox] = useState(false);
  const [resumeBuilderError, setResumeBuilderError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const editCertification = (event) => {
    event.preventDefault();
    setShowModal(!showModal);
    if (showModal) {
      props.cancel('resumeBuilder');
    }
  };



  const saveCertification = (event) => {
    event.preventDefault();
    // if (props.saveContinue('resumeBuilder', false)) {
    //   setShowModal(!showModal);
    // }
    let valid = true;
    const resumeFormValid = validateResume(resumeForm, login.jobTypeInfo, profile_builder_flags);
    if (resumeFormValid.isValid) {
    let certificationAlertFlag = null;
        // While Certificate Not Uploaded Showing the Soft Alert
        let showAlert = true;
        for (let item of resumeForm.certifications.primary) {
          if (item.expirationDate === null && item.name !== "None" && showAlert) {
            showAlert = false;
            certificationAlertFlag = true
          }
    }

    if (!certificationAlertFlag) {
      dispatch(
        saveResumeBuilder({
          url: `${config.baseUrl}`,
          candidateId: login.candidateId,
          userId: login.appUserId,
          gotoNextTab: false,
          flag: login?.jobTypeInfo?.showQATab,
        })
      );
      setShowModal(!showModal);
      setResumeBuilderError({});
    } else {
      setCertificationNotUploadAlertBox(true);
      setResumeBuilderError({});
    }
  } else {
    setResumeBuilderError(resumeFormValid.errors);
    valid = false;
    return;
  }
  };

  // Certification popup with save method
  const certificationAlertPopupWithSave = () => {
    dispatch(
      saveResumeBuilder({
        url: `${config.baseUrl}`,
        candidateId: login.candidateId,
        userId: login.appUserId,
        gotoNextTab: false,
        flag: login?.jobTypeInfo?.showQATab,
      })
    );
    setCertificationNotUploadAlertBox(false);
    setShowModal(!showModal);
    setResumeBuilderError({})
  }

  const downloadUploadedDoc = (sectionId, fieldId, fileId,blobFileName,fileName) => {
    dispatch(downloadUploadedDocumentResumeBuilder(config.baseUrl,  login.candidateUniqueId , sectionId, fieldId, fileId,blobFileName,fileName,login.accessToken));
  }


  return (
    <div className="col-md-12 col-xl-12">
      <div className="card">

      {certificationNotUploadAlertBox &&
      <ModalBox
            show={true}
            size="lg"
            center={true}
            label={<div><i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> Note :</div>}
            headerCloseButton={true}
            closebtnclassName="btn btn-primary"
            onlyClose={true}
            closeButtonName="Ok"
            showHide={certificationAlertPopupWithSave}
            children={
              <div className='d-flex flex-column'>
                <h5>
                  You can continue to create and submit your profile without uploading
                  the associated support documentation.
                </h5>
                <h5>
                  To fully complete your profile,
                  you will need to come back soon and upload documentation where
                  necessary so that our employers have all of your qualifications and
                  support documentation in your profile for their review.
                </h5>
              </div>
            }
          />} 




        {showModal && (
          <Modal
            label={'Certifications, Licenses, Permits'}
            showHide={editCertification}
            saveFunction={saveCertification}
          >
            <Certifications errors={resumeBuilderError} />
          </Modal>
        )}
        <div className="col-lg-12 col-md-12">
          <div className="row">
            <div className="card-header">
                <h4 className='text-wrap mr-2'>Certifications, Licenses, Permits</h4>
                {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                <a id="EditCertification" href="#" onClick={editCertification}>
                  <i className="fe fe-edit" title="Edit Certifications, Licenses, Permits Form"></i>
                </a> 
                : ''}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <div className="row">
              <div className="col-sm-4 col-lg-4 col-md-4">
                <h4>Certifications, Licenses, Permits</h4>
              </div>
              <div className="col-sm-4 col-lg-4 col-md-4">
                <h4>File Name </h4>
              </div>
              <div className="col-sm-4 col-lg-4 col-md-4">
                <h4>Expiration Date </h4>
              </div>
            </div>
            {props?.primary?.map((prim) => (
              <div className="row">
                <div className="col-sm-4 col-lg-4 col-md-4">
                  <label htmlFor="exampleInputname1">{prim.name}</label>
                </div>
                <div className=" col-sm-4 col-lg-4 col-md-4">
                  <label htmlFor="exampleInputname1">
                    {!!prim.uploadedCertificatesList.length &&
                    prim.uploadedCertificatesList[0].name ? (
                      <>
                       <a
                                // href={`${prim.uploadedCertificatesList[0].downloadUrl}/${prim.uploadedCertificatesList[0].blobName}`}
                                // target="_blank"
                                onClick={(e) => { downloadUploadedDoc(prim.uploadedCertificatesList[0].sectionId, prim.uploadedCertificatesList[0].id, prim.uploadedCertificatesList[0].id, prim.uploadedCertificatesList[0].blobName,prim.uploadedCertificatesList[0]?.name) }}
                                rel="noreferrer"
                              >
                              <i>
                                  {prim.uploadedCertificatesList[0].name} -
                                </i>
                                <i
                                  className="mdi mdi-arrange-bring-forward"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  data-bs-original-title="mdi-arrange-bring-forward"
                                  aria-label="mdi-arrange-bring-forward"
                                ></i>
                              </a>
                       
                      </>
                    ) : null}
                  </label>
                </div>
                <div className=" col-sm-4 col-lg-4 col-md-4">
                  <label htmlFor="exampleInputname1">
                    {prim.expirationDate? moment(prim.expirationDate).format('MM/DD/YYYY'):""}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <hr className="Line" style={{ display: 'none' }} />
          <br />
          <div className="row" style={{ display: 'none' }}>
            <div className="col-lg-4 col-md-4">
              <h3>Other Certifications, Licenses, Permits</h3>
            </div>
            <div className="col-lg-2 col-md-2">
              <a id="EditCertification" href="#" onClick={editCertification}>
                <i className="fe fe-edit" title="Edit Certifications, Licenses, Permits Form"></i>
              </a>
            </div>
          </div>
          <div className="form-group" style={{ display: 'none' }}>
            {props.others.map((other) => (
              <div className="col-lg-4 col-md-4">
                <label htmlFor="exampleInputname1">{other}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
