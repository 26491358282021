import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import requestUtil from "../../helpers/requestUtil";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { personalInfoSelector } from "../ProfileBuilder/PersonalInfo/selector";
import { preferenceSelector } from '../ProfileBuilder/Preference/selector';
import { qandAlInfoSelector } from '../ProfileBuilder/QandA/selector';
import { resumeBuilderSelector } from "../ProfileBuilder/ResumeBuilder/selector";
import { profileBuilderSelector } from "../ProfileBuilder/selector";
import { gotoNextTab, gotoPreviousTab } from "../ProfileBuilder/slice";
import Categories from "./categories";
import Certifications from "./certifications";
import Covid from "./covid";
import EducationLevel from "./educationLevel";
import Emergency from "./emergency";
import Emrehr from "./emrehr";
import Holiday from "./holiday";
import './index.css';
import PrefferedWorkdays from "./prefferedWorkdays";
import QandASummary from "./qandaSummary";
import References from "./references";
import Registration from './registration';
import ResumeUpload from "./resumeUpload";
import Shifts from "./shifts";
import Specialities from "./specialities";
import TravelDistance from "./travelDistance";
import ShiftTimeOfDay from "./shiftTimeOfDay";
import { careersAdTracking, getMarketingAppAccessToken } from "../Signup/action";
import { getMarketingBaseURL } from "../../helpers/domainUrl";
import { getCurrentResumeBuilder, getLevelBasedAPIDatas, loadMasterDatas } from "../ProfileBuilder/ResumeBuilder/action";
import { setResumeBuilderData } from "../ProfileBuilder/ResumeBuilder/slice";
import { getCurrentPersonalInfo, loadMasterData } from "../ProfileBuilder/PersonalInfo/action";
import { getMasterData, getPreference } from "../ProfileBuilder/Preference/action";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { data } = useSelector(personalInfoSelector);
    const {
        workHistory,
        data: resumeData,
        educationList,
        categories,
        certifications,
        educationLevel,
        specialities,
        emrehr,
        masterDatas: resumeMasterDatas
    } = useSelector(resumeBuilderSelector);
    const {
        preferredShifts,
        preferredShiftTimeOfTheDay,
        travelDistance,
        preferredWorkDays,
        holidayPreference,
        vaccination,
        masterDatas
    } = useSelector(preferenceSelector);
    const config = useConfiguration();
    const baseMarketingUrl = getMarketingBaseURL();
    const storage = useSessionStorage();
    const login = storage.getItem('login');
    const login_roles_flags_list = storage.getItem('login_roles_flags_list');
    const profileBuilderTabFlags = storage.getItem('profileBuilderTabFlags');
    const domainJobtypeId = storage.getItem('domainJobtypeId');
    const dispatch = useDispatch();
    const { error, errorMessage, login_roles_flags } = useSelector(profileBuilderSelector);
    const { saveContinue } = props;
    const { formdata } = useSelector(qandAlInfoSelector);
    useEffect(() => {
        window.scrollToTop && window.scrollToTop();
    }, []);
    const submitProfile = (event) => {
        event.preventDefault();
        requestUtil(`${config.baseUrl}/Candidates/savesubmitinfo`, 'POST', {
            candidateId: login.candidateId,
            appUserId: login.appUserId,
        }).then(res => {
            dispatch(gotoNextTab(login?.jobTypeId));
            if(res && res?.encryptedCandidateId){
                dispatch(getMarketingAppAccessToken({baseUrl:config?.baseUrl})).then(accessToken => {
                    if(accessToken){
                      const params = {
                        encryptedCandidateId:res?.encryptedCandidateId,
                        isProfileSubmitted:true
                      }
                        dispatch(careersAdTracking({url:`${config?.baseUrl}/MarketingApp/update-careersadtracking`,params,accessToken}))
                    }
                  })
                }
                const isSummary = {
                    ...profileBuilderTabFlags, 
                    isProfileSubmitted: true
                }
                sessionStorage.setItem('profileBuilderTabFlags', JSON.stringify(isSummary));
        }).catch(err => {
            console.log(err);
        });
    }

    const flag =  login?.profileFlag?.isProfileSubmitted ? (config.baseUrl && !resumeMasterDatas?.certifications?.length) : ''

    useEffect(() => {
        if (config.baseUrl && login_roles_flags_list?.can_edit_profile_filter?.status === false || flag) {
            dispatch(
                getCurrentResumeBuilder({
                  url: `${config.baseUrl}/Candidates/getjobinfo`,
                  userId: login.candidateId,
                }))
        
                .then((res) => {
                  if(login.jobTypeInfo.showCertifications && login.jobTypeInfo.showEmr && login.jobTypeInfo.showSpecialties) {
                    dispatch(getLevelBasedAPIDatas(config.baseUrl, login.jobTypeId,login.candidateUniqueId, res?.jobCategoryIdList, login))
                    .then(() => {
                        dispatch(setResumeBuilderData(res))
                    })
                  }
                })
        
                .then(() => {
                  dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
                    dispatch(
                      getCurrentPersonalInfo({
                        url: `${config.baseUrl}/Candidates/getpersonalinfo`,
                        userId: login.candidateId,
                      })
                    )
                  })
                 })
        
              .then(() => {
                  dispatch(
                    loadMasterDatas(
                     config.baseUrl,
                     login.jobTypeId,
                     login.candidateUniqueId
                ))
              })
                     
               .then(() => {
                dispatch(getMasterData(config.baseUrl)).then(() => {
                    dispatch(getPreference(config.baseUrl, login.candidateId));
                  });
               })
        
            //    .then((data) => {
            //     dispatch(getPreference(config.baseUrl, login.candidateId))
            //   })
        
               .then(() => {
                dispatch(
                  getCurrentResumeBuilder({
                    url: `${config.baseUrl}/Candidates/getjobinfo`,
                    userId: login.candidateId,
               }))
               })
                
        }
    },[config.baseUrl])



    useEffect(() => {
        if (config.baseUrl && !login?.profileFlag?.isProfileSubmitted && profileBuilderTabFlags?.isPreferenceInfoCompleted) {
            dispatch(loadMasterData(`${config.baseUrl}`)).then(() => {
                dispatch(
                  getCurrentPersonalInfo({
                    url: `${config.baseUrl}/Candidates/getpersonalinfo`,
                    userId: login.candidateId,
                  })
                );
              });
            dispatch(
              loadMasterDatas(
                config.baseUrl,
                login.jobTypeId,
                login.candidateUniqueId
              )
            ).then(() => {
              dispatch(
                getCurrentResumeBuilder({
                  url: `${config.baseUrl}/Candidates/getjobinfo`,
                  userId: login.candidateId,
                })
              ).then((res) => {
                if(login.jobTypeInfo.showCertifications && login.jobTypeInfo.showEmr && login.jobTypeInfo.showSpecialties) {
                  dispatch(getLevelBasedAPIDatas(config.baseUrl, login.jobTypeId,login.candidateUniqueId, res?.jobCategoryIdList, login))
                  .then(() => {
                      dispatch(setResumeBuilderData(res))
                  })
                }
              })
            });
            config.baseUrl &&
              dispatch(getMasterData(config.baseUrl)).then(() => {
                dispatch(getPreference(config.baseUrl, login.candidateId));
              });
                
        }
    },[config.baseUrl])



    return (
        <div className={"row"}>
            <div className={"col-12"}>
                <div className={"card"}>
                    <div className="card-header border-bottom-0">
                        <h3 className="card-title">Profile Builder</h3>
                    </div>
                    {
                        error &&
                        <>
                            <div className="toast wrap-input100 align-items-center text-white bg-danger border-0 show"
                                role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                                <div className="d-flex col-6">
                                    <div className="toast-body">
                                        {errorMessage || 'Save error! Please try again.'}
                                    </div>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                        </>
                    }
                    <div className={"card-body"}>
                        <div id={"ReviewPage"}>
                            <Registration {...data} saveContinue={saveContinue} errors={props.registrationErrors}
                                cancel={props.cancel} />
                            <Emergency {...data} saveContinue={saveContinue} errors={props.emergencyContactErrors}
                                cancel={props.cancel} />
                            <References {...data} saveContinue={saveContinue} errors={props.referencesErrors} aboutusError={props.aboutUsErrors}
                                cancel={props.cancel} />
                            <ResumeUpload {...resumeData} workHistory={workHistory}
                                education={educationList} saveContinue={saveContinue}
                                errors={props.resumeBuilderError}
                                educationErrors={props.educationErrors}
                                workHistoryErrors={props.workHistoryErrors} cancel={props.cancel} />
                            <Categories categories={categories} saveContinue={saveContinue}
                                errors={props.resumeBuilderError} cancel={props.cancel} />
                            {
                                login.jobTypeInfo.showSpecialties &&
                                <>
                                    <Specialities specialities={specialities} masterDatas={resumeMasterDatas}
                                        saveContinue={saveContinue} cancel={props.cancel} errors={props.resumeBuilderError} />
                                </>
                            }
                            {
                                login.jobTypeInfo.showCertifications &&
                                <>
                                    <Certifications {...certifications} saveContinue={saveContinue}
                                        errors={props.resumeBuilderError} cancel={props.cancel} />
                                </>
                            }
                            <EducationLevel educationLevel={educationLevel}
                                masterEducationLevel={resumeMasterDatas.educationLevel}
                                saveContinue={saveContinue} errors={props.resumeBuilderError}
                                cancel={props.cancel} />
                            {
                                login.jobTypeInfo.showEmr &&
                                <>
                                    <Emrehr {...emrehr} saveContinue={saveContinue} errors={props.resumeBuilderError}
                                        cancel={props.cancel} />
                                </>
                            }
                            <Shifts preferredShifts={preferredShifts} saveContinue={saveContinue}
                                errors={props.preferenceErrors} cancel={props.cancel} />
                           
                           <ShiftTimeOfDay preferredShiftTimeOfTheDay={preferredShiftTimeOfTheDay} saveContinue={saveContinue}
                                errors={props.preferenceErrors} cancel={props.cancel}/>
                               
                            <TravelDistance travelDistance={travelDistance} saveContinue={saveContinue}
                                errors={props.preferenceErrors} cancel={props.cancel} />
                            <PrefferedWorkdays {...preferredWorkDays} masterWorkdays={masterDatas.preferredWorkDaysRaw}
                                saveContinue={saveContinue} cancel={props.cancel} />
                            <Holiday {...holidayPreference} masterHolidays={masterDatas.holidayPreferenceRaw}
                                saveContinue={saveContinue} cancel={props.cancel} />
                            <Covid vaccination={vaccination && masterDatas.covidVaccine.find(vac => vac.id === vaccination).name}
                                masterVaccine={masterDatas.covidVaccine} saveContinue={saveContinue}
                                errors={props.preferenceErrors} cancel={props.cancel} />
                            {login?.jobTypeInfo?.showQATab &&
                                <QandASummary formdata={formdata} errors={props.qandAErrors} cancel={props.cancel} saveContinue={saveContinue} />
                            }
                        </div>
                    </div>
                    <div className="card-footer text-end">

                        {login_roles_flags_list?.can_edit_profile_filter?.status === true ?
                        <div className="col-lg-12 col-md-12">
                            <a href="#" className="btn btn-light float-left" onClick={(event) => {
                                event.preventDefault();
                                dispatch(gotoPreviousTab(login?.jobTypeId));
                            }}>Back</a>
                            <a href="#" className="btn btn-primary" onClick={submitProfile}>Submit Profile</a>
                        </div> : ''
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
