import React, { useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch, useSelector } from "react-redux";
import useConfiguration from '../../hooks/useConfiguration';
import useSessionStorage from '../../hooks/useSessionStorage';
import { ChatWindow } from '../common/chatWindow';
import { deleteMessageData, getPersonalMsg, sendPersonal, updateMessageStatus, updateMsgFlag } from './action';
import './index.css';
import { messaageSelector } from './selector';
import moment from 'moment/moment';

const Inbox = ({ content, isOpen, toggle, openChantWindow, messageStatusHandler, removeMessage }) => {
    return (
        <div className="">
            <div className='row'>
                {/* Toggle */}
                <div className="col-lg-6">
                    <span onClick={toggle} role="button" className='me-4'>
                        {isOpen ?
                            <i class="fa fa-angle-down" style={{ 'fontSize': '24px' }}></i>
                            :
                            <i
                                onClick={() => {
                                    if (!content?.isMessageRead) {
                                        messageStatusHandler({ status: true, readBy: content?.candidateInfo?.candidateUniqueId, msgId: content?.messageId, appMessageType: content?.appMessageType })
                                    }
                                }}
                                class="fa fa-angle-right" style={{ 'fontSize': '24px' }}></i>}</span>
                    {!content?.isMessageRead &&
                        <span className="text-center bg-danger text-white rounded-3 p-1 me-2">New</span>}
                    {content?.messageText}
                </div>

                {/* Message */}
                <div className="col-lg-3"><span>{content?.messageDateTime}</span></div>
                {/* Read / UnRead */}
                {/* <div className="col-lg-2"><span onClick={() => { openChantWindow(content?.jobInfo?.shiftUniqueId) }} style={{ cursor: "pointer" }}><i className="bi bi-reply fa-lg text-success"></i> Send a message </span></div> */}
                {/* Send a Message */}
                <div className='col-lg-2'>
                {content?.isMessageRead ?
                        <span onClick={() => messageStatusHandler({ status: false, readBy: content?.candidateInfo?.candidateUniqueId, msgId: content?.messageId, appMessageType: content?.appMessageType })} style={{ cursor: "pointer" }}
                        ><i className="bi bi-envelope-open fa-lg text-primary"></i> Read</span>
                        :
                        <span onClick={() => messageStatusHandler({ status: true, readBy: content?.candidateInfo?.candidateUniqueId, msgId: content?.messageId, appMessageType: content?.appMessageType })}><i class="bi bi-envelope fa-lg text-primary"></i> Mark as Read</span>}
                </div>
                {/* Delete */}
                <div className="col">
                    <td>
                        <i style={{ cursor: "pointer", fontSize: "1rem" }}
                            onClick={
                                () => removeMessage(content.messageId)
                            }
                            class="text-primary fe fe-trash-2" ></i>
                    </td>
                </div>
            </div>



            <Collapse in={isOpen} className="content">
                <div className='row mt-3 ms-4'>
                    <span className='fw-bolder'><h4>Job info:</h4></span>
                    <div className='row'>
                        <div className="col-md-6">
                            <label className="form-label">Job Code:</label>
                            <label>{content?.jobInfo?.jobCode}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Job Date:</label>
                            <label>{content?.jobInfo?.jobDateToDisplay}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <label className="form-label">Job Title:</label>
                            <label>{content?.jobInfo?.jobTitle}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Job Description:</label>
                            <label dangerouslySetInnerHTML={{ __html: content?.jobInfo?.jobDescription }}></label>
                            {/* <label>{content?.jobInfo?.jobDescription}</label> */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <label className="form-label">Checked In Date:</label>
                            <label>{content?.jobInfo?.checkedInDateToDisplay}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Checked Out Date:</label>
                            <label>{content?.jobInfo?.checkedOutDateToDisplay}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <label className="form-label">Shift Start Time:</label>
                            <label>{content?.jobInfo?.shiftStartTime}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Shift End Time:</label>
                            <label>{content?.jobInfo?.shiftEndTime}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <label className="form-label">Shift Hours:</label>
                            <label>{content?.jobInfo?.shiftHoursToDisplay}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Paid Date:</label>
                            <label>{content?.jobInfo?.paidDateToDisplay}</label>
                        </div>
                    </div>
                </div>
            </Collapse>
            <hr style={{
                color: 'black',
                backgroundColor: 'black',
                height: 1
            }} />
        </div>
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { chat, msgSendFlag } = useSelector(messaageSelector);
    const [isOpen, setIsOpen] = useState(null);
    const [openMessaging, setOpenMessaging] = useState(false);
    const [chatData, setChatData] = useState([]);
    const [msgParams, setmsgParams] = useState({
        "webPageId": 0,
        "shiftUniqueId": '',
        "candidateUniqueId": '',
        "employerUniqueId": ''
    })
    const dispatch = useDispatch();
    const config = useConfiguration();
    const storage = useSessionStorage();
    const login = storage.getItem("login");
    const toggleOpen = (id) => () => {
        console.log(id);
        setIsOpen((isOpen) => (isOpen === id ? null : id));
    }

    const openChantWindow = (jobsShiftUniqueId) => {
        if (jobsShiftUniqueId) {
            setmsgParams({
                webPageId: 0,
                shiftUniqueId: jobsShiftUniqueId,
                candidateUniqueId: login.candidateUniqueId,
                employerUniqueId: null,
            });
            const newparams = {
                webPageId: 0,
                shiftUniqueId: jobsShiftUniqueId,
                candidateUniqueId: login.candidateUniqueId,
                employerUniqueId: null,
            }
            dispatch(getPersonalMsg({
                url: `${config.baseUrl}/Messages/GetCandidateMessages`, params: newparams
            }));
            setOpenMessaging(!openMessaging);
        }
    }

    const removeMessage = messageId => {
        dispatch(deleteMessageData({
            url: config.baseUrl,
            messageId: messageId
        }))
    }

    const messageStatusHandler = ({ status, readBy, msgId, appMessageType }) => {
        const messageStatusParams = {
            "messageId": msgId,
            "messageReadBy": readBy,
            "isMessageRead": status,
            "shiftUniqueId": "",
            "readDate": moment(new Date()).utc().format(),
            "appMessageType": appMessageType
        }
        dispatch(updateMessageStatus({
            url: config.baseUrl,
            messageStatusParams: messageStatusParams
        }))
    }

    const sendMsg = (text) => {
        const newparams = {
            ...msgParams,
            "fromCandidate": true,
            "messageText": text
        }
        dispatch(sendPersonal({
            url: `${config.baseUrl}/Messages/SendMessageToEmployer`, params: newparams
        }));
    }

    useEffect(() => {
        if (msgSendFlag && config.baseUrl) {
            dispatch(getPersonalMsg({
                url: `${config.baseUrl}/Messages/GetCandidateMessages`, params: msgParams
            })).then(() => {
                dispatch(updateMsgFlag(false))
            });
        }
    }, [msgSendFlag])

    useEffect(() => {
        const newData = JSON.parse(JSON.stringify(chat));
        buildGroupMsg(newData)
    }, [chat]);

    const buildGroupMsg = (data) => {
        const groups = data.reduce((groups, messages) => {
            const date = messages.messageDateTime.split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(messages);
            return groups;
        }, {});

        const groupMsg = Object.keys(groups).map(date => {
            return {
                date,
                messages: groups[date]
            };
        })

        setChatData(groupMsg);
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="col-lg-4 col-md-4">
                    <h4 className="card-title">
                    Notifications
                    </h4>
                </div>
            </div>
            <div className="card-body" style={{height: '500px', overflow: 'scroll'}}>
                {props.messageData && props.messageData.length > 0 &&
                    props.messageData.map((item, index) => (
                        <Inbox
                            key={index}
                            content={item}
                            isOpen={isOpen === index}
                            toggle={toggleOpen(index)}
                            openChantWindow={openChantWindow}
                            messageStatusHandler={messageStatusHandler}
                            removeMessage={removeMessage}
                        />
                    ))
                }
                {openMessaging &&
                    <div className="message-position">
                        <ChatWindow closeChatWindow={() => {
                            setOpenMessaging(!openMessaging)
                        }}
                            chatData={chatData}
                            sendMsg={sendMsg}
                        />
                    </div>
                }
            </div>
        </div>
    )
}
