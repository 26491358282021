import {useState} from "react";

export default (props) => {
    const [pageSize] = useState(5);
    const pageData = props.pageData || {};
    const fillArray = (size, startValue, endValue) => {
        const data = new Array(size)
        for(let i=startValue; i<=endValue;i++){
            data.push(i);
        }
        return data;
    }
    const calculatePageNumbers = (currentPage, totalPages) => {
        if(totalPages <= pageSize){
            return fillArray(totalPages,1,totalPages);
        }
        if(currentPage === 1) {
            return fillArray(pageSize, 1, pageSize);
        }
        if(currentPage+5 > totalPages){
            return fillArray(pageSize,totalPages-pageSize, totalPages);
        }
        if(currentPage === totalPages){
           return fillArray(pageSize,totalPages-pageSize, totalPages);
        }
        return fillArray(pageSize, currentPage, currentPage+pageSize);
    }
    const renderPageButtons = (currentPage, totalPages) => {
        const buttons = calculatePageNumbers(currentPage, totalPages);
        const getButtonClass = (btn) => {
            return btn === currentPage ? 'btn-outline-primary' : 'btn-outline-default';
        }
        return buttons.map((btn) =>
            <button className={`btn ${getButtonClass(btn)}`} onClick={(event) =>{
                event.preventDefault();
                props.gotoPage(btn);
            }}> {btn}</button>
        )
    }
  //console.log({props,totalCountInPageBottom:props?.pageData?.total});

    return (
       <>
       {
        props?.pageData?.total ?
         <div className={"row pagination-panel-margin"}>
         <div className={"col-6"}>
             Showing <span className="bold-text">{pageData.from}</span> to <span className={"bold-text"}>{pageData.to}</span> of <span className="bold-text">{pageData.total} </span>entries
         </div>
         <div className="col-6 pagination-panel">
             <div className={"btn-group"}>
                 <button className={'btn btn-outline-default'} onClick={(event) => {
                     event.preventDefault();
                     props.firstPage();
                 }} disabled={pageData.currentPage===1}>
                     First
                 </button>
                 <button className={'btn btn-outline-default'} onClick={(event) => {
                     event.preventDefault();
                     props.previousPage();
                 }} disabled={pageData.currentPage===1}>
                     Previous
                 </button>
                 {
                     renderPageButtons(pageData.currentPage, pageData?.totalPages)
                 }
                 <button className={'btn btn-outline-default'} onClick={(event) => {
                     event.preventDefault();
                     props.nextPage();
                 }} disabled={pageData.currentPage===pageData?.totalPages}>
                     Next
                 </button>
                 <button className={'btn btn-outline-default'} onClick={(event) => {
                     event.preventDefault();
                     props.lastPage();
                 }} disabled={pageData.currentPage===pageData?.totalPages}>
                     Last
                 </button>
             </div>
         </div>
     </div>
     : ''
       }
       </>
    )
}
