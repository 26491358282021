import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Account from './components/Account';
import Activate from './components/Activate';
import AddBankAccount from './components/AddBankAccount';
import AppClearCache from './components/AppClearCache';
import AppLogout from './components/AppLogout';
import BankDetails from './components/BankDetails';
import CancelClaimHistory from './components/CancelClaimHistory/cancelclaimhistory';
import Dashboard from './components/Dashboard';
import EmployerStatus from './components/EmployerStatus';
import EncounterJobInfo from './components/EncounterJobInfo/EncounterJobInfo';
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import Maintenance from './components/Maintenance/Maintenance';
import Messages from './components/Messages';
import MultiShiftJobInfo from './components/MultiShiftJobInfo/MultiShiftJobInfo';
import MyShift from './components/MyShift/myshift';
import ProfileBuilder from './components/ProfileBuilder';
import RatingHistory from './components/RatingHistory/ratinghistory';
import ResetPassword from './components/ResetPassword';
import UnclaimedShifts from './components/Shifts/unclaimed';
import Signup from './components/Signup';
import SignupSuccess from './components/SignupSuccess';
import UnPaid from './components/UnPaid/unpaid';
import UpdatePassword from './components/UpdatePassword';
import VerifyEmail from './components/VerifyEmail';
import configJson from './conf/application.json';
import useSessionStorage from './hooks/useSessionStorage';
import MobileAuth from './components/MobileApp/MobileAuth';
import { Call } from './components/Call/call';
import { domainMappedId } from './helpers/enum';

function App() {
  // Job type value Nurses : 1 ,Security :2
  //http://localhost:3000
  //"https://accountsec.overturexl.com"
  //console.log(window.location);
  const domainLogos = {
    // 0: 'assets/images/brand/logo-3.png',
    // 1: 'assets/images/brand/logo-3.svg',
    // 2: 'assets/images/brand/logo_sec.svg',
    // 5: 'assets/images/brand/logo_yum.png',

    0: 'assets/images/brand/LOGO-XL.png',
    1: 'assets/images/brand/LOGO-XL.png',
    2: 'assets/images/brand/LOGO-SEC.png',
    5: 'assets/images/brand/LOGO-YUM.png',
    6: 'assets/images/brand/LOGO-DDS.png',
    7: 'assets/images/brand/LOGO-5STAR.png',
    8: 'assets/images/brand/LOGO-LSTYLE.png',
    9: 'assets/images/brand/LOGO-NETC.png',
    10: 'assets/images/brand/LOGO-PUREHEART.png',
  };
  const domainIcons = {
    0: 'assets/images/brand/ICON-XL.png',
    1: 'assets/images/brand/ICON-XL.png',
    2: 'assets/images/brand/ICON-SEC.png',
    5: 'assets/images/brand/ICON-YUM.jpg',
    6: 'assets/images/brand/ICON-DDS.png',
    7: 'assets/images/brand/ICON-5STAR.png',
    8: 'assets/images/brand/ICON-LSTYLE.ico',
    9: 'assets/images/brand/ICON-NETC.png',
    10: 'assets/images/brand/ICON-PUREHEART.png',
  };

  const domainTitles = {
    0: 'OvertureXL',
    1: 'OvertureXL',
    2: 'OvertureSEC',
    5: 'OvertureYUM',
    6: 'OvertureDDS',
    7: '5Star',
    8: 'Dynamic Life Style',
    9:"Nurses Etc",
    10:"Pure Heart",
  }
  // const navigateTermsConditions = {
  //   0: 'https://overturexl.com/authorized-candidate-agreement.html',
  //   1: 'https://overturexl.com/authorized-candidate-agreement.html',
  //   2: 'https://overturesec.com/authorized-candidate-agreement.html',
  //   5: 'https://overtureyum.com/authorized-candidate-agreement.html',
  //   6: 'https://overturedds.com/authorized-candidate-agreement.html'
  // };
  const navigateTermsConditions = {
    0: 'https://overturexl.com/candidate-profile-creation-agreement.html',
    1: 'https://overturexl.com/candidate-profile-creation-agreement.html',
    2: 'https://overturesec.com/candidate-profile-creation-agreement.html',
    5: 'https://overtureyum.com/candidate-profile-creation-agreement.html',
    6: 'https://overturedds.com/candidate-profile-creation-agreement.html',
    7: 'https://www.5starmedicalstaffing.com/wp-content/themes/fivestarmediia850/pdf/Policy_Statement.pdf',
    10: 'https://pureheart.overturexl.com/assets/doc/Privacy_Policy_for_PureHeartStaffing.pdf',
  };
  sessionStorage.setItem('domainLogos', JSON.stringify(domainLogos));
  sessionStorage.setItem('domainIcons', JSON.stringify(domainIcons));
  sessionStorage.setItem('domainTitles', JSON.stringify(domainTitles));
  sessionStorage.setItem(
    'termsConditions',
    JSON.stringify(navigateTermsConditions)
  );
  if (
    window.location.origin === 'https://dev.overturexl.com' ||
    window.location.origin === 'https://secjobstaging.overturexl.com'
  ) {
    //Security ID
    sessionStorage.setItem('domainJobtypeId', '2');
  } else if (window.location.origin === 'https://accountsec.overturexl.com') {
    sessionStorage.setItem('domainJobtypeId', '2');
  } else if (window.location.origin === 'https://coreapi.overturexl.com') {
    sessionStorage.setItem('domainJobtypeId', '1');
    // SEC DOMAIN
  } else if (
    window.location.origin === 'https://securityjobdev.overturexl.com' || window.location.origin === 'https://qatestcandidatesec.overturexl.com' || window.location.origin === 'https://elecansec.overturexl.com'
  ) {
    sessionStorage.setItem('domainJobtypeId', '2');
  } 
  // else if (window.location.origin === 'http://localhost:3002') {
  //   sessionStorage.setItem('domainJobtypeId', '2');
  //   // YUM DOMAIN
  // } 
  else if (window.location.origin === 'https://accountyum.overturexl.com'
    || window.location.origin === 'https://yumjobdev.overturexl.com' || window.location.origin === 'https://restjobstaging.overturexl.com' || window.location.origin === 'https://qatestcandidateyum.overturexl.com' || window.location.origin === 'https://elecanyum.overturexl.com') {
    sessionStorage.setItem('domainJobtypeId', '5');
    // DDS DOMAIN
  }
  else if (window.location.origin === 'https://accountdds.overturexl.com'
    || window.location.origin === 'https://dentaljobdev.overturexl.com' || window.location.origin === 'https://dentaljobstaging.overturexl.com' || window.location.origin === 'https://qatestcandidatedental.overturexl.com' || window.location.origin === 'https://elecandds.overturexl.com') {
    sessionStorage.setItem('domainJobtypeId', '6');
  }
  else if (window.location.origin === 'http://dev5starnurse.overturexl.com' || window.location.origin === 'https://dev5starnurse.overturexl.com'
    || window.location.origin === 'https://qatestcandidate5star.overturexl.com' || window.location.origin === 'https://5starmedicalstaffingcandidates.overturexl.com' || window.location.origin === 'https://5starstagingcandidates.overturexl.com') {
    sessionStorage.setItem('domainJobtypeId', '7');
  }
  else if (window.location.origin === 'http://dynamiccandidates.overturexl.com' || window.location.origin === 'https://dynamiccandidates.overturexl.com' ) {
    sessionStorage.setItem('domainJobtypeId', '8');
  }
  else if (window.location.origin === 'https://devcandidatenursesetc.overturexl.com' || window.location.origin === 'https://nursesetccandidates.overturexl.com' || window.location.origin === 'https://qatestcandidatenursesetc.overturexl.com' || window.location.origin === 'http://uatnursesetccandidates.overturexl.com') {
    sessionStorage.setItem('domainJobtypeId', '9');
  }
  else if (window.location.origin === 'https://pureheartcandidates.overturexl.com') {
    sessionStorage.setItem('domainJobtypeId', domainMappedId.pureHeart);
  }
  else {
    sessionStorage.setItem('domainJobtypeId', '1');
  }
  const domainJobtypeId = sessionStorage.getItem('domainJobtypeId');
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    let titleElement = document.querySelector("title");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (!titleElement) {
      titleElement = document.createElement('title');
      document.getElementsByTagName('head')[0].appendChild(titleElement);
    }
    if (link) {
      link.href = domainIcons[domainJobtypeId]
    }
    if (titleElement) {
      titleElement.innerHTML = `${domainTitles[domainJobtypeId]} Candidate Login`
    }

  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <AppLogout>
              <AppClearCache />
              <Dashboard />
            </AppLogout>
          }
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/success" element={<SignupSuccess />} />
        <Route
          path="/profile"
          element={
            <AppLogout>
              <AppClearCache />
              <ProfileBuilder />
            </AppLogout>
          }
        />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route
          path="/updatepassword"
          element={
            <AppLogout>
              <AppClearCache />
              <UpdatePassword />
            </AppLogout>
          }
        />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/activate" element={<Activate />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route
          path="/account"
          element={
            <AppLogout>
              <AppClearCache />
              <Account />
            </AppLogout>
          }
        />
        <Route
          path="/bankdetail"
          element={
            <AppLogout>
              <AppClearCache />
              <BankDetails />
            </AppLogout>
          }
        />
        <Route
          path="/addbankaccount"
          element={
            <AppLogout>
              <AppClearCache />
              <AddBankAccount />
            </AppLogout>
          }
        />
        <Route
          path="/status"
          element={
            <AppLogout>
              <AppClearCache />
              <EmployerStatus />
            </AppLogout>
          }
        />
        <Route
          path="/unclaimedshifts"
          element={
            <AppLogout>
              <AppClearCache />
              <UnclaimedShifts />
            </AppLogout>
          }
        />
        <Route
          path="/ratinghistory"
          element={
            <AppLogout>
              <AppClearCache />
              <RatingHistory />
            </AppLogout>
          }
        />
        <Route
          path="/cancelclaimhistory"
          element={
            <AppLogout>
              <AppClearCache />
              <CancelClaimHistory />
            </AppLogout>
          }
        />
        <Route
          path="/unpaid"
          element={
            <AppLogout>
              <AppClearCache />
              <UnPaid />
            </AppLogout>
          }
        />
        <Route
          path="/myshift"
          element={
            <AppLogout>
              <AppClearCache />
              <MyShift />
            </AppLogout>
          }
        />
        <Route path="*" element={<Login />} />
        {/* <Route path="*" element={<Maintenance/>} />   */}
        <Route
          path="/messages"
          element={
            <AppLogout>
              <AppClearCache />
              <Messages />
            </AppLogout>
          }
        />
        <Route
          path="/multishiftjobinfo"
          element={
            <AppLogout>
              <AppClearCache />
              <MultiShiftJobInfo />
            </AppLogout>
          }
        />
        <Route
          path="/encounterjobinfo"
          element={
            <AppLogout>
              <AppClearCache />
              <EncounterJobInfo />
            </AppLogout>
          }
        />
        <Route
          path="/mobileauthcheking"
          element={
            <AppLogout>
              <AppClearCache />
              <MobileAuth />
            </AppLogout>
          }
        />
        <Route
          path="/call"
          element={
            <AppLogout>
              <AppClearCache />
              <Call />
            </AppLogout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
