import { useState } from "react";

export default (props) => {
    return (
        <div className="col-md-12 col-xl-12">
            <div className="card">

                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="card-header">
                        <div className="col-lg-4 col-md-4 jobheader">
                                <b><h4 className="headerfontbold">EMR/EHR Systems</h4></b>                                 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <>  <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <label className="form-label">System Experience :</label></div>
                                {
                                    props?.experience?.definedExperience.map(exp => (
                                        <div className="row">
                                        <div className="col-lg-6 col-md-6"> <label>{exp.name}</label></div></div>
                                    ))
                                }
                            </div>
                            {props?.experience?.otherExperience.length?
                            <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <label className="form-label">Other EMR/EHR Systems :</label></div>
                                {
                                    props?.experience?.otherExperience.map(exp => (
                                        <div className="row">
                                        <div className="col-lg-6 col-md-6"> <label>{exp.name}</label></div></div>
                                    ))
                                }
                            </div>:''}
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
