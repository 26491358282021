import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobDetailsSelector } from "./selector";
import { getJobDetails, getJobDetailsView } from "./action";
import useConfiguration from "../../hooks/useConfiguration";
import useSessionStorage from "../../hooks/useSessionStorage";
import { useLocation, useNavigate } from "react-router-dom";
import JobLocation from "./jobLocation";
import Shift from "./shift";
import JobInfo from "./jobInfo";
import JobType from "./jobType";
import Education from "./education";
import MedicalSpeciality from "./medicalSpeciality";
import Certification from "./certification";
import EMREHRExperience from "./EMREHRExperience";
import CovidVaccine from "./covidVaccine";
import TemplateInfo from "./templateInfo";
import { claimJob, unClaimJob } from "../Shifts/action";
import ClaimedCandidateInfo from "./claimedCandidateInfo";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { jobDetails } = useSelector(jobDetailsSelector);
  const storage = useSessionStorage();
  const login = storage.getItem("login");
  const config = useConfiguration();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Job Details";
    if (config.baseUrl) {
      if (!login) {
        navigate("/");
      } else {
        dispatch(getJobDetails(config.baseUrl, props.jobId));
        // dispatch(getJobDetailsView(config.baseUrl, login.candidateUniqueId, props.jobId));
      }
    }
  }, [config]);

  const invokeClaimJob = (jobID, clientUID) => {
    dispatch(claimJob(config.baseUrl, clientUID, jobID, 0, "unclaimed"));
  };
  const invokeUnClaimJob = (jobID, clientUID, JobsClaimId) => {
    dispatch(unClaimJob(config.baseUrl, clientUID, jobID, 1, "unclaimed"));
  };
  // const invokeClaimJob = (jobID, clientUID) => {
  //   dispatch(claimJob(config.baseUrl, clientUID, jobID, 1, "claimed", jobDetails.startTime, jobDetails.endTime));
  // };
  // const invokeUnClaimJob = (jobID, clientUID) => {
  //   dispatch(unClaimJob(config.baseUrl, clientUID, jobID, 0, "unclaimed"));
  // };
  return (
    <div className="page-main">
      <div className="main-content app-content mt-0 main-background">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header">
              <h1 className="page-title">Job Details</h1>
            </div>
            <div className="row">
              <div className="col-xl-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="card-body">
                        <div className="row">
                          <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-left">
                            <div
                              className="btn-group me-2 sw-btn-group prev-margin"
                              role="group"
                            >
                              <button
                                className={`btn btn-danger sw-btn-prev`}
                                type="button"
                                onClick={props.returnToGrid}
                              >
                                Back
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <JobType jobType={jobDetails?.jobType} /> */}
                      <ClaimedCandidateInfo
                        claimedCandidateInfo={
                          jobDetails?.claimedCandidateInfo?.claimedCandidateInfo
                        }
                      />
                      <JobLocation
                        jobLocations={jobDetails?.jobLocationsList}
                      />
                      {/* <Shift shiftDetails={jobDetails?.shift} /> */}
                      <JobInfo jobInfo={jobDetails?.jobInfo} />

                      <Education educationInfo={jobDetails?.educationInfo} />
                      {login.jobTypeInfo.showSpecialties && (
                        <>
                          <MedicalSpeciality
                            speciality={jobDetails?.speciality}
                          />
                        </>
                      )}
                      {login.jobTypeInfo.showCertifications && (
                        <>
                          <Certification
                            certifications={jobDetails?.certification}
                          />
                        </>
                      )}
                      {login.jobTypeInfo.showEmr && (
                        <>
                          <EMREHRExperience
                            experience={jobDetails?.emrehrExperience}
                          />
                        </>
                      )}
                      <CovidVaccine
                        vaccination={jobDetails?.COVIDVaccinationDetails}
                      />
                      {/* <TemplateInfo templateInfo={jobDetails?.templateDetails} /> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                    {props.myShiftPage === true ? (
                      <div
                        className="btn-group mr-2 sw-btn-group-extra"
                        role="group"
                      >
                        {/* <button
                          className="btn btn-info"
                          onClick={() =>
                            invokeUnClaimJob(
                              props.jobId,
                              login.candidateUniqueId,
                              jobDetails?.claimedCandidateInfo?.JobsClaimId
                            )
                          }
                        >
                          UnClaim
                        </button> */}
                      </div>
                    ) : props.unClaimedPage === true ? (
                      <div className="btn-group me-2 sw-btn-group" role="group">
                        {/* <button
                          className="btn btn-primary sw-btn-next"
                          type="button"
                          onClick={() =>
                            invokeClaimJob(props.jobId, login.candidateUniqueId)
                          }
                        >
                          Claim
                        </button> */}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-left">
                    <div
                      className="btn-group me-2 sw-btn-group prev-margin"
                      role="group"
                    >
                      <button
                        className={`btn btn-danger sw-btn-prev`}
                        type="button"
                        onClick={props.returnToGrid}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
